import { useTranslation } from "react-i18next";
import { Lead } from "../../api/LeadsAPI";
import CONFIG from "../../config/config";
import { leadStatusToTranslationKey } from "../../shared/leadStatusToTranslationKey";
import { BolderText } from "../BolderText/BolderText";

interface LeadElementProps {
  lead: Lead;
}
  
const LeadElement: React.FC<LeadElementProps> = ({ lead }) => {
    const { t } = useTranslation();
    const countryName = CONFIG.COUNTRY_CODES.find(cc => cc.countryCode.toLowerCase() === lead.countryCode?.toLowerCase())?.countryName.toLowerCase();

    return (
        <div className="space-x-5">
            <BolderText text={`${lead.name}`} />
            {countryName && <span>🌐&nbsp;{t(countryName)}</span>}
            <span>{t(leadStatusToTranslationKey(lead.status))}</span>
        </div>
    );
};

export default LeadElement;