import InputShortText from "../InputShortText/InputShortText";
import TextArea from "../TextArea/TextArea";
import { useTranslation } from "react-i18next";
import { Employee } from "../../api/EmployeesAPI";
import { isValidText } from "../../shared/validation";
import { useFormValidation } from "../../hooks/useFormValidation";
import React, { useState } from "react";
import CONFIG from "../../config/config";
import SmallCheckBox from "../SmallCheckBox/SmallCheckBox";
import Divider from "../Divider/Divider";
import Tooltip from "../Tooltip/Tooltip";
import IconButton from "../IconButton/IconButton";
import { Icon } from "../Icon/Icon";
import trackIcon from "../../assets/images/document-chart-bar.svg";
import { useGetEmployeeHistory } from "../../hooks/useGetEmployeeHistory";
import { Modal } from "../Modal/Modal";
import { OrderHistoryStep } from "../../api/ShopClientsAPI";
import NoData from "../NoData/NoData";
import Table, { ColumnDefinition } from "../Table/Table";
import { BolderText } from "../BolderText/BolderText";
import { orderStatusToTranslationKeys } from "../../shared/orderStatusToTranslationKey";
import TinyText from "../TinyText/TinyText";
import { PermissionFlags } from "../PermissionFlags/PermissionFlags";
import PriceField from "../PriceField/PriceField";

type Props = {
  employee: Employee | null,
  onEmployeeForm?: (value: Employee) => void,
  isDisabled?: boolean,
  actionButton?: JSX.Element
};

const getInitialState = (employee: Employee | null) => {
  if (employee) {
    return {
      name: isValidText(employee.name, 3),
    };
  }
};

export type OrderColumn = ColumnDefinition<OrderHistoryStep>;

function EmployeeForm({ employee, onEmployeeForm = () => null, isDisabled = false, actionButton }: Props) {

  const MEETING_REMOVAL = 'meetingRemoval';
  const CONTRACT_SIGN = 'contractSignature';
  const SALES_REPRESENTATIVE = 'salesRepresentative';

  const { t } = useTranslation();
  const requiredFields = ['name'];
  const initialState = getInitialState(employee);
  const { updateFieldValidation, isFormValid } = useFormValidation(requiredFields, initialState);
  const contains = (empl: Employee, flag: string) => (empl?.flags || []).some(f => f.toLowerCase() === flag.toLowerCase())
  const { isLoadingHistory, employeeHistory } = useGetEmployeeHistory(employee?.id!);
  const [showHistoryModal, setHistoryModal] = useState(false);

  const disabledActionButton = actionButton
  ? React.cloneElement(
      actionButton,
      {},
      React.Children.map(actionButton.props.children, (child) => {
        return child ? React.cloneElement(child, { isDisabled: !isFormValid() }) : null;
      })
    )
  : null;

  const orderColumns: OrderColumn[] = [
    {
      key: 'workerName',
      title: t("admin.employee.form.history.name"),
      render: (step) => 
      <>
        <BolderText text={step.clientName} />
        <p className="truncate"><TinyText text={new Date(step.creationTime * 1000).toLocaleString()}/></p>
      </>
    },
    {
      key: 'status',
      title: t("admin.employee.form.history.step"),
      render: (order) => <Tooltip content={t(orderStatusToTranslationKeys(order.status))}><p className="truncate">{ t(orderStatusToTranslationKeys(order.status)) }</p></Tooltip>,
    }
  ];

  return ( 
    showHistoryModal ? 
      <Modal modalTitle={t("admin.employee.form.history")} canClose onClose={() => setHistoryModal(false)}>
        <>
          <p>{ t("admin.employee.form.history.notice") }</p>
          <div className="flex flex-col w-full space-y-5 pt-5">
            <Table<OrderHistoryStep>
              isLoading={isLoadingHistory}
              data={employeeHistory}
              columns={orderColumns}
              compareFunction={(item, selectedItem) => item.id === selectedItem.id}
              emptyListComponent={
                <NoData
                  message={t("global.empty_results")}
                />
              }
              selectedItems={[]}
            />
          </div>
        </>
      </Modal>
    :
    <>
      <div className="flex justify-end">
        <Tooltip content={t("admin.employee.form.orders")}>
          <IconButton onClick={() => setHistoryModal(v => !v)}>
            <Icon url={trackIcon} cssStyles="w-5"/>
          </IconButton>
        </Tooltip>
      </div>
      <div className="flex flex-col flex-wrap">
        <div className="flex-1">
          <InputShortText 
            label={"admin.employee.form.name"} 
            validationFn={(value) => !!value && isValidText(value, 3)} 
            onChange={e => onEmployeeForm({ ...employee, name: e.currentTarget.value } as Employee)}
            onValidationChange={(isValid) => updateFieldValidation('name', isValid)}
            errorMessage={"errors.too_short"}
            defaultValue={employee?.name || ''}
            isDisabled={isDisabled}
          />
          <Divider />
          <SmallCheckBox
            label={`${t("admin.employee.form.employed")}?`} 
            defaultValue={employee?.active}
            onChange={e => {
              onEmployeeForm({ ...employee, active: e.target.checked} as Employee);
            }}
            isDisabled={isDisabled}
          />
          <SmallCheckBox
            label={`${t("admin.employee.form.meeting_removal")}?`} 
            defaultValue={contains(employee!, MEETING_REMOVAL)}
            onChange={(e) => onEmployeeForm({ 
              ...employee, 
              flags: e.target.checked 
                ? [...employee?.flags || [], MEETING_REMOVAL] 
                : (employee?.flags || []).filter(f => f !== MEETING_REMOVAL)
              } as Employee)
            }
            isDisabled={isDisabled}
          />
          <SmallCheckBox
            label={`${t("admin.employee.form.sign_contracts")}?`} 
            defaultValue={contains(employee!, CONTRACT_SIGN)}
            onChange={(e) => onEmployeeForm({ 
              ...employee, 
              flags: e.target.checked 
                ? [...employee?.flags || [], CONTRACT_SIGN] 
                : (employee?.flags || []).filter(f => f !== CONTRACT_SIGN)
              } as Employee)
            }
            isDisabled={isDisabled}
          />
          <SmallCheckBox
            label={`${t("admin.employee.form.sales_representative")}?`}
            defaultValue={contains(employee!, SALES_REPRESENTATIVE)}
            onChange={(e) => onEmployeeForm({ 
              ...employee, 
              flags: e.target.checked 
                ? [...employee?.flags || [], SALES_REPRESENTATIVE] 
                : (employee?.flags || []).filter(f => f !== SALES_REPRESENTATIVE)
              } as Employee)
            }
            isDisabled={isDisabled}
          />
          <PermissionFlags requiredFlags={['update-employee-salaries'] || []}>
            <>
              <Divider />
              <div className="flex justify-between space-x-3">
                <PriceField 
                      label={"admin.employee.form.salary"}
                      currency={employee?.salaryCurrency || 'EUR'}
                      price={employee?.salary || 0}
                      currencies={[''].concat(CONFIG.SUPPORTED_CURRENCIES)}
                      onCurrencyChange={e => onEmployeeForm({ ...employee, salaryCurrency: e } as Employee)}
                      onPriceChange={value => onEmployeeForm({ ...employee, salary: value, salaryCurrency: employee?.salaryCurrency || 'EUR' } as Employee)}
                      isDisabled={isDisabled}
                  />
              </div>
            </>
          </PermissionFlags>
          <Divider />
          <TextArea
            label={"admin.form.notes"} 
            validationFn={(value) => !!value && isValidText(value, 0, 500)} 
            onChange={e => onEmployeeForm({ ...employee, note: e.currentTarget.value } as Employee)}
            errorMessage={"errors.too_long_short"}
            defaultValue={employee?.note || ''}
            isDisabled={isDisabled}
          />
        </div>
        { disabledActionButton }
      </div>
    </>
  );
}

export default EmployeeForm;
