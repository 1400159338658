import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import ListContainer from "../../../../components/ListContainer/ListContainer";
import ListElement from "../../../../components/ListElement/ListElement";
import { useGetShops } from "../../../../hooks/useGetShops";
import { ShopClient } from "../../../../api/ShopClientsAPI";
import PartnerElement from "../../../../components/PartnerElement/PartnerElement";
import IconButton from "../../../../components/IconButton/IconButton";
import { Icon } from "../../../../components/Icon/Icon";
import deleteIcon from "../../../../assets/images/bin.svg";
import edit from "../../../../assets/images/pen.svg";
import { Modal } from "../../../../components/Modal/Modal";
import ElementActions from "../../../../components/ElementActions/ElementActions";
import PartnerForm from "../../../../components/PartnerForm/PartnerForm";
import PrimaryButton from "../../../../components/PrimaryButton/PrimaryButton";
import { FormType } from "../../../../shared/form.type";
import ConfirmationPopup from "../../../../components/ConfirmationPopup/ConfirmationPopup";
import { PermissionFlags } from "../../../../components/PermissionFlags/PermissionFlags";
import Card from "../../../../components/Card/Card";
import Counter from "../../../../components/Counter/Counter";
import FilterPill from "../../../../components/FilterPill/FilterPill";
import Collapsable from "../../../../components/Collapsable/Collapsable";
import CONFIG from "../../../../config/config";
import * as amplitude from '@amplitude/analytics-browser';
import NoDataCTA from "../../../../components/NoDataCTA/NoDataCTA";
import PageWrapper from "../../../PageWrapper/PageWrapper";
import unlockIcon from "../../../../assets/images/unlock.svg";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import Pill from "../../../../components/Pill/Pill";

type ShopFilter = {
  countryCode?: string;
  searchText?: string;
};

const emptyPartner: ShopClient = {
  name: '',
  supportedByApp: false,
  items: [],
  addressDataAddress: '',
  addressDataName: '',
  email: '',
  fiscalDataAddress: '',
  fiscalDataNIP: '',
  fiscalDataName: '',
  notes: '',
  phone: '',
  phoneAddress: '',
  shopId: ''
}

function PartnersSection() {

  const { t } = useTranslation();

  const [filters, setFilters] = useState<ShopFilter>({});
  const [action, setAction] = useState<FormType>('READ');
  const [partner, setPartner] = useState<ShopClient | null>();
  const { isLoadingShops, shops, addFn, updateFn, removeFn } = useGetShops(filters);
  const [uniqueCountries, setUniqueCountries] = useState<{ name: string, countryCode: string }[]>([]);

  const applyFilter = (newFilter: Partial<ShopFilter>) => {
    if (filters.countryCode === newFilter.countryCode) {
        setFilters(prevFilters => ({ ...prevFilters, countryCode: undefined }));
    } else {
        setFilters(prevFilters => ({ ...prevFilters, ...newFilter }));
    }
  };


  useEffect(() => {
    if (uniqueCountries.length === 0) {
      const countryMapping = CONFIG.COUNTRY_CODES.reduce((acc: { [key: string]: string }, country) => {
        let formattedKey = country.countryName.toLowerCase();
        formattedKey = formattedKey.replace(/\s+/g, '_');
        formattedKey = formattedKey.replace(/[^a-z_]/g, '');
        acc[country.countryCode] = formattedKey;
        return acc;
      }, {});
  
      const countries = shops.reduce<{ [key: string]: { name: string, countryCode: string } }>((acc, shop) => {
        if (shop.countryCode && !acc[shop.countryCode]) {
          acc[shop.countryCode] = {
            name: countryMapping[shop.countryCode] || shop.countryCode, // Default to countryCode if not found in the mapping
            countryCode: shop.countryCode,
          };
        }
        return acc;
      }, {});
      setUniqueCountries(Object.values(countries));
    }

  }, [shops, uniqueCountries.length]);

  const actionButton = useMemo(() => {
    if (isLoadingShops) {
      return <span className="flex justify-center"><PrimaryButton text="..." isLoading/></span>;
    }
    switch (action) {
      case 'CREATE':
        return <span className="flex justify-center"><PrimaryButton text={t("global.create")!} fn={() => registerAndClose(partner!)} /></span>;
      case 'UPDATE':
        return <span className="flex justify-center"><PrimaryButton text={t("global.update")!} fn={() => registerAndClose(partner!)} /></span>;
      default:
        return;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, partner]);

  const registerAndClose = async (e: ShopClient) => {
    if (!!e) {
      if (action === 'CREATE') {
        addFn(e);
      } else {
        updateFn(e);
      }
      setPartner(null);
    }
  }

  const viewPartnerDetails = (e: ShopClient) => {
    amplitude.track('Partner details viewed', { partner: e.name });
    setAction('READ');
    setPartner(e);
  }

  const showNewEmployee = () => {
    setAction('CREATE');
    setPartner(emptyPartner);
  }

  const editPartner = (e: ShopClient) => {
    setAction('UPDATE');
    setPartner(e);
  }

  const remove = async (e: ShopClient) => {
    if (!!e) {
      removeFn(e);
      setPartner(null);
    }
  }

  return (
    <PageWrapper>
      <div className="space-y-5">
        <PermissionFlags requiredFlags={['see-partners'] || []}>
        <>
          <div className="container flex flex-col md:flex-row my-3 items-stretch space-x-0 md:space-x-5">
            <PermissionFlags requiredFlags={['create-partners'] || []}>
              <Card>
                <div className="container flex flex-wrap my-3 justify-between">
                    <PrimaryButton text={t("admin.partners.form.new_partner.button")!} fn={showNewEmployee}/>
                </div>
              </Card>
            </PermissionFlags>
            <Counter data={shops.length} label={t("admin.partners.count")}  />
          </div>
          { uniqueCountries.length > 0 && <Card>
            <Collapsable textOnExpanded={"admin.partners.filters.shops.hide"} textOnHiden={"admin.partners.filters.shops.expand"}>
              <div className="container flex flex-wrap max-h-48 overflow-y-scroll">
                {
                  uniqueCountries.map((country, i) => (
                    <FilterPill
                      isActive={filters.countryCode === country.countryCode}
                      key={i}
                      text={t(country.name)}
                      fn={() => applyFilter({ countryCode: country.countryCode })}
                    />
                  ))
                }
              </div>
            </Collapsable>
          </Card> }
          <Card>
            <div className="overflow-x-scroll w-full">
              <ListContainer 
                  isLoading={isLoadingShops}
                  elements={shops}
                  emptyListComponent={
                    <NoDataCTA
                      headline={t("admin.partners.empty.cta.title")}
                      subtext={t("admin.partners.empty.cta.body")}
                      primaryActionText={t("admin.partners.form.new_partner.button")}
                      onPrimaryAction={showNewEmployee}
                    />
                  }
                  toElementFn={(sc: ShopClient) => 
                    <ListElement onClick={() => viewPartnerDetails(sc)} key={sc.id} css="bg-white text-black hover:bg-darker-gold-100">
                      <ElementActions actions={
                        <>
                          <IconButton onClick={() => editPartner(sc)}>
                              <Tooltip content={t("global.soon")}>
                                <Icon url={unlockIcon} />
                              </Tooltip>
                          </IconButton>
                          <PermissionFlags requiredFlags={['update-partners'] || []}>
                              <IconButton onClick={() => editPartner(sc)}>
                                <Icon url={edit} cssStyles="w-5"/>
                              </IconButton>
                          </PermissionFlags>
                          <PermissionFlags requiredFlags={['remove-partners'] || []}>
                            <IconButton>
                                <ConfirmationPopup 
                                  onConfirm={() => remove(sc)} 
                                  confirmText={"global.confirm"} 
                                  title={`${t("global.remove")}: ${sc.name}`}
                                  extraContent={() => <p><Pill text={t("global.warning")} color='red'/>&nbsp;{ t("admin.partners.remove.warning") }</p>}
                                >
                                  <Icon url={deleteIcon} cssStyles="w-5"/>
                                </ConfirmationPopup>
                              </IconButton>
                          </PermissionFlags>
                        </>
                      }>
                        <PartnerElement shop={sc} />
                      </ElementActions>
                    </ListElement>
                  }
                />
            </div>
          </Card>
          { partner && 
            <Modal modalTitle={partner.name || "admin.partners.form.new_partner"} canClose onClose={() => setPartner(null)}>
              <PartnerForm partner={partner} onPartnerChange={e => setPartner(e)} actionButton={actionButton} isDisabled={action === 'READ'}/>
            </Modal>
          }
        </>
        </PermissionFlags>
      </div>
    </PageWrapper>
  );
}

export default PartnersSection;
