import { useTranslation } from 'react-i18next';
import Title from '../../components/Title/Title';
import PageWrapper from '../PageWrapper/PageWrapper';
import Card from '../../components/Card/Card';
import { AdminRestricted } from '../../components/AdminRestricted/AdminRestricted';

function PnlStatisticsPage() {

  const { t } = useTranslation();

  return (
    <PageWrapper>
      <AdminRestricted>
      <>
        <Title text={t("stats.orders")} />
        <Card>
          <Title text={t("global.soon")} />
        </Card>
      </>
      </AdminRestricted>
    </PageWrapper>
  );
}

export default PnlStatisticsPage;
