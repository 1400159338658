import { useTranslation } from "react-i18next";
import PageWrapper from "../PageWrapper/PageWrapper";
import 'react-datepicker/dist/react-datepicker.css';
import { useCallback, useState } from "react";
import { Contract } from "../../api/ContractsAPI";
import ContractTemplateEditor from "../../components/ContractTemplateEditor/ContractTemplateEditor";
import ContractDisplay from "../../components/ContractDisplay/ContractDisplay";
import { PermissionFlags } from "../../components/PermissionFlags/PermissionFlags";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import Card from "../../components/Card/Card";
import Bubbles from "../../components/Bubbles/Bubbles";
import { useContractTemplate } from "../../hooks/useContractTemplate";
import debounce from "../../shared/debounce";
import SelectInput from "../../components/SelectInput/SelectInput";
import Pill from "../../components/Pill/Pill";
import TinyText from "../../components/TinyText/TinyText";

const EMPTY_CONTRACT: Contract = {
  signatureDay: new Date().toISOString(), // YYYY-MM-DDTHH:mm:ss.sssZ
  clientFullname: '',
  clientPersonalID: '',
  clientAddress: '',
  clientPhoneNumber: '',
  clientEmail: '',
  dress: '',
  dressTrain: '',
  dressVeil: '',
  dressVeilLength: 0,
  dressDescription: '',
  dressLegCut: '',
  weddingDate: new Date().toISOString(), // YYYY-MM-DDTHH:mm:ss.sssZ
  bust: '',
  waist: '',
  hips: '',
  totalPrice: 0,
  totalPriceWording: '',
  totalPriceCurrency: '',
  instalments: [],
  endDate: new Date().toISOString(), // YYYY-MM-DDTHH:mm:ss.sssZ
  clientPickUpDate: new Date().toISOString(), // YYYY-MM-DDTHH:mm:ss.sssZ
  employeeName: '',
  language: 'PL_PL',
  firstFollowUpMeetingDate: new Date().toISOString(), // YYYY-MM-DDTHH:mm:ss.sssZ
}

function ContractsPage() {

  const { t } = useTranslation();
  const [contract, setContract] = useState<Contract>(EMPTY_CONTRACT);
  const { contractTemplate, updateContractTemplate, setLocale } = useContractTemplate();
  const [isEditing, setIsEditing] = useState(false);

  const handleLanguageChange = (newLanguage: string) => {
    setLocale(newLanguage);
    setContract(prev => ({...prev, language: newLanguage }));
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdate = useCallback(
    debounce(updateContractTemplate, 500),
    [updateContractTemplate]
  );
  
  return (
    <PageWrapper>
      <>
        <Bubbles count={10} />
        <Card>
          <>
            <div className="container flex flex-wrap my-3 justify-between">
              <PermissionFlags requiredFlags={['edit-contract-template'] || []}>
                <PrimaryButton text={isEditing ? t("contract_template.button.revert")! : t("contract_template.button")!} fn={() => setIsEditing(!isEditing)}/>
              </PermissionFlags>
            </div>
            <SelectInput values={[
              { value: 'EN_US', readableValue: 'global.english'},
              { value: 'PL_PL', readableValue: 'global.polish'},
              { value: 'PT_PT', readableValue: 'global.portuguese'},
              { value: 'ES_ES', readableValue: 'global.spanish'},
              { value: 'IT_IT', readableValue: 'global.italian'},
              { value: 'DE_DE', readableValue: 'global.german'},
              ]}
                onChange={e => handleLanguageChange(e.target.value)}
                label={"contracts.language"}
            />
            { isEditing && <div className="flex items-center mt-5 space-x-3"><Pill text="!" color="red"/><TinyText text={ t("contract_template.warning")! } /></div> }
          </>
        </Card>

        {isEditing ? (
          <ContractTemplateEditor contractTemplate={contractTemplate} updateContractTemplate={debouncedUpdate}/>
        ) :
          <ContractDisplay contract={contract} template={contractTemplate} setContract={setContract} reset={() => setContract(EMPTY_CONTRACT)}/>
        }
      </>
    </PageWrapper>
  );
}

export default ContractsPage;
