import { t } from "i18next"
import { User, UsersAPI } from "../../api/UsersAPI"
import { toastService } from "../../services/ToastService"
import SectionTitle from "../SectionTitle/SectionTitle"
import SmallCheckBox from "../SmallCheckBox/SmallCheckBox"

type PermissionSectionAction = {
    key: string,
    flags: string[],

}

type PermissionSectionSpecification = {
    ACTIONS: PermissionSectionAction[],
    SERVICE: string
}

type PermissionSectionProps = {
    user: User,
    section: PermissionSectionSpecification,
    updateUserPermissions: (uuid: string, updatedFlags: string[]) => void
}

export function PermissionSection({ user, section, updateUserPermissions }: PermissionSectionProps) {

    const handleCheckboxChange = async (e: React.ChangeEvent<HTMLInputElement>, action: PermissionSectionAction) => {
        let updatedFlags = [...user.flags];
        if(e.target.checked){
            action.flags.forEach(flag => {
                if(!updatedFlags.includes(flag)) {
                    updatedFlags.push(flag);
                }
            });
        } else {
            updatedFlags = updatedFlags.filter(flag => !action.flags.includes(flag));
        }
        try {
            updateUserPermissions(user.uuid!, updatedFlags);
            await UsersAPI.setUserPermissions(user.uuid!, updatedFlags);
        } catch(error) {
            console.error(`Error updating flags: ${error}`);
            toastService.showToast(`Could not grant/remove permission`, 'error');
        }
    };

    return (
        <div>
            <SectionTitle text={section.SERVICE} />
            {section.ACTIONS.map((act, index) => (
                <SmallCheckBox
                    key={index}
                    isDisabled={user.role === 'admin'}
                    label={`${t(act.key)}`}
                    onChange={e => handleCheckboxChange(e, act)}
                    defaultValue={act.flags.length === 0 || user.flags?.some(flag => act.flags.includes(flag))}
                />
            ))}
            <br />
        </div>
    );
}