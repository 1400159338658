import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import ShopClientsAPI, { ShopClient } from "../api/ShopClientsAPI";
import { toastService } from "../services/ToastService";

type Filters = {
  countryCode?: string;
  dress?: string;
}

type ReturnedProps = {
  isLoadingShops: boolean,
  shops: ShopClient[],
  addFn: (e: any) => Promise<any>,
  updateFn: (e: any) => Promise<any>,
  removeFn: (e: any) => Promise<any>,
  refresh: () => Promise<void>,
};

export function useGetShops({ countryCode, dress }: Filters = {}): ReturnedProps {
  
  const { t } = useTranslation();
  const [shops, setShops] = useState<ShopClient[]>([]);
  const [isLoadingShops, setIsLoading] = useState(false);

  const addFn = async (shop: ShopClient) => {
    setIsLoading(true);
    try {
      const newShop = await ShopClientsAPI.add(shop);
      setShops((prevShops: ShopClient[]) => [...prevShops, newShop.data]);
    } catch (error) {
      toastService.showToast(t("toast.errors.partners.add"), 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const updateFn = async (shop: ShopClient) => {
    setIsLoading(true);
    try {
      const updatedShop = await ShopClientsAPI.update(shop.id!, shop);
      setShops((prevShops) =>
        prevShops.map((s) =>
          s.id === shop.id ? updatedShop.data : s
        )
      );
    } catch (error) {
      toastService.showToast(t("toast.errors.partners.update"), 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const removeFn = async (shop: ShopClient) => {
    try {
      await ShopClientsAPI.delete(shop.id!);
      setShops((prevShops) =>
        prevShops.filter((s) => s.id !== shop.id)
      );
    } catch (error) {
      toastService.showToast(t("toast.errors.partners.remove"), 'error');
    }
  };

  const load = useCallback(() => {
    const asyncLoad = async () => {
      setIsLoading(true);
      try {
        const shopsRaw = await ShopClientsAPI.get({countryCode, dress});
        const shopsApi = shopsRaw.data;
        setShops(shopsApi);
      } catch (err) {
        console.error(t("toast.errors.partners.load"));
      }
      setIsLoading(false);
    };
    asyncLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode, dress]);

  useEffect(load, [load]);
  
  const refresh = useCallback(async () => {
    load();
  }, [load]);
  
  return { isLoadingShops, shops, addFn, updateFn, removeFn, refresh };
};
