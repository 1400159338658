import CONFIG from '../config/config';
import { instanceWithV2 as APIClient } from './SimpleAPIClient';

type ReferenceType = 'METERS' | 'UNITS';

export type StockStatus = {
  alarmThreshold: number,
  availability: number,
  id: number,
  name: string,
  newOrderDate: string | null,
  notes: string | null,
  orderedNewBatch: boolean,
  referenceId: string,
  total: number,
  type: ReferenceType
}

export type Reference = {
  id: number,
  name: string,
  notes: string | null,
  pictureUrl: string,
  providerId: number,
  referenceId: string,
  tags: string[],
  type: ReferenceType
}

type BaseProvider = {
  id: number,
  name: string
}

export type Provider = BaseProvider & {
  references: Reference[],
  stockStatuses: StockStatus[]
}

export type SummaryProvider = BaseProvider & {
  noOfReferences: number
}

export type StockOverview = {
  lowStock: any[],
  providers: SummaryProvider[]
}

const StockAPI = {
  get: () => APIClient(CONFIG.API_ENDPOINT).get<StockOverview>(`/v1/production`),
  getStockBy: (providerId: number) => APIClient(CONFIG.API_ENDPOINT).get<Provider>(`/v1/production/details/${providerId}`),
  update: (referenceId: number, reference: StockStatus) => APIClient(CONFIG.API_ENDPOINT).put<StockStatus>(`/v1/references/stock/${referenceId}`, reference),
};

export default StockAPI;
