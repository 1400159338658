import { useState } from "react";
import InputPassword from "../InputPassword/InputPassword";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import { Auth } from 'aws-amplify';
import { isValidPassword } from "../../shared/validation";
import { useTranslation } from "react-i18next";

type ChangePasswordFormProps = {
    username: string,
    onPasswordChanged: () => void
}

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({ username, onPasswordChanged }) => {
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [error, setError] = useState('');
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
  
    const handleChangePassword = async (event: React.FormEvent<HTMLFormElement>) => {
      setIsLoading(true);
      try {
        const user = await Auth.signIn(username, password);
        await Auth.completeNewPassword(user, newPassword);
        onPasswordChanged();
      } catch (error: any) {
        setError("errors.on_change_password");
      } finally {
        setIsLoading(false);
      }
    };

  return (
    <form className="bg-white p-6 shadow-md rounded w-full md:w-96">
      <div className="mb-4">
        <InputPassword 
          validationFn={(value) => !!value && value.length > 3} 
          onChange={e => setPassword(e.target.value)}
          errorMessage={"errors.too_short"}
          label={t("registration.change_password.current")!}
        />
      </div>
      <div className="mb-4">
        <InputPassword 
          validationFn={(value) => isValidPassword(value)} 
          onChange={e => setNewPassword(e.target.value)}
          errorMessage={"errors.password_format"}
          label={t("registration.change_password.new")!}
        />
      </div>
      {error && <p className="text-red-500 text-xs italic mb-4">{t(error)}</p>}
      <br/>
      <PrimaryButton text={t("registration.change_password")!} fn={handleChangePassword} isDisabled={isLoading} actionName="Change Password Button"/>
    </form>
  )
}

export default ChangePasswordForm;
