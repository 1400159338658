import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ShopClient } from "../../api/ShopClientsAPI";
import CONFIG from "../../config/config";
import { BolderText } from "../BolderText/BolderText";
import Tooltip from "../Tooltip/Tooltip";

type Props = {
  shop: ShopClient,
  actions?: JSX.Element
};

function PartnerElement({shop, actions}: Props) {

  const { t } = useTranslation();
  const notesIcon = useMemo(() => shop.notes? <Tooltip content={shop.notes}>📝</Tooltip> : null, [shop.notes]);
  const ipadIcon = useMemo(() => shop.supportedByApp? <Tooltip content="Integrated with iPad">📈</Tooltip> : null, [shop.supportedByApp]);
  const country = useMemo(
    () => shop.countryCode? <span>🌐&nbsp;{t(CONFIG.COUNTRY_CODES.filter(cc => cc.countryCode.toLowerCase() === shop.countryCode?.toLocaleLowerCase())[0]?.countryName?.toLowerCase())}</span> : null, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shop.countryCode]
  );
  
  return ( 
    <>
        <div className="flex justify-between flex-1 px-5 items-center">
          <span className="flex space-x-3 justify-center items-center">
            <p><BolderText text={ shop.name}/></p>
            { country }
            { notesIcon }
            { ipadIcon }
          </span>
          <p>{ shop.items?.length || 0} { t("admin.partners.products") }</p>
        </div>
        <span className="flex">
          { actions }
        </span>
    </>
  );
}

export default PartnerElement;
