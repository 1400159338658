import { useTranslation } from "react-i18next";

export const LoadingScreen = () => {

    const { t } = useTranslation();

    return (
        <div className="flex justify-center items-center h-screen">
            <div className="text-center">
                <div className="mx-auto mb-4 w-20 h-20 bg-gray-200 rounded-full flex items-center justify-center animate-pulse">
                    <p className="text-gray-500"></p>
                </div>
                <p className="animate-blink text-lg font-semibold">{t("global.loading")}</p>
            </div>
        </div>
    );
};
