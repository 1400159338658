import { useTranslation } from "react-i18next";
import PageWrapper from "../PageWrapper/PageWrapper";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import GeneralSegment from "./sections/GeneralSegment";
import PartnersSegment from "./sections/PartnersSegment";
import AccountsSegment from "./sections/AccountsSegment";
import Tab from "../../components/Tab/Tab";
import AlertsSegment from "./sections/AlertsSegment";
import { AdminRestricted } from "../../components/AdminRestricted/AdminRestricted";
import PlanPage from "../PlanPage/PlanPage";
import NotificationsSegment from "./sections/NotificationsSegment";

type AvailableSections = 'general' | 'accounts' | 'external-partners' | 'plan' | 'alerts' | 'notifications';

function SettingsPage() {

  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const [activeSection, setActiveSection] = useState<AvailableSections>('general');

  const activeSegment = useMemo(() => {
    switch(activeSection) {
      case 'external-partners':
        return <PartnersSegment />;
      case 'accounts':
        return <AccountsSegment />;
      case 'general':
        return <GeneralSegment />;
      case 'alerts':
        return <AlertsSegment />;
      case 'notifications':
        return <NotificationsSegment />;
      case 'plan':
          return <PlanPage />;
      default:
        return null;
    }
  }, [activeSection]);

  useEffect(() => {
    const tab = searchParams.get("activeTab");
    if (!!tab) {
      setActiveSection(tab as AvailableSections);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper>
      <AdminRestricted>
        <>
          <div className="flex overflow-x-scroll w-full">
            <Tab fn={() => setActiveSection('general')} label={t("settings.menu.general")} isActive={activeSection === 'general'}/>
            <Tab fn={() => setActiveSection('accounts')} label={t("settings.menu.accounts")} isActive={activeSection === 'accounts'}/>
            <Tab fn={() => setActiveSection('alerts')} label={t("settings.menu.alerts")} isActive={activeSection === 'alerts'}/>
            <Tab fn={() => setActiveSection('notifications')} label={t("settings.menu.notifications")} isActive={activeSection === 'notifications'}/>
            {/* <Tab isDisabled fn={() => setActiveSection('external-partners')} label={t("settings.menu.partners")} isActive={activeSection === 'external-partners'}/> */}
            {/* <Tab fn={() => setActiveSection('plan')} label={t("settings.menu.plan")} isActive={activeSection === 'plan'}/> */}
          </div>
          { activeSegment }
        </>
      </AdminRestricted>
    </PageWrapper>
  );
}

export default SettingsPage;
