import React from 'react';
import SmallCheckBox from '../components/SmallCheckBox/SmallCheckBox';

type SelectableProps = {
  onSelect?: (selectedItem: object | null) => void;
  isSelected?: boolean;
};

export function withSelectable<P extends object>(WrappedComponent: React.ComponentType<P>) {
  return function (props: P & SelectableProps) {
    const handleSelect = () => {
      props.onSelect?.(props);
    };

    return (
        <>
            <SmallCheckBox onChange={handleSelect} defaultValue={props.isSelected}/>
            &nbsp;
            <WrappedComponent {...props} />
        </>
    );
  };
}

