import { useCallback } from 'react';
import Pica from 'pica';
import { toastService } from '../services/ToastService';
import { isLargeFile, shouldBePreCompressed, urlAndS3Encode } from '../shared/fileUtils';
import FilesAPI from '../api/FilesAPI';
import { simplifyUrl } from '../shared/urlUtils';
import { useTranslation } from 'react-i18next';

type TransformerType = (resp: any) => any;

const pica = Pica();

const compressImage = async (file: File) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.src = URL.createObjectURL(file);
    await new Promise(resolve => img.onload = resolve);  // Ensure the image is fully loaded

    let quality = 0.55;
    let resultFile = file;

    while (shouldBePreCompressed(resultFile) && quality > 0.05) {
        console.log(`New size: ${resultFile.size}`);
        const targetWidth = img.width * 0.75;
        const targetHeight = img.height * 0.75;
        canvas.width = targetWidth;
        canvas.height = targetHeight;
        ctx?.drawImage(img, 0, 0, targetWidth, targetHeight);

        const blob = await pica.toBlob(canvas, file.type, quality);
        resultFile = new File([blob], file.name, { type: file.type });
        quality -= 0.1;
    }
    return resultFile;
};

export function useImageUploader(
  apiEndpoint: string = 'files/collections/uploadV2',
  transformer: TransformerType = resp => resp.data.url,
  domain?: string
  ) {

  const { t } = useTranslation();

  const uploadImage = useCallback(async (file: File, endpointPath?: string, optBody: any = {}) => {
    const fileWithS3Encoding = urlAndS3Encode(file.name);
    const displayFileName = decodeURI(fileWithS3Encoding);
    try {
      if (domain === "catalogues" && shouldBePreCompressed(file)) {
        file = await compressImage(file);
      }

      if (isLargeFile(file)) {
        const uploadUrl = (await FilesAPI.getUploadUrl(fileWithS3Encoding, domain)).data.url;
        const response = await FilesAPI.uploadHeavyFile(uploadUrl, file, fileWithS3Encoding);
        if (response.status === 200) {
          return simplifyUrl(uploadUrl);
        } else {
          throw new Error();
        }
      } else {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('requestBody', new Blob([JSON.stringify(optBody)], { type: 'application/json' }));
        const response = await FilesAPI.upload(`/v1/${endpointPath ? endpointPath : apiEndpoint}`, fileWithS3Encoding, formData);
        toastService.showToast(`${t("toast.success.add_file")}: ${displayFileName}.`);
        return transformer(response)
      }
    } catch (error) {
      toastService.showToast(`${t("toast.errors.add_file")}: ${displayFileName}.`, 'error');
      throw error;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiEndpoint, transformer, domain]);

  return { uploadImage };
}
