import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { PermissionFlags } from '../PermissionFlags/PermissionFlags';
import expandRight from '../../assets/images/chevron-double-right.svg';
import expandLeft from '../../assets/images/chevron-double-left.svg';
import { Icon } from '../Icon/Icon';

interface SubmenuItem {
  text: string;
  href?: string;
  onClick?: () => void;
  isDisabled?: boolean;
  flags?: string[];
  icon?: React.ReactNode;
  name?: string;
}

interface MenuItem {
  text: string;
  href?: string;
  name?: string;
  isDisabled?: boolean;
  flags?: string[];
  submenuItems?: SubmenuItem[];
  icon?: React.ReactNode;
}

interface Props {
  menuItems: MenuItem[];
  bottomMenuItems: MenuItem[];
}

const SIDEBAR_SAVING = 'sidebar-collapsed';

const Sidebar: React.FC<Props> = ({ menuItems, bottomMenuItems }) => {
  const [openSubmenus, setOpenSubmenus] = useState<{ [key: number]: boolean }>({});
  const [openSubmenusBottom, setOpenSubmenusBottom] = useState<{ [key: number]: boolean }>({});
  const [isCollapsed, setIsCollapsed] = useState<boolean>(() => {
    const savedState = localStorage.getItem(SIDEBAR_SAVING);
    return savedState ? JSON.parse(savedState) : false;
  });
  const location = useLocation();

  const isActive = (href: string) => {
    return location.pathname === href;
  }

  const toggleSubmenu = (index: number) => {
    setOpenSubmenus(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  const toggleSubmenuBottom = (index: number) => {
    setOpenSubmenusBottom(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  const toggleSidebar = () => {
    const newCollapsedState = !isCollapsed;
    setIsCollapsed(newCollapsedState);
    localStorage.setItem(SIDEBAR_SAVING, JSON.stringify(newCollapsedState));
  };

  return (
    <aside className={`hidden md:flex absolute md:relative ${isCollapsed ? 'w-16' : 'w-64'} p-4 px-7 flex flex-col h-screen z-5 shadow-sm overflow-y-auto`}>
      <div className="flex-1 flex flex-col justify-between">
        <nav className={`fixed left-0 ${isCollapsed ? 'w-15' : 'w-52'} p-4 h-[calc(100vh-300px)] overflow-y-auto`}>
          <ul>
            {menuItems.map((item, index) => (
              <PermissionFlags requiredFlags={item.flags} key={index}>
                <li key={index} className="mb-4">
                  {item.submenuItems ? (
                    <>
                      <button
                        onClick={() => toggleSubmenu(index)}
                        className={`w-full flex items-center p-2 rounded focus:outline-none ${
                          item.isDisabled 
                            ? 
                            'cursor-not-allowed text-gray-400' : 
                            'hover-underline-animation cursor-pointer'
                        }`}
                      >
                        {item.icon}
                        {!isCollapsed && 
                        <>
                          <span className="ml-2">{item.text}</span>
                          <span className={`ml-auto transition-transform ${openSubmenus[index] ? 'transform rotate-90' : ''}`}>
                            ►
                          </span>
                        </>
                        }
                      </button>
                      {openSubmenus[index] && (
                        <ul className={`${isCollapsed ? '' : 'ml-6'} mt-2`}>
                          {item.submenuItems.map((subItem, subIndex) => (
                            <PermissionFlags requiredFlags={subItem.flags} key={subIndex}>
                              <li key={subIndex} className="mb-2">
                                {subItem.isDisabled ? (
                                  <span
                                    className="block p-2 rounded text-gray-400 cursor-not-allowed"
                                  >
                                    {subItem.icon}
                                    {!isCollapsed && <span className="ml-2">{subItem.text}</span>}
                                  </span>
                                ) : (
                                  <Link
                                    to={subItem.href || '#'}
                                    className={`block p-2 rounded ${subItem.isDisabled 
                                      ? 'text-gray-400 cursor-not-allowed' 
                                      : `hover-underline-animation ${
                                        isActive(subItem.href!) ? 'font-bold' : ''
                                      }`}`
                                    }
                                    onClick={subItem.onClick}
                                  >
                                    {subItem.icon}
                                    {!isCollapsed && <span className="ml-2">{subItem.text}</span>}
                                  </Link>
                                )}
                              </li>
                            </PermissionFlags>
                          ))}
                        </ul>
                      )}
                    </>
                  ) : (
                    item.isDisabled ? (
                      <span
                        className="block p-2 rounded text-gray-400 cursor-not-allowed"
                      >
                        {item.icon}
                        {!isCollapsed && <span className="ml-2">{item.text}</span>}
                      </span>
                    ) : (
                      <Link
                        to={item.href || '#'}
                        className={`block p-2 rounded ${item.isDisabled ? 'text-gray-400 cursor-not-allowed' : `hover-underline-animation ${
                          isActive(item.href!) ? 'font-bold' : ''
                        }`}`}
                      >
                        {item.icon}
                        {!isCollapsed && <span className="ml-2">{item.text}</span>}
                      </Link>
                    )
                  )}
                </li>
              </PermissionFlags>
            ))}
          </ul>
        </nav>
        <nav className={`fixed bottom-0 left-0 ${isCollapsed ? 'w-15' : 'w-52'} p-4`}>
          <ul>
            {bottomMenuItems.map((item, index) => (
              <PermissionFlags requiredFlags={item.flags} key={index}>
                <li key={index} className="mb-4">
                  {item.submenuItems ? (
                    <>
                      <button
                        onClick={() => toggleSubmenuBottom(index)}
                        className={`w-full flex items-center p-2 rounded focus:outline-none ${
                          item.isDisabled ? 'cursor-not-allowed text-gray-400' : 'hover-underline-animation cursor-pointer'
                        }`}
                      >
                        {item.icon}
                        {!isCollapsed &&
                          <>
                            <span className="ml-2">{item.text}</span>
                              <span className={`ml-auto transition-transform ${openSubmenusBottom[index] ? 'transform rotate-90' : ''}`}>
                                ►
                              </span>
                          </>
                        }
                      </button>
                      {openSubmenusBottom[index] && (
                        <ul className={`${isCollapsed ? '' : 'ml-6'} mt-2`}>
                          {item.submenuItems.map((subItem, subIndex) => (
                            <PermissionFlags requiredFlags={subItem.flags} key={subIndex}>
                              <li key={subIndex} className="mb-2">
                                {subItem.isDisabled ? (
                                  <span
                                    className="block p-2 rounded text-gray-400 cursor-not-allowed"
                                  >
                                    {subItem.icon}
                                    {!isCollapsed && <span className="ml-2">{subItem.text}</span>}
                                  </span>
                                ) : (
                                  <Link
                                    to={subItem.href || '#'}
                                    className={`block p-2 rounded ${subItem.isDisabled 
                                      ? 'text-gray-400 cursor-not-allowed' 
                                      : `hover-underline-animation ${
                                        isActive(subItem.href!) ? 'font-bold' : ''
                                      }`}`
                                    }
                                    onClick={subItem.onClick}
                                  >
                                    {subItem.icon}
                                    {!isCollapsed && <span className="ml-2">{subItem.text}</span>}
                                  </Link>
                                )}
                              </li>
                            </PermissionFlags>
                          ))}
                        </ul>
                      )}
                    </>
                  ) : (
                    item.isDisabled ? (
                      <span
                        className="block p-2 rounded text-gray-400 cursor-not-allowed"
                      >
                        {item.icon}
                        {!isCollapsed && <span className="ml-2">{item.text}</span>}
                      </span>
                    ) : (
                      <Link
                        to={item.href || '#'}
                        className={`block p-2 rounded ${item.isDisabled ? 'text-gray-400 cursor-not-allowed' : `hover-underline-animation ${
                          isActive(item.href!) ? 'font-bold' : ''
                        }`}`}
                      >
                        {item.icon}
                        {!isCollapsed && <span className="ml-2">{item.text}</span>}
                      </Link>
                    )
                  )}
                </li>
              </PermissionFlags>
            ))}
          </ul>
          <div className={`p-1 w-full flex ${isCollapsed ? 'justify-start' : 'justify-end'} focus:outline-none`}>
            { isCollapsed ? 
              <Icon url={expandRight} onClick={toggleSidebar}/>
              : 
              <Icon url={expandLeft} onClick={toggleSidebar}/>
            }
          </div>
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;