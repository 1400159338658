import ReactDOM from 'react-dom/client';
import './index.css';
import './i18nextConf';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import '../node_modules/fullcalendar-reactwrapper/dist/css/fullcalendar.min.css'
import { registerLocale } from "react-datepicker";
import pl from 'date-fns/locale/pl';
import * as amplitude from '@amplitude/analytics-browser';
import CONFIG from './config/config';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from './store/store';
import { datadogRum } from '@datadog/browser-rum';

registerLocale('pl', pl);

Amplify.configure({
    Auth: {
      region: 'eu-west-1b',
      userPoolId: 'eu-west-1_NAF3GwVTx',
      userPoolWebClientId: '4nkp7cfnnlg6k3ph5matjfb4g2'
    }
});

amplitude.init(CONFIG.AMPLITUDE, {
  // serverZone: 'EU',
  flushQueueSize: 30, // flush queued events when there are 30 or more
  flushIntervalMillis: 10000, // flush queued events every 1 seconds
  useBatch: true //use batch mode with batch API endpoint, `https://api2.amplitude.com/batch`
});

const isLocalhost = () => {
  return window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
};

datadogRum.init({
  version: CONFIG.VERSION || 'Unknown',
  applicationId: 'a837cf2d-a14d-4070-9b9f-e53c5e0995c6',
  clientToken: 'pubeb3c97a8fd3e5af416e5a7e48f663645',
  site: 'datadoghq.eu',
  service: 'web',
  env: isLocalhost() ? 'local' : 'prod',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <Provider store={store}>
    <BrowserRouter>
    <App/>
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
