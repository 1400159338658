import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import MeetingsAPI, { Meeting } from '../../api/MeetingsAPI';
import { MeetingType, meetingTypeToTranslationKey } from '../../shared/meetingTypeToTranslationKey';
import { Modal } from '../Modal/Modal';
import PrimaryButton from '../PrimaryButton/PrimaryButton';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onPrint: (startDate: Date, endDate: Date, meetingTypes: string[]) => void;
  meetingTypes: string[];
};

const PrintModal: React.FC<Props> = ({ isOpen, onClose, onPrint, meetingTypes }) => {
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);

  const saveAsFile = (content: string, filename: string) => {
    const element = document.createElement("a");
    const file = new Blob([content], { type: "text/plain" });
  
    element.href = URL.createObjectURL(file);
    element.download = filename;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };
  
  const fetchMeetingsAndGenerateTextFile = async () => {
    const meetingsByType: Record<string, Meeting[]> = {};
  
    const fetchMeetingsByType = async (type: string) => {
      const { data: meetings } = await MeetingsAPI.get(
        startDate!.toISOString(),
        endDate!.toISOString(),
        type
      );
      meetingsByType[type] = meetings;
    };
  
    const promises = selectedTypes.map((type) => fetchMeetingsByType(type));
    await Promise.all(promises);

    const days: Record<string, Record<string, Meeting[]>> = {};

    for (const type in meetingsByType) {
      const meetings = meetingsByType[type].sort((a, b) => {
        return a.startTime < b.startTime ? -1 : (a.startTime > b.startTime ? 1 : 0);
      });

      meetings.forEach((meeting) => {
        const day = meeting.startTime.split("T")[0];

        if (!days[day]) {
          days[day] = {};
        }

        if (!days[day][type]) {
          days[day][type] = [];
        }

        days[day][type].push(meeting);
      });
    }

    const getDayOfWeek = (date: string): string => {
      const dayNames = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
      const dayIndex = new Date(date).getDay();
      return dayNames[dayIndex];
    };
  
    const formatDate = (date: string): string => {
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    };

    let content = "";

    const sortedDays = Object.keys(days).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

    sortedDays.forEach(day => {
      const weekDay = getDayOfWeek(day);
      content += `${t(`global.${weekDay}`)} - ${formatDate(day)}\n`;
      content += "------------------------\n";

      const dayMeetings = days[day];
      for (const type in dayMeetings) {
        content += `${t(meetingTypeToTranslationKey(type as MeetingType))}\n`;

        const meetingsContent = dayMeetings[type].reduce((acc, meeting) => {
          const dateObject = new Date(meeting.startTime);
          const localHours = String(dateObject.getHours()).padStart(2, '0');
          const localMinutes = String(dateObject.getMinutes()).padStart(2, '0');
          const time = `${localHours}:${localMinutes}`;
          return acc + `${time}: ${meeting.customerSurname}, ${meeting.customerName}\n`;
        }, "");

        content += meetingsContent;
        content += "\n";
      }

      content += "\n";
    });

    saveAsFile(content, "meetings.txt");
  };  

  const handleTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const type = e.target.value;
    if (e.target.checked) {
      setSelectedTypes([...selectedTypes, type]);
    } else {
      setSelectedTypes(selectedTypes.filter((t) => t !== type));
    }
  };

  const handlePrint = () => {
    if (!startDate || !endDate || selectedTypes.length === 0) {
        return;
    }

    fetchMeetingsAndGenerateTextFile();
  };

  return (isOpen? 
    <Modal
      modalTitle={t("calendar.meetings.print")}
      canClose={true}
      onClose={onClose}
      children={
        <div className="flex flex-col space-y-4">
          <div className="flex space-x-4">
            <DatePicker
              selected={startDate}
              onChange={(date: Date | [Date | null, Date | null] | null) => setStartDate(date as Date)}
              placeholderText={t("calendar.meetings.print.form.start")!}
              className="w-full border border-gray-300 rounded p-2"
            />
            <DatePicker
              selected={endDate}
              onChange={(date: Date | [Date | null, Date | null] | null) => setEndDate(date as Date)}
              placeholderText={t("calendar.meetings.print.form.end")!}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>
          <div className="flex flex-col mt-4">
            {meetingTypes.map((type, index) => (
              <label key={index} className="inline-flex items-center space-x-2">
                <input
                  type="checkbox"
                  value={type}
                  checked={selectedTypes.includes(type)}
                  onChange={handleTypeChange}
                  className="rounded"
                />
                <span>{t(meetingTypeToTranslationKey(type as MeetingType))}</span>
              </label>
            ))}
          </div>
          <span className="flex justify-center">
            <PrimaryButton text={t("calendar.meetings.print")!} fn={handlePrint} isDisabled={!startDate || !endDate || selectedTypes.length === 0}/>
          </span>
        </div>
      }
    /> : null
  );
};

export default PrintModal;
