import * as amplitude from '@amplitude/analytics-browser';
import { PulseLoader } from 'react-spinners';
import Button from "../Button/Button";

type Props = {
  text: string | JSX.Element,
  isDisabled?: boolean,
  fn?: (a?: any) => void,
  eventName?: string,
  isLoading?: boolean,
  actionName?: string
};

function PrimaryButton(
  { 
    text, 
    fn = () => null, 
    isDisabled = false, 
    eventName = '', 
    isLoading = false,
    actionName = 'Primary Button'
  }: Props) {

  const track = (e: any) => {
    if (eventName) amplitude.track(eventName);
    fn();
  }

  const buttonStyles = `
    ${isDisabled ? 'bg-gray-300 cursor-not-allowed' : 'bg-darker-gold-300 hover:bg-darker-gold-100'} 
    text-white hover:text-black font-semibold py-2 px-4 border border-gold-700 rounded 
    transition ease-in-out duration-300
    w-full sm:w-auto sm:px-10 md:px-12 lg:px-16 xl:px-20
    h-12 sm:h-14
    inline-flex items-center justify-center whitespace-nowrap
  `;

  return (
    isLoading ? <PulseLoader color="#fdd1d1" /> : <Button 
      fn={track} 
      text={text} 
      isDisabled={isDisabled}
      cssStyles={buttonStyles}
      actionName={actionName}
    />
  );
}

export default PrimaryButton;
