import { useEffect, useState } from "react";
import StockAPI, { Provider, SummaryProvider } from "../api/StockAPI";

export enum SORT { DELIVERY_ASC };

type ReturnedProps = {
  isLoading: boolean,
  providers?: SummaryProvider[],
  lowStock?: any[],
  provider?: Provider,
  loadDetails: (id: number) => void,
  isLoadingDetails: boolean,
};

export function useGetStock(): ReturnedProps {
  
  const [provider, setProvider] = useState<Provider>();
  const [providers, setProviders] = useState<SummaryProvider[]>();
  const [lowStock, setLowStock] = useState<any[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);

  const loadDetails = async (id: number) => {
    if (provider && provider.id === id) {
      setProvider(undefined);
    } else {
      setIsLoadingDetails(true);
      const raw = await StockAPI.getStockBy(id);
      setProvider(raw.data);
      setIsLoadingDetails(false);
    }
  }

  const load = () => {
    const asyncLoad = async () => {
      setIsLoading(true);
      try {
        const raw = await StockAPI.get();
        const api = raw.data;
        
        setProviders(api.providers);
        setLowStock(api.lowStock);
      } catch (err) {
        console.error("Could not load stock");
      }
      setIsLoading(false);
    };
    asyncLoad();
  }

  useEffect(load, []);
  
  return { isLoading, providers, lowStock, provider, loadDetails, isLoadingDetails};
};