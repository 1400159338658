import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ConfirmationPopup from "../../components/ConfirmationPopup/ConfirmationPopup";
import IconButton from "../../components/IconButton/IconButton";
import ListContainer from "../../components/ListContainer/ListContainer";
import ListElement from "../../components/ListElement/ListElement";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import PageWrapper from "../PageWrapper/PageWrapper";
import deleteIcon from "../../assets/images/bin.svg";
import addMeetingIcon from "../../assets/images/add-meeting.svg";
import editIcon from "../../assets/images/pen.svg";
import Card from "../../components/Card/Card";
import { Icon } from "../../components/Icon/Icon";
import { Modal } from "../../components/Modal/Modal";
import { FormType } from "../../shared/form.type";
import Collapsable from "../../components/Collapsable/Collapsable";
import FilterPill from "../../components/FilterPill/FilterPill";
import ElementActions from "../../components/ElementActions/ElementActions";
import CustomerForm from "../../components/CustomerForm/CustomerForm";
import CustomersAPI, { Customer } from "../../api/CustomersAPI";
import { useGetCustomers } from "../../hooks/useGetCustomers";
import EmployeesAPI, { Employee } from "../../api/EmployeesAPI";
import { customerStatusToPillColor, customerStatusToTranslationKey } from "../../shared/customerStatusToTranslationKey";
import { Autocomplete } from "../../components/Autocomplete/Autocomplete";
import SimpleListElement from "../../components/SimpleListElement/SimpleListElement";
import { PermissionFlags } from "../../components/PermissionFlags/PermissionFlags";
import Pill from "../../components/Pill/Pill";
import { CancelToken } from "../../api/SimpleAPIClient";
import MeetingForm from "../../components/MeetingForm/MeetingForm";
import { Meeting } from "../../api/MeetingsAPI";
import CustomerElement from "../../components/CustomerElement/CustomerElement";
import NoDataCTA from "../../components/NoDataCTA/NoDataCTA";
import { useNavigate } from "react-router-dom";

const EMPTY_CUSSTOMER: Customer = {
  name: '',
  surname: '',
  countryCode: 'de',
  status: 'NEW',
  attachments: [],
  notes: '',
};

const EMPTY_MEETING: Meeting = {
  customerName: '',
  customerSurname: '',
  startTime: new Date().toISOString(),
  endTime: new Date().toISOString(),
  type: 'VISIT',
  reminder: false,
  disable: false,
  weddingDate: null,
  phone: null,
  lastUpdateTime: null,
  notes: null,
  title: null
};

const CustomersPage = () => {
  const [action, setAction] = useState<FormType>('READ');
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [filters, setFilters] = useState({ employee: "" });
  const [searchIsLoading, setSearchIsLoading] = useState(false);
  const [showMeetingForm, setShowMeetingForm] = useState(false);
  const { isLoading, customers, addFn, updateFn, removeFn } = useGetCustomers(filters);

  const navigate = useNavigate(); 
  const { t } = useTranslation();

  const search = (input: string, resultsSize: number, setResultsFn: any) => {
    setSearchIsLoading(true);
    CustomersAPI.search(input).then((resp) => {
      const raw = resp.data;
      const respRaw = raw.slice(0, resultsSize);
      setSearchIsLoading(false);
      setResultsFn(respRaw);
    });
    return CancelToken.source().cancel("Cancelling...");
  };

  const load = async () => {
    const employeesResp = await EmployeesAPI.getSalesRepresentatives();
    setEmployees(employeesResp.data.map(s => s));
  };

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilterChange = (filterType: 'employee', value: Employee) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      if (updatedFilters[filterType] === String(value.id)) {
        updatedFilters[filterType] = '';
    } else {
        updatedFilters[filterType] = String(value.id!);
    }
      return updatedFilters;
    });
  };

  const actionButton = (action: FormType) => {
    switch (action) {
      case 'CREATE':
        return <span className="flex justify-center"><PrimaryButton text={t("global.create")!} fn={() => registerAndClose(selectedCustomer!)} /></span>;
      case 'UPDATE':
        return <span className="flex justify-center"><PrimaryButton text={t("global.update")!} fn={() => registerAndClose(selectedCustomer!)} /></span>;
      default:
        return;
    }
  };

  const registerAndClose = async (e: Customer) => {
    if (!!e) {
      if (action === 'CREATE') {
        addFn(e);
      } else {
        updateFn(e);
      }
      setSelectedCustomer(null);
      setAction("READ");
    }
  }

  const openMeetingForm = (customer: Customer) => {
    setShowMeetingForm(false);
    // TODO
  }

  return (
    <PageWrapper>
        <div className="space-y-5">
          <Card>
            <>
            <div className="container flex flex-wrap space-x-5">
              <Autocomplete
                resultsSize={15}
                searchFn={search}
                onSelect={() => null}
                placeholder={"customers.search.placeholder"} 
                isLoading={searchIsLoading}
                errorMessage={"global.empty_results"}
                toElementFn={(or: Customer) => 
                  <SimpleListElement onClick={() => setSelectedCustomer(or)} key={or.id} actions={
                    <>
                      <PermissionFlags requiredFlags={['edit-customers'] || []}>
                        <IconButton onClick={() => { setSelectedCustomer(or); setAction('UPDATE');}}>
                          <Icon url={editIcon}/>
                        </IconButton>
                      </PermissionFlags>
                    </>
                  }>
                    <div className="flex space-x-5 justify-between">
                      <span className="w-14 sm:w-20">
                        <Pill text={t(customerStatusToTranslationKey(or.status))} color={customerStatusToPillColor(or.status)}/>
                      </span>
                      <span className="w-24 truncate">
                        { `${or?.name} ${or?.surname}` }
                      </span>
                      { or?.phone && <span className="w-36 truncate">
                        { `${or?.phone}` }
                      </span> }
                      { or?.email && <span className="w-36 truncate">
                        { `${or?.email}` }
                      </span> }
                    </div>
                  </SimpleListElement>
                }
              />
            </div>
              <Collapsable textOnExpanded={t("customers.filters.employees.hide")!} textOnHiden={t("customers.filters.employees.expand")!}>
                <div className="container flex flex-wrap max-h-48 overflow-y-scroll">
                  {
                    employees.map((s, i) => <FilterPill isActive={filters?.employee === String(s.id)} key={i} text={s.name} fn={() => handleFilterChange("employee", s)} />)
                  }
                </div>
              </Collapsable>
              <div className="container flex flex-wrap space-x-5">
                <PermissionFlags requiredFlags={['edit-customers'] || []}>
                  <PrimaryButton text={t("global.create")!} fn={() => { setAction('CREATE');setSelectedCustomer(EMPTY_CUSSTOMER);}} />
                </PermissionFlags>
              </div>
            </>
          </Card>
          <Card>
              <>
                <ListContainer
                  isLoading={isLoading}
                  elements={customers}
                  emptyListComponent={
                    <NoDataCTA
                      headline={t("customers.empty.cta.title")}
                      subtext={t("customers.empty.cta.body")}
                      primaryActionText={t("customers.new_customer.button")}
                      onPrimaryAction={() => navigate('/calendar')}
                    />
                  }
                  toElementFn={(customer: Customer) => (
                    <ListElement
                      key={customer.id}
                      onClick={() => setSelectedCustomer(customer)}
                      css="bg-white text-black hover:bg-darker-gold-100">
                      <ElementActions actions={
                        <>
                          <PermissionFlags requiredFlags={['edit-customers'] || []}>
                            <IconButton onClick={() => openMeetingForm(customer)}>
                              <Icon url={addMeetingIcon}/>
                            </IconButton>
                          </PermissionFlags>
                          <PermissionFlags requiredFlags={['edit-customers'] || []}>
                            <IconButton onClick={() => { setAction('UPDATE'); setSelectedCustomer(customer);}} >
                              <Icon url={editIcon} cssStyles="w-5" />
                            </IconButton>
                          </PermissionFlags>
                          <PermissionFlags requiredFlags={['remove-customers'] || []}>
                            <IconButton>
                              <ConfirmationPopup onConfirm={() => removeFn(customer)} confirmText={"global.confirm"} title={`global.remove: ${customer.name}`}>
                                <Icon url={deleteIcon} cssStyles="w-5" />
                              </ConfirmationPopup>
                            </IconButton>
                          </PermissionFlags>
                        </>
                      }>
                        <CustomerElement customer={customer} />
                      </ElementActions>
                    </ListElement>
                  )}
                />
              </>
          </Card>
          { selectedCustomer && 
            <Modal modalTitle={selectedCustomer.name || "customers.form.new_customer"} canClose onClose={() => setSelectedCustomer(null)}>
              <CustomerForm customer={selectedCustomer} onCustomerChange={setSelectedCustomer} actionButton={actionButton(action)} isDisabled={action === 'READ'}/>
            </Modal>
          }
          {/* TODO: add Customer info and logic to MeetingForm */}
          { showMeetingForm && 
            <Modal modalTitle={selectedCustomer?.name || "customers.form.new_customer"} canClose onClose={() => {setSelectedCustomer(null); setShowMeetingForm(false);}}>
              <MeetingForm meeting={EMPTY_MEETING} onMeetingChange={() => null} actionButton={actionButton('CREATE')}/>
            </Modal>
          }
      </div>
    </PageWrapper>
  );
}

export default CustomersPage;
