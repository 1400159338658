import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import BarChart from '../../../components/BarChart/BarChart';
import NoData from '../../../components/NoData/NoData';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
import SelectInput, { ValueOption } from '../../../components/SelectInput/SelectInput';
import { useStatistics } from '../../../hooks/useStatistics';

function SalesByShopGroupedByYear() {
  const { t } = useTranslation();

  const [selectedYear, setSelectedYear] = useState<string>('');
  const statisticsData = useStatistics('total-sales-by-shop-grouped-by-year');
  const years = Object.keys(statisticsData).sort();

  useEffect(() => {
    if (years.length > 0 && !selectedYear) {
      setSelectedYear(years[years.length - 1]);
    }
  }, [years, selectedYear]);

  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedYear(event.target.value);
  };

  const yearOptions: ValueOption[] = years.map(year => ({
    value: year,
    readableValue: year,
  }));

  const dataRaw = selectedYear ? statisticsData[selectedYear] : {};

  const backgroundColor = ['rgba(75, 192, 192, 0.6)'];

  const dataArr = Object.entries(dataRaw)
    .filter(([shop]) => shop !== 'null')
    .sort((a, b) => a[1] - b[1]);

  const xLabels = dataArr.map(([shop]) => shop);

  const dataSets = [{
    label: 'Sales',
    data: dataArr.map(([, sales]) => sales),
    backgroundColor: backgroundColor,
    borderColor: backgroundColor,
    borderWidth: 1,
  }];

  return (
    <>
      <SectionTitle text={t("stats.total_sales_by_shop_grouped_by_year")}/>
      {yearOptions.length > 0 ? (
        <>
          <SelectInput
            values={yearOptions}
            label="Select a year"
            onChange={handleYearChange}
            defaultValue={selectedYear}
          />
          <BarChart title={selectedYear} dataSets={dataSets} xLabels={xLabels} />
        </>
      ) : (
        <NoData message={t("global.empty_results")} />
      )}
    </>
  );
}

export default SalesByShopGroupedByYear;