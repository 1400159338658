import Card from "../../components/Card/Card";
import Title from "../../components/Title/Title";
import PageWrapper from "../PageWrapper/PageWrapper";

function WelcomePage() {

  return (
    <PageWrapper>
      <div className="min-h-[75vh]">
        <Title text="Welcome" />
        <Card>
          <p>Have a fantastic day!</p>
        </Card>
      </div>
    </PageWrapper>
  );
}

export default WelcomePage;
