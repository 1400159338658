import { Route, Routes } from 'react-router-dom';
import OrdersPage from './pages/OrdersPage/OrdersPage';
import ContractsPage from './pages/ContractsPage/ContractsPage';
import LoginPage from './pages/LoginPage/LoginPage';
import { ProtectedRoute } from './config/PrivateRoute';
import CalendarPage from './pages/CalendarPage/CalendarPage';
import ProductionPage from './pages/ProductionPage/ProductionPage';
import SettingsPage from './pages/SettingsPage/SettingsPage';
import MarketingPage from './pages/MarketingPage/MarketingPage';
import LeadsPage from './pages/LeadsPage/LeadsPage';
import WelcomePage from './pages/WelcomePage/WelcomePage';
import CustomersPage from './pages/CustomersPage/CustomersPage';
import TabletPage from './components/TabletPage/TabletPage';
import TermsPage from './pages/TermsPage/TermsPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage/PrivacyPolicyPage';
import EmployeesSection from './pages/AdminPage/sections/EmployeesSection/EmployeesSection';
import PartnersSection from './pages/ProductionPage/sections/PartnersSection/PartnersSection';
import StockSection from './pages/ProductionPage/sections/StockSection/StockSection';
import ProvidersSegment from './components/ProvidersSection/ProvidersSection';
import DressesSection from './pages/ProductionPage/sections/DressesSection/DressesSection';
import { useScrollToTop } from './hooks/useScroolToTop';
// import TabletStatisticsPage from './pages/TabletStatisticsPage/TabletStatisticsPage';
import OrdersStatisticsPage from './pages/OrdersStatisticsPage/OrdersStatisticsPage';
import PnlStatisticsPage from './pages/PnlStatisticsPage/PnlStatisticsPage';
import { Settings } from './store/reducers/AccountSlice';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import CatalogSingleShowRoomPage from './pages/CatalogSingleShowRoomPage/CatalogSingleShowRoomPage';

function App() {

  useScrollToTop();
  const settings: Settings = useSelector((state: any) => state.settings.value);
  const brandName = settings.tenantName || 'Unknown Customer';

  useEffect(() => {
    if (brandName) {
      datadogRum.setGlobalContextProperty('brandName', brandName);
    }
  }, [brandName]);

  return (
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/terms-of-service" element={<TermsPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route
            path="/calendar"
            element={
              <ProtectedRoute>
                <CalendarPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/customers"
            element={
              <ProtectedRoute>
                <CustomersPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/leads"
            element={
              <ProtectedRoute>
                <LeadsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <ProtectedRoute>
                <SettingsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/production"
            element={
              <ProtectedRoute>
                <ProductionPage />
              </ProtectedRoute>
            }
          />
        <Route
          path="/tablet"
          element={
            <ProtectedRoute>
              <TabletPage />
            </ProtectedRoute>
          }
        />
          <Route path="/catalogues" element={<ProtectedRoute><MarketingPage /></ProtectedRoute>} />
          {/* <Route path="/catalogues/showroom" element={<CatalogShowRoomListingPage />} /> */}
          <Route path="/catalogues/:someCatalogueId" element={<CatalogSingleShowRoomPage />} />
          <Route path="/catalogues/:someCatalogueId/:itemId" element={<CatalogSingleShowRoomPage />} />
          <Route
            path="/employees"
            element={
              <ProtectedRoute>
                <EmployeesSection />
              </ProtectedRoute>
            }
          />
          <Route
            path="/inventory"
            element={
              <ProtectedRoute>
                <DressesSection />
              </ProtectedRoute>
            }
          />
          <Route
            path="/partners"
            element={
              <ProtectedRoute>
                <PartnersSection />
              </ProtectedRoute>
            }
          />
          <Route
            path="/stock"
            element={
              <ProtectedRoute>
                <StockSection />
              </ProtectedRoute>
            }
          />
          <Route
            path="/providers"
            element={
              <ProtectedRoute>
                <ProvidersSegment />
              </ProtectedRoute>
            }
          />
          <Route
            path="/orders"
            element={
              <ProtectedRoute>
                <OrdersPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/contracts"
            element={
              <ProtectedRoute>
                <ContractsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/insights/tablet"
            element={
              <ProtectedRoute>
                <CalendarPage />
                {/* <TabletStatisticsPage /> */}
              </ProtectedRoute>
            }
          />
          <Route
            path="/insights/orders"
            element={
              <ProtectedRoute>
                <OrdersStatisticsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/insights/pnl"
            element={
              <ProtectedRoute>
                <PnlStatisticsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/home"
            element={
              <ProtectedRoute>
                <WelcomePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/*"
            element={
              <ProtectedRoute>
                <CalendarPage />
              </ProtectedRoute>
            }
          />
        </Routes>
  );
}

export default App;
