import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ContractsAPI, { Contract } from '../../api/ContractsAPI';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker';
import SelectInput from '../SelectInput/SelectInput';
import Subtitle from '../Subtitle/Subtitle';
import Card from '../Card/Card';
import { subWeeks } from "date-fns";
import InputShortText from '../InputShortText/InputShortText';
import { isFutureDate, isValidEmail, isValidPhone, isValidText } from '../../shared/validation';
import InputNumber from '../InputNumber/InputNumber';
import SectionTitle from '../SectionTitle/SectionTitle';
import TextArea from '../TextArea/TextArea';
import { useFormValidation } from '../../hooks/useFormValidation';
import { toastService } from '../../services/ToastService';
import EmployeesAPI, { Employee } from '../../api/EmployeesAPI';
import { ContractTemplate } from '../../hooks/useContractTemplate';
import { BolderText } from '../BolderText/BolderText';
import SmallCheckBox from '../SmallCheckBox/SmallCheckBox';
import TinyText from '../TinyText/TinyText';
import * as amplitude from '@amplitude/analytics-browser';
import PhoneNumberInput from '../PhoneNumberInput/PhoneNumberInput';
import CustomerInput from '../CustomerInput/CustomerInput';
import { Customer } from '../../api/CustomersAPI';
import CONFIG from '../../config/config';
import { ItemAutocomplete } from '../ItemAutocomplete/ItemAutocomplete';
import { ItemVariant } from '../../api/CollectionItemVariantsAPI';
import PriceField from '../PriceField/PriceField';
import { useSelector } from 'react-redux';

type ContractDisplayProps = {
  contract?: Contract;
  template: ContractTemplate;
  setContract: React.Dispatch<React.SetStateAction<Contract>>;
  reset: React.Dispatch<React.SetStateAction<Contract>>;
};

const ContractDisplay: React.FC<ContractDisplayProps> = ({ contract, template, setContract, reset }) => {

  const { t } = useTranslation();
  const [startDate, ] = useState(new Date());
  const [isDisabled, setIsDisabled] = useState(false);
  const [signers, setSigners] = useState<Employee[]>([]);
  const [variants, setVariants] = useState<ItemVariant[]>([]);
  const requiredFields = [
    'clientFullname',
    // 'clientAddress',
    // 'clientPhoneNumber',
    // 'clientEmail',
    'totalPriceWording',
  ];
  const settings = useSelector((state: any) => state.settings.value);

  const { updateFieldValidation, isFormValid } = useFormValidation(requiredFields);

  useMemo(() => {
    const search = async () => {
      const resp = await EmployeesAPI.getContractSigners();
      setSigners(resp.data);
    };
    search();
  }, []);

  const updatePickUpDate = (date: Date) => {
    const endDate = subWeeks(date, 2);
    setContract(prev => 
      { 
        return (
          {...prev, 
            weddingDate: date.toISOString(),
            clientPickUpDate: date.toISOString(),
            endDate: endDate.toISOString()
          }) as Contract} 
    );
  }

  const sign = async () => {
    setIsDisabled(true);
    amplitude.track('Contract signed', {
      price: contract?.totalPrice
    });
    try {
      const response = await ContractsAPI.add(contract!);
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      window.open(url, "_blank");
      toastService.showToast(t("contracts.success"));
    } catch (err) {
      toastService.showToast(t("contracts.error"), 'error');
    }
    setIsDisabled(false);
  }

  const fillData = (customer: Customer) => {
    setContract(prev => 
      { return (
        {...prev, 
          clientFullname: `${customer?.name}${customer?.surname ? ` ${customer?.surname}` : ''}`,
          clientEmail: customer?.email || contract?.clientEmail,
          clientPhoneNumber: customer?.phone || contract?.clientPhoneNumber,
          customer
        }
      ) as Contract} 
    );
  };

  useEffect(() => {
    if (contract?.totalPrice) {
        setContract(prev => {
            const updatedInstalmentDetails = template.payment?.instalments?.map((instalment, index) => ({
                ...prev.instalments?.[index], 
                ...template.payment?.instalments?.[index],
                amount: parseFloat(((instalment.percentage / 100) * prev.totalPrice).toFixed(2))
            }));
            return {...prev, instalments: updatedInstalmentDetails} as Contract;
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract?.totalPrice, template.payment?.instalments]);


  // eslint-disable-next-line react-hooks/exhaustive-deps
  const customerValidationChangeFn = useCallback((isValid: boolean) => updateFieldValidation('clientFullname', isValid), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const phoneValidationChangeFn = useCallback((isValid: boolean) => updateFieldValidation('clientPhoneNumber', isValid), []);
  const isValidPhoneFn = useCallback((value: any) => !!value && isValidPhone(value), []);

  return (
    <>
        <br/>
        <Card>
          <>
            <Subtitle text={t("contracts.general_data")} />
            <div className="flex space-x-2 items-center">
              <p>
                { t("contracts.in_day") }
              </p>
              <CustomDatePicker
                value={startDate}
                onChange={c => setContract(prev => { return ({...prev, signatureDay: c?.toISOString() }) as Contract} )}
                isDisabled
                timeZone={settings.timezone}
              />
              <p>
                { t("contracts.years_between") }:
              </p>
            </div>
            <p>
            <p><BolderText text={template?.general?.companyName }/>,</p>
            <p>{ template?.general?.companyAddress },</p>
            <p>NIP: { template?.general?.taxID }</p>
            </p>
            <CustomerInput
              onCustomerSelect={(customer) => fillData(customer)}
              label={`${t("contracts.with_lady")}*`}
              onChange={c => setContract(prev => { return ({...prev, clientFullname: c.target.value }) as Contract} )}
              errorMessage={"errors.too_short"}
              onValidationChange={customerValidationChangeFn}
              defaultValue={contract?.clientFullname}
            />
            <span className="flex space-x-5">
              <InputShortText 
                defaultValue={contract?.clientAddress}
                label={`${t("contracts.resident")}*`} 
                validationFn={(value) => !!value && isValidText(value, 3)} 
                onChange={c => setContract(prev => { return ({...prev, clientAddress: c.target.value }) as Contract} )}
                onValidationChange={(isValid) => updateFieldValidation('clientAddress', isValid)}
                errorMessage={"errors.too_short"}
              />
              <InputShortText 
                defaultValue={contract?.clientPersonalID}
                label={`${t("contracts.personal_id")}`} 
                validationFn={(value) => !!value && isValidText(value, 3)} 
                onChange={c => setContract(prev => { return ({...prev, clientPersonalID: c.target.value }) as Contract} )}
                errorMessage={"errors.too_short"}
              />
            </span>
            <span className="flex space-x-5">
              <InputShortText 
                defaultValue={contract?.clientEmail}
                label={`${t("contracts.email")}*`} 
                validationFn={(value) => !!value && isValidEmail(value)} 
                onChange={c => setContract(prev => { return ({...prev, clientEmail: c.target.value }) as Contract} )}
                onValidationChange={(isValid) => updateFieldValidation('clientEmail', isValid)}
                errorMessage={"errors.too_short"}
              />
            </span>
            <PhoneNumberInput 
              validationFn={isValidPhoneFn} 
              label={`${t("contracts.contact_phone")}*`} 
              onChange={c => setContract(prev => { return ({...prev, clientPhoneNumber: c }) as Contract} )}
              defaultValue={contract?.clientPhoneNumber}
              onValidationChange={phoneValidationChangeFn}
              isDisabled={isDisabled}
            />
          </>
        </Card>
        <br/>

        <Card>
          <>
            <Subtitle text={`§ 1 ${t("contracts.details")}`} />
            <SectionTitle text={`1.2 ${t("contracts.item_name")}`} />
            <ItemAutocomplete
              onChange={c => setContract(
                prev => { 
                  setVariants(c.variations || []);
                  return ({...prev, itemId: c.id, itemName: c.name, variantName: undefined, variantId: undefined }) as Contract
                } 
              )}
              label={`${t("contracts.model")}* :`} 
              placeholder={"contracts.model.search.placeholder"}
              defaultValue={contract?.dress ?? contract?.dress }
            />
            <span className="flex space-x-5">
              <SelectInput values={
                variants.map(v => { return { value: v.name, readableValue: v.name};} )}
                onChange={c => setContract(
                  prev => { 
                    return ({...prev, variantName: c.target.value, variantId: variants.length > 0 && variants.filter(v => v.name === c.target.value).map(v => v.id)[0] }) as Contract
                  } 
                )}
                label={"contracts.model.variant"} 
              />
              <SelectInput values={[
                { value: '', readableValue: ''},
                { value: 'Gładki', readableValue: 'contracts.veil.smooth'},
                { value: 'Koronka', readableValue: 'contracts.veil.lace'}
                ]}
                onChange={c => setContract(prev => { return ({...prev, dressVeil: c.target.value }) as Contract} )}
                label={"contracts.veil"} 
              />
              <InputNumber 
                label={"contracts.length"} 
                validationFn={(value) => !!value && value >= 0} 
                onChange={c => setContract(prev => { return ({...prev, dressVeilLength: c.target.value || 0 }) as Contract} )}
                errorMessage={"errors.minimum_zero"}
              />
            </span>
            <SelectInput values={[
              { value: '', readableValue: ''},
              { value: 'Lewa noga', readableValue: 'contracts.leg_cut.left'},
              { value: 'Prawa noga', readableValue: 'contracts.leg_cut.right'}
            ]}
              onChange={c => setContract(prev => { return ({...prev, dressLegCut: c.target.value }) as Contract} )}
              label={"contracts.leg_cut"}
            />
            <TextArea
                defaultValue={contract?.dressDescription}
                label={"contracts.description"} 
                validationFn={(value) => !!value && isValidText(value, 3)} 
                onChange={c => setContract(prev => { return ({...prev, dressDescription: c.target.value }) as Contract} )}
                errorMessage={"errors.too_short"}
              />
            <div className="flex">
              <CustomDatePicker
                label={t("contracts.wedding_date")!}
                value={contract?.weddingDate}
                onChange={c => updatePickUpDate(c!)}
                timeZone={settings.timezone}
              />
            </div>
            <br/>

            <SectionTitle text={`1.2 ${t("contracts.measures")}`} />
            <span className="flex space-x-5">
              <InputNumber 
                label={"contracts.bust"} 
                validationFn={(value) => value >= 0} 
                onChange={c => setContract(prev => { return ({...prev, bust: c.target.value }) as Contract} )}
                errorMessage={"errors.too_short"}
              />
              <InputNumber 
                label={"contracts.waist"} 
                validationFn={(value) => value >= 0} 
                onChange={c => setContract(prev => { return ({...prev, waist: c.target.value }) as Contract} )}
                errorMessage={"errors.too_short"}
              />
              <InputNumber 
                label={"contracts.hip"} 
                validationFn={(value) => value >= 0} 
                onChange={c => setContract(prev => { return ({...prev, hips: c.target.value }) as Contract} )}
                errorMessage={"errors.too_short"}
              />
            </span>
            <br/>
          </>
        </Card>
        <br/>

        <Card>
          <>
            <Subtitle text={`§ 2 ${t("contracts.conditions")}`} />
            <div className="flex space-x-2">
              <CustomDatePicker
                label={t("contracts.conditions.point1")!}
                value={contract?.endDate}
                validationFn={(value) => !!value && isFutureDate(value)}
                errorMessage={"errors.past_date"}
                onChange={c => setContract(prev => { return ({...prev, endDate: c?.toISOString() }) as Contract} )}
                timeZone={settings.timezone}
              />
            </div>
            <div className="flex space-x-2">
              <CustomDatePicker
                label={t("contracts.conditions.point2")!}
                validationFn={(value) => !!value && isFutureDate(value)}
                errorMessage={"errors.past_date"}
                value={contract?.clientPickUpDate}
                onChange={c => setContract(prev => { return ({...prev, clientPickUpDate: c?.toISOString() }) as Contract} )}
                timeZone={settings.timezone}
              />
            </div>
            <>
            {template?.conditions?.conditions?.map((condition, index) => (
                <p key={index}>{`${index + 1}. ${condition}`}</p>
            ))}
          </>
          </>
        </Card>
        <br/>

        <Card>
          <>
            <Subtitle text={`§ 3 ${t("contracts.payments")}`} />
            <div className="flex space-x-2">
              <div className="flex space-x-2 w-1/2">
                <PriceField 
                    label={"contracts.conditions.advance"}
                    currency={contract?.totalPriceCurrency || 'EUR'}
                    price={contract?.totalPrice || 0}
                    currencies={[''].concat(CONFIG.SUPPORTED_CURRENCIES)}
                    onCurrencyChange={e => setContract({ ...contract, totalPriceCurrency: e } as Contract)}
                    onPriceChange={value => setContract({ ...contract, totalPrice: value, totalPriceCurrency: contract?.totalPriceCurrency || 'EUR' } as Contract)}
                    isDisabled={isDisabled}
                />
              </div>
              <InputShortText 
                defaultValue={contract?.totalPriceWording}
                label={`${t("contracts.in_words")}*`} 
                validationFn={(value) => !!value && isValidText(value, 2)} 
                onChange={c => setContract(prev => { return ({...prev, totalPriceWording: c.target.value || '' }) as Contract} )}
                onValidationChange={(isValid) => updateFieldValidation('totalPriceWording', isValid)}
                errorMessage={"errors.too_short"}
              />
            </div>

            <p>{t("contracts.payments.how")}:</p>
            {template?.payment?.instalments?.length > 0 ? template?.payment?.instalments?.map((instalment, index) => (
                <div key={index}>
                  {/* <BolderText text={`${index + 1}.${instalment.percentage}% - ${t(paymentMethodTypeToTranslationKey(instalment.method))})`} /> */}
                  <BolderText text={`${index + 1}.${instalment.percentage}%`} />

                  <div className="flex space-x-2">
                    <div className="flex space-x-2 w-1/2">
                      <PriceField 
                          label={"contracts.instalment.amount"}
                          currency={contract?.instalments?.[index]?.currency || 'EUR'}
                          price={contract?.instalments?.[index]?.amount || 0}
                          currencies={[''].concat(CONFIG.SUPPORTED_CURRENCIES)}
                          onCurrencyChange={(value) => {
                            setContract(prev => {
                              const updatedInstalmentDetails = [...prev.instalments];
                              updatedInstalmentDetails[index] = { ...updatedInstalmentDetails[index], currency: value };
                              return {...prev, instalments: updatedInstalmentDetails} as Contract;
                            });
                          }}
                          onPriceChange={(value) => {
                            setContract(prev => {
                              const updatedInstalmentDetails = [...prev.instalments];
                              updatedInstalmentDetails[index] = { ...updatedInstalmentDetails[index], amount: value | 0, currency: updatedInstalmentDetails[index]?.currency || 'EUR' };
                              return {...prev, instalments: updatedInstalmentDetails} as Contract;
                            });
                          }}
                          isDisabled={isDisabled}
                      />
                    </div>
                    <InputShortText
                        label={t("contracts.instalment.amount_wording")!}
                        validationFn={(value) => !!value && isValidText(value, 2, 50)}
                        onChange={(e: any) => {
                            const wording = e.target.value;
                            setContract(prev => {
                                const updatedInstalmentDetails = [...prev.instalments];
                                updatedInstalmentDetails[index] = { ...updatedInstalmentDetails[index], amountInWords: wording };
                                return {...prev, instalments: updatedInstalmentDetails} as Contract;
                            });
                        }}
                        defaultValue={contract?.instalments?.[index]?.amountInWords}
                        errorMessage={t("errors.too_short")!}
                    />
                  </div>

                  <SmallCheckBox
                    label={t("contracts.instalment.due_date")!}
                    defaultValue={Boolean(contract?.instalments?.[index]?.hasDueDate)}
                    onChange={(v) => {
                        setContract(prev => {
                            const updatedInstalmentDetails = [...(prev.instalments || [])];
                            const hasDueDate = v.target.checked;
                            updatedInstalmentDetails[index] = { ...updatedInstalmentDetails[index], hasDueDate: hasDueDate };
                            updatedInstalmentDetails[index] = { ...updatedInstalmentDetails[index], dueDate: undefined };
                            return {...prev, instalments: updatedInstalmentDetails} as Contract;
                        });
                    }}
                  />

                  {contract?.instalments?.[index]?.hasDueDate && (
                    <CustomDatePicker
                      value={contract.instalments[index].dueDate ? new Date(contract.instalments[index].dueDate!) : new Date()}
                      onChange={c => {
                          setContract(prev => {
                              const updatedInstalmentDetails = [...prev.instalments];
                              updatedInstalmentDetails[index] = { ...updatedInstalmentDetails[index], dueDate: c?.toISOString() };
                              return {...prev, instalments: updatedInstalmentDetails} as Contract;
                          });
                      }}
                      timeZone={settings.timezone}
                    />
                  )}
                </div>
            )) : <TinyText text={`${t("contract_template.no_payment_instalments")}.`} />}

            <br/>
            <TextArea
                validationFn={value => true}
                defaultValue={template?.payment?.paymentSpecifications}
                label={t('contract_template.payment_method.other_specifications')!}
                onChange={c => setContract(prev => { return ({...prev, paymentSpecifications: c.target.value || '' }) as Contract} )}
            />
          </>
        </Card>
        <br/>

        <Card>
          <>
            <Subtitle text={`§ 4 ${t("contracts.final_arrangements")}`} />
            {template?.remarks?.conditions?.length > 0 ? template?.remarks?.conditions?.map((condition, index) => (
                <p key={index}>{`${index + 1}. ${condition}`}</p>
            )) : <TinyText text={`${t("contract_template.no_arrangements")}`} />}
          </>
        </Card>

        <br/>

        <Card>
          <>
            <Subtitle text={`${t("contracts.additional_information")}`} />
            { template?.additionalRequirements?.requiresEmployeeSignature 
            && 
            <SelectInput values={signers.map(e => { return { value: e.name, readableValue: e.name}})}
              onChange={c => setContract(prev => { return ({...prev, employeeName: c.target.value }) as Contract} )}
              label={"contracts.signing_employee"}
            /> }
            { template?.additionalRequirements?.requiresFollowUpMeeting 
            &&
            <CustomDatePicker
              label={t("contracts.first_measurement")!}
              validationFn={(value) => !!value && isFutureDate(value)}
              errorMessage={"errors.past_date"}
              value={(contract?.firstFollowUpMeetingDate || new Date(contract?.weddingDate!))}
              onChange={c => setContract(prev => { return ({...prev, firstFollowUpMeetingDate: c.toISOString() }) as Contract} )}
              timeZone={settings.timezone}
            /> }
          </>
        </Card>
        <div className="container flex flex-wrap space-x-1 justify-center py-10">
          <PrimaryButton text={`${t("global.erase")}`} fn={reset}/>
          <PrimaryButton text={`${t("contracts.generate")}`} isDisabled={isDisabled || !isFormValid()} fn={sign}/>
        </div>
    </>
  );
};

export default ContractDisplay;
