import React, { ReactNode, useState } from 'react';

interface TooltipProps {
  children: ReactNode;
  content: string;
  position?: 'top' | 'right' | 'bottom' | 'left';
}

const Tooltip: React.FC<TooltipProps> = ({ children, content, position = 'top' }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="relative inline-flex">
      {showTooltip && (
        <span
          className={`absolute z-tooltip p-2 mt-2 text-white text-xs rounded-md shadow-lg ${
            position === 'top' && 'bottom-full'
          } ${position === 'right' && 'left-full'} ${position === 'bottom' && 'top-full'} ${
            position === 'left' && 'right-full'
          } ${
            position === 'top' || position === 'bottom'
              ? 'left-1/2 transform -translate-x-1/2'
              : 'top-1/2 transform -translate-y-1/2'
          } transition-all duration-300 ease-in-out bg-gray-800`}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          {content}
        </span>
      )}
      <div onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
        {children}
      </div>
    </div>
  );
};

export default Tooltip;