import CONFIG from '../config/config';
import { instanceWithV2 as instance } from './SimpleAPIClient';

export type OnboardingStatus = {
  status: {
    setupStore: boolean;
    startCalendar: boolean;
    publishCatalog: boolean;
    registerOrder: boolean;
  }
};


const OnboardingAPI = {
  get: () => {
    return instance(CONFIG.API_ENDPOINT).get<OnboardingStatus>(`/v1/onboarding`);
  },
  updateStep: (step: string) => {
    return instance(CONFIG.API_ENDPOINT).put<OnboardingStatus>(`/v1/onboarding/${step}`);
  }
};

export default OnboardingAPI;