import { useState, useRef, CSSProperties } from 'react';
import { DndProvider, useDrag, useDrop, DragSourceMonitor } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import deleteIcon from '../../assets/images/bin.svg';
import TextArea from '../TextArea/TextArea';
import { isValidText } from '../../shared/validation';
import ListElement from '../ListElement/ListElement';
import IconButton from '../IconButton/IconButton';
import { Icon } from '../Icon/Icon';
import ListContainer from '../ListContainer/ListContainer';
import PrimaryButton from '../PrimaryButton/PrimaryButton';

type ItemProps = {
  item: string;
  index: number;
  moveItem: (fromIndex: number, toIndex: number) => void;
  deleteItem: (index: number) => void;
};

type DragItem = {
  index: number;
  type: string;
};

const ItemType = {
  ITEM: 'ITEM',
};

const DraggableItem: React.FC<ItemProps> = ({ item, index, moveItem, deleteItem }) => {
  const ref = useRef<HTMLDivElement>(null);
  
  const [, drop] = useDrop({
    accept: ItemType.ITEM,
    hover: (draggedItem: DragItem) => {
      if (draggedItem.index !== index) {
        moveItem(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });
  
  const [{ isDragging }, drag] = useDrag({
    type: ItemType.ITEM,
    item: { index },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  const style: CSSProperties = {
    opacity: isDragging ? 0 : 1,
  };

  return (
    <ListElement key={index}>
      <div ref={ref} style={style} className="flex justify-between items-center">
        <div>{item}</div>
        <IconButton onClick={() => deleteItem(index)}>
          <Icon url={deleteIcon} cssStyles="w-5" />
        </IconButton>
      </div>
    </ListElement>
  );
}

type DraggableListProps = {
  items: string[];
  setItems: (items: string[]) => void;
  placeholder?: string;
  buttonText: string;
};

export const DraggableList: React.FC<DraggableListProps> = ({ items, setItems, placeholder, buttonText }) => {
  const [newItem, setNewItem] = useState<string>('');

  const moveItem = (fromIndex: number, toIndex: number) => {
    const updatedItems = [...items];
    const [movedItem] = updatedItems.splice(fromIndex, 1);
    updatedItems.splice(toIndex, 0, movedItem);
    setItems(updatedItems);
  };

  const addNewItem = () => {
    if (newItem.trim()) {
      setItems([...items, newItem.trim()]);
      setNewItem(''); 
    }
  };

  const deleteItem = (index: number) => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
  };

  return (
    <>
        <DndProvider backend={HTML5Backend}>
        <ListContainer
            elements={items}
            toElementFn={(item, index) => (
            <DraggableItem
                item={item}
                index={index}
                moveItem={moveItem}
                deleteItem={deleteItem}
            />
            )}
        />
        </DndProvider>
        <TextArea
            label={placeholder}
            validationFn={value => !!value && isValidText(value, 5)}
            defaultValue={newItem}
            onChange={(e) => setNewItem(e.target.value)}
        />
        <div className="container flex flex-wrap space-x-5">
            <PrimaryButton text={buttonText} fn={addNewItem}/>
        </div>
    </>
  );
}
