import { PillColor } from "../components/Pill/Pill";

export type ORDER_STATUS = 'DRAFT' | 'WAITING' | 'ACCEPTED' | 'REJECTED' | 'REQUIRES_CHANGES' | 'CANCELED';

export function getExternalStatusesRaw(): ORDER_STATUS[] {
  return ['DRAFT', 'WAITING', 'ACCEPTED', 'REJECTED', 'REQUIRES_CHANGES', 'CANCELED'];
}

export function externalOrderStatusToTranslationKeys(status?: string): string {
  switch (status) {
    case 'DRAFT':
      return "orders.external.status.draft";
    case 'WAITING':
      return "orders.external.status.waiting";
    case 'ACCEPTED':
      return "orders.external.status.accepted";
    case 'REJECTED':
      return "orders.external.status.rejected";
    case 'CANCELED':
      return "orders.external.status.canceled";
    case 'REQUIRES_CHANGES':
      return "orders.external.status.pending_changes";
    default:
      return "global.unknown";
  }
}

export function externalOrderStatusToColor(status?: string): PillColor {
  switch (status) {
    case 'DRAFT':
      return 'grey';
    case 'WAITING':
      return 'blue';
    case 'ACCEPTED':
      return 'green';
    case 'REJECTED':
      return 'red';
    case 'CANCELED':
      return 'grey';
    case 'REQUIRES_CHANGES':
      return 'blue';
    default:
      return 'grey';
  }
}