import { useEffect, useState } from "react";
import ShopClientsAPI, { OrderHistoryStep } from "../api/ShopClientsAPI";

type ReturnedProps = {
  isLoadingHistory: boolean,
  employeeHistory: OrderHistoryStep[],
};

export function useGetEmployeeHistory(workerId: number): ReturnedProps {
  
  const [employeeHistory, setHistory] = useState<OrderHistoryStep[]>([]);
  const [isLoadingHistory, setIsLoading] = useState(false);

  const load = () => {
    const asyncLoad = async () => {
      if (workerId) {
        setIsLoading(true);
        try {
          const empRaw = await ShopClientsAPI.getWorkerStory(workerId);
          const empApi = empRaw.data;
          
          setHistory(empApi);
        } catch (err) {
          console.error("Could not load employees");
        }
        setIsLoading(false);
      }
    };
    asyncLoad();
  }

  useEffect(load, [workerId]);
  
  return { isLoadingHistory, employeeHistory };
};