import { useTranslation } from "react-i18next";
import { Label } from "../Label/Label";
import Pill from "../Pill/Pill";

type DressCardDescriptionProps = {
    details: any;
}

const DressCardDescription = ({ details }: DressCardDescriptionProps) => {

  const { t } = useTranslation();

  return (
    <>
        <h1 className="text-2xl font-bold text-gray-800 mb-4">{details.name}</h1>
        <p className="text-gray-600 mb-4">{details.description}</p>
        <div className="mb-4">
            <Label text={t("marketplace.catalogues.details.designer")!} />
            <p>{ details.designer }</p>
        </div>
        <div className="mb-4">
            <Label text={t("marketplace.catalogues.details.collection")!} />
            <p>{ details.collectionName }</p>
        </div>
        <div className="mb-4">
            <Label text={t("marketplace.catalogues.details.price")!} />
            <Pill text={details.price ? `${details.price} ${details.currency}` : t(t("marketplace.catalogues.details.price.undisclosed")!)}/>
        </div>
    </>
  )
};

export default DressCardDescription;
