import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

type DressCardProps = {
    imageUrl: string;
    name: string;
}

const DressCard: React.FC<DressCardProps> = ({ imageUrl, name }) => {
    const [imageLoaded, setImageLoaded] = useState(false);
    const [currentSrc, setCurrentSrc] = useState(imageUrl);
    const [useSrcSet, setUseSrcSet] = useState(true);
    const { t } = useTranslation();

    const fileExtension = imageUrl.slice(imageUrl.lastIndexOf('.'));
    const baseImageUrl = imageUrl.replace(fileExtension, '');
    const imageSrcSet = useSrcSet ? `
        ${baseImageUrl}-medium${fileExtension} 480w,
        ${baseImageUrl}-large${fileExtension} 800w
    ` : '';

    const handleError = () => {
        setUseSrcSet(false);
        if (currentSrc !== imageUrl) {
            setCurrentSrc(imageUrl);
        }
    };

    return (
        <div className="relative overflow-hidden w-full h-full rounded-lg shadow-lg">
            {!imageLoaded && (
                <div className="absolute inset-0 flex items-center justify-center bg-gray-200 rounded-lg">
                    <span>{t("global.loading")}...</span>
                </div>
            )}
            <Zoom zoomMargin={10}>
                <img
                    srcSet={imageSrcSet.trim()}
                    // sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, 1024px"
                    src={currentSrc}
                    alt={name}
                    className={`w-full h-full object-cover rounded-lg transform hover:scale-105 transition duration-1000 cursor-pointer ease-in-out ${imageLoaded ? 'block' : 'hidden'}`}
                    onLoad={() => setImageLoaded(true)}
                    onError={handleError}
                />
            </Zoom>
            {name && 
                <div className="absolute bottom-0 w-full text-center py-2 bg-white bg-opacity-50 rounded-b-lg">
                    <h3 className="text-lg font-light text-gray-700">{name}</h3>
                </div>
            }
        </div>
    );
};

export default DressCard;