import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import debounce from '../../shared/debounce';
import ColorThemeSelector from '../ColorThemeSelector/ColorThemeSelector';
import Subtitle from '../Subtitle/Subtitle';
import TinyText from '../TinyText/TinyText';

type ThemeColorConfigurationProps = {
    onColorsChange?: (primaryColor: string, backgroundColor: string) => void;
    color?: string,
    defaultBgColor?: string
}

const ThemeColorConfiguration: React.FC<ThemeColorConfigurationProps> = ({ onColorsChange, color = '#C5B078', defaultBgColor = '#fff9f9' }) => {

    const [primaryColor, setPrimaryColor] = useState<string>('');
    const [backgroundColor, setBackgroundColor] = useState<string>('');
    const { t } = useTranslation();

    useEffect(() => {
        setPrimaryColor(color);
        setBackgroundColor(defaultBgColor);
    }, [color, defaultBgColor]);

    const debouncedOnColorsChange = useMemo(() => debounce((primaryColor: string, backgroundColor: string) => {
        onColorsChange && onColorsChange(primaryColor, backgroundColor);
      }, 200), [onColorsChange]);      

    const handlePrimaryColorChange = (color: string) => {
        setPrimaryColor(color);
        debouncedOnColorsChange(color, backgroundColor);
    };

    const handleBackgroundColorChange = (color: string) => {
        setBackgroundColor(color);
        debouncedOnColorsChange(primaryColor, color);
    };

    return (
        <>
            <Subtitle text={t("settings.general.appareance")} />
            <TinyText text={`${t("settings.general.appareance.description")}.`} />
            <div className="flex space-x-5">
                <ColorThemeSelector label={t("settings.general.appareance.color")!} colorVariable="--color-primary" defaultColor={primaryColor} onColorChange={handlePrimaryColorChange}/>
                <ColorThemeSelector label={t("settings.general.appareance.bg_color")!} colorVariable="--color-secondary" defaultColor={backgroundColor} onColorChange={handleBackgroundColorChange} />
            </div>
        </>
    );
};

export default ThemeColorConfiguration;