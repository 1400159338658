import React, { useState } from 'react';
import CustomersAPI, { Customer } from '../../api/CustomersAPI';
import { CancelToken } from '../../api/SimpleAPIClient';
import { Autocomplete } from '../Autocomplete/Autocomplete';
import { Icon } from '../Icon/Icon';
import IconButton from '../IconButton/IconButton';
import { Modal } from '../Modal/Modal';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import Tooltip from '../Tooltip/Tooltip';
import loadIcon from '../../assets/images/load.svg';
import CustomerSummary from '../CustomerSummary/CustomerSummary';
import CustomerElement from '../CustomerElement/CustomerElement';

type CustomerSearchModalProps = {
  onCustomerSelect: (customer: Customer) => void;
}

const CustomerSearchModal = ({ onCustomerSelect }: CustomerSearchModalProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchIsLoading, setSearchIsLoading] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer |  null>();

  const confirm = () => {
    onCustomerSelect(selectedCustomer!);
    setIsModalVisible(false);
    setSelectedCustomer(null);
  }

  const handleSelectCustomer = (customer: Customer) => {
    setSelectedCustomer(customer);
  };

  const close = () => {
    setIsModalVisible(false);
    setSelectedCustomer(null);
  }

  const search = (input: string, resultsSize: number, setResultsFn: any) => {
    setSearchIsLoading(true);
    CustomersAPI.search(input).then((resp) => {
      const raw = resp.data;
      const respRaw = raw.slice(0, resultsSize);
      setSearchIsLoading(false);
      setResultsFn(respRaw);
    });
    return CancelToken.source().cancel("Cancelling...");
  };

  return (
    <>
      <Tooltip content={"Import customer"}>
        <IconButton onClick={() => setIsModalVisible(true)}>
            <Icon url={loadIcon} cssStyles="w-5"/>
        </IconButton>
      </Tooltip>
      {isModalVisible && (
        <Modal
          modalTitle="Search for a Customer"
          canClose
          onClose={() => close()}
        >
          <div className="container flex flex-col space-y-5 mb-5">
            <Autocomplete
                resultsSize={15}
                searchFn={search}
                onSelect={handleSelectCustomer}
                placeholder="Search customers..."
                isLoading={searchIsLoading}
                errorMessage="No customers found"
                toElementFn={(customer: Customer) => (
                  <div className="flex">
                    <CustomerElement customer={customer} />
                  </div>
                )}
            />
            { selectedCustomer && <CustomerSummary customer={selectedCustomer} />}
            <div className="flex justify-center">
              <PrimaryButton isDisabled={!selectedCustomer} text="Select" fn={confirm}/>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default CustomerSearchModal;
