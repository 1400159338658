import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import config from '../../config/config';
import InputShortText from '../InputShortText/InputShortText';

type PhoneNumberInputProps = {
  label?: string,
  defaultValue?: string;
  defaultCountry?: string;
  onChange?: (value: string) => void;
  validationFn?: (input: string) => boolean;
  isDisabled?: boolean;
  onValidationChange?: (isValid: boolean) => void;
};

const parsePhoneNumber = (phoneNumber: string) => {
    for (const country of config.COUNTRY_CODES) {
      if (phoneNumber.startsWith(country.prefix)) {
        return {
          countryName: country.countryName,
          number: phoneNumber.replace(country.prefix, ''),
        };
      }
    }
    return {
      countryName: config.COUNTRY_CODES.filter(c => c.countryCode === 'PL')[0].countryName,
      number: phoneNumber,
    };
  };  

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({ label, defaultValue, defaultCountry, onChange, validationFn, isDisabled, onValidationChange }) => {
  const { countryName, number } = parsePhoneNumber(defaultValue || '');

  const [, setIsValid] = useState<boolean>(true);
  const [selectedCountry, setSelectedCountry] = useState(defaultCountry || countryName);
  const [phoneNumber, setPhoneNumber] = useState(number);    
  const { t } = useTranslation();

  const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newSelectedCountry = event.target.value;
    const countryPrefix = config.COUNTRY_CODES.find(country => country.countryName === newSelectedCountry)?.prefix || '';
    
    setSelectedCountry(newSelectedCountry);
    const updatedPhoneNumber = `${countryPrefix}${phoneNumber}`;  
    setPhoneNumber(phoneNumber.replace(/^(\+\d+)?/, countryPrefix));
  
    if (onChange) {
      onChange(updatedPhoneNumber);
    }
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    const valid = validationFn ? validationFn(value) : true;
    setIsValid(valid);
    
    setPhoneNumber(value);
    
    if (onChange) {
      const countryPrefix = config.COUNTRY_CODES.find(country => country.countryName === selectedCountry)?.prefix || '';
      onChange(`${countryPrefix}${value}`);
    }
  };

  useEffect(() => {
    const { number } = parsePhoneNumber(defaultValue || '');
    setPhoneNumber(number);
  }, [defaultValue]);
  
  
  useEffect(() => {
    const checkValidity = () => {
      if (validationFn && phoneNumber) {
        const valid = validationFn(phoneNumber);
        setIsValid(valid);
        if (onValidationChange) {
          onValidationChange(valid);
        }
      }
    };
  
    checkValidity();
  }, [phoneNumber, selectedCountry, validationFn, onValidationChange]);

  return (
    <div className="flex flex-col w-full">
      { label && <p>{ t(label) }:</p> }
      <div className="flex items-center w-full space-x-3">
        <select value={selectedCountry} onChange={handleCountryChange} className={`pt-3 pb-3 border-stone-200 border rounded focus:outline-none focus:ring-0 ${
                isDisabled ? 'bg-stone-100' : ''
            }`}>
            {config.COUNTRY_CODES.map(country => (
            <option key={country.countryName} value={country.countryName}>
                {country.flag} { t(country.countryCode) } ({country.prefix})
            </option>
            ))}
        </select>
        <InputShortText
            validationFn={validationFn}
            onChange={handlePhoneNumberChange}
            errorMessage={"errors.invalid_phone"}
            defaultValue={phoneNumber}
            isDisabled={isDisabled}
            onValidationChange={setIsValid}
        />
      </div>
    </div>
  );
};

export default PhoneNumberInput;
