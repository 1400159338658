type Props = {
  children: JSX.Element,
  isLoading?: boolean
};

function Card({children, isLoading = false}: Props) {
  
  return (
    <div className="flex flex-col flex-wrap p-7 border-gray-300 border shadow-sm bg-white w-full rounded-xl animate-fade-in-up">
      { isLoading ?
        Array.apply(null, Array(1))
          .map((m, i) => <div key={i} className="shadow p-5 w-full mx-auto animate-pulse"></div>) 
        :
       children }
    </div>
  );
}

export default Card;
