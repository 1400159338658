import CONFIG from '../config/config';
import { instanceWithV2 as APIClient } from './SimpleAPIClient';

export type Employee = {
  id?: number,
  name: string,
  active: boolean,
  flags: string[],
  note?: string,
  salary?: number,
  salaryCurrency?: string,
  cost?: number,
  costCurrency?: string
}

type BodyApiResponse = Employee[];

const EmployeesAPI = {
  get: (activeToo: boolean = false) => APIClient(CONFIG.API_ENDPOINT).get<BodyApiResponse>(`/v1/workers?onlyActive=${activeToo}`),
  getContractSigners: () => APIClient(CONFIG.API_ENDPOINT).get<BodyApiResponse>(`/v1/workers?flags=contractSignature`),
  getMeetingRemovalSigners: () => APIClient(CONFIG.API_ENDPOINT).get<BodyApiResponse>(`/v1/workers?flags=meetingRemoval`),
  getSalesRepresentatives: () => APIClient(CONFIG.API_ENDPOINT).get<BodyApiResponse>(`/v1/workers?flags=salesRepresentative`),
  add: (employee: Employee) => APIClient(CONFIG.API_ENDPOINT).post<BodyApiResponse>(`/v1/workers`, employee),
  update: (employee: Employee) => APIClient(CONFIG.API_ENDPOINT).put<BodyApiResponse>(`/v1/workers/${employee.id}`, employee),
  delete: (id: number) => APIClient(CONFIG.API_ENDPOINT).delete<BodyApiResponse>(`/v1/workers/${id}`)
};

export default EmployeesAPI;
