import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { modalService } from '../../services/ModalService';
import { Modal } from '../Modal/Modal';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import nullIcon from '../../assets/images/error-screen.png';
import { BolderText } from '../BolderText/BolderText';
import CopyText from '../CopyText/CopyText';

const DynamicModalContainer: React.FC = () => {
 
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [contextId, setContextId] = useState<string | null>(null);
  const [content, setContent] = useState<string | null>(null);

  useEffect(() => {
    modalService.setCallback((message, id, content) => {
      setErrorMessage(message);
      setContextId(id);
      setContent(content);
    });
  }, []);

  const handleClose = () => {
    setErrorMessage(null);
    setContextId(null);
    setContent(null);
  };

  return (
    <>
      {errorMessage && 
        <Modal modalTitle={t("global.error")!} canClose onClose={handleClose}>
          <>
            <div className="flex justify-center items-center">
                <img src={nullIcon} alt="Null" className="h-56"/>
            </div>
            <p className="py-5">{t(errorMessage)}.</p>
            <p>ID: <BolderText text={contextId!}/>&nbsp;<CopyText text={contextId!} /></p>
            <p className="mt-4 text-xl text-gray-500">{content}</p>
            <div className="flex justify-center mt-5">
              <PrimaryButton text={t("global.close")!} fn={handleClose} />
            </div>
          </>
        </Modal>
      }
    </>
  );
}

export default DynamicModalContainer;