import React, { ReactNode } from 'react';
import { CloseButton } from '../CloseButton/CloseButton';
import Divider from '../Divider/Divider';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import SecondaryButton from '../SecondaryButton/SecondaryButton';
import SectionTitle from '../SectionTitle/SectionTitle';

interface ButtonProps {
  label: string;
  onClick: () => void;
}

interface SlideOverProps {
  isOpen: boolean;
  setIsOpen: any;
  title?: string;
  primaryButton?: ButtonProps;
  secondaryButton?: ButtonProps;
  children: ReactNode;
  isLoading?: boolean;
}

const SlideOver: React.FC<SlideOverProps> = ({ isOpen, setIsOpen, title, primaryButton, secondaryButton, children, isLoading = false }) => {

  const slideOverClasses = isOpen
    ? "translate-x-0 ease-out"
    : "translate-x-full ease-in";

  return (
    <>
      <div
        className={`fixed inset-0 z-50 overflow-hidden ${isOpen ? 'block' : 'hidden'}`}
        aria-labelledby="slide-over-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

          <section className="absolute inset-y-0 right-0 pl-10 max-w-full flex" aria-labelledby="slide-over-heading">
            <div
              className={`relative w-screen max-w-md transform transition ease-in-out duration-1000 ${slideOverClasses}`}
            >
              <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll space-y-5">
                <div className="px-4 sm:px-6">
                  <div className="flex items-start justify-between">
                    <SectionTitle text={title || ''} />
                    <div className="ml-3 h-7 flex items-center">
                        <CloseButton fn={() => setIsOpen(false)}/>
                    </div>
                  </div>
                </div>
                <Divider />
                <div className="mt-6 relative flex-1 px-4 sm:px-6">
                  {children}
                </div>
                <div className="px-4 sm:px-6 self-center">
                  {secondaryButton && (
                    <SecondaryButton fn={secondaryButton.onClick} text={secondaryButton.label} isDisabled={isLoading} />
                  )}
                  {primaryButton && (
                    <PrimaryButton fn={primaryButton.onClick} text={primaryButton.label} isDisabled={isLoading}/>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default SlideOver;
