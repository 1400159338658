type Props = {
    url: string,
    cssStyles?: string,
    onClick?: () => void,
}

export function Icon({ url, cssStyles = '', onClick }: Props) {
    return (<img 
        src={url} 
        alt={'Icon'} 
        className={`w-4 min-w-[1rem] inline-block ${!!onClick ? `hover:cursor-pointer` : ''} ${cssStyles}`} 
        onClick={onClick} 
        />);
}