import React, { ReactNode } from 'react';

export interface ColumnDefinition<T> {
  key: keyof T;
  title: string;
  cellClass?: (item: T) => string;
  rowClass?: (item: T) => string;
  render?: (item: T, key: keyof T, index: number) => ReactNode;  // Updated signature
}

interface TableProps<T> {
  data: T[];
  isLoading?: boolean;
  columns: ColumnDefinition<T>[];
  emptyListComponent?: ReactNode;
  isSelectable?: boolean;
  selectedItems: T[];
  onSelect?: (item: T) => void;
  compareFunction: (item: T, selectedItem: T) => boolean;
  onRowClick?: (item: T) => void;
  renderActions?: (item: T, index: number) => ReactNode;
  dividerCondition?: (currentItem: T, nextItem: T) => boolean;
  renderDivider?: (item: T) => React.ReactNode;
  width?: string;
  wrapRow?: (row: React.ReactElement, item: T, index: number) => React.ReactElement;
  hoverClassName?: string;
}

function Table<T>({ data, isLoading, columns, emptyListComponent, dividerCondition, selectedItems = [], compareFunction, renderDivider, isSelectable, onSelect, onRowClick, hoverClassName = 'hover:bg-gray-100 hover:cursor-pointer', renderActions, wrapRow }: TableProps<T>): React.ReactElement {

  const handleSelect = (item: T) => {
    if (onSelect) {
      onSelect(item);
    }
  };

  if (isLoading) {
      return (
      <div>
          {Array.from({ length: 10 }).map((_, i) => (
          <div key={i} className="shadow p-5 w-full mx-auto animate-pulse">
              <div className="grid grid-cols-10 gap-4">
              <div className="h-2 bg-darker-gold h-3 rounded col-span-3"></div>
              <div className="h-2 bg-darker-gold rounded col-span-1"></div>
              <div className="h-2 bg-darker-gold rounded col-span-3"></div>
              <div className="h-2 bg-darker-gold h-3 rounded col-span-1"></div>
              <div className="h-2 bg-darker-gold rounded col-span-2"></div>
              </div>
          </div>
          ))}
      </div>
      );
  }

  if (data.length <= 0) {
    return <> { emptyListComponent } </>;
  }


  const handleRowClick = (item: T) => {
    if (onRowClick) {
      onRowClick(item);
    }
  };

  const renderCellContent = (item: T, key: keyof T, index: number, column: ColumnDefinition<T>): ReactNode => {
    if (column.render) {
      return column.render(item, key, index);
    }
    const content = item[key];
    if (typeof content === 'boolean' || typeof content === 'number' || typeof content === 'string') {
      return String(content);
    }
    return null;
  };

  const renderRows = () => {
    const rows = [];
  
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      const nextItem = i < data.length - 1 ? data[i + 1] : null;
      const isSelected = selectedItems.some(selectedItem => compareFunction(item, selectedItem));

      const row = (
        <tr 
          key={`row-${i}`} 
          onClick={() => handleRowClick(item)}
          className={`${hoverClassName} ${isSelected ? 'bg-gray-100' : ''} ${columns.map(col => col.rowClass?.(item)).join(' ')}`}
        >
          {isSelectable && (
            <td className="px-3 py-4 whitespace-nowrap">
              <input 
                type="checkbox" 
                checked={isSelected}
                onChange={() => handleSelect(item)}
                onClick={(e) => e.stopPropagation()} // Prevent the row click event
              />
            </td>
          )}
          {columns.map((column, columnIndex) => (
            <td
              key={`cell-${i}-${columnIndex}`}
              className={`px-3 py-4 whitespace-nowrap ${column.cellClass ? column.cellClass(item) : ''}`}
            >
              {renderCellContent(item, column.key, i, column)}
            </td>
          ))}
          {renderActions && (
            <td className="px-3 py-4 whitespace-nowrap">
              {renderActions(item, i)}
            </td>
          )}
        </tr>
      );
      rows.push(wrapRow ? wrapRow(row, item, i) : row);
  
      if (nextItem && dividerCondition && renderDivider && dividerCondition(item, nextItem)) {
        rows.push(
          <tr key={`divider-${i}`}>
            <td colSpan={columns.length + (renderActions ? 1 : 0) + (isSelectable ? 1 : 0)}>
              {renderDivider(nextItem)}
            </td>
          </tr>
        );
      }
    }
  
    return rows;
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            { isSelectable && <th className="px-3 py-3"> </th> }
            {columns.map((column, idx) => (
              <th
                key={idx}
                scope="col"
                className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                {column.title}
              </th>
            ))}
            {renderActions && <th className="text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {renderRows()}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
