import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DressItem } from '../../api/DressesAPI';
import { Material, Reference } from '../../api/ProvidersAPI';
import { useFormValidation } from '../../hooks/useFormValidation';
import useGetReferences from '../../hooks/useGetReferences';
import { Autocomplete } from '../Autocomplete/Autocomplete';
import FileUploadWrapper from '../FileUploadWrapper/FileUploadWrapper';
import IconButton from '../IconButton/IconButton';
import InputShortText from '../InputShortText/InputShortText';
import ListContainer from '../ListContainer/ListContainer';
import ListElement from '../ListElement/ListElement';
import MaterialElement from '../MaterialElement/OrderElement/MaterialElement';
import SelectInput from '../SelectInput/SelectInput';
import TagsInput from '../TagsInput/TagsInput';
import TextArea from '../TextArea/TextArea';
import { Icon } from '../Icon/Icon';
import InputNumber from '../InputNumber/InputNumber';
import { isValidText } from '../../shared/validation';
import StyledImage from '../StyledImage/StyledImage';
import CONFIG from '../../config/config';
import Divider from '../Divider/Divider';
import deleteIcon from "../../assets/images/bin.svg";
import ElementActions from '../ElementActions/ElementActions';
import { Dot } from '../Dot/Dot';
import { toastService } from '../../services/ToastService';
import TinyText from '../TinyText/TinyText';
import ImageGallery from '../ImageGallery/ImageGallery';
import loadIcon from '../../assets/images/load.svg';
import { PermissionFlags } from '../PermissionFlags/PermissionFlags';
import PriceField from '../PriceField/PriceField';

type Props = {
    dress: DressItem,
    onChange: (value: any) => void,
    isDisabled?: boolean,
    actionButton?: JSX.Element
};

const getInitialState = (partner?: DressItem) => {
    if (partner) {
      return {
        name: isValidText(partner.name, 3),
      };
    }
  };

function DressForm({ dress, onChange, isDisabled = false, actionButton }: Props) {
    const { t } = useTranslation();
    const requiredFields = ['name'];
    const initialState = getInitialState(dress);
    const [calculatedCost, setCalculatedCost] = useState<number>(dress?.cost || 0);
    const { updateFieldValidation, isFormValid } = useFormValidation(requiredFields, initialState);
    const { isLoadingReferences, searchReferences } = useGetReferences();

    const search = async (query: string, size: number, setResults: any) => {
        try {
            const response = await searchReferences(query);
            const filtered = response.slice(0, size);
            setResults(filtered);
        } catch (error) {
            console.error('Error fetching references:', error);
            setResults([]);
        }
    };

    const handleReferenceSelected = (reference: Reference) => {
        const index = dress.materials.findIndex((a: Material) => a.referenceId === reference.id);
        if (index < 0) {
            const material = {
                id: reference.id,
                quantity: 0,
                referenceCode: reference.referenceId,
                referenceId: reference.id,
                referenceName: reference.name,
                type: reference.type,
            };
            const newMaterials = [...dress.materials, material]
            onChange({ ...dress, materials: newMaterials });
        }
    };

    const handleQuantityChange = (mat: Material, newQuantity: number) => {
        const updatedMaterials = [...dress.materials];
        const index = updatedMaterials.findIndex((a: Material) => a.referenceId === mat.referenceId);
        if (index >= 0) {
            updatedMaterials[index].quantity = newQuantity;
            onChange({ ...dress, materials: updatedMaterials });
        }
    };

    const handleRemoveMaterial = (mat: Material) => {
        const updatedMaterials = [...dress.materials];
        const index = updatedMaterials.findIndex((a: Material) => a.referenceId === mat.referenceId);
        if (index >= 0) {
            updatedMaterials.splice(index, 1);
            onChange({ ...dress, materials: updatedMaterials });
        }
    };

    const disabledActionButton = actionButton
        ? React.cloneElement(
            actionButton,
            {},
            React.Children.map(actionButton.props.children, (child) => {
                return child ? React.cloneElement(child, { isDisabled: !isFormValid() }) : null;
            })
        )
        : null;

    const removeLink = (url: string) => {
        const updatedAttachments = dress?.media?.filter((attachmentUrl) => attachmentUrl !== url);
        onChange({ ...dress, media: updatedAttachments } as DressItem);
    }
    
    const removeFile = (fileName: string) => {
        const updatedFiles = dress?.files?.filter((file) => file.name !== fileName);
        onChange({ ...dress, files: updatedFiles } as DressItem);
    }

    const processFile = async (files: File[]) => {
        try {
          const newFiles = [...dress?.files || [], ...files];
          onChange({ ...dress, files: newFiles } as DressItem);
        } catch (error) {
          toastService.showToast(t("toast.errors.add_file"), 'error');
        }
    };

    // TODO: it's not refreshing the value in the input
    const recalculateCost = () => {
        toastService.showToast(t("dresses.form.cost.calculate.success"));
        const totalCost = dress.materials?.filter(m => m.quantity > 0 && m.price > 0).reduce((total, m) => total + m.price * m.quantity, 0);
        setCalculatedCost(totalCost);
        onChange({ ...dress, cost: totalCost } as DressItem);
    }

    return (
        <>
            <div className="flex flex-wrap space-x-5">
                <div className="md:flex-1 w-full">
                    <InputShortText
                        label={`${t("dresses.form.name")}${requiredFields.includes('name') ? '*' : ''}`}
                        validationFn={(value) => !!value && isValidText(value, 3)}
                        onChange={e => onChange({ ...dress, name: e.currentTarget.value })}
                        onValidationChange={(isValid) => updateFieldValidation('name', isValid)}
                        defaultValue={dress.name}
                        isDisabled={isDisabled}
                        errorMessage={"errors.too_short"}
                    />
                    <TagsInput
                        label={"dresses.form.tags"}
                        onChange={e => onChange({ ...dress, tags: e })}
                        tags={dress.tags || []}
                        isDisabled={isDisabled}
                    />
                    <SelectInput
                        isDisabled={isDisabled}
                        label={"dresses.form.main_year"}
                        defaultValue={`${dress.year}`}
                        values={[
                            { value: '2017', readableValue: '2017' },
                            { value: '2018', readableValue: '2018' },
                            { value: '2019', readableValue: '2019' },
                            { value: '2020', readableValue: '2020' },
                            { value: '2021', readableValue: '2021' },
                            { value: '2022', readableValue: '2022' },
                            { value: '2023', readableValue: '2023' },
                            { value: '2024', readableValue: '2024' },
                            { value: '2025', readableValue: '2025' },
                            { value: '2026', readableValue: '2026' },
                            { value: '2027', readableValue: '2027' },
                            { value: '2028', readableValue: '2028' },
                        ]}
                        onChange={e => onChange({ ...dress, year: e.currentTarget.value })}
                    />
                    <Divider />
                    <PermissionFlags requiredFlags={['update-dresses-prices']}>
                        <PriceField 
                            label={"dresses.form.default_price"}
                            currency={dress?.defaultSellCurrency || 'EUR'}
                            price={dress?.defaultSellPrice || 0}
                            currencies={[''].concat(CONFIG.SUPPORTED_CURRENCIES)}
                            onCurrencyChange={e => onChange({ ...dress, defaultSellCurrency: e })}
                            onPriceChange={value => onChange({ ...dress, defaultSellPrice: value, defaultSellCurrency: dress?.defaultSellCurrency || 'EUR' })}
                            isDisabled={isDisabled}
                        />
                    </PermissionFlags>
                    <PermissionFlags requiredFlags={['update-dresses-costs']}>
                        <>
                            <div className="flex flex-col space-x-1 items-center my-5">
                                <PriceField
                                    label={"global.cost"}
                                    currency={dress?.costCurrency || 'EUR'}
                                    price={dress?.cost || calculatedCost}
                                    currencies={[''].concat(CONFIG.SUPPORTED_CURRENCIES)}
                                    onCurrencyChange={e => onChange({ ...dress, costCurrency: e })}
                                    onPriceChange={value => onChange({ ...dress, cost: value, costCurrency: dress?.costCurrency || 'EUR' })}
                                    isDisabled={isDisabled}
                                />
                                <div className="flex space-x-3 mb-5">
                                    <TinyText text={t("dresses.form.cost.calculate")} />
                                    <IconButton onClick={recalculateCost} disabled={isDisabled}>
                                        <Icon url={loadIcon} cssStyles="w-5"/>
                                    </IconButton>
                                </div>
                            </div>
                        </>
                    </PermissionFlags>
                    <TextArea
                        validationFn={value => !!value && isValidText(value, 5)}
                        label={"dresses.form.notes"}
                        onChange={e => onChange({ ...dress, notes: e.currentTarget.value })}
                        defaultValue={dress.notes || ''}
                        isDisabled={isDisabled}
                    />
                </div>

                <div className="md:flex-1 max-h-screen overflow-y-scroll">
                    <Autocomplete
                        label={"dresses.form.materials"}
                        resultsSize={5}
                        searchFn={search}
                        onSelect={handleReferenceSelected}
                        placeholder={"Search for a reference"}
                        errorMessage={t('global.empty_results')!}
                        isDisabled={isDisabled}
                        isLoading={isLoadingReferences}
                        toElementFn={(item: Reference) =>
                            <ListElement onClick={() => handleReferenceSelected(item)} key={item.id}>
                                <p>{item.referenceId}</p>
                            </ListElement>}
                    />
                    <ListContainer
                        elements={dress.materials}
                        emptyListComponent={
                            <TinyText text={`${t("dresses.form.materials.empty")}.`} />
                        }
                        toElementFn={(mat: Material) =>
                            <ListElement key={mat.referenceName}>
                                <MaterialElement material={mat} actions={
                                    <>
                                        <InputNumber
                                            validationFn={(value) => !!value && value >= 0}
                                            onChange={e => handleQuantityChange(mat, Number(e.target.value))}
                                            isDisabled={isDisabled}
                                            defaultValue={mat.quantity || 0}
                                        />
                                        { !isDisabled && 
                                            <IconButton onClick={() => handleRemoveMaterial(mat)}>
                                                <Icon url={deleteIcon} cssStyles="w-5" />
                                            </IconButton> 
                                        }
                                    </>
                                } />
                            </ListElement>
                        }
                    />
                    <Divider />
                    <p className="text-sm font-extrabold">{ t("dresses.form.gallery") }:</p>
                    { (dress?.media && dress?.media.length > 0) &&
                        <ImageGallery images={dress?.media || []} canRemoveImages={!isDisabled} onImageRemove={removeLink}/>
                    }
                    <ListContainer
                        elements={dress?.files || []}
                        toElementFn={(file: File, index: number) =>
                            <ListElement>
                                <ElementActions actions={
                                    isDisabled ? <></> :
                                    <IconButton onClick={() => removeFile(file.name)}>
                                        <Icon url={deleteIcon} cssStyles="w-5" />
                                    </IconButton>
                                }>
                                <span><Dot /> {file.name}</span>
                                </ElementActions>
                            </ListElement>
                        }
                    />
                    <FileUploadWrapper
                        label={`${t("dresses.form.image")}.`}
                        onChange={processFile}
                        isDisabled={isDisabled}
                        allowedTypes={CONFIG.IMAGE_TYPES}
                        allowedMultipleFiles
                    />
                   { (!!dress.pictureUrl && !dress.media && 
                        <div className="mt-5 mb-5">
                            <StyledImage alt="Dress" src={dress.pictureUrl} />
                        </div>) || null
                    }
                </div>
            </div>
            {disabledActionButton}
        </>
    );
}

export default DressForm;
