import { t } from "i18next"
import { User } from "../../api/UsersAPI"
import CONFIG from "../../config/config"
import adminIcon from "../../assets/images/admin.png";
import Tooltip from "../Tooltip/Tooltip"
import { Icon } from "../Icon/Icon"
import { PermissionSection } from "../PermissionSection/PermissionSection"
import { useState } from "react";
import { Transition, TransitionStatus } from 'react-transition-group';
import { useEffect } from "react";
  
type UserPermissionsProps = {
    user: User,
    updateUserPermissions: (uuid: string, updatedFlags: string[]) => void
}

const duration = 300;

const defaultStyle = {
  transition: `max-height ${duration}ms ease-in-out`,
  maxHeight: 0,
  overflow: 'hidden',
}

const transitionStyles: { [key in TransitionStatus]: React.CSSProperties } = {
    entering: { maxHeight: '50vh' },
    entered:  { maxHeight: '50vh' },
    exiting:  { maxHeight: '0' },
    exited:  { maxHeight: '0' },
    unmounted: { maxHeight: '0' }
  };
  

  export function UserPermissions({ user, updateUserPermissions }: UserPermissionsProps) {
    const [isExpanded, setIsExpanded] = useState(false);
    const [overflow, setOverflow] = useState<'hidden' | 'auto'>('hidden');

    useEffect(() => {
        if (isExpanded) {
            const timer = setTimeout(() => {
                setOverflow('auto');
            }, duration);
            return () => {
                clearTimeout(timer);
            };
        } else {
            setOverflow('hidden');
        }
    }, [isExpanded]);

    const toggleExpansion = () => {
      setIsExpanded(exp => !exp);
    };
  
    return (
      <div className="w-full">
        <div className="flex items-center cursor-pointer" onClick={toggleExpansion}>
          <p>{user.email}</p>&nbsp;{user.role === 'admin' ? <Tooltip content={t("permissions.admin.info")}><Icon url={adminIcon} cssStyles="w-5" /></Tooltip> : null}
        </div>
        <Transition in={isExpanded} timeout={duration}>
            {state => (
                <div style={{
                    ...defaultStyle,
                    ...transitionStyles[state],
                    overflow,
                }}>
                    <div className="overflow-y-auto max-h-75vh">
                      <ul>
                        {CONFIG.PERMISSIONS.map((section, index) => (
                          <PermissionSection key={index} user={user} section={section} updateUserPermissions={updateUserPermissions}/>
                        ))}
                      </ul>
                    </div>
                </div>
            )}
        </Transition>
      </div>
    );
}
