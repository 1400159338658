import { useTranslation } from "react-i18next";
import Card from "../../../components/Card/Card";
import Counter from "../../../components/Counter/Counter";
import InputShortText from "../../../components/InputShortText/InputShortText";
import Pill from "../../../components/Pill/Pill";
import PrimaryButton from "../../../components/PrimaryButton/PrimaryButton";
import { isValidEmail } from "../../../shared/validation";

function PartnersSegment() {

  const { t } = useTranslation();

  return (
    <>
        <div className="flex space-x-5 justify-center">
          <Counter data={0} label={t("settings.partners.invited_count")} size="large" layout="vertical"/>
          <Card>
            <>
              <span><Pill text={t("global.soon")} /></span>
              <p>{ t("settings.partners.headline") }</p>
              <InputShortText
                label={t("settings.partners.email")!}
                validationFn={(value) => !!value && isValidEmail(value)}
                onChange={e => null}
                errorMessage={"errors.invalid_email"}
              />&nbsp;
              <PrimaryButton isDisabled text={t("settings.partners.send_button")!} fn={() => null} />
            </>
          </Card>
        </div>
    </>
  );
}

export default PartnersSegment;
