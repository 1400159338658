import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Role, Settings, updateSettings } from '../store/reducers/AccountSlice';
import CONFIG from '../config/config';
import tinycolor from 'tinycolor2';
import { useSelector } from 'react-redux';
import { instanceWithV2 } from '../api/SimpleAPIClient';
import PermissionFlagAPI from '../api/FeatureFlagAPI';

export const useConfiguration = (jwtToken: string | null) => {
  const settings: Settings = useSelector((state: any) => state.settings.value);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (settings?.isConfigurationLoaded || !jwtToken) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    const configurationPromise = instanceWithV2(CONFIG.API_ENDPOINT).get('/v1/configuration').then(response => response.data);
    const featureFlagsPromise = PermissionFlagAPI.get().then(response => response.data);

    Promise.all([configurationPromise, featureFlagsPromise])
    .then(([configurationData, featureFlagsData]) => {
      updateColorTheme(configurationData.primaryColor || CONFIG.DEFAULT_PRIMARY_COLOR, 'primary');
      updateColorTheme(configurationData.backgroundColor || CONFIG.DEFAULT_BACKGROUND_COLOR, 'secondary');
      
      updatePageMetadata(configurationData.brandName || '', `${configurationData.brandName} - eAteliers`);
      
      dispatch(updateSettings({
        tenantName: configurationData.brandName,
        isConfigurationLoaded: true,
        logoUrl: configurationData.logoUrl,
        openingHours: configurationData.openingHours || "10:00",
        closingHours: configurationData.closingHours || "17:00",
        timezone: configurationData.timezone,
        role: featureFlagsData.role as Role,
        permissions: featureFlagsData.flags || []
      }));

      sessionStorage.setItem(CONFIG.LOCAL_SESSION.FLAGS_FIELD, JSON.stringify(featureFlagsData.flags || []));
      sessionStorage.setItem(CONFIG.LOCAL_SESSION.ROLE_FIELD, featureFlagsData.role || '');

    })
    .catch((error) => {
      console.error('Failed to fetch data:', error);
    })
    .finally(() => setIsLoading(false));

    instanceWithV2(CONFIG.API_ENDPOINT).get('/v1/configuration')
    .then(response => response.data)
    .then((data) => {
      updateColorTheme(data.primaryColor || CONFIG.DEFAULT_PRIMARY_COLOR, 'primary');
      updateColorTheme(data.backgroundColor || CONFIG.DEFAULT_BACKGROUND_COLOR, 'secondary');
      
      updatePageMetadata(data.brandName || '', `${data.brandName} - eAteliers`);
      
      dispatch(updateSettings({ tenantName: data.brandName, isConfigurationLoaded: true, logoUrl: data.logoUrl, openingHours: data.openingHours || "10:00", closingHours: data.closingHours || "17:00", timezone: data.timezone }));
    })
    .catch((error) => {
      console.error('Failed to fetch configuration:', error);
    })
    .finally(() => setIsLoading(false));

  }, [dispatch, jwtToken, settings?.isConfigurationLoaded]);

  return isLoading;
};

const updateColorTheme = (color: string, colorVariable: string) => {
  const shades = generateShades(color);
  const rootStyle = document.documentElement.style;

  for (const [key, value] of Object.entries(shades)) {
    rootStyle.setProperty(`--color-${colorVariable}-${key}`, value);
  }
};

const generateShades = (baseColor: string) => {
  return {
    '50': tinycolor(baseColor).lighten(40).toString(),
    '100': tinycolor(baseColor).lighten(30).toString(),
    '200': tinycolor(baseColor).lighten(20).toString(),
    '300': tinycolor(baseColor).lighten(10).toString(),
    'default': baseColor,
    '500': tinycolor(baseColor).darken(10).toString(),
    '600': tinycolor(baseColor).darken(20).toString(),
    '700': tinycolor(baseColor).darken(30).toString(),
    '800': tinycolor(baseColor).darken(40).toString(),
  };
};

const updatePageMetadata = (tenantName: string, description: string) => {
  document.title = tenantName ? `${tenantName} - eAteliers` : `Login - eAteliers`;

  let metaDescription = document.querySelector('meta[name="description"]');
  if (!metaDescription) {
    metaDescription = document.createElement('meta');
    metaDescription.setAttribute('name', 'description');
    document.head.appendChild(metaDescription);
  }
  metaDescription.setAttribute('content', description);
};
