import { memo, useState } from "react";
import InputShortText from "../InputShortText/InputShortText";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import { Auth } from 'aws-amplify';
import { useLocation, useNavigate } from "react-router-dom";
import InputPassword from "../InputPassword/InputPassword";
import { Role, setSettings, Settings } from "../../store/reducers/AccountSlice";
import { useSelector } from "react-redux";
import FeatureFlagsAPI from "../../api/FeatureFlagAPI";
import { useDispatch } from "react-redux";
import CONFIG from "../../config/config";
import { useTranslation } from "react-i18next";
import * as amplitude from '@amplitude/analytics-browser';
import { datadogRum } from '@datadog/browser-rum';

interface Props {
  callbackFn?: (a?: any) => void;
  onPasswordChangeRequired?: (username: string) => void;
}

const LoginForm: React.FC<Props> = ({ callbackFn, onPasswordChangeRequired }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const settings: Settings = useSelector((state: any) => state.settings.value);
    let navigate = useNavigate();
    let location = useLocation();
    
    const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
      setIsLoading(true);
      try {
        const user = await Auth.signIn(email, password);
          if (user.challengeName && user.challengeName === "NEW_PASSWORD_REQUIRED") {
            onPasswordChangeRequired && onPasswordChangeRequired(email);
          } else {
            const jwtToken = user.signInUserSession.idToken.jwtToken
            sessionStorage.setItem(CONFIG.LOCAL_SESSION.TOKEN, jwtToken);
            
            const userConfig = await FeatureFlagsAPI.get();
            const updatedSettings = 
              {...settings, permissions: userConfig.data.flags || [], role: userConfig.data.role as Role, isLoggedIn: true  };
            dispatch(setSettings(updatedSettings));

            const identifyObj = new amplitude.Identify();
            identifyObj.set('userId', settings.userName);
            amplitude.track('User logged in');

            datadogRum.setUser({
              id: settings.userName,
              // role: settings.role,
              // plan: 'premium'
            });
            
            sessionStorage.setItem(CONFIG.LOCAL_SESSION.FLAGS_FIELD, JSON.stringify(userConfig.data.flags || []));
            sessionStorage.setItem(CONFIG.LOCAL_SESSION.ROLE_FIELD, userConfig.data.role || '');

            if (callbackFn) {
              callbackFn();
            } else {
              navigate(location.state?.from || '/calendar');
            }
          }
        } catch (error: any) {
          setError(t("errors.on_login")!);
          amplitude.track("Error Log in failed");
        } finally {
          setIsLoading(false);
        }
      };

    return (
        <form className="bg-white flex flex-col p-6 shadow-md rounded w-full md:w-96">
          <div className="mb-4">
            <InputShortText 
            validationFn={(value) => !!value && value.length > 3} 
            onChange={e => setEmail((prev) => e.target.value)}
            errorMessage={"errors.too_short"}
            label={t("global.email")!}
            isLoading={isLoading}
            />
          </div>
          <div className="mb-4">
            <InputPassword 
                validationFn={(value) => !!value && value.length > 3} 
                onChange={e => setPassword((prev) => e.target.value)}
                errorMessage={"errors.too_short"}
                label={t("global.password")!}
                isLoading={isLoading}
            />
          </div>
          {error && <p className="text-red-500 text-xs italic mb-4">{t(error)}</p>}
          <br/>
          <PrimaryButton text={t("registration.login")!} fn={handleLogin} isDisabled={isLoading} eventName="log_in_button_clicked" actionName="Log In Button"/>
        </form>
  )
}

export default memo(LoginForm);