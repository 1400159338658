import React from 'react';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import SecondaryButton from '../SecondaryButton/SecondaryButton';

interface ActionCardProps {
  title: string;
  description: string;
  secondaryText?: string;
  primaryAction: { label: string; onClick: () => void };
  secondaryAction?: { label: string; onClick: () => void };
}

const Banner: React.FC<ActionCardProps> = ({
  title,
  description,
  secondaryText,
  primaryAction,
  secondaryAction,
}) => {
  return (
    <div className="mx-auto p-12 bg-gradient-to-tr from-red-500 to-blue-800 rounded-lg shadow-md flex items-center justify-between flex-col lg:flex-row text-white">
      <div className="flex flex-col lg:flex-row lg:items-center">
        <div className="mb-4 lg:mb-0 lg:mr-6">
          <h2 className="text-3xl font-bold">{title}</h2>
          <p className="mt-2 text-gray-200 font-bold">{description}</p>
          { secondaryText && <p className="mt-2 text-gray-200 font-bold">{secondaryText}</p> }
        </div>
      </div>
      <div className="flex flex-col space-y-4">
        <PrimaryButton
          text={primaryAction.label}
          fn={primaryAction.onClick}
        />
        { secondaryAction && <SecondaryButton
          fn={secondaryAction.onClick}
          text={secondaryAction.label}
        /> }
      </div>
    </div>
  );
};

export default Banner;
