import { useState } from "react";
import { useTranslation } from "react-i18next";
import OrdersAPI, { ExternalOrder, isExternalOrder, isInternalOrder, Order, OrderDress } from "../../api/OrdersAPI";
import { CancelToken } from "../../api/SimpleAPIClient";
import { Filters, useGetOrders } from "../../hooks/useGetOrders";
import { useGetShops } from "../../hooks/useGetShops";
import { Autocomplete } from "../Autocomplete/Autocomplete";
import Collapsable from "../Collapsable/Collapsable";
import DropSelect from "../DropSelect/DropSelect";
import FilterPill from "../FilterPill/FilterPill";
import { Icon } from "../Icon/Icon";
import IconButton from "../IconButton/IconButton";
import { Modal } from "../Modal/Modal";
import OrderBasket from "../OrderBasket/OrderBasket";
import { containsFileType } from "../OrderElement/OrderElement";
import OrderForm from "../OrderForm/OrderForm";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import PrintButton from "../PrintButton/PrintButton";
import deleteIcon from "../../assets/images/bin.svg";
import editIcon from "../../assets/images/pen.svg";
import hideIcon from "../../assets/images/eye.svg";
import info from "../../assets/images/info.svg";
import showIcon from "../../assets/images/eye-slash.svg";
import scaleIcon from "../../assets/images/scale.png";
import disabledScaleIcon from "../../assets/images/disabled-scale.png";
import outIcon from "../../assets/images/arrow-up-right.svg";
import inIcon from "../../assets/images/arrow-down-right.svg";
import ConfirmationPopup from "../ConfirmationPopup/ConfirmationPopup";
import { ShopClient } from "../../api/ShopClientsAPI";
import { format } from "date-fns";
import { FormType } from "../../shared/form.type";
import Subtitle from "../Subtitle/Subtitle";
import { PermissionFlags } from "../PermissionFlags/PermissionFlags";
import SimpleListElement from "../SimpleListElement/SimpleListElement";
import { paymentStatusToAbbreviatedTranslationKey, paymentStatusToPillColor, paymentStatusToTranslationKey } from "../../shared/paymentStatusToTranslationKey";
import Pill from "../Pill/Pill";
import { BolderText } from "../BolderText/BolderText";
import useFeatureFlag from "../../hooks/usePermissionFlag";
import lowPriorityIcon from '../../assets/images/warning-off.png';
import highPriorityIcon from '../../assets/images/warning.png';
import Card from "../Card/Card";
import WorkArea from "../WorkArea/WorkArea";
import NoDataCTA from "../NoDataCTA/NoDataCTA";
import SecondaryButton from "../SecondaryButton/SecondaryButton";
import Tab from "../Tab/Tab";
import * as amplitude from '@amplitude/analytics-browser';
import { Link } from "../Link/Link";
import Table, { ColumnDefinition } from "../Table/Table";
import Tooltip from "../Tooltip/Tooltip";
import { orderStatusToTranslationKeys } from "../../shared/orderStatusToTranslationKey";
import CONFIG from "../../config/config";
import NoData from "../NoData/NoData";
import ExternalOrderForm from "../ExternalOrderForm/ExternalOrderForm";
import { externalOrderStatusToColor, externalOrderStatusToTranslationKeys } from "../../shared/externalOrderStatusToTranslationKey";

const renderOrderDressName = (dress?: OrderDress, dressDescription?: string): React.ReactNode => {
  return (
    <Tooltip content={dress?.name || dressDescription || '-'}>
      <p className="w-32 truncate">{dress?.name || dressDescription}</p>
    </Tooltip>
  );
};

export type OrderColumn = ColumnDefinition<Order>;
export type ExternalOrderColumn = ColumnDefinition<ExternalOrder>;

function OrdersSegment() {

  const { t } = useTranslation();

  const { hasAccess: canPrioritize } = useFeatureFlag(['set-high-priority-orders']);
  const { hasAccess: canSetPendingMeasurements } = useFeatureFlag(['set-pending-measures-orders']);
  const [filters, setFilters] = useState<Filters>({ shopId: '', date: '', hidden: false, external: false });
  const { isLoadingOrders, orders, externalOrders, updateFn, removeFn, refresh } = useGetOrders(filters);
  const [searchIsLoading, setSearchIsLoading] = useState(false);
  const [showBasket, setShowBasket] = useState(false);
  const [order, setOrder] = useState<Order | ExternalOrder | null>();
  const { shops } = useGetShops();
  const [action, setAction] = useState<FormType>('READ');
  const [temporaryList, setTemporaryList] = useState<any[]>([]);

  const orderColumns: OrderColumn[] = [
    {
      key: 'shopName',
      title: t("orders.details.salon"),
      render: (order) => 
      <Tooltip content={order.shopName!}>
        <p className="w-36 truncate">{order.shopName}</p>
      </Tooltip>,
      rowClass: (or) => 
      `
        ${or.visible && or.status !== 'BLOCKED' ? 'hover:bg-darker-gold-50' : ''} 
        ${or.status === 'BLOCKED' ? 'bg-custom-red' : ''}
        ${!or.visible ? 'bg-darker-gold-100 text-grey-50' : ''} 
        ${or.status === 'SENT' && or.visible ? 'bg-darker-gold-300' : ''}
      `
    },
    {
      key: 'paymentStatus',
      title: t("orders.details.payment_status"),
      render: (order) => (
        <Tooltip content={t(paymentStatusToTranslationKey(order.paymentStatus))}>
          <Pill text={t(paymentStatusToAbbreviatedTranslationKey(order.paymentStatus))} color={paymentStatusToPillColor(order.paymentStatus)}/>
        </Tooltip>
      ),
    },
    {
      key: 'clientName',
      title: t("orders.details.name"),
      render: (order) => <Tooltip content={order.clientName}><p className="w-36 truncate">{order.clientName}</p></Tooltip>,
    },
    {
      key: 'dress',
      title: t("orders.details.description"),
      render: (order) => renderOrderDressName(order.dress, order.dressDescription),
    },
    {
      key: 'deliveryDate',
      title: t("orders.details.delivery_time"),
      render: (order) => <BolderText text={order.deliveryDate} /> ,
    },
    {
      key: 'status',
      title: t("orders.details.status"),
      render: (order) => <Tooltip content={t(orderStatusToTranslationKeys(order.status))}><p className="w-12 sm:w-20 truncate">{ t(orderStatusToTranslationKeys(order.status)) }</p></Tooltip>,
    },
    {
      key: 'attachmentUrls',
      title: t("orders.details.files"),
      render: (order) => (
        <div className="flex space-x-3">
          { order.notes? <span className="w-3">📝</span> : null }
          { containsFileType(order.attachmentUrls || [], CONFIG.VIDEO_TYPES)? <span className="w-3">📹</span> : null }
          { containsFileType(order.attachmentUrls || [], CONFIG.TEXT_TYPES.concat(CONFIG.IMAGE_TYPES))? <span className="w-3">📁</span> : null }
        </div>
      ),
    },
  ];

  const externalOrderColumns: ExternalOrderColumn[] = [
    {
      key: 'b2bType',
      title: "",
      render: (order: any) => {
        return order.b2bType === "OUT" ?
          <Tooltip position="right" content={t("orders.listing.external.outcoming")}><Icon url={outIcon} cssStyles="w-5"/></Tooltip>
          : <Tooltip position="right" content={t("orders.listing.external.incoming")}><Icon url={inIcon} cssStyles="w-5"/></Tooltip>
        },
    },
    {
      key: 'receiverShopName',
      title: t("orders.external.details.shop_name"),
      render: (order: any) =>
        order.b2bType === "OUT" ?
        <Tooltip content={order.receiverShopName}><p className="w-36 truncate">{order.receiverShopName}</p></Tooltip>
        : <Tooltip content={order.requesterShopName}><p className="w-36 truncate">{order.requesterShopName}</p></Tooltip>,
    },
    {
      key: 'clientName',
      title: t("orders.external.details.name"),
      render: (order: any) => <Tooltip content={order.clientName}><p className="w-36 truncate">{order.clientName}</p></Tooltip>,
    },
    {
        key: 'itemName',
        title: t("orders.external.details.item_name"),
        render: (order: any) => <p>{order.itemName}</p>,
    },
    {
      key: 'inStoreDate',
      title: t("orders.external.details.delivery_time"),
      render: (order: any) => <BolderText text={order.inStoreDate} /> ,
    },
    {
      key: 'status',
      title: t("orders.external.details.status"),
      render: (order: any) =>
        (<>
        <Tooltip content={t(externalOrderStatusToTranslationKeys(order.status))}>
          <p className="w-12 sm:w-20 truncate">
            <Pill text={t(externalOrderStatusToTranslationKeys(order.status))} color={externalOrderStatusToColor(order.status)}/>
          </p>
        </Tooltip>
        { order.deletableFrom && 
          <Tooltip content={t("orders.external.details.status.deletable") + " " + order.deletableFrom}>
            <Icon url={info} cssStyles="w-3"/>
          </Tooltip>
        }
        </>),
    },
    {
      key: 'attachmentUrls',
      title: t("orders.external.details.files"),
      render: (order: any) => (
        <div className="flex space-x-3">
          { order.notes? <span className="w-3">📝</span> : null }
          { containsFileType(order.attachmentUrls || [], CONFIG.VIDEO_TYPES)? <span className="w-3">📹</span> : null }
          { containsFileType(order.attachmentUrls || [], CONFIG.TEXT_TYPES.concat(CONFIG.IMAGE_TYPES))? <span className="w-3">📁</span> : null }
        </div>
      ),
    },
  ];

  const handleSelection = (itemOrder: Order) => {
  
    setTemporaryList(prevList => {
      if (prevList.some(order => order?.id === itemOrder.id)) {
        return prevList.filter(order => order?.id !== itemOrder.id);
      } else {
        return [...prevList, itemOrder];
      }
    });
  };

  const actionButton = (action: FormType) => {
    if (isLoadingOrders) {
      return <span className="flex justify-center"><PrimaryButton text="..." isLoading /></span>
    }
    switch (action) {
      case 'UPDATE':
        return <span className="flex justify-center"><PrimaryButton text={t("admin.collections.items.form.update")!} fn={processUpdateOrder} /></span>;
      default:
        return;
    }
  };

  const search = (input: string, resultsSize: number, setResultsFn: any) => {
    setSearchIsLoading(true);
    OrdersAPI.search(input).then((resp) => {
      const raw = resp.data;
      const respRaw = raw.slice(0, resultsSize);
      setSearchIsLoading(false);
      setResultsFn(respRaw);
    });
    return CancelToken.source().cancel("Cancelling...");
  };

  const processUpdateOrder = () => {
    if (isInternalOrder(order)) {
      processItem(updateFn, order);
    } else if (isExternalOrder(order)) {
      processItem(updateFn, order);
    }
  }

  async function processItem(actionFn: (item: Order | ExternalOrder) => Promise<void>, item: Order | ExternalOrder
  ) {
    await actionFn(item);
    setOrder(null);
    setAction('READ');
    refresh();
  }

  const view = (or: Order) => {
    setOrder(or);
    amplitude.track('Order opened');
  }

  const changePriority = async (or: Order) => {
    const existing = or.prioritized;
    const reverse = !existing;
    or.prioritized = reverse;
    processItem(updateFn, or);
  };

  const setPendingMeasurements = async (or: Order) => {
    const existing = or.pendingMeasures;
    const reverse = !existing;
    or.pendingMeasures = reverse;
    processItem(updateFn, or);
  };

  const hide = async (or: Order) => {
    or.visible = !or.visible;
    processItem(updateFn, or);
  };

  const setShopFilter = (s: ShopClient) => {
    amplitude.track('Order Filter touched', { name: s.name });
    setShop(s);
  }

  const setShop = (s: ShopClient) => {
    setFilters(prev => { 
      if (prev.shopId === String(s.id!)) {
        let copy = Object.assign({}, prev); 
        return {...copy, shopId: ''};
      } else {
        let copy = Object.assign({}, prev); 
        return {...copy, shopId: String(s.id!)};
      }
    })
  }

  const setHidden = (status: boolean) => {
    setFilters(prev => { 
        let copy = Object.assign({}, prev); 
        return {...copy, hidden: status, external: false};
    });
  }

  const setExternal = (status: boolean) => {
    setFilters(prev => {
        let copy = Object.assign({}, prev);
        return {...copy, external: status};
    });
  }

  const setDate = (e: Date) => { 
    if (e) {
      const tempDate = new Date(e);
      const formatTempDate = format(tempDate!, "yyyy-MM-dd");
      setFilters(prev => { return {...prev, date: formatTempDate}})
    } else {
      setFilters(prev => { return {...prev, date: ''}});
    }
  }

  const isDifferentMonth = (date1: Date, date2: Date) => {
    return date1.getMonth() !== date2.getMonth() || date1.getFullYear() !== date2.getFullYear();
  };

  const renderActions = (order: Order, index: number): React.ReactNode => {
    return (
      <span className="flex">
        <IconButton 
          actionName="Pending Measurements"
          onClick={() => { if (canSetPendingMeasurements) setPendingMeasurements(order); }}>
          <Tooltip content={t("orders.actions.measures")}>
            <Icon url={order?.pendingMeasures ? scaleIcon : disabledScaleIcon} cssStyles="w-5"/>
          </Tooltip>
        </IconButton>
        <IconButton 
          actionName="Set High Priority"
          onClick={() => { if (canPrioritize) changePriority(order); }}>
          <Tooltip content={t("orders.actions.priority")}>
            <Icon url={order?.prioritized ? highPriorityIcon : lowPriorityIcon} cssStyles="w-5"/>
          </Tooltip>
        </IconButton>
        <PermissionFlags requiredFlags={['hide-orders'] || []}>
          <IconButton 
            actionName="Hide/Show Order"
            onClick={() => hide(order)}>
            <Tooltip content={order.visible ? t("orders.actions.hide") : t("orders.actions.show")}>
              <Icon url={order.visible ? hideIcon : showIcon } cssStyles="w-5"/>
            </Tooltip>
          </IconButton>
        </PermissionFlags>
        <PermissionFlags requiredFlags={['update-orders', 'order-story-write'] || []}>
          <IconButton 
            actionName="Update Order"
            onClick={() => { setOrder(order); setAction('UPDATE');}}>
            <Icon url={editIcon} cssStyles="w-5"/>
          </IconButton>
        </PermissionFlags>
        <PermissionFlags requiredFlags={['remove-orders'] || []}>
          <IconButton
            actionName="Delete Order"
            >
            <ConfirmationPopup onConfirm={() => removeFn(order)} confirmText={"global.confirm"} title={`${t("global.remove")}`}>
              <Icon url={deleteIcon} cssStyles="w-5"/>
            </ConfirmationPopup>
          </IconButton>
        </PermissionFlags>
      </span>
    );
  };

  const renderActionsOnExternal = (order: ExternalOrder, index: number): React.ReactNode => {
    return (
      <span className="flex">
        <PermissionFlags requiredFlags={['update-orders', 'order-story-write'] || []}>
          <IconButton 
            actionName="Delete External Order"
            onClick={() => { setOrder(order); setAction('UPDATE');}}>
            <Icon url={editIcon} cssStyles="w-5"/>
          </IconButton>
        </PermissionFlags>
        <PermissionFlags requiredFlags={['remove-orders'] || []}>
          <IconButton
            actionName="Edit External Order"
            >
            <ConfirmationPopup onConfirm={() => removeFn(order)} confirmText={"orders.listing.external.remove.warning"} title={`${t("global.remove")}`}>
              <Icon url={deleteIcon} cssStyles="w-5"/>
            </ConfirmationPopup>
          </IconButton>
        </PermissionFlags>
      </span>
    );
  };

  return (
      <div className="mt-5 space-y-5">
      <Card>
        <>
          <div className="flex-col md:flex md:flex-row justify-between items-center space-x-3">
            <DropSelect onSelect={(e: any) => setDate(e.target.value)} value={filters?.date?.toString()}/>
            <Autocomplete
              resultsSize={15}
              searchFn={search}
              onSelect={() => null}
              placeholder={"orders.search.placeholder"} 
              isLoading={searchIsLoading}
              errorMessage={"global.empty_results"}
              toElementFn={(or: Order) => 
                <SimpleListElement onClick={() => view(or)} key={or.id} actions={
                  <>
                    <IconButton onClick={() => { if (canSetPendingMeasurements) setPendingMeasurements(or); }}>
                      <Icon url={or?.pendingMeasures ? scaleIcon : disabledScaleIcon} cssStyles="w-5"/>
                    </IconButton>
                    <IconButton onClick={() => { if (canPrioritize) changePriority(or); }}>
                      <Icon url={or?.prioritized ? highPriorityIcon : lowPriorityIcon} cssStyles="w-5"/>
                    </IconButton>
                    <PermissionFlags requiredFlags={['hide-orders'] || []}>
                      <IconButton onClick={() => hide(or)}>
                          <Icon url={or.visible ? hideIcon : showIcon }/>
                        </IconButton>
                    </PermissionFlags>
                    <PermissionFlags requiredFlags={['update-orders', 'order-story-write'] || []}>
                      <IconButton onClick={() => { setOrder(or); setAction('UPDATE');}}>
                        <Icon url={editIcon}/>
                      </IconButton>
                    </PermissionFlags>
                  </>
                }>
                  <div className="flex space-x-5 justify-between">
                    <span className="w-24">
                      <BolderText text={or.shopName || ''} />
                    </span>
                    <span className="w-14 sm:w-20">
                      <Pill text={t(paymentStatusToAbbreviatedTranslationKey(or.paymentStatus))} color={paymentStatusToPillColor(or.paymentStatus, or.shopIdV2 === 'ATELIER')}/>
                    </span>
                    <span className="w-24 truncate">
                      { or?.clientName }
                    </span>
                    <span className="w-36 truncate">
                      { `${or.dress?.name}` }
                    </span>
                    <span className="w-24">
                      <BolderText text={or?.deliveryDate} /> 
                    </span>
                  </div>
                </SimpleListElement>
              }
            />
          </div>
          <Collapsable textOnExpanded={"orders.filters.shops.hide"} textOnHiden={"orders.filters.shops.expand"}>
            <div className="container flex flex-wrap max-h-48 overflow-y-scroll">
              {
                shops.length > 0 ? shops.map((s, i) => <FilterPill isActive={filters?.shopId === String(s.id)} key={i} text={s.name} fn={() => setShopFilter(s)} />)
                : <>
                    <p>{t("orders.filters.shops.empty")}.</p>&nbsp;
                    <Link url="/partners" text={t("orders.filters.shops.empty.cta")}/>
                  </>
              }
            </div>
          </Collapsable>
          <div className="flex-col md:flex md:flex-row justify-between md:justify-start items-center space-x-4">
            <PermissionFlags requiredFlags={['order-add'] || []}>
              <PrimaryButton actionName="Open/Close Order Basket" text={showBasket ? t("orders.orders_basket.close_form")! : t("orders.orders_basket.add_form")!} fn={() => setShowBasket(prev => !prev)}/>
            </PermissionFlags>
            <PermissionFlags requiredFlags={['print-orders'] || []}>
              <PrintButton orders={orders}/>
            </PermissionFlags>
            <SecondaryButton actionName="Show All Orders" text={t("orders.filters.reset")!} fn={() => setFilters({ shopId: '', date: '', hidden: filters.hidden })} isDisabled={!filters.date && !filters.shopId}/>
          </div>
        </>
      </Card>
        { showBasket && <><br/><OrderBasket fn={refresh} /></> }
        <Card>
          <>
            <div className="flex">
              <Tab fn={() => setHidden(false)} label={t("orders.listing.visible")} isActive={!filters.hidden && !filters.external}/>
              <Tab fn={() => {amplitude.track('Closed Orders tab clicked'); setHidden(true)}} label={t("orders.listing.hidden")} isActive={filters.hidden && !filters.external}/>
              <Tab fn={() => {amplitude.track('External Orders tab clicked'); setExternal(true)}} label={t("orders.listing.incoming")} isActive={filters.external}/>
            </div>
            {
              filters.external ? (
                <div className="container">
                  <Table<ExternalOrder>
                      isLoading={isLoadingOrders}
                      data={externalOrders}
                      columns={externalOrderColumns}
                      onRowClick={(or) => setOrder(or)}
                      emptyListComponent={<NoData message={t("orders.empty.cta.title")} />}
                      selectedItems={[]}
                      isSelectable={false}
                      compareFunction={(item, selectedItem) => item.id === selectedItem.id}
                      dividerCondition={(current, next) => isDifferentMonth(new Date(current.inStoreDate), new Date(next.inStoreDate))}
                      renderDivider={(current) => (
                        <div className="mt-10 mb-4">
                          <Subtitle text={t(`global.${format(new Date(current.inStoreDate), "MMMM").toLowerCase()}`)} />
                        </div>
                      )}
                      renderActions={renderActionsOnExternal}
                  />
                </div>
              )
            :
            <>
              <div className="container">
                <Table<Order>
                  isLoading={isLoadingOrders}
                  data={orders}
                  columns={orderColumns}
                  onRowClick={(or) => setOrder(or)}
                  compareFunction={(item, selectedItem) => item.id === selectedItem.id}
                  emptyListComponent={
                    <NoDataCTA
                      headline={t("orders.empty.cta.title")}
                      subtext={t("orders.empty.cta.body")}
                      primaryActionText={t("orders.orders_basket.new_order")}
                      onPrimaryAction={() => setShowBasket(prev => !prev)}
                    />
                  }
                isSelectable
                onSelect={handleSelection}
                renderActions={renderActions}
                dividerCondition={(current, next) => isDifferentMonth(new Date(current.deliveryDate), new Date(next.deliveryDate))}
                renderDivider={(current) => (
                  <div className="mt-10 mb-4">
                    <Subtitle text={t(`global.${format(new Date(current.deliveryDate), "MMMM").toLowerCase()}`)} />
                  </div>
                )}
                selectedItems={temporaryList}
                />
              </div>
            </>}
          </>
        </Card>
        {temporaryList.length > 0 && 
          <WorkArea 
            items={temporaryList}
            callbackFn={setTemporaryList}
            renderItem={(item: any) => <span>{item?.clientName}</span>}
            onItemToggle={handleSelection}
            actions={
              <>
                <SecondaryButton fn={() => console.log(temporaryList)} text={t("global.remove")!} isDisabled/>
                <PermissionFlags requiredFlags={['print-orders'] || []}>
                  <PrintButton orders={temporaryList}/>
                </PermissionFlags>
              </>
            }
          /> 
        }
        { order && 
          <Modal modalTitle={order.clientName} canClose onClose={() => {setOrder(null); setAction('READ');}}>
            { isInternalOrder(order) ?
              <OrderForm
                order={order}
                isDisabled={action === 'READ' || isLoadingOrders}
                onOrderChange={setOrder}
                actionButton={actionButton(action)}
              />
              : <ExternalOrderForm
                order={order}
                isDisabled={action === 'READ' || isLoadingOrders}
                onOrderChange={setOrder}
                actionButton={actionButton(action)}
              />
            }
          </Modal>
        }
      </div>
  );
}

export default OrdersSegment;
