import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Order, OrderDress } from "../../api/OrdersAPI";
import CONFIG from "../../config/config";
import { withSelectable } from "../../hooks/withSelectable";
import { itemTypeToIcon } from "../../shared/itemTypeToIcon";
import { orderStatusToTranslationKeys } from "../../shared/orderStatusToTranslationKey";
import { paymentStatusToAbbreviatedTranslationKey, paymentStatusToPillColor, paymentStatusToTranslationKey } from "../../shared/paymentStatusToTranslationKey";
import { BolderText } from "../BolderText/BolderText";
import Pill from "../Pill/Pill";
import Tooltip from "../Tooltip/Tooltip";

type Props = {
  order: Order,
  actions?: JSX.Element
};

export const containsFileType = ((files: string[], types: string[]) => files.some(f => types.some(t => f.toUpperCase().endsWith(t.toUpperCase()))));

function OrderElement({order, actions}: Props) {

  const { t } = useTranslation();
  const notesIcon = useMemo(() => order.notes? '📝' : null, [order.notes]);
  const videoIcon = useMemo(() => containsFileType(order.attachmentUrls || [], CONFIG.VIDEO_TYPES)? '📹' : null, [order.attachmentUrls]);
  const filesIcon = useMemo(() => containsFileType(order.attachmentUrls || [], CONFIG.TEXT_TYPES.concat(CONFIG.IMAGE_TYPES))? '📁' : null, [order.attachmentUrls]);

  return ( 
    <div className="flex w-full justify-between space-x-5 items-center">
        <Tooltip content={order.shopName || ''}>
          <p className="w-42 truncate uppercase"><BolderText text={ (order.shopName) || t("global.unknown")! }/></p>
        </Tooltip>
        <span className="w-20 sm:w-14">
          <Tooltip content={t(paymentStatusToTranslationKey(order.paymentStatus))}>
            <Pill text={t(paymentStatusToAbbreviatedTranslationKey(order.paymentStatus))} color={paymentStatusToPillColor(order.paymentStatus, order.shopIdV2 === 'ATELIER')}/>
          </Tooltip>
        </span>
        <p className="w-3 sm:w-3">{ itemTypeToIcon(order.type) }</p>
        <Tooltip content={order.clientName}>
          <p className="w-12 sm:w-36 truncate">{ order.clientName }</p>
        </Tooltip>
        <Tooltip content={order.dress?.name || ''}>
          <p className="w-36 sm:w-12 truncate">{ (order.dress as OrderDress)?.name || order.dressDescription }</p>
        </Tooltip>
        <p className="w-12 sm:w-20 truncate"><BolderText text={ order.deliveryDate }/></p>
        <p className="w-12 sm:w-20 truncate">{ t(orderStatusToTranslationKeys(order.status)) }</p>
        <span className="flex space-x-3">
          <p className="w-3">{ notesIcon }</p>
          <p className="w-3">{ videoIcon }</p>
          <p className="w-3">{ filesIcon }</p>
        </span>
        <span className="flex">
          { actions }
        </span>
    </div>
  );
}

export default withSelectable(OrderElement);
