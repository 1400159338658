import { useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import DangerPrimaryButton from "../DangerPrimaryButton/DangerPrimaryButton";
import { Modal } from "../Modal/Modal";

type Props = {
  title?: string,
  onConfirm: (extraState?: any) => void;
  onCancel?: () => void;
  confirmText: string;
  children: React.ReactNode;
  extraContent?: (setExtraState: React.Dispatch<React.SetStateAction<any>>) => React.ReactNode;
};

function ConfirmationPopup({ title, onConfirm, onCancel, confirmText, children, extraContent }: Props) {

  const { t } = useTranslation();

  const [showPopup, setShowPopup] = useState(false);
  const [extraState, setExtraState] = useState<any>(null);

  const handleConfirm = () => {
    if (extraContent && extraState === null) {
      // Check if the extraContent does not require extraState
      const requiresExtraState = typeof extraContent === 'function' && extraContent.length > 0;
      if (!requiresExtraState) {
        onConfirm();
        setShowPopup(false);
      } else {
        // Do nothing or display an error, as an option must be selected before confirmation.
      }
    } else {
      onConfirm(extraState);
      setShowPopup(false);
    }
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
    setShowPopup(false);
  };

  const modal = showPopup && (
    <Modal modalTitle={title || "global.confirmation_popup.title"} canClose onClose={handleCancel}>
      <>
        <p className="py-5">{ t("global.confirmation_popup.body") }</p>
        {extraContent ? extraContent(setExtraState) : null}
        <div className="flex justify-center mt-5">
          <DangerPrimaryButton text={t(confirmText)!} fn={handleConfirm} eventName={`confirm_action_popup`}/>
        </div>
      </>
    </Modal>
  );

  const modalRoot = document.getElementById("modal-root");

  return (
    <>
      {modalRoot && ReactDOM.createPortal(modal, modalRoot)}
      <span onClick={() => setShowPopup(true)}>{children}</span>
    </>
  );
}

export default ConfirmationPopup;
