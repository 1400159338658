import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import BarChart from '../../../components/BarChart/BarChart';
import NoData from '../../../components/NoData/NoData';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
import { useStatistics } from '../../../hooks/useStatistics';

function TotalOrdersPerYear() {
  const { t } = useTranslation();
  const data = useStatistics('orders-total-per-year');
  const [chartData, setChartData] = useState<any[]>([]);

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      const chartValues = Object.values(data);
      const backgroundColor = ['rgba(75, 192, 192, 0.6)'];
      setChartData([{
        label: 'Total Orders',
        data: chartValues,
        backgroundColor,
        borderColor: backgroundColor,
        borderWidth: 1,
      }]);
    }
  }, [data]);

  return (
    <>
      <SectionTitle text={t("stats.total_orders_per_year")} />
      {chartData.length > 0 ? (
        <BarChart title="Total Orders" xLabels={Object.keys(data)} dataSets={chartData} />
      ) : (
        <NoData message={t("global.empty_results")} />
      )}
    </>
  );
}

export default TotalOrdersPerYear;
