import React, { useState, useRef, useEffect, ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PermissionFlags } from '../PermissionFlags/PermissionFlags';

interface SubmenuItem {
  headline: string;
  text: ReactNode;
  href?: string;
  onClick?: () => void;
  isDisabled?: boolean;
  flags?: string[];
  icon?: ReactNode;
  styles?: React.CSSProperties;
}

interface MenuItem {
  headline: string;
  text: ReactNode;
  href?: string;
  onClick?: () => void;
  isDisabled?: boolean;
  flags?: string[];
  submenuItems?: SubmenuItem[];
  icon?: ReactNode;
  styles?: React.CSSProperties;
}

interface MenuProps {
  menuItems: MenuItem[];
  trigger: ReactNode;
  mobileTrigger?: ReactNode;
}

const FlyoutMenu: React.FC<MenuProps> = ({ menuItems, trigger, mobileTrigger }) => {
  const [openSubmenus, setOpenSubmenus] = useState<{ [key: number]: boolean }>({});
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const submenuRef = useRef<HTMLDivElement>(null);

  const isActive = (href: string) => {
    return location.pathname === href;
  };

  const handleNavigation = (path: string) => {
    navigate(path);
    setIsMenuOpen(false);
  };

  const handleSubmenuClick = (index: number) => {
    setOpenSubmenus(prevState => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (submenuRef.current && !submenuRef.current.contains(event.target as Node)) {
      setOpenSubmenus({});
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={submenuRef}>
      {/* Trigger button */}
      <div className="cursor-pointer" onClick={() => setIsMenuOpen(prev => !prev)}>
        <div className="block lg:hidden">{mobileTrigger || trigger}</div>
        <div className="hidden lg:block">{trigger}</div>
      </div>

      {/* Full-screen overlay on small screens */}
      {isMenuOpen && (
        <>
          <div className="fixed inset-0 bg-black bg-opacity-50 md:hidden" onClick={() => setIsMenuOpen(false)} />
          <nav className="fixed inset-0 flex flex-col items-center justify-center bg-white z-50 md:hidden">
            <button
              onClick={() => setIsMenuOpen(false)}
              className="absolute top-4 right-4 focus:outline-none text-gray-700"
            >
              X
            </button>
            <ul>
              {menuItems.map((item, index) => (
                <PermissionFlags requiredFlags={item.flags} key={index}>
                  <div className="w-full text-center">
                    {item.submenuItems ? (
                      <>
                        <button
                          onClick={() => handleSubmenuClick(index)}
                          style={item.styles}
                          className={`flex flex-1 w-full items-start p-4 bg-white border-b-2 border-transparent shadow-lg transition-colors duration-300 ${
                            item.isDisabled
                              ? 'cursor-not-allowed pointer-events-none text-gray-400'
                              : `hover:text-darker-gold cursor-pointer text-gray-700 hover-underline-animation ${
                                  isActive(item.href || '') ? 'text-custom-orange font-bold' : ''
                                }`
                          }`}
                        >
                          {item.icon && <span className="mr-2">{item.icon}</span>}
                          <div>
                            <div className="font-semibold">{item.headline}</div>
                            <div className="text-sm">{item.text}</div>
                          </div>
                        </button>
                        {openSubmenus[index] && (
                          <div className="w-full mt-1 bg-white border border-gray-200 shadow-lg">
                            {item.submenuItems.map((subItem, subIndex) => (
                              <PermissionFlags requiredFlags={subItem.flags} key={subIndex}>
                                <button
                                  key={subIndex}
                                  onClick={() =>
                                    subItem.onClick ? subItem.onClick() : handleNavigation(subItem.href || '')
                                  }
                                  style={subItem.styles}
                                  className={`flex items-start flex-1 w-full p-4 border-b-2 border-transparent transition-colors duration-300 ${
                                    subItem.isDisabled
                                      ? 'cursor-not-allowed pointer-events-none text-gray-400'
                                      : `hover:text-darker-gold cursor-pointer text-gray-700 hover-underline-animation ${
                                          isActive(subItem.href || '') ? 'text-custom-orange font-bold' : ''
                                        }`
                                  }`}
                                >
                                  {subItem.icon && <span className="mr-2">{subItem.icon}</span>}
                                  <div>
                                    <div className="font-semibold">{subItem.headline}</div>
                                    {subItem.text}
                                  </div>
                                </button>
                              </PermissionFlags>
                            ))}
                          </div>
                        )}
                      </>
                    ) : (
                      <button
                        onClick={() =>
                          item.onClick ? item.onClick() : handleNavigation(item.href || '')
                        }
                        style={item.styles}
                        className={`flex items-start flex-1 w-full bg-white shadow-lg p-4 border-b-2 border-transparent transition-colors duration-300 ${
                          item.isDisabled
                            ? 'cursor-not-allowed pointer-events-none text-gray-400'
                            : `hover:text-darker-gold cursor-pointer text-gray-700 hover-underline-animation ${
                                isActive(item.href || '') ? 'text-custom-orange font-bold' : ''
                              }`
                        }`}
                      >
                        {item.icon && <span className="mr-2">{item.icon}</span>}
                        <div>
                          <div className="font-semibold">{item.headline}</div>
                          {item.text}
                        </div>
                      </button>
                    )}
                  </div>
                </PermissionFlags>
              ))}
            </ul>
          </nav>
        </>
      )}

      {/* Regular dropdown for larger screens */}
      {isMenuOpen && (
        <nav className="absolute top-full w-full bg-white shadow-lg z-50 hidden md:block" style={{ left: '-50%' }}>
          <ul>
            {menuItems.map((item, index) => (
              <PermissionFlags requiredFlags={item.flags} key={index}>
                <div className="relative">
                  {item.submenuItems ? (
                    <>
                      <button
                        onClick={() => handleSubmenuClick(index)}
                        style={item.styles}
                        className={`flex items-start p-4 bg-white border-b-2 border-transparent shadow-lg transition-colors duration-300 ${
                          item.isDisabled
                            ? 'cursor-not-allowed pointer-events-none text-gray-400'
                            : `hover:text-darker-gold cursor-pointer text-gray-700 hover-underline-animation ${
                                isActive(item.href || '') ? 'text-custom-orange font-bold' : ''
                              }`
                        }`}
                      >
                        {item.icon && <span className="mr-2">{item.icon}</span>}
                        <div>
                          <div className="font-semibold">{item.headline}</div>
                          <div className="text-sm">{item.text}</div>
                        </div>
                      </button>
                      {openSubmenus[index] && (
                        <div className="absolute left-0 mt-1 bg-white border border-gray-200 shadow-lg">
                          {item.submenuItems.map((subItem, subIndex) => (
                            <PermissionFlags requiredFlags={subItem.flags} key={subIndex}>
                              <button
                                key={subIndex}
                                onClick={() =>
                                  subItem.onClick ? subItem.onClick() : handleNavigation(subItem.href || '')
                                }
                                style={subItem.styles}
                                className={`flex items-start w-full p-4 border-b-2 border-transparent transition-colors duration-300 ${
                                  subItem.isDisabled
                                    ? 'cursor-not-allowed pointer-events-none text-gray-400'
                                    : `hover:text-darker-gold cursor-pointer text-gray-700 hover-underline-animation ${
                                        isActive(subItem.href || '') ? 'text-custom-orange font-bold' : ''
                                      }`
                                }`}
                              >
                                {subItem.icon && <span className="mr-2">{subItem.icon}</span>}
                                <div>
                                  <div className="font-semibold">{subItem.headline}</div>
                                  {subItem.text}
                                </div>
                              </button>
                            </PermissionFlags>
                          ))}
                        </div>
                      )}
                    </>
                  ) : (
                    <button
                      onClick={() =>
                        item.onClick ? item.onClick() : handleNavigation(item.href || '')
                      }
                      style={item.styles}
                      className={`flex bg-white items-start shadow-lg p-4 border-b-2 border-transparent transition-colors duration-300 ${
                        item.isDisabled
                          ? 'cursor-not-allowed pointer-events-none text-gray-400'
                          : `hover:text-darker-gold cursor-pointer text-gray-700 hover-underline-animation ${
                              isActive(item.href || '') ? 'text-custom-orange font-bold' : ''
                            }`
                      }`}
                    >
                       {item.icon && <span className="mr-2">{item.icon}</span>}
                      <div>
                        <div className="font-semibold">{item.headline}</div>
                        {item.text}
                      </div>
                    </button>
                  )}
                </div>
              </PermissionFlags>
            ))}
          </ul>
        </nav>
      )}
    </div>
  );
};

export default FlyoutMenu;