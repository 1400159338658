import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

interface LineData {
  label: string;
  data: number[];
  backgroundColor?: string;
  borderColor?: string;
  borderWidth?: number;
}

interface LinearGraphProps {
  title: string;
  xLabels: string[];
  dataSets: LineData[];
}

const LinearGraph: React.FC<LinearGraphProps> = ({ title, xLabels, dataSets }) => {
  const chartData = {
    labels: xLabels,
    datasets: dataSets,
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return <Line data={chartData} options={options} />;
};

export default LinearGraph;
