import React, { useState } from 'react';
import { RouteProps } from 'react-router-dom';
import SignUpForm from '../../components/SignUpForm/SignUpForm';
import LoginForm from '../../components/LoginForm/LoginForm';
import ChangePasswordForm from '../../components/ChangePasswordForm/ChangePasswordForm';
import { useTranslation } from 'react-i18next';
import ForgotPasswordForm from '../../components/ForgotPasswordForm/ForgotPasswordForm';
import PrimaryButton from '../PrimaryButton/PrimaryButton';

const FormStates = {
  LOGIN: 'LOGIN',
  SIGNUP: 'SIGNUP',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
};

const LoginFlow: React.FC<RouteProps & { callbackFn?: any }> = ({ callbackFn }) => {
  const { t } = useTranslation();
  const [formState, setFormState] = useState('LOGIN');
  const [username, setUsername] = useState('');

  const handlePasswordChangeRequired = (username: string) => {
    setFormState(FormStates.CHANGE_PASSWORD)
    setUsername(username);
  };
  
  const setLoginPage = () => {
    setFormState(FormStates.LOGIN)
  };
  
  const toggleIsSignUp = () => {
    setFormState('SIGNUP');
  };

  const toggleForgot = () => setFormState('FORGOT_PASSWORD');

  const renderForm = () => {
    switch (formState) {
      case FormStates.CHANGE_PASSWORD:
        return <ChangePasswordForm username={username} onPasswordChanged={setLoginPage} />;
      case FormStates.SIGNUP:
        return <SignUpForm callbackFn={callbackFn}/>;
      case FormStates.LOGIN:
        return <LoginForm onPasswordChangeRequired={handlePasswordChangeRequired} callbackFn={callbackFn}/>
      case FormStates.FORGOT_PASSWORD:
        return <ForgotPasswordForm onBackToLogin={setLoginPage} />;
      default:
        return <LoginForm onPasswordChangeRequired={handlePasswordChangeRequired} callbackFn={callbackFn}/>;
    }
  };  

  const renderButton = () => {
    switch (formState) {
      case FormStates.SIGNUP:
        return <PrimaryButton fn={setLoginPage} text={t("registration.login")!} actionName="Switch to Login Form"/>
      default: 
        return <PrimaryButton fn={toggleIsSignUp} text={t("registration.signup")!} actionName="Switch to Sign Up Form" />
    }
  };

  return (
    <div className="flex flex-col justify-center items-center h-full">
        <div className="max-w-sm">
            { renderForm() }
        </div>
        <div className="flex flex-col md:flex-row md:space-x-3 mt-5 items-center">
          { renderButton() }
          <PrimaryButton text={t("registration.forgot_password.text")!} fn={toggleForgot} actionName="Forgot Password"/>
        </div>
    </div>
  );
};

export default LoginFlow;

