import CONFIG from '../config/config';
import { ItemVariant } from './CollectionItemVariantsAPI';
import { DressItem } from './DressesAPI';
import { instanceWithV2 as APIClient } from './SimpleAPIClient';

export type Collection = {
  id?: number;
  name: string;
  shortName: string;
  type: string; // deprecated
  types: string[];
  views: number;
  description: string;
  featured: boolean; // deprecated
  public: boolean;
  lastUpdateTime: string | null;
  items: Item[];
  shareableByLink: boolean;
  pricesArePublic: boolean;
  uuid?: string;
};

export type Item = {
  id?: number;
  name: string;
  featuredPictureUrl: string; // deprecated
  type: string; // deprecated
  placeOrder: number;
  media?: string[];
  files?: File[]
  price?: number;
  currency?: string;
  cost?: number;
  costCurrency?: string;
  description?: string;
  plan?: DressItem;
  collectionName?: string;
  collection?: number;
  variations?: ItemVariant[];
};

const CollectionsAPI = {
  getCollections: (type?: string) => APIClient(CONFIG.API_ENDPOINT).get<Collection[]>(`/v1/collections${type ? `?type=${type}` : ''}`),
  add: (collection: Collection) => APIClient(CONFIG.API_ENDPOINT).post<Collection>(`/v1/collections`, collection),
  duplicate: (id: number) => APIClient(CONFIG.API_ENDPOINT).post<Collection>(`/v1/collections/${id}/duplicates`),
  remove: (id: number) => APIClient(CONFIG.API_ENDPOINT).delete<Collection>(`/v1/collections/${id}`),
  update: (id: number, collection: Collection) => APIClient(CONFIG.API_ENDPOINT).put<Collection>(`/v1/collections/${id}`, collection),
  searchItems: (searchText: string) => APIClient(CONFIG.API_ENDPOINT).get<Item[]>(`/v1/collections/items?searchText=${searchText}`),
  addItem: (id: number, item: Item) => APIClient(CONFIG.API_ENDPOINT).post<Item>(`/v1/collections/${id}`, item),
  updateItem: (id: number, itemId: number, item: Item) => APIClient(CONFIG.API_ENDPOINT).patch<Item>(`/v1/collections/${id}/${itemId}`, item),
  addImage: (id: number, itemId: number, url: string) => APIClient(CONFIG.API_ENDPOINT).patch<Item>(`/v1/collections/${id}/${itemId}/files/images`, 
  { collection_id: id, id: itemId, featuredPictureUrl: url }
  ),
  removeItem: (id: number, itemId: number) => APIClient(CONFIG.API_ENDPOINT).delete<Item>(`/v1/collections/${id}/${itemId}`),
};

export default CollectionsAPI;
