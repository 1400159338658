import Footer from "../../components/Footer/Footer";
import MainMenu from "../../components/MainMenu/MainMenu";
import ToastContainer from "../../components/ToastContainer/ToastContainer";
import DynamicModalContainer from "../../components/DynamicModalContainer/DynamicModalContainer";
import { useRetrieveUser } from "../../hooks/useRetrieveUser";
import Sidebar from "../../components/Sidebar/Sidebar";
import { Icon } from "../../components/Icon/Icon";
import store from '../../assets/images/store.svg';
import catalogues from '../../assets/images/catalogue.svg';
import contractsIcon from '../../assets/images/pencil-square.svg';
import backOfficeIcon from '../../assets/images/rectangle-group.svg';
import bookingsIcon from '../../assets/images/inbox.svg';
import orders from '../../assets/images/orders.svg';
import stockIcon from '../../assets/images/numbered-list.svg';
import providersIcon from '../../assets/images/swatch.svg';
import productPlansIcon from '../../assets/images/tag.svg';
import productionIcon from '../../assets/images/arrow-path.svg';
import chartBar from '../../assets/images/chart-bar.svg';
import calendarIcon from '../../assets/images/calendar.svg';
import leadsIcon from '../../assets/images/funnel.svg';
import marketingIcon from '../../assets/images/sphere.svg';
import partnersIcon from '../../assets/images/globe.svg';
import customersIcon from '../../assets/images/users.svg';
import employeesIcon from '../../assets/images/identification.svg';
import ordersStatsIcon from '../../assets/images/arrow-trending-up.svg';
import { useTranslation } from "react-i18next";
import settings from '../../assets/images/settings.svg';
import { useSelector } from "react-redux";

type Props = {
  children: JSX.Element
};

function PageWrapper({children}: Props) {

  const isLoggedIn = useSelector((state: any) => state.settings.value.isLoggedIn);
  const { t } = useTranslation();
  useRetrieveUser();

  const menuItems = [
    { text: t('menu.store')!, href: '/', isDisabled: false, flags: ['see-meetings', 'see-contracts'], name: 'Store', 
    icon: <><Icon url={store} />&nbsp;</>, 
      submenuItems: [
        { text: t('menu.calendar')!, href: '/calendar', flags: ['see-meetings'], isDisabled: false, name: 'Calendar', icon: <><Icon url={calendarIcon} />&nbsp;&nbsp;</>},
        { text: t('menu.calendar_app'), href: '/bookings', flags: ['create-meetings'], isDisabled: true, name: 'Bookings',
        icon: <><Icon url={bookingsIcon} />&nbsp;&nbsp;</>},
        { text: t('menu.contracts')!, href: '/contracts', flags: ['see-contracts'], isDisabled: false, name: 'Contracts', icon: <><Icon url={contractsIcon} />&nbsp;&nbsp;</>},
        { text: t('menu.customers')!, href: '/customers', flags: ['see-customers'], isDisabled: false, name: 'Customers CRM', icon: <><Icon url={customersIcon} />&nbsp;&nbsp;</>},
      ] 
    },
    { 
      text: t('menu.orders')!, href: '/orders', flags: ['see-orders', 'see-self-orders'], name: 'Orders',
      icon: <><Icon url={orders} />&nbsp;</>,  
    },
    { text: t('menu.marketing')!, href: '/', isDisabled: false, flags: ['see-marketing-collections', 'see-leads'], name: 'Marketing', 
    icon: <><Icon url={marketingIcon} />&nbsp;</>, 
      submenuItems: [
        { text: t('admin.menu.collections')!, href: '/catalogues', isDisabled: false, flags: ['see-marketing-collections'], name: 'Catalogues', icon: <><Icon url={catalogues} />&nbsp;</> },
        { text: t('menu.leads')!, href: '/leads', isDisabled: false, flags: ['see-leads'], name: 'Leads', icon: <><Icon url={leadsIcon} />&nbsp;</>, },
      ] 
    },
    { text: t('menu.production')!, href: '/production', flags: ['see-providers'], name: 'Production', 
    icon: <><Icon url={productionIcon} />&nbsp;</>, 
      submenuItems: [
        { text: t('production.menu.providers')!, href: '/providers', isDisabled: false, flags: ['see-providers'], name: 'Providers', icon: <><Icon url={providersIcon} />&nbsp;</>},
        { text: t('production.menu.stock')!, href: '/stock', isDisabled: false, flags: ['see-stock'], name: 'Stock', icon: <><Icon url={stockIcon} />&nbsp;</>},
        { text: t('production.menu.dresses')!, href: '/inventory', isDisabled: false, flags: ['see-dresses'], name: 'Item Plans', icon: <><Icon url={productPlansIcon} />&nbsp;</>}
      ] 
    },
    { text: t('menu.stats')!, href: '/', isDisabled: false, flags: ['see-employees'], name: 'Statistics', 
      icon: <><Icon url={chartBar} />&nbsp;</>, 
      submenuItems: [
        { text: t('Orders')!, href: '/insights/orders', isDisabled: false, flags: ['see-employees'], name: 'Stats Orders', icon: <><Icon url={ordersStatsIcon} />&nbsp;</> },
      ] 
    },
  ];

  const bottomMenu = [
    { text: t('menu.admin')!, href: '/admin', flags: ['see-partners'], name: 'Admin', 
    icon: <><Icon url={backOfficeIcon} />&nbsp;</>, 
      submenuItems: [
        { text: t('admin.menu.partners')!, href: '/partners', flags: ['see-partners'], isDisabled: false, name: 'Partners', icon: <><Icon url={partnersIcon} />&nbsp;</>},
        { text: t('admin.menu.employees')!, href: '/employees', flags: ['see-employees'], name: 'Employees', icon: <><Icon url={employeesIcon} />&nbsp;</> },
      ] 
    },
    { 
      text: t('menu.settings')!, href: '/settings', flags: ['see-employees'], name: 'Settings',
      icon: <><Icon url={settings} />&nbsp;</>,  
    },
  ]

  const notLoggedMenuItems = [
    { text: t('menu.store')!, href: '/', isDisabled: true, flags: [], name: 'Store', 
    icon: <><Icon url={store} />&nbsp;</>, 
      submenuItems: [
        { text: t('menu.calendar')!, href: '/', flags: [], isDisabled: true, name: 'Calendar', icon: <><Icon url={calendarIcon} />&nbsp;&nbsp;</>},
        { text: t('menu.contracts')!, href: '/', flags: [], isDisabled: true, name: 'Contracts', icon: <><Icon url={contractsIcon} />&nbsp;&nbsp;</>},
        { text: t('menu.customers')!, href: '/', flags: [], isDisabled: true, name: 'Customers CRM', icon: <><Icon url={customersIcon} />&nbsp;&nbsp;</>},
        { text: t('menu.calendar_app'), href: '/', flags: [], isDisabled: true, name: 'Booking Calendar',
        icon: <><Icon url={calendarIcon} />&nbsp;&nbsp;</>},
      ] 
    },
    { 
      text: t('menu.orders')!, href: '/', flags: [], isDisabled: true, name: 'Orders',
      icon: <><Icon url={orders} />&nbsp;</>,  
    },
    { text: t('menu.marketing')!, href: '/', isDisabled: true, flags: [], name: 'Marketing', 
    icon: <><Icon url={marketingIcon} />&nbsp;</>, 
      submenuItems: [
        { text: t('admin.menu.collections')!, href: '/', isDisabled: true, flags: [], name: 'Catalogues', icon: <><Icon url={catalogues} />&nbsp;</> },
        { text: t('menu.leads')!, href: '/', isDisabled: true, flags: [], name: 'Leads', icon: <><Icon url={leadsIcon} />&nbsp;</>, },
      ] 
    },
    { text: t('menu.production')!, href: '/', isDisabled: true, flags: [], name: 'Production', 
    icon: <><Icon url={productionIcon} />&nbsp;</>, 
      submenuItems: [
        { text: t('production.menu.providers')!, href: '/', isDisabled: true, flags: [], name: 'Providers', icon: <><Icon url={providersIcon} />&nbsp;</>},
        { text: t('production.menu.stock')!, href: '/', isDisabled: true, flags: [], name: 'Stock', icon: <><Icon url={stockIcon} />&nbsp;</>},
        { text: t('production.menu.dresses')!, href: '/', isDisabled: true, flags: [], name: 'Item Plans', icon: <><Icon url={productPlansIcon} />&nbsp;</>}
      ] 
    },
    { text: t('menu.stats')!, href: '/', isDisabled: true, flags: [], name: 'Statistics', 
      icon: <><Icon url={chartBar} />&nbsp;</>, 
      submenuItems: [
        { text: t('Orders')!, href: '/', isDisabled: true, flags: [], name: 'Stats Orders', icon: <><Icon url={ordersStatsIcon} />&nbsp;</> },
      ] 
    },
  ];

  const notLoggedBottomMenu = [
    { text: t('menu.admin')!, href: '/', isDisabled: true, flags: [], name: 'Admin', 
    icon: <><Icon url={backOfficeIcon} />&nbsp;</>, 
      submenuItems: [
        { text: t('admin.menu.partners')!, href: '/', flags: [], isDisabled: true, name: 'Partners', icon: <><Icon url={partnersIcon} />&nbsp;</>},
        { text: t('admin.menu.employees')!, href: '/', flags: [], isDisabled: true, name: 'Employees', icon: <><Icon url={employeesIcon} />&nbsp;</> },
      ] 
    },
    { 
      text: t('menu.settings')!, href: '/', flags: [], isDisabled: true, name: 'Settings',
      icon: <><Icon url={settings} />&nbsp;</>,  
    },
  ]

  return (
    <div className="light-black">
      <MainMenu />
      <div className="flex">
          <Sidebar
            menuItems={isLoggedIn ? menuItems.map((item) => ({
              ...item,
              icon: item.icon,
              submenuItems: item.submenuItems?.map((subItem: any) => ({
                ...subItem,
                icon: subItem.icon
              }))
            })) : notLoggedMenuItems.map((item) => ({
              ...item,
              icon: item.icon,
              submenuItems: item.submenuItems?.map((subItem: any) => ({
                ...subItem,
                icon: subItem.icon
              }))
            }))}
            bottomMenuItems={isLoggedIn ? bottomMenu.map((item) => ({
              ...item,
              icon: item.icon,
            })): notLoggedBottomMenu.map((item) => ({
              ...item,
              icon: item.icon,
            }))}
          />
          <div id="content" className="flex flex-col min-h-screen max-h-screen w-full overflow-y-scroll">
            <div className="container p-3 md:pt-7 w-full mx-auto max-w-7xl flex-1">
              { children }
            </div>
            <Footer />
          </div>
      </div>
      <ToastContainer />
      <DynamicModalContainer />
    </div>
  );
}

export default PageWrapper;
