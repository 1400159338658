import React from "react";
import { ExternalOrder } from "../../api/OrdersAPI";
import CONFIG from "../../config/config";
import InputShortText from "../InputShortText/InputShortText";
import SelectInput from "../SelectInput/SelectInput";
import TextArea from "../TextArea/TextArea";
import { useTranslation } from "react-i18next";
import 'react-datepicker/dist/react-datepicker.css';
import SectionTitle from "../SectionTitle/SectionTitle";
import CustomDatePicker from "../CustomDatePicker/CustomDatePicker";
import { format } from "date-fns";
import { isValidText } from "../../shared/validation";
import useFeatureFlag from "../../hooks/usePermissionFlag";
import { externalOrderStatusToTranslationKeys } from "../../shared/externalOrderStatusToTranslationKey";
import OrderComments from "../OrderComments/OrderComments";
import FileUploadWrapper from "../FileUploadWrapper/FileUploadWrapper";
import ListContainer from "../ListContainer/ListContainer";
import ListElement from "../ListElement/ListElement";
import ElementActions from "../ElementActions/ElementActions";
import IconButton from "../IconButton/IconButton";
import { Icon } from "../Icon/Icon";
import { Dot } from "../Dot/Dot";
import deleteIcon from "../../assets/images/bin.svg";
import fileIcon from "../../assets/images/file.svg";
import { toastService } from "../../services/ToastService";
import { PrivateSharedResourceLink } from "../PrivateSharedResourceLink/PrivateSharedResourceLink";

type Props = {
  order: ExternalOrder | null,
  onOrderChange?: (value: ExternalOrder) => void,
  isDisabled?: boolean,
  actionButton?: JSX.Element
};

function ExternalOrderForm({ order, onOrderChange = () => null, isDisabled = false, actionButton }: Props) {

  const { t } = useTranslation();

  const { hasAccess } = useFeatureFlag(['update-orders']);

  const acceptsChanges = (status: string, inOrOut: string) => {
    return status !== "ACCEPTED" && inOrOut === "OUT";
  }

  const removeLink = (url: string) => {
    const updatedAttachments = order?.attachmentUrls?.filter((attachmentUrl) => attachmentUrl !== url);
    onOrderChange({ ...order, attachmentUrls: updatedAttachments } as ExternalOrder);
  }

  const removeFile = (fileName: string) => {
    const updatedFiles = order?.files?.filter((file) => file.name !== fileName);
    onOrderChange({ ...order, files: updatedFiles } as ExternalOrder);
  }

  const processFile = async (files: File[]) => {
    try {
      const newFiles = [...order?.files || [], ...files];
      onOrderChange({ ...order, files: newFiles } as ExternalOrder);
    } catch (error) {
      toastService.showToast('Something went wrong adding file', 'error');
    }
  };

  const disabledActionButton = actionButton
    ? React.cloneElement(
      actionButton,
      {},
      React.Children.map(actionButton.props.children, (child) => {
        return child ? React.cloneElement(child, { isDisabled: isDisabled }) : null;
      })
    )
    : null;

  return (
    <>
      <div className="container flex space-x-5 flex-wrap mb-5">
        <div className="flex-1 space-y-1">
          <SectionTitle text={t("orders.orders_basket.add_form.general_data")} />
          <InputShortText
            label={"orders.external.add_form.name"}
            validationFn={(value) => !!value && isValidText(value, 1, 100)}
            onChange={c => onOrderChange({ ...order, clientName: c.target.value} as ExternalOrder)}
            errorMessage={"errors.too_long_short"}
            defaultValue={order?.clientName}
            isDisabled={isDisabled || !hasAccess || !acceptsChanges(order?.status!, order?.b2bType!)}
          />
          <InputShortText
            label={"orders.external.add_form.item_name"}
            onChange={e => onOrderChange({ ...order, dressDescription: e.currentTarget.value } as ExternalOrder)}
            defaultValue={order?.itemName || ''}
            isDisabled
          />
          <div className="flex flex-wrap space-x-5">
            <CustomDatePicker
              isDisabled={isDisabled || !hasAccess || !acceptsChanges(order?.status!, order?.b2bType!)}
              label="orders.external.add_form.delivery_time"
              value={order?.inStoreDate}
              onChange={c => onOrderChange({ ...order, inStoreDate: format(c, 'yyyy-MM-dd') } as ExternalOrder)}
            />
            <SelectInput values={CONFIG.EXTERNAL_ORDERS.STATUS.map(e => { return { value: e, readableValue: t(externalOrderStatusToTranslationKeys(e)) } })}
              onChange={e => onOrderChange({ ...order, status: e.target.value } as ExternalOrder)}
              label={"orders.external.add_form.status"}
              defaultValue={order?.status}
              isDisabled={isDisabled || !hasAccess || order?.b2bType !== "IN"}
            />
          </div>
          <TextArea
            label={"orders.external.add_form.notes"}
            validationFn={(value) => !!value && value.length > 3}
            onChange={e => onOrderChange({ ...order, notes: e.currentTarget.value } as ExternalOrder)}
            errorMessage={"errors.too_short"}
            defaultValue={order?.notes || ''}
            isDisabled={isDisabled || !hasAccess || !acceptsChanges(order?.status!, order?.b2bType!)}
          />
          <div>
            <p>{`${t("orders.orders_basket.add_form.files")} [${order?.attachmentUrls?.length || 0}] (${CONFIG.MAX_FILE_UPLOAD}MB):`}</p>
            <ListContainer
              elements={order?.attachmentUrls || []}
              toElementFn={(url: string, index: number) =>
                <ListElement>
                  <ElementActions actions={
                    isDisabled || !hasAccess ? <></> :
                      <IconButton onClick={() => removeLink(url)}>
                        <Icon url={deleteIcon} cssStyles="w-5" />
                      </IconButton>
                  }>
                  <span><Icon url={fileIcon} cssStyles="w-5" /> <PrivateSharedResourceLink orderId={order?.id!} file={url} /></span>
                  </ElementActions>
                </ListElement>
              }
            />
            <ListContainer
              elements={order?.files || []}
              toElementFn={(file: File, index: number) =>
                <ListElement>
                  <ElementActions actions={
                    isDisabled || !hasAccess ? <></> :
                      <IconButton onClick={() => removeFile(file.name)}>
                        <Icon url={deleteIcon} cssStyles="w-5" />
                      </IconButton>
                  }>
                  <span><Dot /> {file.name}</span>
                  </ElementActions>
                </ListElement>
              }
            />
            <FileUploadWrapper
              label={`${t("orders.orders_basket.add_form.files.button")}.`}
              onChange={processFile}
              isDisabled={isDisabled || !hasAccess || order?.b2bType !== "OUT"}
              allowedMultipleFiles
            />
          </div>
        </div>
        <OrderComments
          orderId={order?.id!}
          isDisabled={!order?.id || isDisabled}
        />
      </div>
      {disabledActionButton}
    </>
  );
}

export default ExternalOrderForm;
