import { Reorder, useDragControls } from "framer-motion";
import { useCallback, useEffect, useState } from "react";
import debounce from "../../shared/debounce";
import { Icon } from "../Icon/Icon";
import squares from "../../assets/images/squares.svg";

type Props = {
  elements?: any[],
  toElementFn: (element: any, index: number) => JSX.Element,
  isLoading?: boolean,
  loadingNumber?: number,
  emptyListComponent?: JSX.Element | (() => JSX.Element),
  enableDragAndDrop?: boolean,
  onMovedItem?: (input: any) => void
};

function ListContainer({elements = [], toElementFn, isLoading = false, loadingNumber = 10, emptyListComponent, enableDragAndDrop = false, onMovedItem}: Props) {
  const [dragItems, setDragItems] = useState(elements);
  const controls = useDragControls();

  useEffect(() => {
    setDragItems(elements);
  }, [elements]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnMovedItem = useCallback(debounce((newItems: any[]) => {
    onMovedItem && onMovedItem(newItems);
  }, 1500), []);

  const moveItem = (newItems: any[]) => {
    setDragItems(newItems);
    debouncedOnMovedItem(newItems);
  };

  const renderElement = (el: any, i: number) => {
    const element = toElementFn(el, i);
    return enableDragAndDrop ? (
      <Reorder.Item key={el.id} value={el} >
        <span className="flex w-full items-center space-x-2">
          <span
            className="reorder-handle inline"
          >
            <Icon url={squares} cssStyles="w-5 pointer-events-none" onClick={() => controls.start(el)}/>
          </span>
          <span key={el.id} className="flex-1">
            {element}
          </span>

        </span>
      </Reorder.Item>
    ) : (
      <span key={i}>{ element }</span>
    );
  };

  return (
      <div className="flex flex-col flex-1 space-y-2">
        { isLoading ?
          Array.apply(null, Array(loadingNumber)).map((m, i) => <div key={i} className="shadow p-5 w-full mx-auto animate-pulse">
          <div className="grid grid-cols-10 gap-4">
              <div className="h-2 bg-darker-gold h-3 rounded col-span-3"></div>
              <div className="h-2 bg-darker-gold rounded col-span-1"></div>
              <div className="h-2 bg-darker-gold rounded col-span-3"></div>
              <div className="h-2 bg-darker-gold h-3 rounded col-span-1"></div>
              <div className="h-2 bg-darker-gold rounded col-span-2"></div>
          </div>
        </div>)
        : 
        dragItems.length === 0 && emptyListComponent
          ? (typeof emptyListComponent === 'function' ? emptyListComponent() : emptyListComponent)
          :
          <Reorder.Group axis="y" onReorder={newItems => moveItem(newItems)} values={dragItems}>
            { dragItems.map(renderElement) }
          </Reorder.Group> 
        }
      </div>
  );
}

export default ListContainer;
