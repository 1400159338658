import { useState, useEffect } from 'react';
import AccountsAPI, { Account } from '../api/AccountAPI';
import { User, UsersAPI } from '../api/UsersAPI';

type ReturnedProps = {
  isLoading: boolean,
  users: User[],
  addFn: (e: any) => Promise<any>,
  removeFn: (e: any) => Promise<any>,
  updateUserPermissions: (uuid: string, updatedFlags: string[]) => Promise<void>,
};

function useGetUsers(): ReturnedProps {
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const addFn = async (newAccount: Account) => {
    setIsLoading(true);
    try {
      const usersResponse = await AccountsAPI.add(newAccount);
      setUsers(prevUsers => [...prevUsers, usersResponse.data]);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const removeFn = async (uuid: string) => {
    setIsLoading(true);
    try {
      await AccountsAPI.delete(uuid);
      setUsers((prevUsers) => prevUsers.filter((p) => p.uuid !== uuid));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const usersResponse = await UsersAPI.getUsers();
      setUsers(usersResponse.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateUserPermissions = async (uuid: string, updatedFlags: string[]) => {
    // setIsLoading(true);
    try {
      setUsers(prevUsers => {
        const index = prevUsers.findIndex(user => user.uuid === uuid);
        if (index !== -1) {
          const updatedUsers = [...prevUsers];
          updatedUsers[index] = {
            ...updatedUsers[index],
            flags: updatedFlags
          };
          return updatedUsers;
        }
        return prevUsers;
      });
      await UsersAPI.setUserPermissions(uuid, updatedFlags);
    } catch (error) {
      console.error(error);
    } finally {
      // setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchUsers();
  }, []);

  return { isLoading, users, addFn, removeFn, updateUserPermissions };
}

export default useGetUsers;
