import * as amplitude from '@amplitude/analytics-browser';

type Props = {
  text?: string,
  onClick?: (a?: any) => void,
  disabled?: boolean,
  children?: JSX.Element,
  eventName?: string,
  actionName?: string
};

function IconButton({text = "", onClick = () => null, disabled, children, eventName = '', actionName = 'Icon'}: Props) {

  const track = (e: any) => {
    e.stopPropagation();
    if (!!eventName) amplitude.track(eventName);
    onClick();
  }

  return (
    <button 
      className="flex items-end hover:text-slate-700 hover:cursor-pointer transition-brightness duration-200"
      onClick={(e) => track(e)} 
      disabled={disabled}
      data-dd-action-name={actionName}
      >
        { text } { children }
    </button>
  );
}

export default IconButton;
