export function roleToTranslationKey(type: string | undefined): string {
  if (type === 'admin') {
    return "global.role.admin";
  }
  if (type === 'office') {
    return "global.role.office";
  }
  if (type === 'manager') {
    return "global.role.manager";
  }
  if (type === 'shop') {
    return "global.role.shop";
  }
  if (type === 'worker') {
    return "global.role.worker";
  }
  return 'global.unknown';
}

export function roleDescriptionToTranslationKey(type: string | undefined): string {
  if (type === 'office') {
    return "settings.accounts.roles.office";
  }
  if (type === 'manager') {
    return "settings.accounts.roles.manager";
  }
  if (type === 'shop') {
    return "settings.accounts.roles.shop";
  }
  if (type === 'worker') {
    return "settings.accounts.roles.worker";
  }
  return 'global.unknown';
}