type Props = {
  children: JSX.Element,
  onClick?: (a?: any) => void,
  css?: string
};

function ListElement({children, onClick = () => null, css = ''}: Props) {
  return (
    <div 
        className={`group flex justify-between text-sm px-5 py-3 duration-500 hover:cursor-pointer ${css}`} 
        onClick={e => { onClick ?? e.stopPropagation(); onClick(e); }}
      >
        { children }
    </div>
  );
}

export default ListElement;
