import { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { updateSettings } from '../store/reducers/AccountSlice';

// TODO: to consider returning here the credentials
export const useRetrieveUser = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const retrieveUser = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        const userData = {
          isLoggedIn: true
        };
        dispatch(updateSettings(userData));
      } catch (error) {
        // TODO: do nothing
      }
    };

    retrieveUser();
  }, [dispatch]);
};
