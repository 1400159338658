import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum OrderStatus {
  WAITING,
  TRANSLATION,
  CUT,
  DECORATION,
  CONNECTED,
  READY_TO_SEND,
  SENT,
  FIRST_MEASUREMENT
}

export enum PaymentStatuses {
  UNKNOWN = 'Unknown',
  PAID = 'Paid',
  PARTIALLY_PAID = 'Partially paid',
  NOT_PAID = 'Not paid',
}

export type Role = 'admin' | 'worker' | 'office' | 'manager' | 'service' | '';

export type Settings = {
  userName: string,
  tenantName?: string,
  logoUrl?: string,
  permissions: string[],
  role: Role,
  isLoggedIn: boolean,
  isConfigurationLoaded: boolean,
  orderStatuses: OrderStatus[],
  orderPaymentStatuses: PaymentStatuses[]
  openingHours?: string;
  closingHours?: string;
  timezone?: string;
}

export interface SettingsState {
  value: Settings
};
  
const initialState: SettingsState = {
  value: { 
    userName: '',
    permissions: [], 
    role: '',
    isLoggedIn: false, 
    isConfigurationLoaded: false,
    orderStatuses: Object.values(OrderStatus) as OrderStatus[],
    orderPaymentStatuses: Object.values(PaymentStatuses) as PaymentStatuses[],
  },
};

export const AccountSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSettings: (state: SettingsState, action: PayloadAction<Settings>) => {
      state.value = action.payload;
    },
    updateSettings: (state: SettingsState, action: PayloadAction<Partial<Settings>>) => {
      state.value = { ...state.value, ...action.payload };
    },
    clearSettings: (state: SettingsState) => {
      state.value = initialState.value;
    },
  },
});

export const { setSettings, updateSettings, clearSettings } = AccountSlice.actions;

export default AccountSlice.reducer;