import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ProviderAPI, Provider } from '../api/ProvidersAPI';
import { toastService } from '../services/ToastService';

type ReturnedProps = {
  isLoadingProviders: boolean,
  providers: Provider[],
  addFn: (provider: Provider) => Promise<any>,
  updateFn: (provider: Provider) => Promise<any>,
  removeFn: (provider: Provider) => Promise<any>,
  searchProviders: (searchText: string) => Promise<any>,
  refresh: () => Promise<void>
};

function useGetProviders(): ReturnedProps {
  const { t } = useTranslation();
  const [providers, setProviders] = useState<Provider[]>([]);
  const [isLoadingProviders, setIsLoadingProviders] = useState(false);

  const refresh = useCallback(async () => {
    fetchProviders();
  }, []);

  const addFn = async (provider: Provider) => {
    try {
      const newProvider = await ProviderAPI.add(provider);
      setProviders((prevProviders) => [...prevProviders, newProvider.data]);
    } catch (error) {
      toastService.showToast(t("toast.errors.providers.add"), 'error');
    }
  };
  
  const updateFn = async (provider: Provider) => {
    try {
      const updatedProvider = await ProviderAPI.update(provider.id!, provider);
      setProviders((prevProviders) =>
        prevProviders.map((p) =>
          p.id === provider.id ? updatedProvider.data : p
        )
      );
    } catch (error) {
      toastService.showToast(t("toast.errors.providers.update"), 'error');
    }
  };
  
  const removeFn = async (provider: Provider) => {
    try {
      await ProviderAPI.remove(provider.id!);
      setProviders((prevProviders) =>
        prevProviders.filter((p) => p.id !== provider.id)
      );
    } catch (error) {
      toastService.showToast(t("toast.errors.providers.remove"), 'error');
    }
  };  

  const fetchProviders = async () => {
    setIsLoadingProviders(true);
    try {
      const fetchedProviders = await ProviderAPI.getProviders();
      setProviders(fetchedProviders.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingProviders(false);
    }
  };

  const searchProviders = async (searchText: string) => {
    const fetchedProviders = await ProviderAPI.getProviders(searchText);
    return fetchedProviders.data;
  };

  useEffect(() => {
    fetchProviders();
  }, []);

  return { isLoadingProviders, providers, addFn, updateFn, removeFn, searchProviders, refresh };
}

export default useGetProviders;
