import React from 'react';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import SectionTitle from '../SectionTitle/SectionTitle';

interface PlaceholderProps {
  illustration?: JSX.Element;
  headline: string;
  subtext: string;
  primaryActionText: string;
  onPrimaryAction: () => void;
  secondaryActionText?: string;
  onSecondaryAction?: () => void;
}

const NoDataCTA: React.FC<PlaceholderProps> = ({
  illustration,
  headline,
  subtext,
  primaryActionText,
  onPrimaryAction,
  secondaryActionText,
  onSecondaryAction,
}) => {
  return (
    <div className="flex flex-col items-center justify-center p-8 space-y-4">
      { illustration && 
        <div className="bg-gray-100 p-4 rounded-lg shadow-md w-full text-center">
            {illustration}
        </div>
      }
      <SectionTitle text={headline} />
      <p className="text-gray-600">{subtext}</p>
      <PrimaryButton fn={onPrimaryAction} text={primaryActionText}/>
      {secondaryActionText && onSecondaryAction && (
        <button
          className="text-blue-500 hover:text-blue-700 underline font-semibold"
          onClick={onSecondaryAction}
        >
          {secondaryActionText}
        </button>
      )}
    </div>
  );
};

export default NoDataCTA;
