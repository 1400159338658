type ModalCallback = (message: string, id: string, content: string) => void;

class ModalService {
  private callback: ModalCallback | null = null;

  setCallback(cb: ModalCallback) {
    this.callback = cb;
  }

  showModal(message: string, id: string, content: string) {
    if (this.callback) {
      this.callback(message, id, content);
    }
  }
}

export const modalService = new ModalService();
