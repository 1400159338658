import { useTranslation } from 'react-i18next';
import { DressItem } from '../../api/DressesAPI';
import Subtitle from '../Subtitle/Subtitle';

type YearCollectionType = {
  id: number,
  dresses: DressItem[]
}

type Props = {
  yearCollection: YearCollectionType,
}

function YearCollection({ yearCollection }: Props) {

  const { t } = useTranslation();

  return (
    <div className="flex justify-between items-center">
      <Subtitle text={`${t("dresses.season")} ${yearCollection.id}`} />
      <span className="flex space-x-5 text-slate-500">
        <p>[{ yearCollection.dresses.length } {`${t("dresses.season.noun")}`}]</p>
      </span>
    </div>
  );
}

export default YearCollection;
