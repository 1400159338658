import { BlockedDate } from '../components/BlockedDatesForm/BlockedDatesForm';
import CONFIG from '../config/config';
import { MeetingType } from '../shared/meetingTypeToTranslationKey';
import { Customer } from './CustomersAPI';
import { instanceWith as APIClient } from './SimpleAPIClient';

export type Meeting = {
    id?: number,
    customerName: string,
    customerSurname: string,
    startTime: string,
    endTime: string,
    type: MeetingType,
    weddingDate: string | null,
    reminder: boolean,
    disable: boolean,
    phone: string | null,
    lastUpdateTime: string | null,
    notes: string | null,
    title: string | null,
    email?: string,
    customer?: Customer
}

export type UnavailableDate = {
    id?: number,
    start: number,
    end: number,
}

type AvailabilityResponse = {
    matches: boolean
}
  
type BodyApiResponse = Meeting[];

const MeetingsAPI = {
    get: (startDate: string, endDate: string, type?: string) => APIClient(CONFIG.API_ENDPOINT).get<BodyApiResponse>(`/v1/meetings?startTime=${startDate}&endTime=${endDate}${type? `&type=${type}` : ''}`),
    checkAvailability: (date: string, slotSizeMinutes: number = 30, daysRange: number = 2) => APIClient(CONFIG.API_ENDPOINT).get<AvailabilityResponse>(`/v1/meetings/availability?date=${date}&slotSizeMinutes=${slotSizeMinutes}&daysRange=${daysRange}`),
    search: (text: string) => APIClient(CONFIG.API_ENDPOINT).get<BodyApiResponse>(`v1/meetings?searchText=${text}`),
    getUnavailableDates: (startDate: string, endDate: string) => APIClient(CONFIG.API_ENDPOINT).get<UnavailableDate[]>(`/v1/blockedDates?start=${startDate}&end=${endDate}`),
    add: (meeting: Meeting) => APIClient(CONFIG.API_ENDPOINT).post<BodyApiResponse>(`/v1/meetings`, meeting),
    block: (blockedDates: BlockedDate) => APIClient(CONFIG.API_ENDPOINT).post<BodyApiResponse>(`/v1/blockedDates`, blockedDates),
    unblock: (id: number) => APIClient(CONFIG.API_ENDPOINT).delete<BodyApiResponse>(`/v1/blockedDates/${id}`),
    update: (id: number, meeting: Meeting) => APIClient(CONFIG.API_ENDPOINT).put<BodyApiResponse>(`/v1/meetings/${id}`, meeting),
    delete: (id: number, employee?: string) => APIClient(CONFIG.API_ENDPOINT).delete<BodyApiResponse>(`/v1/meetings/disable/${id}${employee ? `?workerName=${employee}` : ''}`),
  };
  
  export default MeetingsAPI;
  