import CONFIG from '../config/config';
import { instanceWith } from './SimpleAPIClient';

export type PermissionFlag = {
  flags?: string[],
  role?: string
}

const PermissionFlagAPI = {
  get: () => instanceWith(CONFIG.API_ENDPOINT).get<PermissionFlag>(`/v1/public/user/featureFlags`),
};

export default PermissionFlagAPI;
