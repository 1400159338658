import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import CONFIG from "../../config/config";
import { Link } from "react-router-dom";

function Footer() {

  const { t } = useTranslation();
  const currentYear = useMemo(() => new Date().getFullYear(), []);

  return (
      <div className="md:py-12 text-white flex flex-col items-center bg-darker-gold-300 font-light space-x-5 space-y-5">
        <div className="p-7 flex space-x-3 items-center flex-wrap">
          <Link to="/terms-of-service" className="hover:text-gray-200">{t("footer.terms_of_service")}</Link>
          <span>|</span>
          <Link to="/privacy-policy" className="hover:text-gray-200">{t("footer.privacy_policy")}</Link>
          <span>|</span>
          <a href={`mailto:${CONFIG.CONTACT_INFO.EMAIL}`} className="hover:text-gray-200">{t("footer.contact")}</a>
          <span>|</span>
          <a href="https://calendly.com/eateliers" target="_blank" rel="noopener noreferrer" className="hover:text-gray-200">{t("footer.demo")}</a>
          <span>|</span>
          <a href={`mailto:${CONFIG.CONTACT_INFO.EMAIL}`} target="_blank" rel="noopener noreferrer" className="hover:text-gray-200">{t("footer.support")}</a>
          <span>|</span>
        </div>

        <p>eAteliers &copy; 2019 - { currentYear } v{CONFIG.VERSION} {t("footer.copyright")}</p>
      </div>
  );
}

export default Footer;
