import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Pill from '../Pill/Pill';
import Tooltip from '../Tooltip/Tooltip';
import IconButton from '../IconButton/IconButton';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import linkIcon from '../../assets/images/link.svg';
import { Icon } from '../Icon/Icon';
import { Modal } from '../Modal/Modal';
import { DressAutocomplete } from '../DressAutocomplete/DressAutocomplete';
import deleteIcon from '../../assets/images/x-mark.svg';
import { ItemOrVariant } from '../../api/CollectionItemVariantsAPI';

interface PlanLinkModalProps {
  itemOrVariant: ItemOrVariant;
  isDisabled: boolean;
  onCollectionItemForm: (updatedItem: any) => void | Promise<void>;
}  

function PlanLinkModal({ itemOrVariant, isDisabled, onCollectionItemForm }: PlanLinkModalProps) {
  const { t } = useTranslation();
  const [dressLinkModal, setDressLinkModal] = useState(false);
  const [selectedDress, setSelectedDress] = useState();

  return (
    <>
      { dressLinkModal && (
        <Modal modalTitle={t("global.link")} canClose onClose={() => setDressLinkModal(false)}>
          <>
            <DressAutocomplete
              onChange={e => setSelectedDress(e)}
              label={"orders.orders_basket.add_form.dress"}
              isDisabled={isDisabled}
            />
            <br />
            <div className="flex justify-center">
              <PrimaryButton isDisabled={isDisabled} text="Select" fn={() => { onCollectionItemForm({ ...itemOrVariant, plan: selectedDress }); setDressLinkModal(false); }} />
            </div>
          </>
        </Modal>
      )}
    <div className="flex justify-end items-center">
      { itemOrVariant.plan && <div className="flex items-center">
            <div className="flex flex-col"><Pill text={itemOrVariant.plan.name} color='red' /></div>
            <Tooltip content={t("admin.collections.items.form.unlink")}>
              <IconButton onClick={() => { onCollectionItemForm({ ...itemOrVariant, plan: null })}}>
                <Icon url={deleteIcon} cssStyles="w-5 h-5" />
              </IconButton>
            </Tooltip>
        </div>
      }
      <Tooltip content={t("admin.collections.items.form.link")} position='left'>
        <IconButton onClick={() => setDressLinkModal(true)}>
          <Icon url={linkIcon} cssStyles="w-5" />
        </IconButton>
      </Tooltip>
    </div>
  </>);
}

export default PlanLinkModal;
