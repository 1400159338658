import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CatalogItem } from '../../api/CataloguesAPI';

type SelectedItemsState = {
  items: CatalogItem[];
};

const initialState: SelectedItemsState = {
  items: [],
};

export const MarketplaceBasketSlice = createSlice({
  name: 'selectedItems',
  initialState,
  reducers: {
    addItem: (state, action: PayloadAction<CatalogItem>) => {
      const existingItem = state.items.find(item => item.name === action.payload.name);
      if (!existingItem) {
        state.items.push(action.payload);
      }
    },
    removeItem: (state, action: PayloadAction<number>) => {
      state.items.splice(action.payload, 1);
    },
    clearItems: (state) => {
      state.items = [];
    }
  },
});

export const { addItem, removeItem, clearItems } = MarketplaceBasketSlice.actions;
export default MarketplaceBasketSlice.reducer;
