import React from 'react';

interface ImageProps {
  src: string;
  alt: string;
}

const StyledImage: React.FC<ImageProps> = ({ src, alt }) => (
    <div className="flex justify-center items-center">
        <img 
            className="shadow-lg rounded-md" 
            src={src} 
            alt={alt}
        />
    </div>
);

export default StyledImage;
