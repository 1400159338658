import React from 'react';

interface TableRowProps {
  isActive?: boolean,
  isDisabled?: boolean,
  fn?: (a?: any) => void,
  label: string,
}

const Tab: React.FC<TableRowProps> = ({ isActive = true, isDisabled = false, fn = () => null, label }) => {
  return (
    <button 
      className={`px-4 py-2 mx-2 border-b-2 ${!isDisabled && 'hover:border-pink-200'} ${isActive ? 'border-pink-200' : 'border-transparent'} ${isDisabled ? 'opacity-50' : ''}`} 
      onClick={fn}
      disabled={isDisabled}
    >
      { label }
    </button>
  );
};

export default Tab;
