export function isValidEmail(email?: string): boolean {
  if (email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  return false;
}

export function isValidTime(time: string): boolean {
  return /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(time);
}

export function isValidUrl(url?: string): boolean {
  if (url) {
    const urlRegex = /^https?:\/\/[^\s/$.?#].[^\s]*\.[a-zA-Z0-9-]+$/;
    try {
      new URL(url);
      return urlRegex.test(url);
    } catch (_) {
      return false;
    }
  }
  return false;
}

export function isValidPassword(password?: string): boolean {
  if (password) {
    /* eslint-disable no-useless-escape */
    const regex = /^(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return regex.test(password);
  }
  return false;
}
  
export function isValidPhone(phone?: string): boolean {
  if (phone) {
    const phoneRegex = /^\+?\d{1,4}[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
    return phoneRegex.test(phone);
  }
  return false;
}

export function isFutureDate(date: Date): boolean {
  const now = new Date();
  now.setSeconds(0, 0);

  return date > now;
}

export function isValidText(text: string, min?: number, max?: number): boolean {

  if (text.trim() !== text) {
    return false;
  }

  if (min !== undefined && text.length < min) {
    return false;
  }

  if (max !== undefined && text.length > max) {
    return false;
  }

  return true;
}

export const isDateInFuture = (date: string | Date): boolean => {
  const inputDate = new Date(date);
  const currentDate = new Date();
  
  currentDate.setHours(0, 0, 0, 0);
  
  return inputDate > currentDate;
};
