import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Reference, ReferenceAPI } from '../api/ProvidersAPI';
import { toastService } from '../services/ToastService';
import { useImageUploader } from './useImageUploader';

function useGetReferences() {
  const { t } = useTranslation();
  const [references, setReferences] = useState<any[]>([]);
  const [isLoadingReferences, setIsLoadingReferences] = useState(false);
  const { uploadImage } = useImageUploader('', response => response.data.pictureUrl, "images");

  useEffect(() => {
    fetchReferences();
  }, []);

  const fetchReferences = async () => {
    setIsLoadingReferences(true);
    try {
      const fetchedReferences = await ReferenceAPI.getReferences();
      setReferences(fetchedReferences.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingReferences(false);
    }
  };

  const searchReferences = async (searchText: any) => {
    const fetchedReferences = await ReferenceAPI.getReferences(searchText);
    return fetchedReferences.data;
  };

  const addReference = async (reference: Reference) => {
    setIsLoadingReferences(true);
    try {
      const newReference = await ReferenceAPI.add(reference);
      if (reference.file) {
        const urlOrUpdatedItem = await uploadImage(reference.file, `references/${newReference.data.id}/imageV2`);
        if (typeof urlOrUpdatedItem === 'string') {
          newReference.data.pictureUrl = urlOrUpdatedItem;
          await ReferenceAPI.update(reference.id!, newReference.data);
        }
      }
      setReferences(prevReferences => [...prevReferences, newReference.data]);
    } catch (error) {
      toastService.showToast(t("toast.errors.references.add"), 'error');
    } finally {
      setIsLoadingReferences(false);
    }
  };

  const updateReference = async (reference: Reference) => {
    setIsLoadingReferences(true);
    try {
      const updatedReference = await ReferenceAPI.update(reference.id!, reference);
      if (reference.file) {
        const urlOrUpdatedItem = await uploadImage(reference.file, `references/${updatedReference.data.id}/imageV2`);
        if (typeof urlOrUpdatedItem === 'string') {
          updatedReference.data.pictureUrl = urlOrUpdatedItem;
          await ReferenceAPI.update(reference.id!, updatedReference.data);
        }
      }
      setReferences(prevReferences => prevReferences.map(ref => ref.id === reference.id ? updatedReference.data : ref));
    } catch (error) {
      toastService.showToast(t("toast.errors.references.update"), 'error');
    } finally {
      setIsLoadingReferences(false);
    }
  };

  const removeReference = async (reference: Reference) => {
    try {
      await ReferenceAPI.remove(reference.id!);
      setReferences(prevReferences => prevReferences.filter(ref => ref.id !== reference.id));
    } catch (error) {
      toastService.showToast(t("toast.errors.references.remove"), 'error');
    }
  };

  return {
    isLoadingReferences,
    references,
    searchReferences,
    addReference,
    updateReference,
    removeReference,
  };
}

export default useGetReferences;
