import { v4 as uuidv4 } from 'uuid';

type ToastType = 'success' | 'error';
type ToastCallback = (message: string, id: string, type: ToastType) => void;

class ToastService {
  private listeners: ToastCallback[] = [];

  addListener(listener: ToastCallback) {
    this.listeners.push(listener);
  }

  removeListener(listener: ToastCallback) {
    this.listeners = this.listeners.filter((registered) => registered !== listener);
  }

  showToast(message: string, type: ToastType = 'success') {
    const id = uuidv4();
    this.listeners.forEach((listener) => listener(message, id, type));
  }
}

export const toastService = new ToastService();
