import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Config } from "../../api/ConfigAPI";
import { isValidText } from "../../shared/validation";
import InputShortText from "../InputShortText/InputShortText";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import SectionTitle from "../SectionTitle/SectionTitle";
import Subtitle from "../Subtitle/Subtitle";

type PaymentInfoProps = {
    config: Config | undefined;
    isLoading: boolean;
    handleInputChange: (key: keyof Config, value: any) => void;
    updateConfig: () => void;
}  

export const AccountPaymentInformation = ({ config, isLoading, handleInputChange, updateConfig }: PaymentInfoProps) => {

    const { t } = useTranslation();
    const [hasChanged, setHasChanged] = useState(false);

    const handleUpdate = () => {
        setHasChanged(false);
        updateConfig();
    }

    const handleLocalInputChange = (key: keyof Config, value: any) => {
      handleInputChange(key, value);
      setHasChanged(true);
    };

    return (
      <>
        <Subtitle text={t("Specific Information")} />
        <SectionTitle text={t("admin.form.delivery_data")} />
        <div className="flex flex-wrap space-x-5">
        <InputShortText 
            label={"settings.general.specific_info.delivery_address"} 
            validationFn={(value) => !!value && isValidText(value, 7)} 
            onChange={(e: any) => handleLocalInputChange('deliveryAddress', e.target.value)}
            // onValidationChange={(isValid) => updateFieldValidation('addressDataAddress', isValid)}
            errorMessage={"errors.too_short"}
            defaultValue={config?.deliveryAddress || ''}
            placeholder={"admin.form.address.placeholder"}
        />
        <InputShortText 
            label={"settings.general.specific_info.delivery_postalcode"} 
            validationFn={(value) => !!value && isValidText(value, 2, 15)} 
            onChange={(e: any) => handleLocalInputChange('deliveryPostalCode', e.target.value)}
            errorMessage={"errors.too_short"}
            defaultValue={config?.deliveryPostalCode}
            placeholder={"admin.form.postal_code.placeholder"}
        />
        </div>
        <br/>

        <SectionTitle text={t("admin.form.fiscal_data")} />
        <InputShortText 
            label={"settings.general.specific_info.tax_id"} 
            validationFn={(value) => !!value && isValidText(value, 3)} 
            onChange={(e: any) => handleLocalInputChange('taxId', e.target.value)}
            // onValidationChange={(isValid) => updateFieldValidation('fiscalDataNIP', isValid)}
            errorMessage={"errors.too_short"}
            defaultValue={config?.taxId || ''}
            placeholder={"admin.form.tax_id.placeholder"}
        />
        <InputShortText 
            label={"settings.general.specific_info.tax_name"} 
            validationFn={(value) => !!value && isValidText(value, 3)} 
            onChange={(e: any) => handleLocalInputChange('companyName', e.target.value)}
            // onValidationChange={(isValid) => updateFieldValidation('fiscalDataName', isValid)}
            errorMessage={"errors.too_short"}
            defaultValue={config?.companyName || ''}
            placeholder={"admin.form.tax_company_name.placeholder"}
        />
        <div className="flex flex-wrap space-x-5">
        <InputShortText 
            label={"settings.general.specific_info.tax_address"} 
            validationFn={(value) => !!value && isValidText(value, 5)} 
            onChange={(e: any) => handleLocalInputChange('companyAddress', e.target.value)}
            // onValidationChange={(isValid) => updateFieldValidation('fiscalDataAddress', isValid)}
            errorMessage={"errors.too_short"}
            defaultValue={config?.companyAddress || ''}
            placeholder={"admin.form.tax_company_address.placeholder"}
        />
        <InputShortText 
            label={"settings.general.specific_info.tax_postalcode"} 
            validationFn={(value) => !!value && isValidText(value, 2, 15)} 
            onChange={(e: any) => handleLocalInputChange('companyPostalCode', e.target.value)}
            errorMessage={"errors.too_short"}
            defaultValue={config?.companyPostalCode}
            placeholder={"admin.form.tax_postal_code.placeholder"}
        />
        </div>
        <InputShortText 
            label={"settings.general.specific_info.bank_account"} 
            validationFn={(value) => !!value && isValidText(value, 7, 35)} 
            onChange={(e: any) => handleLocalInputChange('bankAccount', e.target.value)}
            errorMessage={"errors.too_long_short"}
            defaultValue={config?.bankAccount}
            placeholder={"admin.form.bank_account.placeholder"}
        />
        <br/>
        <div className="container flex flex-wrap space-x-5">
            <PrimaryButton text={t("settings.general.basic_info.button")!} fn={() => handleUpdate()} isDisabled={isLoading || !hasChanged}/>
        </div>
      </>
    );
  }
  