import React from 'react';
import { useTranslation } from 'react-i18next';
import { getMeetingColor } from '../../shared/meetingTypeToColor';
import { MeetingType, meetingTypeToTranslationKey } from '../../shared/meetingTypeToTranslationKey';

interface LegendItemProps {
  color: string;
  label: string;
}

const LegendItem: React.FC<LegendItemProps> = ({ color, label }) => (
  <div className="flex items-center mr-2">
    <div
      className="w-4 h-4 mr-1 rounded-lg"
      style={{ backgroundColor: color }}
    ></div>
    <span>{label}</span>
  </div>
);

const meetingTypes: MeetingType[] = [
  'VISIT',
  'CONTRACT',
  'TAKING_MEASUREMENTS',
  'FIRST_MEASUREMENT',
  'SECOND_MEASUREMENT',
  'SECOND_MEASUREMENT_AND_RECEIVING',
  'RECEIVING',
  'OTHER',
  'WAITING_LIST',
  'NOTE',
];

const Legend: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-wrap">
      {meetingTypes.map((type) => (
        <LegendItem key={type} color={getMeetingColor(type)} label={t(meetingTypeToTranslationKey(type))} />
      ))}
    </div>
  );
};

export default Legend;
