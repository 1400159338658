import { Icon } from '../Icon/Icon';
import backArrow from '../../assets/images/back.svg';

type Props = {
  fn: () => void,
  text?: string,
};

export function BackButton({ fn = () => null, text = ''}: Props) {


  return (
    <div className="flex items-center cursor-pointer" onClick={fn}>
      <Icon url={backArrow} cssStyles="w-5"/>
      { text && <span>{ text } </span>}
    </div>
  );
}
