import React from 'react';
import { RouteProps } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import { Icon } from '../../components/Icon/Icon';
import LoginFlow from '../../components/LoginFlow/LoginFlow';
import eAteliersLogo from "../../assets/images/eateliers-logo.svg";
import { useSelector } from 'react-redux';
import { Settings } from '../../store/reducers/AccountSlice';
import { useTranslation } from 'react-i18next';
import CONFIG from '../../config/config';

const LoginPage: React.FC<RouteProps> = () => {

  const { i18n } = useTranslation();
  const settings: Settings = useSelector((state: any) => state.settings.value);
  const languages = CONFIG.SUPPORTED_LANGUAGES.map(l => {
    return {
      code: l.code,
      icon: <Icon url={l.icon} onClick={() => i18n.changeLanguage(l.code)} />,
    }
  });

  return (
    <div className="flex flex-col min-h-screen">
      <div className="m-auto space-y-5">
        <div className="flex justify-center w-full">
          <Icon url={settings.logoUrl || eAteliersLogo} cssStyles="w-48 min-w-full max-w-full" />
        </div>
        <LoginFlow />
        <div className="flex justify-center mt-4">
          {languages.map((lang, index) => (
            <div key={index} className="mr-2">
              {lang.icon}
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LoginPage;

