import * as amplitude from '@amplitude/analytics-browser';

import Button from "../Button/Button";

type Props = {
  text: string | JSX.Element,
  isDisabled?: boolean,
  fn?: (a?: any) => void,
  eventName?: string,
  actionName?: string
};

function SecondaryButton({text, fn = () => null, isDisabled = false, eventName = '', actionName = 'Secondary Button'}: Props) {

  const track = (e: any) => {
    if (!!eventName) amplitude.track(eventName);
    fn();
  }

  return (
    <Button 
      text={text} 
      fn={track} 
      isDisabled={isDisabled} 
      cssStyles='bg-transparent font-light bg-clip-text bg-gradient-to-r from-amber-300 to-amber-600 hover:text-amber-600 duration-500 px-2 py-2'
      actionName={actionName}
    />
  );
}

export default SecondaryButton;
