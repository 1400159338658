type Props = {
  text: string | JSX.Element,
  isDisabled?: boolean,
  fn?: (a?: any) => void,
  cssStyles?: string,
  propagate?: boolean,
  actionName?: string
};

function Button(
  {
    text, 
    fn = () => null, 
    isDisabled = false, 
    cssStyles = '', 
    propagate = false,
    actionName = 'Button'
  }: Props) {

  return (
    <button 
      type="button" 
      onClick={(e) => { if (!propagate) { e.stopPropagation(); } fn();}} 
      className={
        isDisabled ? `max-w-sm pointer-events-none focus:outline-none rounded-lg px-5 py-2.5 ${cssStyles}`
        : `max-w-sm font-light rounded duration-500 px-7 py-2.5 ${cssStyles}`
        }
        data-dd-action-name={actionName}
    >
      { text }
    </button>
  );
}

export default Button;
