export type PaymentMethodType = "CARD" | "TRANSFER" | "CASH";

export function getPaymentMethodType(): PaymentMethodType[] {
  return ['CARD', 'TRANSFER', 'CASH'];
}

export function paymentMethodTypeToTranslationKey(status: string | undefined): string {
  switch (status) {
    case 'CARD':
      return "contract_template.payment_method.type.card";
    case 'TRANSFER':
      return "contract_template.payment_method.type.transfer";
    case 'CASH':
        return "contract_template.payment_method.type.cash";
    default:
      return "global.unknown";
  }
}
