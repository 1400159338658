import CONFIG from '../config/config';
import { instanceWithV2 as APIClient } from './SimpleAPIClient';

export type User = {
  uuid?: string,
  role: string,
  flags: string[],
  email: string,
}

const UsersAPI = {
  getUsers: () => APIClient(CONFIG.API_ENDPOINT).get<User[]>(`/v1/admin/users`),
  setUserPermissions: (uuid: string, flags: string[]) => APIClient(CONFIG.API_ENDPOINT).put<User[]>(`/v1/admin/users`, { uuid, flags }),
};

export { UsersAPI };
