import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import BarChart from '../../../components/BarChart/BarChart';
import NoData from '../../../components/NoData/NoData';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
import { useStatistics } from '../../../hooks/useStatistics';
import { orderStatusToTranslationKeys } from '../../../shared/orderStatusToTranslationKey';

function ActiveOrdersGroupedByStatus() {
  const { t } = useTranslation();
  const data = useStatistics('active-orders-grouped-by-status');
  const [chartData, setChartData] = useState<any[]>([]);

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      Object.keys(data).forEach((acc, key) => {
        const translatedkey = t(orderStatusToTranslationKeys(acc));
        data[translatedkey] = data[acc];
        delete data[acc];
      }, {});
      const chartValues = Object.values(data);
      const backgroundColor = ['rgba(75, 192, 192, 0.6)'];
      setChartData([{
        label: 'Active Orders',
        data: chartValues,
        backgroundColor,
        borderColor: backgroundColor,
        borderWidth: 1,
      }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <SectionTitle text={t("stats.active_orders_grouped_by_status")} />
      {chartData.length > 0 ? (
        <BarChart title="Active Orders by Status" xLabels={Object.keys(data)} dataSets={chartData} />
      ) : (
        <NoData message={t("global.empty_results")} />
      )}
    </>
  );
}

export default ActiveOrdersGroupedByStatus;
