import React from 'react';
import { Provider } from '../../api/ProvidersAPI';

type Props = {
  provider: Provider,
  actions?: JSX.Element
}

function ProviderElement({ provider, actions }: Props) {
  return (
    <div className="flex justify-between space-x-3">
      <p className="bolder">{provider.name}</p>
      <span className="flex space-x-5">
        <p>[{ provider.references.length } reference(s)]</p>
        { actions }
      </span>
    </div>
  );
}

export default ProviderElement;
