import { t } from "i18next";
import { useState } from "react";
import SectionTitle from "../SectionTitle/SectionTitle";
import SmallCheckBox from "../SmallCheckBox/SmallCheckBox";
import TagsInput from "../TagsInput/TagsInput";

type Props = {
  title: string,
  label?: string,
  onUpdate: (data: { enabled: boolean, emails: string[] }) => void,
  initialEmails: string[],
  initialIsEnabled: boolean,
  isDisabled: boolean
}

function AlertEmailField({
  title = '',
  label = '',
  onUpdate,
  initialEmails = [],
  initialIsEnabled = false,
  isDisabled = false
}: Props) {
  const [emails, setEmails] = useState(initialEmails);
  const [enabled, setEnabled] = useState(initialIsEnabled);

  return (
    <>
      <SectionTitle text={title} />
      <SmallCheckBox
        label={t("global.enabled")!}
        defaultValue={enabled}
        onChange={e => {
          setEnabled(e.target.checked);
          onUpdate({ enabled: e.target.checked, emails });
        }}
        isDisabled={isDisabled}
      />
      <TagsInput
        label={label}
        onChange={newEmails => {
          setEmails(newEmails);
          onUpdate({ enabled, emails: newEmails });
        }}
        tags={emails}
        isDisabled={isDisabled}
      />
    </>
  );
}

export default AlertEmailField;