import CONFIG from '../config/config';
import { ORDER_STATUS } from '../shared/orderStatusToTranslationKey';
import { instanceWithV2 as APIClient } from './SimpleAPIClient';

export type DressSample = {
  featuredPictureUrl: string,
  id: number,
  name: string, 
  type: string
}

export type ShopClient = {
  shopId: string,
  supportedByApp: boolean,
  id?: number,
  items: DressSample[],
  name: string,
  addressDataAddress?: string,
  addressDataName?: string,
  addressDataPostalCode?: string,
  email?: string,
  fiscalDataAddress?: string,
  fiscalDataNIP?: string,
  fiscalDataName?: string,
  fiscalDataPostalCode?: string,
  notes?: string,
  phone?: string,
  phoneAddress?: string,
  countryCode?: string,
}

export type OrderStep = NewOrderStep & {
  creationTime: number,
  id: number,
  orderId: number,
}

export type OrderHistoryStep = OrderStep & {
  clientName: string,
}

export type NewOrderStep = {
  status: ORDER_STATUS,
  workerName: string,
  workerId?: string,
  comment?: string
}

export type OrderComment = {
  id?: number;
  comment: string;
  by?: string;
  creationTime?: number;
  shopName?: string;
  self?: boolean;
}

type ShopApiResponse = ShopClient[];
type OrdeStoryApiResponse = OrderStep[];
type OrderCommentsApiResponse = OrderComment[];
type OrderWorkerStoryApiResponse = OrderHistoryStep[];

const ShopClientsAPI = {
  get: (filters: {countryCode?: string, dress?: string} = {}) => {
    let url = `/v1/shops`;
    const queryParams = new URLSearchParams();
    if (filters.countryCode) queryParams.append('countryCode', filters.countryCode);
    if (filters.dress) queryParams.append('dress', filters.dress);

    if (queryParams.toString()) {
      url += `?${queryParams.toString()}`;
    }
    return APIClient(CONFIG.API_ENDPOINT).get<ShopApiResponse>(url);
  },
  getStory: (shopId: string, orderId: number) => APIClient(CONFIG.API_ENDPOINT).get<OrdeStoryApiResponse>(`/v1/shops/${shopId}/orders/${orderId}/history`),
  getComments: (orderId: number) => APIClient(CONFIG.API_ENDPOINT).get<OrderCommentsApiResponse>(`/v1/shops/orders/in-out/${orderId}/comments`),
  getWorkerStory: (workerId: number) => APIClient(CONFIG.API_ENDPOINT).get<OrderWorkerStoryApiResponse>(`/v1/shops/orders/workers/${workerId}/history`),
  addStoryStep: (shopId: string, orderId: number, step: NewOrderStep) => APIClient(CONFIG.API_ENDPOINT).post<OrdeStoryApiResponse>(`/v1/shops/${shopId}/orders/${orderId}/history`, step),
  addComment: (orderId: number, comment: OrderComment) => APIClient(CONFIG.API_ENDPOINT).post<OrderCommentsApiResponse>(`/v1/shops/orders/in-out/${orderId}/comments`, comment),
  add: (shop: ShopClient) => APIClient(CONFIG.API_ENDPOINT).post<ShopClient>(`/v1/shops`, shop),
  update: (id: number, shop: ShopClient) => APIClient(CONFIG.API_ENDPOINT).put<ShopClient>(`/v1/shops/${id}`, shop),
  delete: (id: number) => APIClient(CONFIG.API_ENDPOINT).delete<ShopApiResponse>(`/v1/shops/${id}`)
};

export default ShopClientsAPI;