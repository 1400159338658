import { format, utcToZonedTime } from 'date-fns-tz';

export const parseDateTime = (dateTimeStr: string, timeZone?: string): { date: string, time: string } => {
    const effectiveTimeZone = timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone;

    const zonedDate = utcToZonedTime(dateTimeStr, effectiveTimeZone);

    const date = format(zonedDate, 'yyyy-MM-dd', { timeZone: effectiveTimeZone });
    const time = format(zonedDate, 'HH:mm', { timeZone: effectiveTimeZone });

    return { date, time };
}
