import { useTranslation } from "react-i18next";
import Title from "../../components/Title/Title";
import PageWrapper from "../PageWrapper/PageWrapper";

const TermsPage = () => {

  const { t } = useTranslation("terms");
  const termsSections = [
    'terms_section1', 'terms_section2', 'terms_section3', 'terms_section4', 'terms_section5', 
    'terms_section6', 'terms_section7', 'terms_section8', 'terms_section9', 'terms_section10', 
    'terms_section11', 'terms_section12', 'terms_section13', 'terms_section14', 'terms_section15', 
    'terms_section16', 'terms_section17', 'terms_section18', 'terms_section19', 'terms_section20', 
    'terms_section21', 'terms_section22', 'terms_section23', 'terms_section24', 
  ];

  return (
    <PageWrapper>
      <>
        <Title text={t("menu.terms_of_service")} />
        {termsSections.map((key) => (
          <div className="pt-5 pb-5" key={key} dangerouslySetInnerHTML={{ __html: t(key) }} />
        ))}
      </>
    </PageWrapper>
  );
}

export default TermsPage;
