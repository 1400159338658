import { t } from "i18next";
import { useEffect, useState } from "react";
import RemindersAPI, { Reminders } from "../../../api/RemindersAPI";
import Card from "../../../components/Card/Card";
import PrimaryButton from "../../../components/PrimaryButton/PrimaryButton";
import { VisitReminder } from "../reminders/VisitReminder/VisitReminder";
import { WeddingRateReminder } from "../reminders/WeddingRateReminder/WeddingRateReminder";

export interface VisitReminderConfig {
  enabled: boolean;
  type: 'sms' | 'email';
  details?: {
    phoneId?: string;
    emailTitle?: string;
    text?: string;
    daysInAdvance?: number;
  };
}

export interface WeddingRateConfig {
  enabled: boolean;
  emailTitle: string;
  text: string;
}

export type ReminderConfig = VisitReminderConfig | WeddingRateConfig;

export type UpdateCallback = (reminder: string, config: ReminderConfig) => void;

function NotificationsSegment() {

  const [hasChanges, setHasChanges] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reminders, setReminders] = useState<Reminders>({
    smsReminder: undefined,
    weddingRateReminder: undefined
  });

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        const fetchedReminders = await RemindersAPI.get();
        setReminders(fetchedReminders.data);
      } catch (error) {
        console.error("Failed to fetch initial reminders data:", error);
      }
    };
    loadInitialData();
  }, []); 

  const updateReminders = (reminder: string, config: ReminderConfig) => {
    setHasChanges(true);
    setReminders(prevReminders => {
      return {
        ...prevReminders,
        [reminder]: config,
      };
    });
  };

  const saveChanges = async () => {
    setIsLoading(true);

    try {
      await RemindersAPI.update(reminders);
      setHasChanges(false);
    } catch (error) {
      console.error("Failed to update reminders:", error);
    }
    setIsLoading(false);
  };

  return (
      <>
        <Card>
          <VisitReminder update={updateReminders} defaultConfig={reminders.smsReminder && 'type' in reminders.smsReminder ? reminders.smsReminder : undefined} />
        </Card>
        <br/>
        <br/>
        <Card>
          <WeddingRateReminder update={updateReminders} defaultConfig={reminders.weddingRateReminder || {}} />
        </Card>
        <br/>
        <div className="container flex justify-center flex-wrap space-x-5">
          <PrimaryButton text={t("global.save")!} fn={saveChanges} isDisabled={!hasChanges || isLoading} />
        </div>
      </>
  );
}

export default NotificationsSegment;
