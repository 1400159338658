import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Customer } from '../../api/CustomersAPI';
import { isValidText } from '../../shared/validation';
import CustomerSearchModal from '../CustomerSearchTrigger/CustomerSearchTrigger';
import CustomerSummary from '../CustomerSummary/CustomerSummary';
import { Icon } from '../Icon/Icon';
import IconButton from '../IconButton/IconButton';
import InputShortText from '../InputShortText/InputShortText';
import deleteIcon from '../../assets/images/bin.svg';

interface CustomerInputProps {
  label?: string;
  onCustomerSelect: (customer: Customer) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isDisabled?: boolean;
  errorMessage?: string;
  hasAccess?: boolean;
  defaultValue?: string;
  onValidationChange?: (isValid: boolean) => void;
  initialCustomerValue?: Customer;
}

const CustomerInput: React.FC<CustomerInputProps> = ({
  label,
  onCustomerSelect,
  onChange,
  isDisabled = false,
  hasAccess = true,
  errorMessage,
  defaultValue,
  onValidationChange,
  initialCustomerValue = null
}) => {
  const { t } = useTranslation();
  const [customer, setCustomer] = useState<Customer | null>(initialCustomerValue);
  const [isValid, setIsValid] = useState<boolean>(true);

  const inputShortTextProps = {
    validationFn: (value: string) => !!value && isValidText(value, 0),
    isDisabled: isDisabled || !hasAccess,
    errorMessage,
    defaultValue,
    onValidationChange: setIsValid,
  };

  useEffect(() => {
    if (onValidationChange) {
      onValidationChange(isValid);
    }
  }, [isValid, onValidationChange]);
  
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    const valid = !!value && isValidText(value, 0);
    setIsValid(valid);
    onChange(event);
  };
  
  return (
    <div className="flex-1 max-w-full">
      <span className="flex">
        { label && <p className="text-sm font-extrabold">{ t(label) }: &nbsp;</p> }
        <CustomerSearchModal onCustomerSelect={c => { setCustomer(c); onCustomerSelect(c);}} />
      </span>
      { customer ? 
        <div className="relative w-full">
            <div className="flex flex-col w-full"><CustomerSummary customer={customer} /></div>
            <div className="absolute top-0 right-0 p-2">
                <IconButton onClick={() => setCustomer(null)}>
                <Icon url={deleteIcon} cssStyles="w-5 h-5" />
                </IconButton>
            </div>
        </div>
      :
      <InputShortText 
        {...inputShortTextProps}
        onChange={handleInputChange}
      /> }
    </div>
  );
};

export default CustomerInput;
