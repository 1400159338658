import { useState, useEffect, useCallback } from 'react';
import ConfigAPI, { TabletConfig } from '../api/ConfigAPI';

const useTabletConfig = () => {
  const [config, setConfig] = useState<TabletConfig | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchConfig = useCallback(async () => {
    try {
      setLoading(true);
      const response = await ConfigAPI.getTablet();
      setConfig(response.data);
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
    } finally {
      setLoading(false);
    }
  }, []);

  const updateConfig = useCallback(async (newConfig: TabletConfig) => {
    try {
      setLoading(true);
      await ConfigAPI.updateTablet(newConfig);
      setConfig(newConfig);
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchConfig();
  }, [fetchConfig]);

  return { config, isLoading, error, updateConfig };
};

export default useTabletConfig;
