import { Order, OrderDress } from "../../api/OrdersAPI";
import { orderStatusToTranslationKeys } from "../../shared/orderStatusToTranslationKey";
import { saveAs } from 'file-saver';
import { useTranslation } from "react-i18next";
import SecondaryButton from "../SecondaryButton/SecondaryButton";

type Props = {
  orders: Order[]
};

interface FileText {
  parts: string[];
  fileName: string;
}

function PrintButton({ orders = [] }: Props) {

  const { t } = useTranslation();

  const getTextFile = () => {
    const sortedOrders = sortOrders([...orders]);
    let fileText = generateText(sortedOrders);
    saveToFileSystem(fileText.parts, fileText.fileName);
  }

  const sortOrders = (orders: Order[]): Order[] => {
    return orders.sort((a, b) => {
      if (a.linkedShopId !== b.linkedShopId) {
        return (a.shopName || "").localeCompare(b.shopName || "");
      } else {
        return new Date(a.deliveryDate).getTime() - new Date(b.deliveryDate).getTime();
      }
    });
  };

  const generateText = (orders: Order[]): FileText => {
    let currentShop = '';
    const parts: string[] = [];

    orders.forEach(or => {
      let shop = or.shopName!;
      // eslint-disable-next-line eqeqeq
      if (currentShop != shop) {
        currentShop = shop;
        parts.push('\n');
        parts.push(shop + '\n');
        parts.push('------------------------\n');
        parts.push('------------------------\n');
      }

      parts.push(printOrder(or));
    });

    return {
      parts: parts,
      fileName: t("menu.orders")
    }
  }

  const printOrder = (or: Order) => {
    return or.shopName + ' | ' + or.clientName + ' | ' + ((or.dress as OrderDress)?.name || or.dressDescription) + ' | ' + or.deliveryDate + ' | ' + t(orderStatusToTranslationKeys(or.status)) + '\n';
  }

  const saveToFileSystem = (parts: string[], fileName: string) => {
    const blob = new Blob(parts, {type: 'text/plain'});
    saveAs(blob, fileName);
  }

  return <SecondaryButton eventName="print_orders_button_clicked" text={t("global.print")!} fn={() => getTextFile()} isDisabled={orders.length === 0}/>;
}

export default PrintButton;
