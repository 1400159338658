import React from 'react';
import { useTranslation } from "react-i18next";
import { Customer } from "../../api/CustomersAPI";
import { customerStatusToPillColor, customerStatusToTranslationKey } from "../../shared/customerStatusToTranslationKey";
import { BolderText } from "../BolderText/BolderText";
import Pill from "../Pill/Pill";

type CustomerSummaryProps = {
  customer: Customer;
};

const CustomerSummary: React.FC<CustomerSummaryProps> = ({ customer }) => {
  const { t } = useTranslation();

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
        <span>
          <BolderText text={`${customer.name} ${customer.surname}`} />
        </span>
        <Pill text={t(customerStatusToTranslationKey(customer.status))} color={customerStatusToPillColor(customer.status)} />
      </div>
      <div className="border-t border-gray-200">
        <dl>
          <div className="bg-white px-4 py-5 grid grid-cols-3 gap-4 sm:grid-cols-3">
            <dt className="text-sm font-medium text-gray-500">{t("customers.summary.phone")}:</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{customer.phone}</dd>
            <dt className="text-sm font-medium text-gray-500">{t("customers.summary.email")}:</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{customer.email}</dd>
          </div>
        </dl>
      </div>
    </div>
  );
};

export default CustomerSummary;
