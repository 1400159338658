import { ShopClient } from "../../api/ShopClientsAPI";
import InputShortText from "../InputShortText/InputShortText";
import TextArea from "../TextArea/TextArea";
import { useTranslation } from "react-i18next";
import SectionTitle from "../SectionTitle/SectionTitle";
import ListContainer from "../ListContainer/ListContainer";
import ListElement from "../ListElement/ListElement";
import React, { useState } from "react";
import { isValidEmail, isValidPhone, isValidText } from "../../shared/validation";
import { useFormValidation } from "../../hooks/useFormValidation";
import { Autocomplete } from "../Autocomplete/Autocomplete";
import CollectionsAPI, { Item } from "../../api/CollectionsAPI";
import MarketingCollectionItemElement from "../MarketingCollectionItemElement/MarketingCollectionItemElement";
import IconButton from "../IconButton/IconButton";
import { Icon } from "../Icon/Icon";
import deleteIcon from "../../assets/images/bin.svg";
import SelectInput from "../SelectInput/SelectInput";
import CONFIG from "../../config/config";

type Props = {
  partner: ShopClient,
  onPartnerChange?: (value: ShopClient) => void,
  isDisabled?: boolean,
  actionButton?: JSX.Element
};

const getInitialState = (partner?: ShopClient) => {
  if (partner) {
    return {
      name: !!partner.name && isValidText(partner.name, 2),
      email: !!partner.email && isValidEmail(partner.email),
    };
  }
};

function PartnerForm({ partner, onPartnerChange = () => null, isDisabled = false, actionButton }: Props) {

  const { t } = useTranslation();
  const requiredFields = ['name', 'email'];
  const initialState = getInitialState(partner);
  const [isSearchLoding, setIsSearchLoading] = useState(false);
  const { updateFieldValidation, isFormValid } = useFormValidation(requiredFields, initialState);

  const disabledActionButton = actionButton
    ? React.cloneElement(
        actionButton,
        {},
        React.Children.map(actionButton.props.children, (child) => {
          return child ? React.cloneElement(child, { isDisabled: !isFormValid() }) : null;
        })
      )
    : null;

  const search = async (input: string, resultsSize: number, setResultsFn: any) => {
    setIsSearchLoading(true);
    try {
      const searchResults = await CollectionsAPI.searchItems(input);
      const items = searchResults.data;
      const filteredItems = items.slice(0, resultsSize);
      setResultsFn(filteredItems);
    } catch (err) {
      console.error('Could not search collections');
    }
    setIsSearchLoading(false);
  };

  const containsItem = (item: Item) => {
    return (partner?.items || []).some(i => i.id === item.id);
  };

  const addItemToPartner = (item: Item) => {
    if (partner && !containsItem(item)) {
      const updatedItems = [...(partner.items || []), item];
      onPartnerChange({ ...partner, items: updatedItems } as ShopClient);
    }
  };

  const removeItemFromPartner = (item: Item) => {
    if (partner) {
      const updatedItems = (partner.items || []).filter(i => i.id !== item.id);
      onPartnerChange({ ...partner, items: updatedItems } as ShopClient);
    }
  };

  return ( 
      <>
        <div className="flex flex-wrap space-x-5">
          <div className="md:flex-1 w-full">
            <SectionTitle text={t("admin.form.general_data")} />
            <InputShortText 
              label={"admin.form.name"}
              validationFn={(value) => !!value && isValidText(value, 2)} 
              onValidationChange={(isValid) => updateFieldValidation('name', isValid)}
              onChange={e => onPartnerChange({ ...partner, name: e.currentTarget.value } as ShopClient)}
              errorMessage={"errors.too_short"}
              defaultValue={partner?.name || ''}
              isDisabled={isDisabled}
            />
            <InputShortText 
              label={"admin.form.email"} 
              validationFn={(value) => !!value && isValidEmail(value)} 
              onValidationChange={(isValid) => updateFieldValidation('email', isValid)}
              onChange={e => onPartnerChange({ ...partner, email: e.currentTarget.value } as ShopClient)}
              errorMessage={"errors.too_short"}
              defaultValue={partner?.email || ''}
              isDisabled={isDisabled}
            />
            <div className="flex flex-wrap space-x-5">
              <InputShortText 
                label={"admin.form.phone"} 
                validationFn={(value) => !!value && isValidPhone(value)} 
                onChange={e => onPartnerChange({ ...partner, phone: e.currentTarget.value } as ShopClient)}
                errorMessage={"errors.too_short"}
                defaultValue={partner?.phone || ''}
                isDisabled={isDisabled}
              />
              <SelectInput
                values={CONFIG.COUNTRY_CODES.map(e => { return { value: e.countryCode, readableValue: t(e.countryName?.toLowerCase()) } })}
                onChange={e => onPartnerChange({ ...partner, countryCode: e.currentTarget.value } as ShopClient)}
                label={"admin.form.country_code"}
                defaultValue={partner?.countryCode}
                isDisabled={isDisabled}
              />
            </div>
            <br/>

            <SectionTitle text={t("admin.form.delivery_data")} />
            <InputShortText 
              label={"admin.form.delivery_receiver"} 
              validationFn={(value) => !!value && isValidText(value, 5)} 
              onValidationChange={(isValid) => updateFieldValidation('addressDataName', isValid)}
              onChange={e => onPartnerChange({ ...partner, addressDataName: e.currentTarget.value } as ShopClient)}
              errorMessage={"errors.too_short"}
              defaultValue={partner?.addressDataName || ''}
              placeholder={"admin.form.delivery_receiver.placeholder"}
              isDisabled={isDisabled}
            />
            <InputShortText 
              label={"admin.form.address"} 
              validationFn={(value) => !!value && isValidText(value, 7)} 
              onValidationChange={(isValid) => updateFieldValidation('addressDataAddress', isValid)}
              onChange={e => onPartnerChange({ ...partner, addressDataAddress: e.currentTarget.value } as ShopClient)}
              errorMessage={"errors.too_short"}
              defaultValue={partner?.addressDataAddress || ''}
              placeholder={"admin.form.address.placeholder"}
              isDisabled={isDisabled}
            />
            <InputShortText 
              label={"admin.form.postal_code"} 
              validationFn={(value) => !!value && isValidText(value, 2, 15)} 
              onChange={e => onPartnerChange({ ...partner, addressDataPostalCode: e.currentTarget.value } as ShopClient)}
              errorMessage={"errors.too_short"}
              defaultValue={partner?.addressDataPostalCode}
              placeholder={"admin.form.postal_code.placeholder"}
              isDisabled={isDisabled}
            />
            <InputShortText 
              label={"admin.form.phone_address"} 
              validationFn={(value) => !!value && isValidPhone(value)} 
              onChange={e => onPartnerChange({ ...partner, phoneAddress: e.currentTarget.value } as ShopClient)}
              errorMessage={"errors.too_short"}
              defaultValue={partner?.phoneAddress}
              placeholder={"admin.form.phone_address.placeholder"}
              isDisabled={isDisabled}
            />
            <br/>

            <SectionTitle text={t("admin.form.fiscal_data")} />
            <InputShortText 
              label={"admin.form.tax_id"} 
              validationFn={(value) => !!value && isValidText(value, 3)} 
              onChange={e => onPartnerChange({ ...partner, fiscalDataNIP: e.currentTarget.value } as ShopClient)}
              onValidationChange={(isValid) => updateFieldValidation('fiscalDataNIP', isValid)}
              errorMessage={"errors.too_short"}
              defaultValue={partner?.fiscalDataNIP || ''}
              placeholder={"admin.form.tax_id.placeholder"}
              isDisabled={isDisabled}
            />
            <InputShortText 
              label={"admin.form.tax_company_name"} 
              validationFn={(value) => !!value && isValidText(value, 3)} 
              onChange={e => onPartnerChange({ ...partner, fiscalDataName: e.currentTarget.value } as ShopClient)}
              onValidationChange={(isValid) => updateFieldValidation('fiscalDataName', isValid)}
              errorMessage={"errors.too_short"}
              defaultValue={partner?.fiscalDataName || ''}
              placeholder={"admin.form.tax_company_name.placeholder"}
              isDisabled={isDisabled}
            />
            <InputShortText 
              label={"admin.form.tax_company_address"} 
              validationFn={(value) => !!value && isValidText(value, 5)} 
              onChange={e => onPartnerChange({ ...partner, fiscalDataAddress: e.currentTarget.value } as ShopClient)}
              onValidationChange={(isValid) => updateFieldValidation('fiscalDataAddress', isValid)}
              errorMessage={"errors.too_short"}
              defaultValue={partner?.fiscalDataAddress || ''}
              placeholder={"admin.form.tax_company_address.placeholder"}
              isDisabled={isDisabled}
            />
            <InputShortText 
              label={"admin.form.tax_postal_code"} 
              validationFn={(value) => !!value && isValidText(value, 2, 15)} 
              onChange={e => onPartnerChange({ ...partner, fiscalDataPostalCode: e.currentTarget.value } as ShopClient)}
              errorMessage={"errors.too_short"}
              defaultValue={partner?.fiscalDataPostalCode}
              placeholder={"admin.form.tax_postal_code.placeholder"}
              isDisabled={isDisabled}
            />
            <br/>

            <SectionTitle text={t("admin.form.other_data")} />
            <TextArea
              label={"admin.form.notes"} 
              validationFn={(value) => !value || (!!value &&  isValidText(value, 0, 1024))}
              onChange={e => onPartnerChange({ ...partner, notes: e.currentTarget.value } as ShopClient)}
              errorMessage={"errors.too_short"}
              defaultValue={partner?.notes}
              isDisabled={isDisabled}
            />
          </div>

          <div className="md:flex-1 max-h-screen overflow-y-scroll">
            <SectionTitle text={`${t("admin.form.dresses")} [${partner?.items.length || 0}] `} />
            <Autocomplete
              resultsSize={5}
              searchFn={search}
              onSelect={addItemToPartner}
              placeholder={t("admin.collections.search.placeholder")!}
              isLoading={isSearchLoding}
              errorMessage={t('global.empty_results')!}
              isDisabled={isDisabled}
              toElementFn={(item: Item) =>
                <ListElement key={item.id}>
                  <MarketingCollectionItemElement item={item} />
                </ListElement>
              }
            />
            {/* TODO: switch to marketing dresses? Retrocompatibility? */}
            {/* <DressAutocomplete
              onChange={addItemToPartner}
              label={`${t("contracts.model")}* :`} 
              placeholder={t("admin.collections.search.placeholder")!}    
            /> */}
            <ListContainer 
              elements={partner?.items || []}
              toElementFn={(item: Item) => 
                <ListElement
                    key={item.id}
                    css="bg-white text-black"
                    onClick={() => null}
                  >
                    <div className="flex flex-col w-full">
                      <MarketingCollectionItemElement
                        item={item}
                        actions={
                          isDisabled ? <></> : <>
                            <IconButton onClick={() => removeItemFromPartner(item)}>
                                <Icon url={deleteIcon} cssStyles="w-5" />
                            </IconButton>
                          </>
                        }
                      />
                    </div>
                  </ListElement>
              }
            />
          </div>
        </div>
        { disabledActionButton }
      </>
  );
}

export default PartnerForm;
