import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ConfigAPI, { Config } from "../../../api/ConfigAPI";
import { AccountBasicInformation } from "../../../components/AccountBasicInformation/AccountBasicInformation";
import { AccountPaymentInformation } from "../../../components/AccountPaymentInformation/AccountPaymentInformation";
import Card from "../../../components/Card/Card";
import PrimaryButton from "../../../components/PrimaryButton/PrimaryButton";
import SelectInput from "../../../components/SelectInput/SelectInput";
import Subtitle from "../../../components/Subtitle/Subtitle";
import ThemeColorConfiguration from "../../../components/ThemeColorConfiguration/ThemeColorConfiguration";
import TinyText from "../../../components/TinyText/TinyText";
import CONFIG from "../../../config/config";
import { useGetShops } from "../../../hooks/useGetShops";
import { updateSettings } from "../../../store/reducers/AccountSlice";

function GeneralSegment() {

  const { shops } = useGetShops();
  const [config, setConfig] = useState<Config>();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
        setIsLoading(true);
        try {
            const configResponse = await ConfigAPI.get();
            setConfig(configResponse.data);
        } catch (error) {
            console.error('Failed to fetch data:', error);
        } finally {
            setIsLoading(false);
        }
    };
    fetchData();
  }, []);

  const handleInputChange = (key: keyof Config, value: any) => {
    setConfig(prevConfig => ({...prevConfig, [key]: value}));
  };

  const updateConfig = async () => {
    setIsLoading(true);
    if (config) {
      setConfig(prevConfig => config);
      (await ConfigAPI.update(config));
    }
    setIsLoading(false);
    dispatch(updateSettings(
      { tenantName: config?.brandName, 
        isConfigurationLoaded: true, 
        logoUrl: config?.logoUrl, 
        openingHours: config?.openingHours || "10:00", 
        closingHours: config?.closingHours || "17:00", 
        timezone: config?.timezone 
    }))
  }

  const updateCurrency = async (currency: string) =>  {
    const updatedConfig = (await ConfigAPI.update({...config, currency: currency})).data;
    setConfig(prevConfig => updatedConfig);
  }

  const updateShop = async (shopId: string) =>  {
    const updatedConfig = (await ConfigAPI.update({...config, shopId: shopId})).data;
    setConfig(prevConfig => updatedConfig);
  }

  const updateColorConfiguration = async (primaryColor: string, backgroundColor: string) => {
    const updatedConfig = {...config, primaryColor, backgroundColor };
    setConfig(prevConfig => updatedConfig);
    (await ConfigAPI.update(updatedConfig));
  };

  return (
    <>
        <div className="flex-col md:flex md:flex-row justify-between md:space-x-3 mb-3">
          <Card>
            <AccountBasicInformation config={config} isLoading={isLoading} handleInputChange={handleInputChange} updateConfig={updateConfig} />
          </Card>
          <Card>
            <AccountPaymentInformation config={config} isLoading={isLoading} handleInputChange={handleInputChange} updateConfig={updateConfig}/>
          </Card>
        </div>
        <div className="flex-col md:flex md:flex-row justify-between md:space-x-3 mb-3">
          <Card>
            <>
              <Subtitle text={t("settings.general.set_currency")} />
              <TinyText text={`${t("settings.general.set_currency.description")}.`} />
              <SelectInput
                  values={CONFIG.SUPPORTED_CURRENCIES.map(e => { return { value: e, readableValue: e } })}
                  onChange={e => updateCurrency(e.target.value)}
                  label={t("settings.general.set_currency.label")!}
                  defaultValue={config?.currency || "EUR"}
                  isDisabled={isLoading}
              />
            </>
          </Card>
          <Card>
              <>
                <Subtitle text={t("settings.general.set_store")} />
                <TinyText text={`${t("settings.general.set_store.description")}.`} />
                <SelectInput
                    values={(shops || []).map(e => { return { value: String(e.id), readableValue: e.name } })}
                    onChange={e => updateShop(e.target.value)}
                    label={t("settings.general.set_store.label")!}
                    defaultValue={String(config?.shopId)}
                    isDisabled={true}
                />
              </>
          </Card>
        </div>
        <div className="flex-col md:flex md:flex-row justify-between md:space-x-3">
          <Card>
              <>
                <Subtitle text={t("settings.general.backup")} />
                <TinyText text={`${t("settings.general.backup.description")}.`} />
                <TinyText text={`${t("settings.general.backup.description2")}.`} />
                <span className="flex space-x-3">
                  <PrimaryButton text={t("settings.general.backup.orders")!} fn={() => null} isDisabled/>
                  <PrimaryButton text={t("settings.general.backup.calendar")!} fn={() => null} isDisabled/>
                </span>
              </>
          </Card>
          <Card>
            <ThemeColorConfiguration onColorsChange={updateColorConfiguration} color={config?.primaryColor} defaultBgColor={config?.backgroundColor}/>
          </Card>
        </div>
    </>
  );
}

export default GeneralSegment;
