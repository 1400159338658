import { useTranslation } from 'react-i18next';
import { OrderStep } from '../../api/ShopClientsAPI';
import { orderStatusToTranslationKeys } from '../../shared/orderStatusToTranslationKey';
import { BolderText } from '../BolderText/BolderText';
import TinyText from '../TinyText/TinyText';

type Props = {
    step: OrderStep
}

const OrderWorkStep = ({ step }: Props) => {
    const { t } = useTranslation();

    return (
        <div>
            <p>
                {step.workerName} {t("global.completed")} <span className="text-darker-gold"><BolderText text={t(orderStatusToTranslationKeys(step.status))!} /></span>
            </p>
            <p><TinyText text={new Date(step.creationTime).toLocaleString()}/></p>
            <p>{ step.comment && <TinyText text={step.comment} /> }</p>
        </div>
    );
};

export default OrderWorkStep;
