import { useEffect, useState } from "react";
import ContractsAPI from "../api/ContractsAPI";

type GeneralData = {
    companyName: string;
    companyAddress: string;
    taxID: string;
    logoUrl: string;
  };
  
  type Conditions = {
    conditions: string[];
  };
  
export type Instalment = {
  percentage: number;
  method: 'Card' | 'Transfer' | 'Cash';
};

type PaymentMethod = {
  instalments: Instalment[];
  paymentSpecifications: string;
};
  
  type Remarks = {
    conditions: string[];
  };
  
  type AdditionalRequirements = {
    requiresFollowUpMeeting: boolean;
    requiresEmployeeSignature: boolean;
  };
  
export type ContractTemplate = {
    general: GeneralData;
    conditions: Conditions;
    payment: PaymentMethod;
    remarks: Remarks;
    additionalRequirements: AdditionalRequirements;
    locale: string;
  };  

type UseContractTemplateReturnType = {
  contractTemplate: ContractTemplate;
  updateContractTemplate: (updatedContractTemplate: Partial<ContractTemplate>) => void;
  locale: string;
  setLocale: any;
};

export function useContractTemplate(): UseContractTemplateReturnType {

  const [locale, setLocale] = useState<string>('EN_US');

  const [contractTemplate, setContractTemplate] = useState<ContractTemplate>({
    general: {
      companyName: "Sample Co.",
      companyAddress: "123 Sample St., Example City",
      taxID: "123-456-789",
      logoUrl: ""
    },
    conditions: {
      conditions: ["Condition 1", "Condition 2"],
    },
    payment: {
      instalments: [],
      paymentSpecifications: ''
    },
    remarks: {
      conditions: ["Remark 1", "Remark 2"],
    },
    additionalRequirements: {
      requiresFollowUpMeeting: false,
      requiresEmployeeSignature: false,
    },
    locale: "EN_US"
  });

  useEffect(() => {
    ContractsAPI.getTemplate(locale).then(response => {
      setContractTemplate(response.data);
    }).catch(error => {
      console.error('Error fetching contract template:', error);
    });
  }, [locale]);

  const updateContractTemplate = (updatedContractTemplate: Partial<ContractTemplate>) => {
    const completeTemplate: ContractTemplate = {
      ...contractTemplate,
      ...updatedContractTemplate
    };
  
    setContractTemplate(completeTemplate);

    ContractsAPI.save(locale, completeTemplate).then(response => {
    }).catch(error => {
      console.error('Error saving contract template:', error);
    });
  };

  return {
    contractTemplate,
    updateContractTemplate,
    locale, 
    setLocale
  };
}
