import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  label?: string,
  validationFn?: (input: string) => boolean,
  onChange: (input: React.ChangeEvent<HTMLInputElement>) => void,
  errorMessage?: string,
  isDisabled?: boolean,
  placeholder?: string,
  defaultValue?: string,
  isLoading?: boolean
};

function InputPassword({label = '', validationFn, errorMessage = '', isDisabled = false, placeholder = '', onChange = () => null, defaultValue = '', isLoading = false}: Props) {
  
  const { t } = useTranslation();
  const [isInvalidInput, setIsInvalidInput] = useState<boolean>();
  const [localValue, setLocalValue] = useState<string>('');

  const displayError: string = useMemo(() => {
    return isInvalidInput ? t(errorMessage) : '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInvalidInput]);

  useEffect(() => {
    if (!localValue) {
      setLocalValue(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localValue]);

  const onChangeWithValidation = (input: React.ChangeEvent<HTMLInputElement>) => {
    const value = input.currentTarget.value;
    if (!!validationFn && validationFn(value)) {
      setIsInvalidInput(false);
      setLocalValue(value);
      onChange(input);
    } else {
      setIsInvalidInput(true);
      setLocalValue(value);
    }
  };

  return (
    <div className="flex-1 max-w-full">
      <p className="text-sm font-extrabold">{ t(label) }:</p>
      {isLoading ? (
        <div className="bg-gray-200 animate-pulse p-1 w-full border rounded"></div>
      ) : (
      <input 
        className={`p-3 bg-transparent w-full border-stone-200 border rounded focus:outline-none ${isInvalidInput ? 'border-amber-700' : null}`}
        type="password" 
        placeholder={t(placeholder) || ''} 
        onChange={(ev) => onChangeWithValidation(ev)}
        disabled={isDisabled}
        value={localValue}
      />
      )}
      <p className="text-xs text-red-600 my-0">{ displayError }</p>
    </div>
  );
}

export default InputPassword;
