import { PillColor } from "../components/Pill/Pill";

export type PAYMENT_STATUS = 'UNKNOWN' | 'PAID' | 'PARTIALLY_PAID' | 'NOT_PAID';

export function getStatusesRaw(): PAYMENT_STATUS[] {
  return ['UNKNOWN', 'PAID', 'PARTIALLY_PAID', 'NOT_PAID'];
}

export function productTypeToTranslationKey(status: string | undefined): string {
  switch (status) {
    case 'dresses':
      return "orders.type.dress";
    case 'veils':
        return "orders.type.veils";
    case 'shoes':
        return "orders.type.shoes";
    default:
      return 'orders.payment.unknown';
  }
}

export function paymentStatusToTranslationKey(status: string | undefined): string {
  switch (status) {
    case 'PAID':
      return "orders.payment.paid";
    case 'PARTIALLY_PAID':
        return "orders.payment.partially_paid";
    case 'NOT_PAID':
        return "orders.payment.not_paid";
    default:
      return 'orders.payment.unknown';
  }
}

export function paymentStatusToAbbreviatedTranslationKey(status: string | undefined): string {
  switch (status) {
    case 'PAID':
      return "orders.payment.paid.abbreviated";
    case 'PARTIALLY_PAID':
        return "orders.payment.partially_paid.abbreviated";
    case 'NOT_PAID':
        return "orders.payment.not_paid.abbreviated";
    default:
      return 'orders.payment.unknown.abbreviated';
  }
}

export function paymentStatusToPillColor(status: string | undefined, isSelfStore = false): PillColor {
  if (isSelfStore && status === 'PARTIALLY_PAID') return 'green';
  
  switch (status) {
    case 'PAID':
      return 'green';
    case 'PARTIALLY_PAID':
        return 'blue';
    case 'NOT_PAID':
        return 'red';
    default:
      return 'grey';
  }
}