import './Autocomplete.css';
import { useTranslation } from "react-i18next";
import { useLayoutEffect, useRef, useMemo, useState, useEffect } from 'react';

export function Autocomplete({ 
  resultsSize = 5, 
  searchFn, 
  onSelect, 
  isLoading = false, 
  label = '', 
  placeholder = '', 
  errorMessage = '',
  toElementFn,
  defaultValue = '',
  isDisabled = false
}) {
  const ENTER_KEY = 13;
  const ARROW_DOWN = 40;
  const ARROW_UP = 38;
  const [userInput, setUserInput] = useState('');
  const [options, setOptions] = useState();
  const [activeOption, setActiveOption] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestionsWidth, setSuggestionsWidth] = useState('300px');
  const [typingTimeout, setTypingTimeout] = useState(0);
  const divRef = useRef();

  const inputRef = useRef(null);

  const { t } = useTranslation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setUserInput(defaultValue), [])

  function handleClickOutside(event) {
    const suggestionsList = event.target.closest("[data-suggestions-list]");
    if (divRef.current && !divRef.current.contains(event.target) && !suggestionsList) {
      setShowSuggestions(false);
      setActiveOption(0);
      setOptions();
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useLayoutEffect(() => {
    if (divRef.current.clientWidth > 100) {
      setSuggestionsWidth(divRef.current.clientWidth);
    }
  }, []);

  const displayError = useMemo(() => {
    return !!options && options.length === 0 ? t(errorMessage) : '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const clearAndSetOptions = (results) => {
    if (!!results && results.length === 0) {
      setOptions(); 
      setShowSuggestions(false);
    } else {
      setShowSuggestions(true);
      setOptions(results);
    }
  }

  const onClick = (input) => {
    if (input !== undefined) {
      setShowSuggestions(false);
      setActiveOption(0);
      setOptions();
      setUserInput(options[input].name);
      onSelect(options[input]);
    }
  };

  const onChange = (e) => {
    const input = e.currentTarget.value;
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setUserInput(input);
    setTypingTimeout(
      setTimeout(() => {
        if (input !== '' && input.length > 2) {
          searchFn(input, resultsSize, clearAndSetOptions);
        } else {
          setOptions([]);
        }
      }, 300),
    );
  };  

  useEffect(() => {
    if (inputRef.current) {
      // inputRef.current.focus();
    }
  }, [options]); // focus whenever options changes

  const onKeyDown = (e) => {
    if (e.keyCode === ENTER_KEY) {
      setActiveOption(0);
      setOptions([]);
      setUserInput(options[activeOption].name);
      setShowSuggestions(false);
      onSelect(options[activeOption]);
    } else if (e.keyCode === ARROW_UP) {
      if (activeOption === 0) {
        setActiveOption(options.length - 1);
      } else {
        setActiveOption(activeOption - 1);
      }
    } else if (e.keyCode === ARROW_DOWN) {
      if (activeOption + 1 === options.length) {
        setActiveOption(0);
      } else {
        setActiveOption(activeOption + 1);
      }
    }
  };

  const loader = <div className="loaderInInput" />;

  return (
    <div className="w-full max-w-full">
      <p className="text-sm font-extrabold">{ t(label) }</p>
      <label>
        <span className="autocompleteInputWrapper formValue autocomplete" ref={divRef}>
          <input
            className="p-3 w-full border-stone-200 border rounded focus:outline-none"
            onChange={(v) => onChange(v)}
            placeholder={t(placeholder)}
            value={userInput}
            onKeyDown={onKeyDown}
            disabled={isLoading || isDisabled}
            ref={inputRef}
            data-dd-action-name={'Autocomplete'}
          />
          {isLoading && loader}
        </span>
        {showSuggestions && (
          <ul className="suggestions" style={{ width: suggestionsWidth + 'px' }} data-suggestions-list>
            {options.map((suggestion, index) => {
              let className;

              if (index === activeOption) {
                className = 'suggestion-active';
              }
              return (
                <li className={className} key={index} onClick={() => onClick(index)}>
                  { toElementFn !== undefined ? toElementFn(suggestion)  : <span className="exerciseSuggestionName">{suggestion.name}</span> }
                </li>
              );
            })}
          </ul>
        )}
      </label>
      <p className="text-xs text-red-600 my-0">{ displayError }</p>
    </div>
  );
}
