import { useTranslation } from "react-i18next";
import { Customer } from "../../api/CustomersAPI";
import CONFIG from "../../config/config";
import { customerStatusToPillColor, customerStatusToTranslationKey } from "../../shared/customerStatusToTranslationKey";
import { BolderText } from "../BolderText/BolderText";
import Pill from "../Pill/Pill";

interface CustomerElementProps {
  customer: Customer;
}
  
const CustomerElement: React.FC<CustomerElementProps> = ({ customer }) => {
    const { t } = useTranslation();
    const countryName = CONFIG.COUNTRY_CODES.find(cc => cc.countryCode.toLowerCase() === customer.countryCode?.toLowerCase())?.countryName.toLowerCase();

    return (
        <div className="space-x-5">
            <BolderText text={`${customer.name} ${customer.surname}`} />
            <Pill text={t(customerStatusToTranslationKey(customer.status))} color={customerStatusToPillColor(customer.status)} />
            {countryName && <span>🌐&nbsp;{t(countryName)}</span>}
        </div>
    );
};

export default CustomerElement;