import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { meetingTypesArray } from '../../shared/meetingTypeToTranslationKey';
import { PermissionFlags } from '../PermissionFlags/PermissionFlags';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import PrintModal from '../PrintModal/PrintModal';
import SecondaryButton from '../SecondaryButton/SecondaryButton';

type Props = {
  onNewFn?: (value: any) => void,
}

const CalendarFooter = ({ onNewFn }: Props) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const togglePrintModal = () => setIsModalOpen(prev => !prev);

  return (
    <>
        <PrintModal 
            onClose={() => setIsModalOpen(false)}
            onPrint={() => null} // Not being used => to remove?
            isOpen={isModalOpen}
            meetingTypes={meetingTypesArray}
        />
        <div className="mt-4 flex space-x-4">
          <PermissionFlags requiredFlags={['create-meetings'] || []}>
            <PrimaryButton text={t("calendar.new_meeting.button")!} fn={onNewFn} actionName="New Meeting"/>
          </PermissionFlags>
          <PermissionFlags requiredFlags={['print-meetings'] || []}>
              <SecondaryButton text={t("calendar.print.button")!} fn={togglePrintModal} actionName="Print Meetings" />
          </PermissionFlags>
        </div>
    </>
  );
};

export default CalendarFooter;
