import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import MeetingsAPI from "../../api/MeetingsAPI";
import CustomDatePicker from "../CustomDatePicker/CustomDatePicker";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import SmallCheckBox from "../SmallCheckBox/SmallCheckBox";

export interface BlockedDate {
  id?: number;
  start: string;
  end: string;
}

type Props = {
  defaultDate?: Date;
  fn?: () => void;
};

const BlockedDatesForm: React.FC<Props> = ({ defaultDate, fn = () => null }) => {
  const [start, setStart] = useState(defaultDate || new Date());
  const [end, setEnd] = useState(defaultDate || new Date());
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [blockAllDay, setBlockAllDay] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (blockAllDay) {
      let adjustedStart = new Date(start);
      adjustedStart.setHours(0, 0, 0, 0);
      let adjustedEnd = new Date(adjustedStart);
      adjustedEnd.setHours(23, 59, 59, 999);

      setStart(adjustedStart);
      setEnd(adjustedEnd);
    }
  }, [blockAllDay, start]);

  useEffect(() => {
    setIsButtonDisabled(end <= start);
  }, [start, end]);

  const blockDate = async () => {
    await MeetingsAPI.block({
      start: start.toISOString(),
      end: end.toISOString(),
    });
    fn();
  };

  return (
    <div className="flex flex-col">
      <div className="flex space-x-5 mb-5">
        <CustomDatePicker
          label="calendar.blocked_dates.form.start"
          value={start}
          onChange={(c) => setStart(c)}
          showTimeSelect
          dateFormat="yyyy-MM-dd HH:mm"
          isDisabled={blockAllDay}
        />
        <CustomDatePicker
          label="calendar.blocked_dates.form.end"
          value={end}
          onChange={(c) => setEnd(c)}
          showTimeSelect
          dateFormat="yyyy-MM-dd HH:mm"
          isDisabled={blockAllDay}
        />
      </div>
      <div className="flex justify-between mb-5">
        <SmallCheckBox
          label={t("calendar.blocked_dates.form.all_day")!}
          onChange={() => setBlockAllDay(!blockAllDay)}
          defaultValue={blockAllDay}
        />
      </div>
      <span className="flex justify-center">
        <PrimaryButton text={t("calendar.blocked_dates.form.confirm")!} fn={blockDate} isDisabled={isButtonDisabled} />
      </span>
    </div>
  );
};

export default BlockedDatesForm;
