import { t } from 'i18next';
import { useState } from 'react';
import { isValidText } from '../../shared/validation';
import InputNumber from '../InputNumber/InputNumber';
import InputShortText from '../InputShortText/InputShortText';
import PrimaryButton from '../PrimaryButton/PrimaryButton';

type Props = {
    onSubmit: any;
    propName?: string;
    propPrice?: number;
}

const CollectionItemVariantInput = ({ onSubmit, propName, propPrice }: Props) => {
  const [name, setName] = useState(propName);
  const [price, setPrice] = useState<number | undefined>(propPrice);

  const handleSubmit = () => {
    onSubmit({ name, price });
  };

  return (
    <div className="flex flex-col space-y-3">
      <InputShortText 
        label={t("admin.collections.items.form.variants.name")!} 
        validationFn={(value) => !!value && isValidText(value, 1, 50)} 
        onChange={(e: any) => setName(e.target.value)}
        errorMessage={"errors.too_long_short"}
        defaultValue={name}
        placeholder={t("admin.collections.items.form.variants.name.example")!}
      />
      <InputNumber 
        defaultValue={price}
        label={t("admin.collections.items.form.variants.price")!} 
        validationFn={(value) => value >= 0} 
        onChange={c => setPrice(Number(c.target.value))}
        />
      <span className="flex justify-center">
        <PrimaryButton fn={handleSubmit} text={t("global.save")!}/>
      </span>
    </div>
  );
};

export default CollectionItemVariantInput;
