import CONFIG from '../config/config';
import { instanceWithV2 as APIClient } from './SimpleAPIClient';

export type DressItem = {
  id?: number,
  materials: any[],
  name: string,
  notes?: string,
  pictureUrl?: string,
  tags?: string[],
  year: number,
  file?: File,
  defaultSellPrice?: number,
  defaultSellCurrency?: string,
  cost?: number,
  costCurrency?: string,
  media?: string[],
  files?: File[]
}

export type DressesResponse = {
  dressesByYear: { [key: string]: DressItem[] }
}

type BodyApiResponse = DressItem[];

const DressesAPI = {
  get: (searchText?: string) => APIClient(CONFIG.API_ENDPOINT).get<BodyApiResponse>(`/v1/dresses${searchText ? `?searchText=${searchText}` : ''}`),
  getProduction: () => APIClient(CONFIG.API_ENDPOINT).get<DressesResponse>(`/v1/dresses/listing`),
  add: (dress: DressItem) => APIClient(CONFIG.API_ENDPOINT).post<DressItem>(`/v1/dresses`, dress),
  update: (id: number, dress: DressItem) => APIClient(CONFIG.API_ENDPOINT).put<DressItem>(`/v1/dresses/${id}`, dress),
  remove: (id: number) => APIClient(CONFIG.API_ENDPOINT).delete<BodyApiResponse>(`/v1/dresses/${id}`),
  createCatalog: (selectedIds: number[]) => APIClient(CONFIG.API_ENDPOINT).post('/v1/dresses/catalogues', { ids: selectedIds })
};

export default DressesAPI;
