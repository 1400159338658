import CONFIG from '../config/config';
import { instanceWith } from './SimpleAPIClient';

export type Tenant = {
  email: string,
  password: string,
  domain: string,
  tenantName: string
}

const TenantsAPI = {
  create: (tenant: Tenant) => instanceWith(CONFIG.API_ENDPOINT).put(`/v1/public/tenants`, tenant),
};

export default TenantsAPI;
