import CONFIG from '../config/config';
import { ContractTemplate } from '../hooks/useContractTemplate';
import { Customer } from './CustomersAPI';
import { instanceWithV2 as APIClient } from './SimpleAPIClient';

export type InstalmentDetail = {
  hasDueDate?: boolean;
  dueDate?: string; // Date in string format, e.g., YYYY-MM-DD
  amount: number;
  amountInWords: string;
  currency?: string;
  method: 'Card' | 'Transfer' | 'Cash';
};

export type Contract = {
  signatureDay: string; // YYYY-MM-DDTHH:mm:ss.sssZ
  clientFullname: string;
  clientPersonalID: string;
  clientAddress: string;
  clientPhoneNumber: string;
  clientEmail: string;
  dress: string; // deprecated
  dressTrain: string; // deprecated

  itemId?: string;
  variantId?: string;
  itemName?: string;
  variantName?: string;

  dressVeil: string;
  dressVeilLength: number;
  dressDescription: string;
  dressLegCut: string;
  weddingDate: string; // YYYY-MM-DDTHH:mm:ss.sssZ
  bust: string;
  waist: string;
  hips: string;
  totalPrice: number;
  totalPriceWording: string;
  totalPriceCurrency: string;
  instalments: InstalmentDetail[];
  endDate: string; // YYYY-MM-DDTHH:mm:ss.sssZ
  clientPickUpDate: string; // YYYY-MM-DDTHH:mm:ss.sssZ
  employeeName: string;
  language: string;
  firstFollowUpMeetingDate: string; // YYYY-MM-DDTHH:mm:ss.sssZ,
  customer?: Customer
}

type BodyApiResponse = any;

const ContractsAPI = {
  add: (contract: Contract) => APIClient(CONFIG.API_ENDPOINT).post<BodyApiResponse>(
    `/v1/contracts`,
    contract,
    {
      responseType: "arraybuffer",
      headers: {
        'accept': 'application/pdf'
      }
    }
  ),
  getTemplate: (locale: string = "EN_US") => APIClient(CONFIG.API_ENDPOINT).get<ContractTemplate>(`/v1/contracts/templates?locale=${locale}`),
  save: (locale: string = "EN_US", contractTemplate: ContractTemplate) => APIClient(CONFIG.API_ENDPOINT).put<BodyApiResponse>(
    `/v1/contracts/templates/${locale}`,
    contractTemplate
  ),
};

export default ContractsAPI;
