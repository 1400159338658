import React from 'react';
import { Lead } from '../../api/LeadsAPI';
import InputShortText from '../InputShortText/InputShortText';
import TextArea from '../TextArea/TextArea';
import { useTranslation } from 'react-i18next';
import { isValidEmail, isValidText } from '../../shared/validation';
import { useFormValidation } from '../../hooks/useFormValidation';
import SelectInput from '../SelectInput/SelectInput';
import { getLeadStatuses } from '../../shared/leadStatusToTranslationKey';
import CONFIG from '../../config/config';

type Props = {
  lead: Lead,
  onLeadChange?: (value: Lead) => void,
  isDisabled?: boolean,
  actionButton?: JSX.Element
};

const getInitialState = (lead?: Lead) => {
  if (lead) {
    return {
      name: isValidText(lead.name, 3),
      email: isValidEmail(lead.email),
    };
  }
};

function LeadForm({ lead, onLeadChange = () => null, isDisabled = false, actionButton }: Props) {
  const { t } = useTranslation();
  const requiredFields = ['name', 'email'];
  const initialState = getInitialState(lead);
  const { updateFieldValidation, isFormValid } = useFormValidation(requiredFields, initialState);

  const disabledActionButton = actionButton
    ? React.cloneElement(
        actionButton,
        {},
        React.Children.map(actionButton.props.children, (child) => {
          return child ? React.cloneElement(child, { isDisabled: !isFormValid() }) : null;
        })
      )
    : null;

  return (
    <>
      <div className="flex flex-wrap space-x-5">
        <div className="md:flex-1 w-full">
          <InputShortText 
            label={`${t("leads.form.name")!}* `}
            validationFn={(value) => !!value && isValidText(value, 3)}
            onValidationChange={(isValid) => updateFieldValidation('name', isValid)}
            onChange={e => onLeadChange({ ...lead, name: e.currentTarget.value } as Lead)}
            errorMessage={t("errors.too_short")!}
            defaultValue={lead?.name || ''}
            isDisabled={isDisabled}
          />
          <InputShortText 
            label={`${t("leads.form.email")!}* `}
            validationFn={(value) => !!value && isValidEmail(value)}
            onValidationChange={(isValid) => updateFieldValidation('email', isValid)}
            onChange={e => onLeadChange({ ...lead, email: e.currentTarget.value } as Lead)}
            errorMessage={t("errors.invalid_email")!}
            defaultValue={lead?.email || ''}
            isDisabled={isDisabled}
          />
          <div className="flex flex-wrap space-x-5">
            <SelectInput
                values={getLeadStatuses().map(e => { return { value: e, readableValue: t(`leads.status.${e.toLocaleLowerCase()}`) } })}
                onChange={e => onLeadChange({ ...lead, status: e.currentTarget.value } as Lead)}
                label={"leads.form.status"}
                defaultValue={lead?.status}
                isDisabled={isDisabled}
            />
            <SelectInput
                values={
                  CONFIG.COUNTRY_CODES
                    .map(e => { return { value: e.countryCode, readableValue: t(e.countryName) } })
                    .sort((a, b) => a.readableValue.localeCompare(b.readableValue))
                }
                onChange={e => onLeadChange({ ...lead, countryCode: e.currentTarget.value } as Lead)}
                label={"leads.form.country"}
                defaultValue={lead?.countryCode}
                isDisabled={isDisabled}
            />
          </div>
          <TextArea
            label={t("leads.form.notes")!} 
            validationFn={(value) => !!value && isValidText(value, 0, 500)}
            onChange={e => onLeadChange({ ...lead, notes: e.currentTarget.value } as Lead)}
            defaultValue={lead?.notes || ''}
            isDisabled={isDisabled}
          />
        </div>
      </div>
      { disabledActionButton }
    </>
  );
}

export default LeadForm;
