import { useState } from "react";

type ValidationState = { [key: string]: boolean };

export const useFormValidation = (requiredFields: string[], initialState: ValidationState = {}) => {
  const [fieldValidation, setFieldValidation] = useState<ValidationState>({...initialState});

  const updateFieldValidation = (fieldName: string, isValid: boolean) => {
    setFieldValidation((prev) => ({
      ...prev,
      [fieldName]: isValid,
    }));
  };

  const isFormValid = () => {
    return requiredFields.every((field) => fieldValidation[field]);
  };

  return {
    fieldValidation,
    updateFieldValidation,
    isFormValid,
  };
};
