import { useEffect, useState } from 'react';
import ElementActions from '../ElementActions/ElementActions';
import { Icon } from '../Icon/Icon';
import IconButton from '../IconButton/IconButton';
import { Label } from '../Label/Label';
import ListContainer from '../ListContainer/ListContainer';
import ListElement from '../ListElement/ListElement';
import deleteIcon from '../../assets/images/bin.svg';
import editIcon from '../../assets/images/pen.svg';
import add from '../../assets/images/plus.svg';
import NoData from '../NoData/NoData';
import Tooltip from '../Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';
import { Modal } from '../Modal/Modal';
import CollectionItemVariantInput from '../CollectionItemVariantInput/CollectionItemVariantInput';
import useGetCollectionItemVariants from '../../hooks/useGetCollectionItemVariants';
import { ItemVariant } from '../../api/CollectionItemVariantsAPI';
import PlanLinkModal from '../PlanLinkModal/PlanLinkModal';

type Props = {
    collectionId: number,
    itemId: number,
    initialVariants: ItemVariant[],
    isDisabled?: boolean,
    onNewCollectionItemFn?: (e: ItemVariant[]) => any;
};

const CollectionItemVariantsForm = ({ collectionId, itemId, initialVariants, isDisabled = false, onNewCollectionItemFn }: Props) => {

    const { t } = useTranslation();
    const [showAddModal, setShowAddModal] = useState(false);
    const [selectedVariant, setSelectedVariant] = useState<ItemVariant>();

    const { variants, setVariants, addVariant, removeVariant, loading, updateVariant } = useGetCollectionItemVariants({collectionId, itemId, initialVariants, onNewCollectionItemFn});

    useEffect(() => {
        setVariants(initialVariants);
    }, [initialVariants]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <Label text={t("admin.collections.items.form.variants")!} />
            { !isDisabled && 
                <div className="flex justify-end">
                    <IconButton 
                        onClick={() => { setSelectedVariant(undefined); setShowAddModal(true);}}
                        actionName="Add Variant"
                    >
                        <Tooltip content={t("global.add")}>
                            <Icon url={add} cssStyles="w-5" />
                        </Tooltip>
                    </IconButton> 
                </div>
            }
            {showAddModal && (
                <Modal modalTitle={"admin.collections.items.form.variants.title"} canClose onClose={() => setShowAddModal(false)}>
                    <CollectionItemVariantInput propName={selectedVariant?.name} propPrice={selectedVariant?.price} onSubmit={(variant: ItemVariant) => {
                        selectedVariant ? updateVariant(selectedVariant.id!, {...selectedVariant, ...variant}) : addVariant(variant);
                        setShowAddModal(false);
                    }} />
                </Modal>
            )}
            <ListContainer
                isLoading={loading}
                elements={variants}
                toElementFn={(variant, index) => (
                    <ListElement key={index}>
                        <ElementActions actions={
                            isDisabled ? <></> :
                            <div className='flex items-center'>
                                { variant.id && <PlanLinkModal
                                    itemOrVariant={variant}
                                    isDisabled={isDisabled}
                                    onCollectionItemForm={(v) => updateVariant(v.id, v)}
                                /> }
                                { variant.id && <IconButton actionName="Edit Variant" onClick={() => { setSelectedVariant(variant); setShowAddModal(true);}}>
                                    <Icon url={editIcon} cssStyles="w-5" />
                                </IconButton> }
                                <IconButton actionName="Delete Variant" onClick={() => removeVariant(variant.id ?? index)}>
                                    <Icon url={deleteIcon} cssStyles="w-5" />
                                </IconButton>
                            </div>
                        }>
                            <span className="flex items-center">{variant.name} {variant.price ? `- ${variant.price}${variant.currency}` : null}</span>
                        </ElementActions>
                    </ListElement>
                )}
                emptyListComponent={
                    <NoData message={t("admin.collections.items.form.variants.empty")}/>
                }
            />
        </div>
    );
};

export default CollectionItemVariantsForm;
