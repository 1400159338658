import { useEffect, useState } from "react";
import EmployeesAPI, { Employee } from "../api/EmployeesAPI";

export enum SORT { DELIVERY_ASC };

type ReturnedProps = {
  isLoadingEmployees: boolean,
  employees: Employee[],
  addFn: (e: any) => Promise<any>,
  removeFn: (e: any) => Promise<any>,
  updateFn: (e: any) => Promise<any>,
};

export function useGetEmployees(): ReturnedProps {
  
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [isLoadingEmployees, setIsLoading] = useState(false);

  const addFn = async (employee: Employee) => {
    await EmployeesAPI.add(employee);
    load();
  };

  const updateFn = async (employee: Employee) => {
    await EmployeesAPI.update(employee);
    load();
  };

  const removeFn = async (employee: Employee) => {
    await EmployeesAPI.delete(employee.id!);
    load();
  };

  const load = () => {
    const asyncLoad = async () => {
      setIsLoading(true);
      try {
        const empRaw = await EmployeesAPI.get();
        const empApi = empRaw.data;
        
        setEmployees(empApi);
      } catch (err) {
        console.error("Could not load employees");
      }
      setIsLoading(false);
    };
    asyncLoad();
  }

  useEffect(load, []);
  
  return { isLoadingEmployees, employees, addFn, removeFn, updateFn };
};