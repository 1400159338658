import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Config } from "../../api/ConfigAPI";
import CONFIG from "../../config/config";
import { useImageUploader } from "../../hooks/useImageUploader";
import { toastService } from "../../services/ToastService";
import { isValidText, isValidTime, isValidUrl } from "../../shared/validation";
import CustomTimePicker from "../CustomTimePicker/CustomTimePicker";
import FileUploadWrapper from "../FileUploadWrapper/FileUploadWrapper";
import InputShortText from "../InputShortText/InputShortText";
import Pill from "../Pill/Pill";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import SelectInput from "../SelectInput/SelectInput";
import Subtitle from "../Subtitle/Subtitle"; 

type BasicInfoProps = {
    config: Config | undefined;
    isLoading: boolean;
    handleInputChange: (key: keyof Config, value: any) => void;
    updateConfig: () => void;
}  

export const AccountBasicInformation = ({ config, isLoading, handleInputChange, updateConfig }: BasicInfoProps) => {

    const { t } = useTranslation();
    const { uploadImage } = useImageUploader('', response => response.data.url, "images");
    const [hasChanged, setHasChanged] = useState(false);

    const handleUpdate = () => {
        setHasChanged(false);
        updateConfig();
    }

    const handleLocalInputChange = (key: keyof Config, value: any) => {
      handleInputChange(key, value);
      setHasChanged(true);
    };

    const uploadLogo = async (file: File) => {
        try {
            const fileUrl = await uploadImage(file, `configuration/logo`);
            if (!fileUrl) {
                throw new Error('File upload failed');
            }
    
            handleLocalInputChange('logoUrl', fileUrl);
        } catch (error) {
            toastService.showToast(t("toast.errors.logo"), 'error');
        }
    };    

    return (
      <>
        <div className="flex justify-between items-center">
            <Subtitle text={t("settings.general.basic_info")} />
            <Pill text={config?.domain || ''} />
        </div>
        <div className="flex flex-col flex-wrap space-y-5">
            <InputShortText
                label={t("settings.general.basic_info.brand_name")!}
                validationFn={(value) => !!value && isValidText(value, 2, 50)}
                onChange={(e: any) => handleLocalInputChange('brandName', e.target.value)}
                errorMessage={"errors.too_short"}
                defaultValue={config?.brandName}
                isDisabled
            />
            <InputShortText
                label={t("settings.general.basic_info.website")!}
                validationFn={(value) => !!value && isValidUrl(value)}
                onChange={(e: any) => handleLocalInputChange('websiteUrl', e.target.value)}
                onValidationChange={(isValid) => null}
                errorMessage={"errors.invalid_url"}
                defaultValue={config?.websiteUrl}
                isDisabled={isLoading}
            />
            <div className="flex space-x-5">
                <SelectInput
                    values={CONFIG.TIMEZONES.sort().map(t => { return { value: t, readableValue: t }})}
                    onChange={(e: any) => handleLocalInputChange('timezone', e.currentTarget.value)}
                    label={t("settings.general.basic_info.timezone")!}
                    defaultValue={config?.timezone}
                    isDisabled={false}
                />
                <SelectInput
                    values={CONFIG.COUNTRY_CODES.sort((a, b) => a.countryName.localeCompare(b.countryName)).map(e => { return { value: e.countryCode, readableValue: e.countryName } })}
                    onChange={(e: any) => handleLocalInputChange('country', e.currentTarget.value)}
                    label={t("settings.general.basic_info.country")!}
                    defaultValue={config?.country || ""}
                    isDisabled={isLoading}
                />
            </div>
            <div className="flex flex-wrap space-x-5">
                <CustomTimePicker
                    validationFn={(value) => !!value && isValidTime(value)}
                    errorMessage={"errors.past_date"}
                    label={t("settings.general.basic_info.opening_hours")!}
                    value={config?.openingHours ? config.openingHours : "07:00"}
                    onChange={(value: string) => handleLocalInputChange('openingHours', value )}
                    isDisabled={isLoading}
                />
                <CustomTimePicker
                    validationFn={(value) => !!value}
                    errorMessage={"errors.past_date"}
                    label={t("settings.general.basic_info.closing_hours")!}
                    value={config?.closingHours ? config.closingHours : "17:00"}
                    onChange={(value: string) => handleLocalInputChange('closingHours', value )}
                    isDisabled={isLoading}
                />
            </div>
            <FileUploadWrapper
                onChange={(file) => {
                    if (file) {
                        uploadLogo(file);
                    }
                }}
                allowedTypes={CONFIG.IMAGE_TYPES}
                isDisabled={isLoading}
                maxSize={1.5}
                allowedMultipleFiles={false}
            />
        </div>
        <br/>
        <div className="container flex flex-wrap space-x-5">
            <PrimaryButton text={t("settings.general.basic_info.button")!} fn={() => handleUpdate()} isDisabled={isLoading || !hasChanged}/>
        </div>
      </>
    );
  }
  