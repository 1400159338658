import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CatalogItem } from '../../api/CataloguesAPI';
import { useScrollToTop } from '../../hooks/useScroolToTop';
import { addItem } from '../../store/reducers/MarketplaceBasketSlice';
import { BackButton } from '../BackButton/BackButton';
import Card from '../Card/Card';
import DressCard from '../DressCard/DressCard';
import DressCardDescription from '../DressCardDescription/DressCardDescription';
import PrimaryButton from '../PrimaryButton/PrimaryButton';

type DressCardDetailsProps = {
    item: CatalogItem;
    backButton?: () => void;
}

const DressCardDetails = ({ item, backButton = () => null }: DressCardDetailsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // TODO: to remove some day - hack!
  useScrollToTop();

  const { name, media, price, currency, description, designer, collectionName } = item;

  const handleAddItem = (item: CatalogItem) => {
    dispatch(addItem(item));
  };

  return (
    <>
      <BackButton fn={backButton} text={item?.collectionName} />
      <div className="mx-auto flex flex-wrap lg:flex-nowrap">
        <div className="w-full lg:w-1/3 xl:w-1/3 pt-4">
          <Card>
            <>
              <DressCardDescription details={{ name, media, price, currency, description, designer, collectionName }} />
              <PrimaryButton text={t("marketplace.catalogues.details.select")!} fn={() => handleAddItem(item)} />
            </>
          </Card>
        </div>
        <div className="w-full lg:w-1/2 xl:w-2/3 p-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {media?.map((image, index) => (
              <DressCard imageUrl={image} name={''} key={index}/>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default DressCardDetails;
