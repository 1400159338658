import React from 'react';
import { useTranslation } from 'react-i18next';
import { Item } from '../../api/CollectionsAPI';
import CONFIG from '../../config/config';
import { useFormValidation } from '../../hooks/useFormValidation';
import { isValidText } from '../../shared/validation';
import { Icon } from '../Icon/Icon';
import IconButton from '../IconButton/IconButton';
import ImageGallery from '../ImageGallery/ImageGallery';
import InputShortText from "../InputShortText/InputShortText";
import StyledImage from '../StyledImage/StyledImage';
import TextArea from '../TextArea/TextArea';
import deleteIcon from '../../assets/images/bin.svg';
import FileUploadWrapper from '../FileUploadWrapper/FileUploadWrapper';
import { Dot } from '../Dot/Dot';
import ElementActions from '../ElementActions/ElementActions';
import ListElement from '../ListElement/ListElement';
import ListContainer from '../ListContainer/ListContainer';
import { toastService } from '../../services/ToastService';
import PlanLinkModal from '../PlanLinkModal/PlanLinkModal';
import { PermissionFlags } from '../PermissionFlags/PermissionFlags';
import CollectionItemVariantsForm from '../CollectionItemVariantsForm/CollectionItemVariantsForm';
import { ItemVariant } from '../../api/CollectionItemVariantsAPI';
import PriceField from '../PriceField/PriceField';

type Props = {
  item: Item,
  onCollectionItemForm: (value: any) => void,
  isDisabled?: boolean,
  actionButton?: JSX.Element
};

const getInitialState = (partner?: Item) => {
  if (partner) {
    return {
      name: isValidText(partner.name, 3),
    };
  }
};

function CollectionItemForm({ item, onCollectionItemForm, isDisabled = false, actionButton }: Props) {

  const { t } = useTranslation();
  const requiredFields = ['name'];
  const initialState = getInitialState(item);
  const { updateFieldValidation, isFormValid } = useFormValidation(requiredFields, initialState);

  const removeLink = (url: string) => {
    const updatedAttachments = item?.media?.filter((attachmentUrl) => attachmentUrl !== url);
    onCollectionItemForm({ ...item, media: updatedAttachments });
  }

  const updateImagesOrder = (newImages: string[]) => {
    onCollectionItemForm({ ...item, media: newImages });
  }

  const removeFile = (fileName: string) => {
    const updatedFiles = item?.files?.filter((file) => file.name !== fileName);
    onCollectionItemForm({ ...item, files: updatedFiles });
  }

  const processFile = async (files: File[]) => {
      try {
        const newFiles = [...item?.files || [], ...files];
        onCollectionItemForm({ ...item, files: newFiles });
      } catch (error) {
        toastService.showToast(t("toast.errors.add_file"), 'error');
      }
  };

  const disabledActionButton = actionButton
  ? React.cloneElement(
      actionButton,
      {},
      React.Children.map(actionButton.props.children, (child) => {
        return child ? React.cloneElement(child, { isDisabled: !isFormValid() }) : null;
      })
    )
  : null;

  return (
    <>
      <PlanLinkModal
        itemOrVariant={item}
        isDisabled={isDisabled}
        onCollectionItemForm={onCollectionItemForm}
      />
      <div className="flex flex-wrap md:space-x-5">
        <div className="md:flex-1 w-full space-y-5">
          <InputShortText
            label={"admin.collections.items.form.name"}
            validationFn={(value) => !!value && isValidText(value, 3)}
            onChange={e => onCollectionItemForm({ ...item, name: e.currentTarget.value })}
            onValidationChange={(isValid) => updateFieldValidation('name', isValid)}
            errorMessage={"errors.too_short"}
            defaultValue={item?.name || ''}
            isDisabled={isDisabled}
          />
          {/* <SelectInput
            label={"admin.collections.items.form.type"}
            defaultValue={item?.type || 'dresses'}
            values={[{ readableValue: 'Dresses', value: 'dresses' }]}
            onChange={e => onCollectionItemForm({ ...item, type: e.target.value })}
            isDisabled={isDisabled}
          /> */}
          <div className="flex space-x-1">
            <PermissionFlags requiredFlags={['update-marketing-collection-items-costs']}>
              <PriceField 
                label={"admin.collections.items.form.cost"}
                currency={item?.costCurrency || 'EUR'}
                price={item?.cost || 0}
                currencies={[''].concat(CONFIG.SUPPORTED_CURRENCIES)}
                onCurrencyChange={e => onCollectionItemForm({ ...item, costCurrency: e })}
                onPriceChange={value => onCollectionItemForm({ ...item, cost: value, costCurrency: item?.costCurrency || 'EUR' })}
                isDisabled={isDisabled}
              />
            </PermissionFlags>
            <PermissionFlags requiredFlags={['update-marketing-collection-items-prices']}>
              <PriceField 
                label={"admin.collections.items.form.price"}
                currency={item?.currency || 'EUR'}
                price={item?.price || 0}
                currencies={[''].concat(CONFIG.SUPPORTED_CURRENCIES)}
                onCurrencyChange={e => onCollectionItemForm({ ...item, currency: e })}
                onPriceChange={value => onCollectionItemForm({ ...item, price: value, currency: item?.currency || 'EUR' })}
                isDisabled={isDisabled}
              />
            </PermissionFlags>
          </div>
          <TextArea
              validationFn={value => !value || (!!value && isValidText(value, 0, 1000))}
              label={"admin.collections.items.form.description"}
              onChange={e => onCollectionItemForm({ ...item, description: e.currentTarget.value })}
              defaultValue={item.description || ''}
              isDisabled={isDisabled}
          />
          <CollectionItemVariantsForm 
            collectionId={1} 
            itemId={item.id!} 
            isDisabled={isDisabled} 
            initialVariants={item.variations || []} 
            onNewCollectionItemFn={(e: ItemVariant[]) => onCollectionItemForm({ ...item, variations: e })}
          />
        </div>
        <div className="md:flex-1 max-h-screen overflow-y-scroll">
            <p className="text-sm font-extrabold">{ t("dresses.form.gallery") }:</p>
            { (item?.media && item?.media.length > 0) &&
                <ImageGallery 
                  images={item?.media || []} 
                  canChangeImageOrder={!isDisabled} 
                  canRemoveImages={!isDisabled} 
                  onImageRemove={removeLink}
                  onImageOrderChange={updateImagesOrder}
                />
            }
            <ListContainer
                elements={item?.files || []}
                toElementFn={(file: File, index: number) =>
                    <ListElement>
                        <ElementActions actions={
                            isDisabled ? <></> :
                            <IconButton onClick={() => removeFile(file.name)} actionName="Delete File">
                                <Icon url={deleteIcon} cssStyles="w-5" />
                            </IconButton>
                        }>
                        <span><Dot /> {file.name}</span>
                        </ElementActions>
                    </ListElement>
                }
            />
            <FileUploadWrapper
                label={`${t("dresses.form.image")}.`}
                onChange={processFile}
                isDisabled={isDisabled}
                allowedTypes={CONFIG.IMAGE_TYPES}
                allowedMultipleFiles
                maxSize={50}
            />
            { (item.featuredPictureUrl && !item.media && 
              <div className="mt-5 mb-5">
                <StyledImage alt="Featuring" src={item.featuredPictureUrl} />
              </div>) || null
            }
        </div>
      </div>
      { disabledActionButton }
    </>
  );
}

export default CollectionItemForm;
