import React, { useState, KeyboardEvent } from 'react';
import InputShortText from '../InputShortText/InputShortText';

type TagsInputProps = {
  tags: string[];
  onChange: (tags: string[]) => void;
  label?: string;
  isDisabled: boolean;
};

const TagsInput: React.FC<TagsInputProps> = ({ tags, onChange, label, isDisabled = false }) => {
  const [inputValue, setInputValue] = useState('');

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const trimmedValue = inputValue.trim();
      if (trimmedValue) {
        onChange([...tags, trimmedValue]);
        setInputValue('');
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.currentTarget.value);
  };

  const removeTag = (index: number) => {
    if (!isDisabled) {
      const newTags = [...tags];
      newTags.splice(index, 1);
      onChange(newTags);
    }
  };

  return (
    <div className="w-full">
      <InputShortText
        label={label}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        defaultValue={inputValue}
        isDisabled={isDisabled}
      />
      <div className="mt-2 space-x-2">
        {tags.map((tag, index) => (
          <span key={index} className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-darker-gold text-as-pink-100 ${isDisabled ? 'opacity-50' : ''}`}>
            {tag}
            {!isDisabled && (
              <button
                type="button"
                className="flex-shrink-0 ml-1.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-as-pink-100 focus:outline-none"
                onClick={() => removeTag(index)}
              >
                <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                  <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                </svg>
              </button>
            )}
          </span>
        ))}
      </div>
    </div>
  );
};

export default TagsInput;
