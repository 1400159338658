import CONFIG from '../config/config';
import { Customer } from './CustomersAPI';
import { instanceWithV2 as instance } from './SimpleAPIClient';

export type OrderDress = {
  dressPlanId: string,
  name: string,
  type?: 'CATALOG_ITEM' | 'CATALOG_ITEM_VARIANT' | 'PRODUCTION_ITEM'
}

type BaseOrder = {
  clientName: string,
  creationTime?: string,
  id?: number,
  attachmentUrls?: string[],
  lastUpdateTime?: string,
  notes?: string,
  files?: File[];
}

export type Order = InternalOrder & {
  dress?: OrderDress,
}

export type InternalOrder = BaseOrder & {
  disable: boolean,
  shopName?: string,
  linkedShopId?: number,
  dressDescription?: string,
  deliveryDate: string,
  visible: boolean,
  prioritized: boolean,
  pendingMeasures: boolean,
  paymentStatus?: string,
  shop?: string,
  shopIdV2?: string,
  status?: string,
  type?: string,
  customer?: Customer;
}

export type ExternalOrder = BaseOrder & {
  b2bType: string;
  itemName: string;
  collectionName: string;
  receiverShopName: string;
  requesterShopName: string;
  inStoreDate: string;
  to: string;
  status: string;
  deletableFrom?: number;
}

export function isInternalOrder(order: any): order is InternalOrder {
  return (order as InternalOrder).disable !== undefined;
}

export function isExternalOrder(order: any): order is ExternalOrder {
  return (order as ExternalOrder).to !== undefined;
}

export type FileUploadResponse = {
  url: string;
};

export type BatchOrderResponse = {
  message: string;
  data: Order[];
};

export type FileUploadRequest = {
  filename: string;
  file: FormData;
};

type BodyApiResponse = Order[];

const OrdersAPI = {
  get: (date?: string, shopId?: string, hidden?: boolean) => {
    const queryParams = new URLSearchParams();
    if (date) queryParams.append("date", date);
    if (shopId) queryParams.append("shopId", shopId);
    if (hidden) queryParams.append("completed", "true");
    return instance(CONFIG.API_ENDPOINT).get<BodyApiResponse>(`v1/shops/orders${!!queryParams.toString() ? `?${queryParams.toString()}` : ''}`)
  },
  getExternalOrders: (date?: string, shopId?: string) => {
    const queryParams = new URLSearchParams();
    if (date) queryParams.append("date", date);
    if (shopId) queryParams.append("shopId", shopId);
    return instance(CONFIG.API_ENDPOINT).get<any>(`v1/shops/orders/in-out${!!queryParams.toString() ? `?${queryParams.toString()}` : ''}`)
  },
  search: (value: string) => instance(CONFIG.API_ENDPOINT).get<BodyApiResponse>(`v1/shops/orders?search=${value}`),
  add: (shopId: string, value: Order) => instance(CONFIG.API_ENDPOINT).put<Order>(`v1/shops/${shopId}/orders`, value),
  update: (shopId: string, orderId: number, value: Order) => instance(CONFIG.API_ENDPOINT).put<Order>(`v1/shops/${shopId}/orders/${orderId}`, value),
  updateExternal: (orderId: number, value: ExternalOrder) => instance(CONFIG.API_ENDPOINT).put<ExternalOrder>(`v1/shops/orders/in-out/${orderId}`, value),
  delete: (shopId: string, orderId: number) => instance(CONFIG.API_ENDPOINT).delete<BodyApiResponse>(`v1/shops/${shopId}/orders/${orderId}`),
  deleteExternal: (orderId: number) => instance(CONFIG.API_ENDPOINT).delete<BodyApiResponse>(`v1/shops/orders/in-out/${orderId}`),
  uploadFile: (file: FileUploadRequest) => {
    const fName = file.filename.replace(/\s+/g, '_');
    return instance(CONFIG.API_ENDPOINT).post<FileUploadResponse>(`v1/files/orders/uploadV2`, file.file, {
      headers: {
        'Filename': fName,
      },
    })
  },
  addBatch: (orders: Order[]) => 
    instance(CONFIG.API_ENDPOINT).post<BatchOrderResponse>(`v1/shops/orders`, { orders }),
};

export default OrdersAPI;
