import { useState } from "react";
import { useTranslation } from "react-i18next";
import IconButton from "../IconButton/IconButton";

type Props = {
  textOnExpanded?: string,
  textOnHiden?: string,
  children: JSX.Element
};

function Collapsable({textOnExpanded = 'global.hide', textOnHiden = 'global.expand', children }: Props) {

  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(true);

  return (
    <div className="flex space-x-1 p-1">
      { collapsed ? <IconButton text={`${t(textOnHiden)} +`} onClick={() => setCollapsed(c => !c)} />
        : <div className="flex flex-col">
          <IconButton text={`${t(textOnExpanded)} -`} onClick={() => setCollapsed(c => !c)} />
            { children }
        </div> 
      }
    </div>
  );
}

export default Collapsable;
