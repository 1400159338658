import * as amplitude from '@amplitude/analytics-browser';
import Button from "../Button/Button";

type Props = {
  text: string | JSX.Element,
  alignment: 'left' | 'center' | 'right',
  isDisabled?: boolean,
  fn?: (a?: any) => void,
  eventName?: string,
};

function FloatingCTAButton({ text, alignment = 'center', fn = () => null, isDisabled = false, eventName = '' }: Props) {

  const track = (e: any) => {
    if (eventName) {
      amplitude.track(eventName);
    }
    fn();
  }

  const buttonStyles = `
    ${isDisabled ? 'bg-gray-300 cursor-not-allowed' : 'bg-darker-gold-300 hover:bg-darker-gold-100'} 
    text-white hover:text-black font-semibold py-2 px-4 border border-gold-700 rounded 
    transition ease-in-out duration-300
    w-full sm:w-auto sm:px-10 md:px-12 lg:px-16 xl:px-20
    h-12 sm:h-14
    inline-flex items-center justify-center
  `;

  const containerStyle: React.CSSProperties = {
    position: 'fixed',
    bottom: 0,
    zIndex: 10,
    padding: '8px',
    boxSizing: 'border-box',
  };

  if (alignment === 'left') {
    containerStyle.left = 0;
    containerStyle.width = 'auto';
  } else if (alignment === 'right') {
    containerStyle.right = 0;
    containerStyle.width = 'auto';
  } else {
    containerStyle.left = '50%';
    containerStyle.transform = 'translateX(-50%)';
    containerStyle.width = 'auto';
  }

  return (
    <div style={containerStyle} className="animate-pulse">
      <Button 
        fn={track} 
        text={text} 
        isDisabled={isDisabled}
        cssStyles={buttonStyles}
      />
    </div>
  );
}

export default FloatingCTAButton;
