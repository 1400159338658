import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  label?: string,
  validationFn?: (input: boolean) => boolean,
  onValidationChange?: (status: boolean) => void,
  onChange: (input: React.ChangeEvent<HTMLInputElement>) => void,
  errorMessage?: string,
  isDisabled?: boolean,
  defaultValue?: boolean,
};

function SmallCheckBox({ label = '', validationFn, onValidationChange = () => null, errorMessage = '', isDisabled = false, onChange = () => null, defaultValue = false }: Props) {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState<boolean>(defaultValue);
  const [isInvalidInput, setIsInvalidInput] = useState<boolean>();

  useEffect(() => {
    if (defaultValue !== undefined && validationFn && !validationFn(defaultValue)) {
      setIsInvalidInput(true);
    }
    setIsChecked(defaultValue);
  }, [defaultValue, validationFn]);

  const displayError: string = useMemo(() => {
    return isInvalidInput ? t(errorMessage) : '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInvalidInput]);

  const onChangeWithValidation = (input: React.ChangeEvent<HTMLInputElement>) => {
    const value = input.currentTarget.checked;

    if (validationFn) {
      if (validationFn(value)) {
        const isValid = validationFn(value);
        onValidationChange(isValid);
        setIsInvalidInput(false);
        setIsChecked(value);
        onChange(input);
      } else {
        setIsInvalidInput(true);
        setIsChecked(value);
      }
    } else {
      setIsChecked(value);
      onChange(input);
    }
  };

  return (
    <div className="flex items-center max-w-full">
      { label && <p className="mr-2">{ t(label) }:</p> }
      <label className="flex items-center space-x-2" onClick={(ev) => { ev.stopPropagation();}} >
        <input 
          className="hidden"
          type="checkbox" 
          onChange={(ev) => onChangeWithValidation(ev)}
          disabled={isDisabled}
          defaultChecked={defaultValue}
        />
        <span className={`w-4 h-4 border-2 border-gray-500 rounded transition-all duration-200 cursor-pointer ${isDisabled ? 'bg-gray-200 border-none' : (isChecked ? 'bg-darker-gold border-none' : '')}`}></span>
      </label>
      <p className="text-xs text-red-600 my-0">{ displayError }</p>
    </div>
  );
}

export default SmallCheckBox;
