import { memo, useState } from "react";
import InputShortText from "../InputShortText/InputShortText";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import InputPassword from "../InputPassword/InputPassword";
import { useTranslation } from "react-i18next";
import TinyText from "../TinyText/TinyText";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as amplitude from '@amplitude/analytics-browser';
import TenantsAPI from "../../api/TenantAPI";
import { isValidPassword } from "../../shared/validation";
import FeatureFlagsAPI from "../../api/FeatureFlagAPI";
import { Auth } from "aws-amplify";
import CONFIG from "../../config/config";
import { Role, setSettings, Settings } from "../../store/reducers/AccountSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import axios from 'axios';

type Props = {
    callbackFn?: (a?: any) => void,
}

export default memo(({ callbackFn }: Props) => {
    const [email, setEmail] = useState('');
    const [domain, setDomain] = useState('');
    const [tenantName, setTenantName] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const settings: Settings = useSelector((state: any) => state.settings.value);
    
    const handleSignUp = async (event: React.FormEvent<HTMLFormElement>) => {
      try {
        await TenantsAPI.create({
          email: email,
          password: password,
          domain: domain,
          tenantName: tenantName
        });
        setError(t("registration.accepted")!);
    
        const user = await Auth.signIn(email, password);
        const jwtToken = user.signInUserSession.idToken.jwtToken;
        sessionStorage.setItem(CONFIG.LOCAL_SESSION.TOKEN, jwtToken);
    
        const userConfig = await FeatureFlagsAPI.get();
        const updatedSettings = {
          ...settings,
          permissions: userConfig.data.flags || [],
          role: userConfig.data.role as Role,
          isLoggedIn: true
        };
        dispatch(setSettings(updatedSettings));
    
        const identifyObj = new amplitude.Identify();
        identifyObj.set('userId', settings.userName);
        amplitude.track('User signed up and logged in');
    
        sessionStorage.setItem(CONFIG.LOCAL_SESSION.FLAGS_FIELD, JSON.stringify(userConfig.data.flags || []));
        sessionStorage.setItem(CONFIG.LOCAL_SESSION.ROLE_FIELD, userConfig.data.role || '');

        if (callbackFn) {
          callbackFn();
        } else {
          navigate(location.state?.from || '/calendar');
        }

      } catch (error) {
        amplitude.track("error_sign_up_failed");
        console.error(error);
      
        if (axios.isAxiosError(error)) {
          if (error.response && error.response.data && error.response.data.message === "EMAIL_ALREADY_EXISTS") {
            setError(t("registration.email_already_exists")!);
          } else {
            setError(t("registration.failed")!);
          }
        } else {
          setError(t("registration.failed")!);
        }
      }
    };
    
    return (
      <div className="w-full">
        <form className="bg-white flex flex-col p-6 shadow-md rounded w-full md:w-96" onSubmit={handleSignUp}>
            <div className="mb-4">
              <InputShortText 
                validationFn={(value) => !!value && value.length > 3} 
                onChange={e => setEmail((prev) => e.target.value)}
                errorMessage={"errors.too_short"}
                label={t("global.email")!}
                />
            </div>
            <TinyText text={`${t("registration.password.text")}.`} />
            <div className="mb-4">
                <InputPassword 
                  validationFn={(value) => isValidPassword(value)}
                  onChange={e => setPassword((prev) => e.target.value)}
                  errorMessage={"errors.password_format"}
                  label={t("global.password")!} 
                />
            </div>
            {error && <p className="text-red-500 text-xs italic mb-4">{error}</p>}
            <hr className="my-4 border-gray-200" />
            <div className="mb-4">
              <InputShortText 
                validationFn={(value) => !!value && value.length > 3} 
                onChange={e => { setDomain(e.target.value.replace(/\s+/g, '').toLowerCase()); setTenantName(e.target.value) }}
                errorMessage={"errors.too_short"}
                label={t("registration.store_name")!}
              />
              {/* {domain && <p className="text-sm text-gray-600">
                {t("registration.domain.text")} <BolderText text={`${domain}.ateliers.com`}/>
                </p>} */}
            </div>
            <PrimaryButton text={t("registration.signup")!} fn={handleSignUp} eventName="sign_up_button_clicked" actionName="Sign Up Button"/>
            <span><TinyText text={`${t("registration.conditions.begining")} `} />
            <Link to="/terms-of-service" className="text-as-pink-300"><TinyText text={t("footer.terms_of_service")!} /></Link>
            <TinyText text={` ${t("global.and")} `} />
            <Link to="/privacy-policy" className="text-as-pink-300"><TinyText text={t("footer.privacy_policy")!} /></Link>
            <TinyText text={"."} />
            </span>
        </form>
      </div>
  )}
);