import Pill from "../Pill/Pill";
import TinyText from "../TinyText/TinyText";

type NoticeProps = {
    pillText: string;
    tinyText: string;
  };

const Notice: React.FC<NoticeProps> = ({ pillText, tinyText }) => {
    return (
      <div className="flex items-center mt-5 space-x-3">
        <Pill text={pillText} color='green' />
        <TinyText text={tinyText} />
      </div>
    );
  };
  
export default Notice;