export type ConversionStatus = 'TO_CONTACT' | 'FIRST_CONTACT' | 'REMINDER' | 'SENT_CATALOGUE' | 'DEAL_CLOSED' | 'REJECTED';

export function getLeadStatuses(): ConversionStatus[] {
  return ['TO_CONTACT', 'FIRST_CONTACT', 'REMINDER', 'SENT_CATALOGUE', 'DEAL_CLOSED', 'REJECTED'];
}

export function leadStatusToTranslationKey(status: string | undefined): string {
  switch (status) {
    case 'TO_CONTACT':
      return "leads.status.to_contact";
    case 'FIRST_CONTACT':
        return "leads.status.first_contact";
    case 'REMINDER':
        return "leads.status.reminder";
    case 'SENT_CATALOGUE':
        return "leads.status.sent_catalogue";
    case 'DEAL_CLOSED':
        return "leads.status.deal_closed";
    case 'REJECTED':
      return "leads.status.rejected";
    default:
      return "global.unknown";
  }
}