import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LeadsAPI, { Lead } from "../api/LeadsAPI";
import { toastService } from "../services/ToastService";

export type Filters = {
  country?: string;
  status?: string;
};

type ReturnedProps = {
  isLoading: boolean,
  leads: Lead[],
  addFn: (lead: Lead) => Promise<any>,
  updateFn: (lead: Lead) => Promise<any>,
  removeFn: (lead: Lead) => Promise<any>,
};

export function useGetLeads({ country, status }: Filters): ReturnedProps {
  const { t } = useTranslation();
  const [leads, setLeads] = useState<Lead[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const addFn = async (lead: Lead) => {
    try {
      const resp = await LeadsAPI.add(lead);
      setLeads(prevLeads => [...prevLeads, resp.data]);
    } catch (err) {
      toastService.showToast(t("toast.errors.leads.add"), 'error');
    }
  };

  const updateFn = async (lead: Lead) => {
    try {
      const resp = await LeadsAPI.update(lead.id!, lead);
      setLeads(prevLeads => prevLeads.map(l => l.id === resp.data.id ? resp.data : l));
    } catch (err) {
      toastService.showToast(t("toast.errors.leads.update"), 'error');
    }
  };

  const removeFn = async (lead: Lead) => {
    try {
      await LeadsAPI.remove(lead.id!);
      setLeads(prevLeads => prevLeads.filter(l => l.id !== lead.id));
    } catch (err) {
      toastService.showToast(t("toast.errors.leads.remove"), 'error');
    }
  };

  const load = useCallback(() => {
    setIsLoading(true);
    LeadsAPI.get({ status, country })
      .then(response => {
        setLeads(response.data);
      })
      .catch(() => {
        toastService.showToast(t("toast.errors.leads.load"), 'error');
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, status]);

  useEffect(() => {
    load();
  }, [load]);

  return { isLoading, leads, addFn, updateFn, removeFn };
};
