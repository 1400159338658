export type MeetingType = 'VISIT' | 'FIRST_MEASUREMENT' | 'SECOND_MEASUREMENT' | 'OTHER' | 'CONTRACT' | 'RECEIVING' | 'WAITING_LIST'
 | 'NOTE' | 'SECOND_MEASUREMENT_AND_RECEIVING' | 'TAKING_MEASUREMENTS';

export const meetingTypesArray: string[] = [
    'VISIT',
    'FIRST_MEASUREMENT',
    'SECOND_MEASUREMENT',
    'OTHER',
    'CONTRACT',
    'RECEIVING',
    'WAITING_LIST',
    'NOTE',
    'SECOND_MEASUREMENT_AND_RECEIVING',
    'TAKING_MEASUREMENTS',
];
  

export function meetingTypeToTranslationKey(status: MeetingType): string {
  switch (status) {
    case 'VISIT' as MeetingType:
      return "calendar.meeting.status.visit";
    case 'FIRST_MEASUREMENT' as MeetingType:
        return "calendar.meeting.status.first_measurement";
    case 'SECOND_MEASUREMENT' as MeetingType:
        return "calendar.meeting.status.second_measurement";
    case 'OTHER' as MeetingType:
        return "calendar.meeting.status.other";
    case 'CONTRACT' as MeetingType:
        return "calendar.meeting.status.contract";
    case 'RECEIVING' as MeetingType:
        return "calendar.meeting.status.receiving";
    case 'WAITING_LIST' as MeetingType:
        return "calendar.meeting.status.waiting_list";
    case 'NOTE' as MeetingType:
        return "calendar.meeting.status.note";
    case 'SECOND_MEASUREMENT_AND_RECEIVING' as MeetingType:
        return "calendar.meeting.status.second_measurement_and_receiving";
    case 'TAKING_MEASUREMENTS' as MeetingType:
        return "calendar.meeting.status.taking_measurements";
    default:
      return "global.unknown";
  }
}