import { useTranslation } from 'react-i18next';
import SecondaryButton from '../SecondaryButton/SecondaryButton';
import { Icon } from '../Icon/Icon';
import { ResponsiveMenu } from '../ResponsiveMenu/ResponsiveMenu';
import MenuContainer from '../MenuContainer/MenuContainer';
import LogoutButton from '../LogOutButton/LogOutButton';
import CONFIG from '../../config/config';
import { isSupportedLanguage } from '../../shared/supported-languages';
import store from '../../assets/images/store.svg';
import catalogues from '../../assets/images/catalogue.svg';
import settings from '../../assets/images/settings.svg';
import orders from '../../assets/images/orders.svg';
import box from '../../assets/images/box.svg';
import chartBar from '../../assets/images/chart-bar.svg';
import { useSelector } from 'react-redux';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import { useState } from 'react';
import { Modal } from '../Modal/Modal';
import check from '../../assets/images/check.svg';
import { BolderText } from '../BolderText/BolderText';
import LoginFlow from '../LoginFlow/LoginFlow';
import { ActivePricePlan } from '../ActivePricePlan/ActivePricePlan';
import { useNavigate } from 'react-router-dom';
import FlyoutMenu from '../FlyoutMenu/FlyoutMenu';
import calendarIcon from '../../assets/images/calendar.svg';
import { AdminRestricted } from '../AdminRestricted/AdminRestricted';
import PlainTextButton from '../PlainTextButton/PlainTextButton';
import { useOnboarding } from '../../hooks/useOnboarding';

function MainMenu() {
  const { t, i18n } = useTranslation();
  const isLoggedIn = useSelector((state: any) => state.settings.value.isLoggedIn);
  const [displayLoginSignUpFlow, setDisplayLoginSignUpFlow] = useState(false);
  const navigate = useNavigate();
  const { onboardingStatus, skipStep } = useOnboarding(isLoggedIn);
  const allStepsCompleted = onboardingStatus && Object.values(onboardingStatus.status).every(step => step);

  const languages = CONFIG.SUPPORTED_LANGUAGES.map(l => {
    return {
      code: l.code,
      name: '',
      onClick: () => i18n.changeLanguage(l.code),
      icon: <Icon url={l.icon} cssStyles="w-5 rounded h-3"/>,
    }
  })

  const goToLoginPage = () => {
    navigate(`/login`);
  };

  const menuItems: any[] = [
    { text: t('menu.store')!, href: '/', isDisabled: false, flags: ['see-meetings', 'see-contracts'], name: 'Store', 
    icon: <><Icon url={store} />&nbsp;&nbsp;</>, 
      submenuItems: [
        { text: t('menu.calendar')!, href: '/calendar', flags: ['see-meetings'], isDisabled: false, name: 'Calendar'},
        { text: t('menu.calendar_app'), href: '/', flags: [], isDisabled: true, name: 'Booking Calendar'},
        { text: t('menu.contracts')!, href: '/contracts', flags: ['see-contracts'], isDisabled: false, name: 'Contracts'},
        { text: t('menu.customers')!, href: '/customers', flags: ['see-customers'], isDisabled: false, name: 'Customers CRM'},
      ] 
    },
    { 
      text: t('menu.orders')!, href: '/orders', flags: ['see-orders', 'see-self-orders'], name: 'Orders',
      icon: <><Icon url={orders} />&nbsp;&nbsp;</>,  
    },
    { text: t('menu.marketing')!, href: '/', isDisabled: false, flags: ['see-marketing-collections', 'see-leads'], name: 'Marketing', 
    icon: <><Icon url={catalogues} />&nbsp;&nbsp;</>, 
      submenuItems: [
        { text: t('admin.menu.collections')!, href: '/catalogues', isDisabled: false, flags: ['see-marketing-collections'], name: 'Catalogues' },
        { text: t('menu.leads')!, href: '/leads', isDisabled: false, flags: ['see-leads'], name: 'Leads' },
        { text: t('menu.tablet')!, href: '/tablet', isDisabled: true, flags: ['see-marketing-collections'], name: 'Tablet' },
      ] 
    },
    { text: t('menu.production')!, href: '/production', flags: ['see-providers'], name: 'Production', 
    icon: <><Icon url={box} />&nbsp;&nbsp;</>, 
      submenuItems: [
        { text: t('production.menu.providers')!, href: '/providers', isDisabled: false, flags: ['see-providers'], name: 'Providers'},
        { text: t('production.menu.stock')!, href: '/stock', isDisabled: false, flags: ['see-stock'], name: 'Stock'},
        { text: t('production.menu.dresses')!, href: '/inventory', isDisabled: false, flags: ['see-dresses'], name: 'Item Plans'}
      ] 
    },
    { text: t('menu.stats')!, href: '/', isDisabled: false, flags: ['see-employees'], name: 'Statistics', 
      icon: <><Icon url={chartBar} />&nbsp;&nbsp;</>, 
      submenuItems: [
        { text: t('Orders')!, href: '/insights/orders', isDisabled: false, flags: ['see-employees'], name: 'Stats Orders' },
        { text: t('PnL')!, href: '/insights/pnl', isDisabled: true, flags: ['see-employees'], name: 'Stats PnL' },
        { text: t('Tablet')!, href: '/insights/tablet', isDisabled: true, flags: ['see-employees'], name: 'Stats Tablet' },
      ] 
    },
    { text: t('menu.admin')!, href: '/', flags: ['see-partners'], name: 'Admin', 
    icon: <><Icon url={settings} />&nbsp;&nbsp;</>, 
      submenuItems: [
        { text: t('admin.menu.partners')!, href: '/partners', flags: ['see-partners'], isDisabled: false, name: 'Partners'},
        { text: t('admin.menu.employees')!, href: '/employees', flags: ['see-employees'], name: 'Employees' },
      ] 
    },
    { text: t('menu.settings')!, href: '/settings', flags: ['see-employees'], isDisabled: false, name: 'Settings', 
      icon: <><Icon url={settings} />&nbsp;&nbsp;</>
    }
  ];

  const languageMenuItems: any[] = 
    languages.map((lang) => ({
      text: lang.name,
      onClick: () => i18n.changeLanguage(lang.code),
      isDisabled: false,
      icon: lang.icon,
    }))
    ;

  const onboardingItems = [
    {
      headline: t("onboarding.menu.store"),
      text: 
        <>
          <div className="text-sm min-w-[300px]">{t("onboarding.menu.store.description")}</div>
          { !onboardingStatus?.status.setupStore ?
            <>
              <PlainTextButton text={t("global.mark_completed")!} onClick={() => skipStep('setupStore')} actionName="Complete Onboarding Settings"/>
              <SecondaryButton text={t("global.go")!} fn={() => navigate('/settings')} actionName="Onboarding Settings"/>
            </>
            : null
          }
        </>,
      href: '#',
      onClick: () => navigate('/settings'),
      isDisabled: onboardingStatus?.status.setupStore,
      icon: <><Icon url={settings} />&nbsp;&nbsp;</>,
      styles: onboardingStatus?.status.setupStore
        ? { backgroundColor: '#f3f4f6', textDecoration: 'line-through', color: 'gray' }
        : { backgroundColor: '#fff1f2' },
    },
    {
      headline: t("onboarding.menu.calendar"),
      text: 
        <>
          <div className="text-sm min-w-[300px]">{t("onboarding.menu.calendar.description")}</div>
          { !onboardingStatus?.status.startCalendar ?
            <>
              <PlainTextButton text={t("global.mark_completed")!} onClick={() => skipStep('startCalendar')} actionName="Complete Onboarding Calendar"/>
              <SecondaryButton text={t("global.go")!} fn={() => navigate('/calendar')} actionName="Onboarding Calendar"/>
            </>
            : null
          }
        </>,
      href: '#',
      onClick: () => navigate('/calendar'),
      icon: <><Icon url={calendarIcon} />&nbsp;&nbsp;</>,
      isDisabled: onboardingStatus?.status.startCalendar,
      styles: onboardingStatus?.status.startCalendar
        ? { backgroundColor: '#f3f4f6', textDecoration: 'line-through', color: 'gray' }
        : { backgroundColor: '#fff1f2' },
    },
    {
      headline: t("onboarding.menu.catalog"),
      text: 
        <>
          <div className="text-sm min-w-[300px]">{t("onboarding.menu.catalog.description")}</div>
          { !onboardingStatus?.status.publishCatalog ?
            <>
              <PlainTextButton text={t("global.mark_completed")!} onClick={() => skipStep('publishCatalog')} actionName="Complete Onboarding Catalogues"/>
              <SecondaryButton text={t("global.go")!} fn={() => navigate('/catalogues')} actionName="Onboarding Catalogues"/>
            </>
            : null
          }
        </>,
      href: '#',
      onClick: () => navigate('/catalogues'),
      icon: <><Icon url={catalogues} />&nbsp;&nbsp;</>,
      isDisabled: onboardingStatus?.status.publishCatalog,
      styles: onboardingStatus?.status.publishCatalog
        ? { backgroundColor: '#f3f4f6', textDecoration: 'line-through', color: 'gray' }
        : { backgroundColor: '#fff1f2' },
    },
    {
      headline: t("onboarding.menu.order"),
      text: 
        <>
          <div className="text-sm min-w-[300px]">{t("onboarding.menu.order.description")}</div>
          { !onboardingStatus?.status.registerOrder ?
            <>
              <PlainTextButton text={t("global.mark_completed")!} onClick={() => skipStep('registerOrder')} actionName="Complete Onboarding Orders"/>
              <SecondaryButton text={t("global.go")!} fn={() => navigate('/orders')} actionName="Onboarding Orders"/>
            </>
            : null
          }
        </>,
      href: '#',
      onClick: () => navigate('/orders'),
      icon: <><Icon url={orders} />&nbsp;&nbsp;</>,
      isDisabled: onboardingStatus?.status.registerOrder,
      styles: onboardingStatus?.status.registerOrder
        ? { backgroundColor: '#f3f4f6', textDecoration: 'line-through', color: 'gray' }
        : { backgroundColor: '#fff1f2' },
    },
  ];

  const notLoggedInMenuItems = [
    { text: t('menu.store')!, href: '', isDisabled: false, flags: [], 
    icon: <><Icon url={store} />&nbsp;&nbsp;</>, 
      submenuItems: [
        { text: t('menu.calendar')!, href: '', flags: [], isDisabled: true},
        { text: t('menu.contracts')!, href: '', flags: [], isDisabled: true},
        { text: t('menu.customers')!, href: '', flags: [], isDisabled: true},
      ] 
    },
    { 
      text: t('menu.orders')!, href: '', flags: [], isDisabled: true,
      icon: <><Icon url={orders} />&nbsp;&nbsp;</>,  
    },
    { text: t('menu.marketing')!, href: '', isDisabled: false, flags: [], 
    icon: <><Icon url={catalogues} />&nbsp;&nbsp;</>, 
      submenuItems: [
        { text: t('admin.menu.collections')!, href: '', isDisabled: true, flags: [] },
        { text: t('menu.leads')!, href: '', isDisabled: true, flags: [] },
        { text: t('menu.tablet')!, href: '', isDisabled: true, flags: [] },
      ] 
    },
    { text: t('menu.production')!, href: '', flags: [], 
    icon: <><Icon url={box} />&nbsp;&nbsp;</>, 
      submenuItems: [
        { text: t('production.menu.providers')!, href: '', isDisabled: true, flags: []},
        { text: t('production.menu.stock')!, href: '', isDisabled: true, flags: []},
        { text: t('production.menu.dresses')!, href: '', isDisabled: true, flags: []}
      ] 
    },
    { text: t('menu.stats')!, href: '', isDisabled: false, flags: [], 
      icon: <><Icon url={chartBar} />&nbsp;&nbsp;</>, 
      submenuItems: [
        { text: t('Tablet')!, href: '', isDisabled: true, flags: [] },
        { text: t('Orders')!, href: '', isDisabled: true, flags: [] },
        { text: t('PnL')!, href: '', isDisabled: true, flags: [] },
      ] 
    },
    { text: t('menu.admin')!, href: '', flags: [], 
    icon: <><Icon url={settings} />&nbsp;&nbsp;</>, 
      submenuItems: [
        { text: t('admin.menu.partners')!, href: '', flags: [], isDisabled: true},
        { text: t('admin.menu.employees')!, href: '', flags: [], isDisabled: true },
      ] 
    },
    { text: t('menu.settings')!, href: '', flags: [], isDisabled: true, name: 'Settings', 
      icon: <><Icon url={settings} />&nbsp;&nbsp;</>
    }
  ];

  return (
    <MenuContainer>
      { isLoggedIn ? <>
        <div className="flex items-center flex-wrap space-x-3">
          <ResponsiveMenu
            menuItems={menuItems.map((item) => ({
              ...item,
              element: (
                <SecondaryButton
                  key={item.text}
                  text={item.text}
                  isDisabled={item.isDisabled || false}
                  eventName={`Menu - Clicked ${item.name || item.text}`}
                  actionName={`Menu - ${item.name || item.text}`}
                />
              ),
            }))}
          />
          <AdminRestricted >
            {
              <>
                {!allStepsCompleted && 
                <FlyoutMenu
                  menuItems={onboardingItems}
                  trigger={
                    <button 
                      type="button" 
                      className="max-w-sm font-bold bg-gradient-to-r from-orange-300 via-rose-400 to-pink-400 rounded-3xl duration-1000 px-7 py-2.5 animate-pulse"
                      data-dd-action-name="Click on Onboarding Menu (Desktop)"
                    >
                      {t("onboarding.menu")}
                    </button>
                  }
                  mobileTrigger={
                    <button 
                      type="button" 
                      className="max-w-sm text-sm font-bold bg-gradient-to-r from-orange-300 via-rose-400 to-pink-400 rounded-3xl duration-1000 px-7 py-1 animate-pulse"
                      data-dd-action-name="Click on Onboarding Menu (Mobile)"
                    >
                      {t("onboarding.menu.mobile")}
                    </button>
                  }
                /> }
              </>
            }
          </AdminRestricted>
          <FlyoutMenu
            menuItems={languageMenuItems}
            trigger={
              isSupportedLanguage(i18n.language)
                ? languages.filter(l => l.code === i18n.language)[0]?.icon
                : languages.filter(l => l.code === CONFIG.DEFAULT_LANGUAGE.code)[0]?.icon
            }
            mobileTrigger={isSupportedLanguage(i18n.language)
              ? languages.filter(l => l.code === i18n.language)[0]?.icon
              : languages.filter(l => l.code === CONFIG.DEFAULT_LANGUAGE.code)[0]?.icon}
          />
          <ActivePricePlan />
          <LogoutButton />
        </div>
      </> : 
      <>
        <div className="flex items-center flex-wrap space-x-2">
          <ResponsiveMenu
            menuItems={notLoggedInMenuItems.map((item) => ({
              ...item,
              element: (
                <SecondaryButton
                  key={item.text}
                  text={item.text}
                  isDisabled={item.isDisabled || false}
                  eventName={`Guest Menu - Clicked ${item.text}`}
                  actionName={`Guest Menu - ${item.text}`}
                />
              ),
            }))}
          />
          <FlyoutMenu
            menuItems={languageMenuItems}
            trigger={isSupportedLanguage(i18n.language) ? 
              languages.filter(l => l.code === i18n.language)[0]?.icon
              : languages.filter(l => l.code === CONFIG.DEFAULT_LANGUAGE.code)[0]?.icon}
            mobileTrigger={'='}
          />
          <PrimaryButton text={t("guest.login.cta")!} fn={() => goToLoginPage()} />
        </div>
        { displayLoginSignUpFlow && 
          <Modal canClose modalTitle={t("guest.login.form.title")} onClose={() => setDisplayLoginSignUpFlow(false)}>
            <>
              <p>
                { t("guest.login.form.description") }.
              </p>
              <p><Icon url={check}></Icon>&nbsp; <BolderText text={t("guest.login.form.reason_1")!}/></p>
              <p><Icon url={check}></Icon>&nbsp; <BolderText text={t("guest.login.form.reason_2")!}/></p>
              <p><Icon url={check}></Icon>&nbsp; <BolderText text={t("guest.login.form.reason_3")!}/></p>
              <p><Icon url={check}></Icon>&nbsp; ... { t("guest.login.form.reason_others") } </p>
              <br/>
              <LoginFlow />
            </>
          </Modal> 
        }
      </> }
    </MenuContainer>
  );
}

export default MainMenu;
