import { useEffect, useState } from 'react';
import tinycolor from 'tinycolor2';

type ColorSelectorProps = {
    colorVariable: string;
    defaultColor: string;
    label?: string;
    onColorChange?: (color: string) => void;
};

const ColorThemeSelector = ({ colorVariable, defaultColor, label, onColorChange }: ColorSelectorProps) => {
  const [selectedColor, setSelectedColor] = useState(defaultColor);

  useEffect(() => {
    setSelectedColor(defaultColor);
  }, [defaultColor]);

  const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newColor = event.target.value;
    setSelectedColor(newColor);
    generateShades(newColor);

    onColorChange && onColorChange(newColor);
  };

  const generateShades = (baseColor: string) => {
    const shades = {
      50: tinycolor(baseColor).lighten(40).toString(),
      100: tinycolor(baseColor).lighten(30).toString(),
      200: tinycolor(baseColor).lighten(20).toString(),
      300: tinycolor(baseColor).lighten(10).toString(),
      DEFAULT: baseColor,
      500: tinycolor(baseColor).darken(10).toString(),
      600: tinycolor(baseColor).darken(20).toString(),
      700: tinycolor(baseColor).darken(30).toString(),
      800: tinycolor(baseColor).darken(40).toString(),
    };

    const rootStyle = document.documentElement.style;

    for (const [key, value] of Object.entries(shades)) {
      rootStyle.setProperty(`${colorVariable}-${key}`, value);
    }
  };

  return (
    <div className="max-w-full">
        <p>{ label }:</p>
        <input type="color" value={selectedColor} onChange={handleColorChange} />
    </div>
  );
};

export default ColorThemeSelector;