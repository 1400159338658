import { PillColor } from "../components/Pill/Pill";

export type CustomerStatus = 'NEW' | 'VISIT' | 'FOLLOW_UP' | 'SECOND_FOLLOW_UP' | 'CLOSED' | 'FAILED' | 'NO_SHOW'

export function getCustomerStatuses(): CustomerStatus[] {
  return ['NEW', 'VISIT', 'FOLLOW_UP', 'SECOND_FOLLOW_UP', 'CLOSED', 'FAILED', 'NO_SHOW'];
}

export function customerStatusToTranslationKey(status: string | undefined): string {
  switch (status) {
    case 'NEW':
      return "customers.status.new";
    case 'VISIT':
      return "customers.status.visit";
    case 'FOLLOW_UP':
        return "customers.status.follow_up";
    case 'SECOND_FOLLOW_UP':
        return "customers.status.second_follow_up";
    case 'CLOSED':
        return "customers.status.closed";
    case 'FAILED':
        return "customers.status.failed";
    case 'NO_SHOW':
        return "customers.status.no_show";
    default:
      return "global.unknown";
  }
}

export function customerStatusToPillColor(status: string | undefined): PillColor {  
  switch (status) {
    case 'NEW':
    case 'VISIT':
    case 'CLOSED':
      return 'green';
    case 'FOLLOW_UP':
        return 'blue';
    case 'FAILED':
    case 'NO_SHOW':
        return 'red';
    default:
      return 'grey';
  }
}