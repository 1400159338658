import { useTranslation } from "react-i18next";
import { FileUploader } from "react-drag-drop-files";
import CONFIG from "../../config/config";
import { useState } from "react";

type Props = {
  label?: string,
  onChange: (input: any) => void,
  isDisabled?: boolean,
  allowedTypes?: string[],
  allowedMultipleFiles?: boolean,
  maxSize?: number,
};

function FileUploadWrapper({ label = '', isDisabled = false, onChange = () => null, allowedTypes = CONFIG.FILE_TYPES, allowedMultipleFiles = false, maxSize = CONFIG.MAX_FILE_UPLOAD }: Props) {

  const [error, setError] = useState<string | undefined>();
  const { t } = useTranslation();
  
  const wrapOnChange = (params: any) => {
    setError(undefined);
    onChange(params);
  };

  return (
    <>
      { error && <p className="text-xs text-red-600 my-0">{ t(error) }</p> }
      <FileUploader 
        label={t(label)} 
        handleChange={wrapOnChange} 
        name="file" 
        types={allowedTypes}
        maxSize={maxSize}
        disabled={isDisabled}
        multiple={allowedMultipleFiles}
        onSizeError={() => setError("errors.too_heavy")}
      />
    </>
  );
}

export default FileUploadWrapper;
