import './Modal.css';
import { CloseButton } from '../CloseButton/CloseButton';
import { useTranslation } from 'react-i18next';
import Subtitle from '../Subtitle/Subtitle';
import { useEffect } from 'react';

type Props = {
  modalTitle: string,
  canClose: boolean,
  fn?: () => void,
  onClose?: () => void,
  children: JSX.Element
};

export function Modal({
  modalTitle = '',
  canClose = true,
  onClose = () => null,
  children,
}: Props) {

  const { t } = useTranslation();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => { document.body.style.overflow = 'unset' };
  }, []);

  return (
    <div className="modalOverlay">
      <div className={`modalContainer`}>
        <div className="modalHeader">
          <Subtitle text={t(modalTitle)} />
          {canClose && <CloseButton fn={onClose} />}
        </div>
        <div className="modalBody">{children}</div>
        {/* <div className="modalFooter"></div> */}
      </div>
    </div>
  );
}
