import React from "react";
import PricingPlan from "../PricingPlan/PricingPlan";

const PricingPlans: React.FC = () => {
  const plans = [
    {
      plan: "plans.reseller",
      name: "plans.reseller.name",
      description: "plans.reseller.description",
      features: ["plans.reseller.features.one", "plans.reseller.features.two", "plans.reseller.features.three", "plans.reseller.features.four", "plans.reseller.features.five"],
      price: 100,
      offerPrice: 79.99,
    },
    {
      plan: "plans.producent",
      name: "plans.producent.name",
      description: "plans.producent.description",
      features: ["plans.producent.features.one", "plans.producent.features.two", "plans.producent.features.three", "plans.producent.features.four", "plans.producent.features.five"],
      price: 200,
      offerPrice: 149.99,
    },
    {
      plan: "plans.premium",
      name: "plans.premium.name",
      description: "plans.premium.description",
      features: ["plans.premium.features.one", "plans.premium.features.two", "plans.premium.features.three", "plans.premium.features.four", "plans.premium.features.five"],
      price: 375,
      offerPrice: 299.99,
    },
  ];

  return (
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {plans.map((plan, index) => (
          <PricingPlan key={index} plan={plan} />
        ))}
      </div>
  );
};

export default PricingPlans;
