import nullIcon from '../../assets/images/null.png';

type Props = {
    message: string
};

const NoData = ({ message }: Props) => {
  return (
    <div className="flex flex-col items-center justify-center p-8">
      <div className="flex justify-center items-center">
        <img src={nullIcon} alt="Null"/>
      </div>
      <p className="mt-4 text-xl text-gray-500">{message}</p>
    </div>
  );
};

export default NoData;
