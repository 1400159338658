import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LinearGraph from '../../../components/LinearGraph/LinearGraph';
import NoData from '../../../components/NoData/NoData';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
import { useStatistics } from '../../../hooks/useStatistics';

function OrdersMonthlyGroupedByYear() {
  const { t } = useTranslation();

  const [chartData, setChartData] = useState<any[]>([]);
  const data = useStatistics('orders-monthly-grouped-by-year');

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      const months = Array.from({ length: 12 }, (_, i) => `${i + 1}`);
      const years = Object.keys(data);
      const lineData = years.map((year, index) => {
        const values = months.map((month) => data[year][month] ?? 0);
        const backgroundColor = `rgba(75, 192, 192, 0.${index + 2})`;
        return {
          label: year,
          data: values,
          backgroundColor,
          borderColor: backgroundColor,
          borderWidth: 1,
        };
      });
      setChartData(lineData);
    }
  }, [data]);

  return (
    <>
      <SectionTitle text={t("stats.orders_monthly_grouped_by_year")} />
      {chartData.length > 0 ? (
        <LinearGraph title="Years" xLabels={Array.from({length: 12}, (_, i) => `${i + 1}`)} dataSets={chartData} />
      ) : (
        <NoData message={t("global.empty_results")} />
      )}
    </>
  );
}

export default OrdersMonthlyGroupedByYear;