import { User } from "../../../api/UsersAPI";
import Card from "../../../components/Card/Card";
import ListContainer from "../../../components/ListContainer/ListContainer";
import ListElement from "../../../components/ListElement/ListElement";
import PrimaryButton from "../../../components/PrimaryButton/PrimaryButton";
import useGetUsers from "../../../hooks/useGetUsers";
import { UserPermissions } from "../../../components/UserPermissions/UserPermissions";
import { Account } from "../../../api/AccountAPI";
import { useState } from "react";
import AccountForm from "../../../components/AccountForm/AccountForm";
import { Modal } from "../../../components/Modal/Modal";
import { useTranslation } from "react-i18next";
import { FormType } from "../../../shared/form.type";
import IconButton from "../../../components/IconButton/IconButton";
import ElementActions from "../../../components/ElementActions/ElementActions";
import ConfirmationPopup from "../../../components/ConfirmationPopup/ConfirmationPopup";
import { Icon } from "../../../components/Icon/Icon";
import deleteIcon from "../../../assets/images/bin.svg";
import NoDataCTA from "../../../components/NoDataCTA/NoDataCTA";

const NEW_USER: Account = {
  email: "",
  role: "shop",
  password: ""
}

function AccountsSegment() {

  const { t } = useTranslation();
  const [action, ] = useState<FormType>('CREATE');
  const [user, setUser] = useState<Account | null>();
  const { isLoading, users, addFn, removeFn, updateUserPermissions } = useGetUsers();

  const registerAndClose = async (e: Account) => {
    
    if (!!e) {
      if (action === 'CREATE') {
        addFn(e);
      }
      setUser(null);
    }
  }

  return (
    <>
      <Card>
        <>
          <div className="flex mb-5">
            <PrimaryButton eventName="create_tenant_user" text={t("settings.accounts.create_account")!} fn={() => setUser(NEW_USER)}/>
          </div>
          <ListContainer
              isLoading={isLoading}
              elements={users || []}
              emptyListComponent={
                <NoDataCTA
                  headline={t("settings.accounts.empty.cta.title")}
                  subtext={t("settings.accounts.empty.cta.body")}
                  primaryActionText={t("settings.accounts.create_account")}
                  onPrimaryAction={() => setUser(NEW_USER)}
                />
              }
              toElementFn={(user: User, index:number ) =>
                <ListElement 
                  key={user.uuid || index} 
                  css="w-full flex duration-500 hover:bg-darker-gold-100"
                  >
                  <>
                    <ElementActions actions={
                      <>
                        { user.role === "admin" ? 
                          null : 
                          <IconButton>
                            <ConfirmationPopup onConfirm={() => removeFn(user.uuid)} confirmText={"global.confirm"} title={`${t("global.remove")}: ${user.email}`}>
                              <Icon url={deleteIcon} cssStyles="w-5"/>
                            </ConfirmationPopup>
                          </IconButton> 
                        }
                      </>
                    }>
                      <UserPermissions user={user} updateUserPermissions={updateUserPermissions} />
                    </ElementActions>
                  </>
                </ListElement>
              }
            />
            
        </>
      </Card>
      { user && 
        <Modal modalTitle={t("settings.accounts.form.new_account")} canClose onClose={() => setUser(null)}>
          <AccountForm account={user} onAccountForm={setUser} actionButton={
            <span className="flex justify-center"><PrimaryButton eventName="confirm_create_tenant_user" text={t("global.create")!} fn={() => registerAndClose(user!)} /></span>
          } isDisabled={isLoading}/>
        </Modal>
      }
    </>
  );
}

export default AccountsSegment;
