import { useTranslation } from "react-i18next";
import ListContainer from "../../../../components/ListContainer/ListContainer";
import ListElement from "../../../../components/ListElement/ListElement";
import { useGetStock } from "../../../../hooks/useGetStock";
import { StockStatus, SummaryProvider } from "../../../../api/StockAPI";
import SummaryProviderElement from "../../../../components/SummaryProviderElement/SummaryProviderElement";
import SectionTitle from "../../../../components/SectionTitle/SectionTitle";
import ReferenceStockLevels from "../../../../components/ReferenceStockLevels/ReferenceStockLevels";
import NoData from "../../../../components/NoData/NoData";
import PageWrapper from "../../../PageWrapper/PageWrapper";
import Card from "../../../../components/Card/Card";

function StockSection() {

  const { t } = useTranslation();
  
  const { isLoading, providers, lowStock, provider, loadDetails, isLoadingDetails  } = useGetStock();

  return (
    <PageWrapper>
      <>
        <>
          {/* <br/>
          <IconButton>
            <ConfirmationPopup onConfirm={() => console.log('Reset')} confirmText={"global.confirm"} title={`${t("global.remove")}`}>
              <PrimaryWithPropagate fn={() => null} text={t("stock.reset")!} isDisabled={true}/>
            </ConfirmationPopup>
          </IconButton> */}
          <br/>
          { lowStock && lowStock.length > 0 && 
            <>
              <SectionTitle text={t("stock.low_stock")} />
              <Card>
                <>
                  <ListContainer 
                    isLoading={isLoading}
                    elements={lowStock || []}
                    toElementFn={(lw: any) => 
                      <ListElement key={lw.id} css="bg-white text-black">
                          <ReferenceStockLevels reference={lw} />
                      </ListElement>
                    }
                  /> 
                </>
              </Card>
              <br/>
            </>
          }
          <SectionTitle text={t("stock.current_levels")} />
          <Card>
            <>
              <ListContainer 
                  isLoading={isLoading}
                  elements={providers || []}
                  emptyListComponent={
                    <NoData message={t("stock.empty")}/>
                  }
                  toElementFn={(p: SummaryProvider) => 
                    <ListElement onClick={() => loadDetails(p.id)} key={p.id} css="bg-white text-black">
                      <div className="flex flex-col w-full">
                        <SummaryProviderElement provider={p} />
                        { !!provider && provider.id === p.id ?
                          <ListContainer 
                            isLoading={isLoadingDetails}
                            elements={provider.stockStatuses || []}
                            toElementFn={(ss: StockStatus) => 
                              <ListElement key={ss.id}>
                                <ReferenceStockLevels reference={ss} />
                              </ListElement>
                            }
                          />
                        : null }
                      </div>
                    </ListElement>
                  }
                />
            </>
          </Card>
        </>
      </>
    </PageWrapper>
  );
}

export default StockSection;
