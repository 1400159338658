import React, { useEffect, useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { isValidTime } from '../../shared/validation';

interface Props {
    value: string | null | undefined;
    onChange: (time: string) => void;
    label?: string;
    isDisabled?: boolean;
    validationFn?: (value: string) => boolean;
    errorMessage?: string; 
}

const CustomTimePicker: React.FC<Props> = ({
  value,
  onChange,
  label,
  isDisabled = false,
  validationFn,
  errorMessage = '',
}) => {
  const { t, i18n } = useTranslation();
  const [isInvalidInput, setIsInvalidInput] = useState<boolean>(false);

  const dateValue = value && isValidTime(value) ? new Date(`1970-01-01T${value}`) : null;

  useEffect(() => {
    if (value && validationFn) {
      setIsInvalidInput(!validationFn(value));
    }
  }, [value, validationFn]);

  const displayError: string = useMemo(() => {
    return isInvalidInput ? t(errorMessage) : '';
  }, [isInvalidInput, t, errorMessage]);

  const onChangeWithValidation = (date: Date | null) => {
    const time = date ? date.toLocaleTimeString().substring(0, 5) : '';
    if (validationFn) {
      if (validationFn(time)) {
        setIsInvalidInput(false);
        onChange(time);
      } else {
        setIsInvalidInput(true);
      }
    } else {
      onChange(time);
    }
  };

  return (
    <div className="flex-1 max-w-full">
      { label && <p className="text-sm font-extrabold">{ t(label) }&nbsp; </p> }
      <ReactDatePicker
        selected={dateValue}
        onChange={onChangeWithValidation}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        disabled={isDisabled}
        locale={i18n.language}
        dateFormat="HH:mm"
      />
      <p className="text-xs text-red-600 my-0">{ displayError }</p>
    </div>
  );
};

export default CustomTimePicker;
