import { useTranslation } from "react-i18next";
import { SummaryProvider } from "../../api/StockAPI";

type Props = {
  provider: SummaryProvider
};

function SummaryProviderElement({provider}: Props) {
  
  const { t } = useTranslation();

  return ( 
    <>
        <div className="flex space-x-3">
          <p>{ provider.name}</p>
          <p>{ `[${provider.noOfReferences} ${t("admin.stock.references")}]` }</p>
        </div>
    </>
  );
}

export default SummaryProviderElement;
