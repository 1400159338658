import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  label?: string,
  validationFn: (input: string) => boolean,
  onChange: (input: React.ChangeEvent<HTMLTextAreaElement>) => void,
  errorMessage?: string,
  isDisabled?: boolean,
  placeholder?: string,
  defaultValue?: string
};

function TextArea({label = '', validationFn, errorMessage = '', isDisabled = false, placeholder = '', onChange = () => null, defaultValue = ''}: Props) {
  
  const { t } = useTranslation();
  const [isInvalidInput, setIsInvalidInput] = useState<boolean>();
  const [localValue, setLocalValue] = useState<string>('');

  useEffect(() => {
    setLocalValue(defaultValue);
  }, [defaultValue]);

  const displayError: string = useMemo(() => {
    return isInvalidInput ? t(errorMessage) : ''
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInvalidInput]);

  const onChangeWithValidation = (input: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = input.currentTarget.value;
    if (validationFn(value)) {
      setIsInvalidInput(false);
      setLocalValue(value);
      onChange(input);
    } else {
      setIsInvalidInput(true);
      setLocalValue(value);
    }
  };

  return (
    <div className="flex-1 max-w-full">
      <p className="text-sm font-extrabold">{ t(label) }:</p>
      <textarea
        rows={5} 
        className={`p-1 w-full border-stone-200 border rounded focus:outline-none ${isInvalidInput ? 'border-amber-700' : null}`}
        placeholder={t(placeholder) || ''} 
        onChange={(ev) => onChangeWithValidation(ev)}
        disabled={isDisabled}
        value={localValue}
      />
      <p className="text-xs text-red-600 my-0">{ displayError }</p>
    </div>
  );
}

export default TextArea;
