import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

interface ChartData {
  label: string;
  data: number[];
  backgroundColor?: string[];
  borderColor?: string[];
  borderWidth?: number;
}

interface BarChartProps {
  title: string;
  xLabels: string[];
  dataSets: ChartData[];
}

const BarChart: React.FC<BarChartProps> = ({ title, xLabels, dataSets }) => {
  const chartData = {
    labels: xLabels,
    datasets: dataSets,
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return <Bar data={chartData} options={options} />;
};

export default BarChart;
