import PageWrapper from "../PageWrapper/PageWrapper";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ProvidersSection from "../../components/ProvidersSection/ProvidersSection";
import StockSection from "./sections/StockSection/StockSection";
import DressesSection from "./sections/DressesSection/DressesSection";

type AvailableSections = 'providers' | 'dresses' | 'stock';

function ProductionPage() {

  const [searchParams, ] = useSearchParams();
  const [activeSection, setActiveSection] = useState<AvailableSections>('providers');

  const activeSegment = useMemo(() => {
    switch(activeSection) {
      case 'providers':
        return <ProvidersSection />;
      case 'dresses':
        return <DressesSection />;
      case 'stock':
        return <StockSection />;
      default:
        return null;
    }
  }, [activeSection]);

  useEffect(() => {
    const tab = searchParams.get("activeTab");
    if (!!tab) {
      setActiveSection(tab as AvailableSections);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <PageWrapper>
      <>
        { activeSegment }
      </>
    </PageWrapper>
  );
}

export default ProductionPage;
