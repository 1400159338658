import React, { useEffect } from 'react';
import CONFIG from '../../config/config';
import { CSSTransition } from 'react-transition-group'; // Import CSSTransition for animations

interface ToastProps {
  message: string;
  id: string;
  removeToast: (id: string) => void;
  type: 'success' | 'error';
  duration?: number;
}

const Toast: React.FC<ToastProps> = ({ message, id, removeToast, type = 'success', duration = CONFIG.NOTIFICATION_DECAY }) => {
  
  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(id);
    }, duration);
    return () => {
      clearTimeout(timer);
    };
  }, [id, removeToast, duration]);

  return (
    <CSSTransition
      in={true}
      appear={true}
      timeout={300}
      classNames="toast"
      unmountOnExit
    >
      <div
        className={`${
          type === 'success' ? 'bg-white text-green-800' : 'bg-white text-red-800'
        } border-l-4 ${
          type === 'success' ? 'border-green-500' : 'border-red-500'
        } rounded-sm px-4 py-3 shadow-md flex items-center space-x-2 transition-all duration-300 ease-in-out`}
        role="alert"
        aria-live="assertive"
      >
        <i className={`fas ${type === 'success' ? 'fa-check-circle' : 'fa-times-circle'} text-xl`}></i>
        <p className="flex-1 text-sm">{message}</p>
      </div>
    </CSSTransition>
  );
};

export default Toast;