import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DressItem } from '../../../../api/DressesAPI';
import { Autocomplete } from '../../../../components/Autocomplete/Autocomplete';
import ConfirmationPopup from '../../../../components/ConfirmationPopup/ConfirmationPopup';
import ElementActions from '../../../../components/ElementActions/ElementActions';
import { Icon } from '../../../../components/Icon/Icon';
import IconButton from '../../../../components/IconButton/IconButton';
import ListContainer from '../../../../components/ListContainer/ListContainer';
import ListElement from '../../../../components/ListElement/ListElement';
import PrimaryButton from '../../../../components/PrimaryButton/PrimaryButton';
import { useGetProductionDresses } from '../../../../hooks/useGetProductionDresses';
import deleteIcon from "../../../../assets/images/bin.svg";
import edit from "../../../../assets/images/pen.svg";
import YearCollection from '../../../../components/YearCollection/YearCollection';
import { Modal } from '../../../../components/Modal/Modal';
import DressForm from '../../../../components/DressForm/DressForm';
import { FormType } from '../../../../shared/form.type';
import DressItemElement from '../../../../components/DressItemElement/DressItemElement';
import Card from '../../../../components/Card/Card';
import { PermissionFlags } from '../../../../components/PermissionFlags/PermissionFlags';
import SimpleListElement from '../../../../components/SimpleListElement/SimpleListElement';
import NoDataCTA from '../../../../components/NoDataCTA/NoDataCTA';
import PageWrapper from '../../../PageWrapper/PageWrapper';
import WorkArea from '../../../../components/WorkArea/WorkArea';
import SecondaryButton from '../../../../components/SecondaryButton/SecondaryButton';
import Confetti from 'react-dom-confetti';
import { useNavigate } from 'react-router-dom';
import { toastService } from '../../../../services/ToastService';

const EmptyDress = {
  name: '',
  year: new Date().getFullYear(),
  tags: [],
  materials: []
};

function DressesSection() {
  const { t } = useTranslation();
  const { isLoading, yearCollections, searchDresses, addDress, updateDress, removeDress, createCatalog } = useGetProductionDresses();
  const [expandedId, setExpandedId] = useState<number | null>(null);
  const [editDressModalOpen, setEditDressModalOpen] = useState(false);
  const [currentDress, setCurrentDress] = useState<any>(null);
  const [action, setAction] = useState<FormType>('READ');
  const [successfullyImported, setSuccessfullyImported] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [selectedItems, setSelectedItems] = useState<DressItem[]>([]);
  const navigate = useNavigate();
  const config = { angle: 90, spread: 360, startVelocity: 40, elementCount: 170, dragFriction: 0.12, duration: 10000, stagger: 3, width: "10px", height: "10px", perspective: "500px", colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"] };

  const handleCreateCatalog = async () => {
    try {
      await createCatalog(selectedItems.map(si => si.id!));
      setSuccessfullyImported(true);
      setShowConfetti(true);
    } catch (error: any) {
      toastService.showToast(t("toast.errors.create_catalog"), 'error');
      console.error("Something went wrong");
    }
  };

  const closeSuccessPopup = () => {
    setSuccessfullyImported(false);
    setShowConfetti(false);
  };

  const actionButton = (action: FormType) => {
    switch (action) {
      case 'CREATE':
        return <span className="flex mt-3 justify-center"><PrimaryButton text={t("admin.collections.items.form.add")!} fn={() => doAndClose(addDress, currentDress)} /></span>;
      case 'UPDATE':
        return <span className="flex mt-3 justify-center"><PrimaryButton text={t("admin.collections.items.form.update")!} fn={() => doAndClose(updateDress, currentDress)} /></span>;
      default:
        return;
    }
  };

  const toggleExpansion = (id: number) => {
    if (expandedId === id) {
      setExpandedId(null);
    } else {
      setExpandedId(id);
    }
  };

  const search = async (input: string, resultsSize: number, setResultsFn: any) => {
    let items = []
    try {
      items = await searchDresses(input);
      const filteredItems = items.slice(0, resultsSize);
      setResultsFn(filteredItems);
    } catch (err) {
      console.error('Could not search dress');
    }
  };

  const handleDress = async (action: FormType, dress: DressItem) => {
    setAction(action);
    setCurrentDress(dress);
    setEditDressModalOpen(true);
  };

  const doAndClose = async (fn: any, dress: DressItem) => {
    fn(dress);
    setCurrentDress(null);
    setEditDressModalOpen(false);
  }

  const toggleSelection = (item: DressItem) => {
    setSelectedItems((prevSelectedItems) => 
      prevSelectedItems.some((selectedItem) => selectedItem.id === item.id)
        ? prevSelectedItems.filter((selectedItem) => selectedItem.id !== item.id)
        : [...prevSelectedItems, item]
    );
  };
  
  const isItemSelected = (id: number) => {
    return selectedItems.some((selectedItem) => selectedItem.id! === id);
  };

  return (
    <PageWrapper>
      <div className="space-y-5">
        <Card>
            <>
            <div className="container flex flex-wrap space-x-5">
              <Autocomplete
                resultsSize={15}
                searchFn={search}
                onSelect={(e: any) => handleDress('UPDATE', e)}
                placeholder={t('dresses.search.placeholder')!}
                isLoading={isLoading}
                errorMessage={t('global.empty_results')!}
                toElementFn={(item: DressItem) => 
                  <SimpleListElement onClick={(e) => {e.stopPropagation(); handleDress('READ', item); }} key={item.id} actions={
                    <>
                      <PermissionFlags requiredFlags={['update-dresses'] || []}>
                        <IconButton onClick={() => handleDress('UPDATE', item)}>
                          <Icon url={edit} cssStyles="w-5"/>
                        </IconButton>
                      </PermissionFlags>
                    </>
                    }>
                    <p>{ item.name }</p>
                  </SimpleListElement>
                }
              />
            </div>
            <div className="container flex flex-wrap space-x-5 my-3">
              <PermissionFlags requiredFlags={['create-dresses'] || []}>
                <PrimaryButton text={t("dresses.new_dress.button")!} fn={() => handleDress('CREATE', EmptyDress)}/>
              </PermissionFlags>
            </div>
          </>
        </Card>

        <Card>
          <>
            <ListContainer
              isLoading={isLoading}
              elements={yearCollections}
              emptyListComponent={
                <NoDataCTA
                  headline={t("dresses.empty.cta.title")}
                  subtext={t("dresses.empty.cta.body")}
                  primaryActionText={t("dresses.new_dress.button")}
                  onPrimaryAction={() => handleDress('CREATE', EmptyDress)}
                />
              }
              toElementFn={(yearCollection) => (
                <ListElement key={yearCollection.id} css="bg-white text-black" onClick={() => toggleExpansion(yearCollection.id)}>
                  <div className="flex flex-col w-full">
                    <YearCollection yearCollection={yearCollection} />
                    { !!expandedId && expandedId === yearCollection.id ?
                      <ListContainer
                      elements={yearCollection.dresses}
                      toElementFn={(dress: DressItem) => (
                        <div key={dress.id} className={`flex items-center pl-3 py-3 space-x-3 ${isItemSelected(dress.id!) ? 'bg-gray-100' : 'hover:bg-darker-gold-100'}`}>
                          <input 
                            type="checkbox" 
                            checked={isItemSelected(dress.id!)}
                            onChange={() => toggleSelection(dress)}
                            onClick={(e) => e.stopPropagation()}
                            className="form-checkbox h-4 w-4"
                          />
                          <ListElement 
                            css={`w-full flex duration-500`}
                            onClick={(e) => { 
                              e.stopPropagation(); 
                              handleDress('READ', dress);
                            }}>
                            <>
                              <ElementActions actions={
                                <>
                                  <PermissionFlags requiredFlags={['update-dresses'] || []}>
                                    <IconButton onClick={() => handleDress('UPDATE', dress)}>
                                      <Icon url={edit} cssStyles="w-5"/>
                                    </IconButton>
                                  </PermissionFlags>
                                  <PermissionFlags requiredFlags={['remove-dresses'] || []}>
                                    <IconButton>
                                      <ConfirmationPopup onConfirm={() => removeDress(dress.id!)} confirmText={"global.confirm"} title={`${t("global.remove")}: ${dress.name}`}>
                                        <Icon url={deleteIcon} cssStyles="w-5"/>
                                      </ConfirmationPopup>
                                    </IconButton>
                                  </PermissionFlags>
                                </>
                              }>
                                <DressItemElement item={dress} />
                              </ElementActions>
                            </>
                          </ListElement>
                        </div>
                      )}
                      /> 
                      : null }
                  </div>
                </ListElement>
              )}
            />
          </>
        </Card>
        {selectedItems.length > 0 && 
          <WorkArea 
            items={selectedItems}
            callbackFn={setSelectedItems}
            renderItem={(item: DressItem) => <span>{item.name }</span>}
            onItemToggle={(dress) => null}
            actions={
              <>
                <PrimaryButton fn={handleCreateCatalog} text={t("dresses.catalog.cta")!} actionName="Create Catalog from Product Plan"/>
              </>
            }
          /> 
        }
        { successfullyImported && 
            <Modal canClose modalTitle={t("marketplace.import.title")} onClose={closeSuccessPopup}>
                <div className="flex flex-col space-y-5">
                  <p>{t("dresses.catalog.success")}</p>
                  <div className="flex justify-center">
                    <Confetti active={showConfetti} config={config} />
                    <p className="text-9xl">🎉</p>
                  </div>
                  <div className="flex justify-center space-x-3">
                    <SecondaryButton 
                      text={t("global.stay_here")!} fn={closeSuccessPopup} 
                      actionName="Stay here"
                    />
                    <PrimaryButton 
                      text={t("marketplace.import.cta.catalogues")!} fn={() => navigate('/catalogues')} 
                      actionName="Go to Catalogues"
                    />
                  </div>
                </div>
            </Modal>
          }
        {editDressModalOpen && (
          <Modal
            modalTitle={currentDress.name || "dresses.form.new_dress"}
            canClose
            onClose={() => setEditDressModalOpen(false)}
          >
            <DressForm 
              dress={currentDress} 
              onChange={setCurrentDress} 
              isDisabled={action === 'READ'} 
              actionButton={actionButton(action)}
            />
          </Modal>
        )}
      </div>
    </PageWrapper>
  );
}

export default DressesSection;
