import { useTranslation } from 'react-i18next';
import Pill from '../Pill/Pill';

export function ActivePricePlan() {

  const { t } = useTranslation();

  return (
    <span className="px-2"><Pill text={t("global.pricing.free")} color='green'/></span>
  );
}
