import OrdersSegment from "../../components/OrdersSegment/OrdersSegment";
import PageWrapper from "../PageWrapper/PageWrapper";

function OrdersPage() {

  return (
    <PageWrapper>
      <>
        <OrdersSegment />
      </>
    </PageWrapper>
  );
}

export default OrdersPage;
