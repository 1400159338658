import React, { useEffect, useRef } from 'react';
import './Bubbles.css';

interface BubbleProps {
  count: number;
}

const Bubbles: React.FC<BubbleProps> = ({ count }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!containerRef.current) return;

    const container = containerRef.current;

    for (let i = 0; i < count; i++) {
      const bubble = document.createElement('div');
      bubble.classList.add('bubble');
      bubble.style.width = `${Math.random() * 100}px`;
      bubble.style.height = bubble.style.width;
      bubble.style.left = `${Math.random() * 100}%`;
      bubble.style.filter = `blur(${Math.random() * 10}px)`;
      bubble.style.opacity = `0.05`;
      bubble.style.bottom = `${Math.random() * 100}%`;
      bubble.style.animationDuration = `${10 + Math.random() * 10}s`;
      bubble.style.animationDelay = `${Math.random() * 5}s`;
      bubble.style.backgroundColor = `#d97706`;

      container.appendChild(bubble);
    }
  }, [count]);

  return <div ref={containerRef} className="bubble-container" />;
};

export default Bubbles;
