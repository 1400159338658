import React from 'react';
import { useTranslation } from 'react-i18next';
import CONFIG from '../../config/config';
import InputNumber from '../InputNumber/InputNumber';

interface PriceFieldProps {
  label: string;
  currency: string;
  price: number;
  currencies: string[];
  onCurrencyChange: (currency: string) => void;
  onPriceChange: (price: number) => void;
  isDisabled?: boolean;
}

const PriceField: React.FC<PriceFieldProps> = ({
  label,
  currency,
  price,
  currencies,
  onCurrencyChange,
  onPriceChange,
  isDisabled = false,
}) => {

  const { t } = useTranslation();

  return (
    <div className="flex-1 w-full">
      { label && <p className="text-sm w-full font-extrabold">{ t(label) }:</p> }
      <div className="relative rounded-md">
        <InputNumber
            validationFn={(value) => !!value && value >= 0}
            onChange={e => onPriceChange(Number(e.target.value))}
            defaultValue={price}
            isDisabled={isDisabled}
            errorMessage={"errors.minimum_zero"}
            symbol={CONFIG.SUPPORTED_CURRENCIES_SYMBOLS[currency as keyof typeof CONFIG.SUPPORTED_CURRENCIES_SYMBOLS]}
        />
        <div className="absolute inset-y-0 right-0 flex items-center">
          <select
            value={currency}
            onChange={(e) => onCurrencyChange(e.target.value)}
            className="rounded-md border-0 bg-transparent px-1 pt-3 pb-3 w-full text-gray-500 focus:outline-none focus:ring-0 sm:text-sm"
            disabled={isDisabled}
          >
            {currencies.map((curr) => (
              <option key={curr} value={curr}>
                {curr}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default PriceField;