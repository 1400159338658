import React from 'react';
import { useTranslation } from 'react-i18next';
import Pill from '../Pill/Pill';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import TinyText from '../TinyText/TinyText';
import * as amplitude from '@amplitude/analytics-browser';

interface Plan {
  plan: string;
  name: string;
  description: string;
  features: string[];
  price: number;
  offerPrice: number;
}

interface PricingPlanProps {
  plan: Plan;
}

const PricingPlan: React.FC<PricingPlanProps> = ({ plan }) => {

  const { t } = useTranslation();

  return (
    <div className="flex flex-col justify-between bg-white p-6 rounded-lg shadow-md">
        <div>
            <h2 className="text-2xl font-bold mb-2 uppercase text-center">{t(plan.name)}</h2>
            <TinyText text={t(plan?.description) || ''} />
        </div>
        <ul className="py-3 font-light">
        {plan.features.map((feature, index) => (
            <li key={index} className="mb-1 text-justify">{t(`${plan.plan}.features.${index + 1}`)}</li>
        ))}
        </ul>
        <span>
            <Pill text={`${t("plans.offer")} -15%`} color="red" />
            <p className="text-2xl mt-1">{plan?.offerPrice}$/{t("global.month")}</p>
        </span>
        <PrimaryButton text={t("plans.purchase")!} fn={() => amplitude.track('subscription_purchase_button_clicked', { plan: plan.name, price: plan.price, offerPrice: plan.offerPrice })}/>
        <TinyText text={`* ${t("plans.limited_offer")}`} />
    </div>
  );
};

export default PricingPlan;
