import React from 'react';
import { Auth } from 'aws-amplify';
import IconButton from '../IconButton/IconButton';
import { Icon } from '../Icon/Icon';
import logoutIcon from '../../assets/images/exit.svg';
import CONFIG from '../../config/config';
import { useDispatch } from 'react-redux';
import { clearSettings } from '../../store/reducers/AccountSlice';
import { datadogRum } from '@datadog/browser-rum';

const LogoutButton: React.FC = () => {

  const dispatch = useDispatch();

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      sessionStorage.removeItem(CONFIG.LOCAL_SESSION.TOKEN);
      sessionStorage.removeItem(CONFIG.LOCAL_SESSION.ROLE_FIELD);
      sessionStorage.removeItem(CONFIG.LOCAL_SESSION.FLAGS_FIELD);
      dispatch(clearSettings());
      datadogRum.clearUser();
      window.location.reload();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <IconButton onClick={handleLogout} actionName="Login/Logout Button">
      <Icon url={logoutIcon} cssStyles="w-5"/>
    </IconButton>
  );
};

export default LogoutButton;
