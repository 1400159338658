import React, { useCallback, useState } from "react";
import StockAPI, { StockStatus } from "../../api/StockAPI";
import InputNumber from "../InputNumber/InputNumber";
import { Modal } from "../Modal/Modal";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import TextArea from "../TextArea/TextArea";
import Tooltip from "../Tooltip/Tooltip";
import waitingIcon from "../../assets/images/clock.svg";
import requestIcon from "../../assets/images/bell-alert.svg";
import { Icon } from "../Icon/Icon";
import { t } from "i18next";
import { unitsToTranslationKey } from "../../shared/unitsToTranslationKey";
import useFeatureFlag from "../../hooks/usePermissionFlag";
import debounce from "../../shared/debounce";
import { Dot } from "../Dot/Dot";

type Props = {
    reference: StockStatus,
};

function ReferenceStockLevels({ reference: ss }: Props) {

    const { hasAccess } = useFeatureFlag(['update-stock'])
    const [notesModalOpen, setNotesModalOpen] = useState(false);
    const [notes, setNotes] = useState(ss.notes);
    const [stockStatus, setStockStatus] = useState(ss);

    const updateReference = useCallback(async (updatedReference: StockStatus) => {
        if (hasAccess) {
            try {
                await StockAPI.update(updatedReference.id, updatedReference);
            } catch (error) {
                console.error('Error updating reference:', error);
            }
        }
    }, [hasAccess]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedUpdateReference = useCallback(debounce(updateReference, 500), [updateReference]);

    const handleStockStatusChange = (updatedFields: Partial<StockStatus>) => {
        const updatedStatus = { ...stockStatus, ...updatedFields };
        setStockStatus(updatedStatus);
        debouncedUpdateReference(updatedStatus);
    };

    const handleAvailabilityChange = (value: number) => {
        handleStockStatusChange({ availability: value });
    };

    const handleNotesModalConfirm = (value: string) => {
        handleStockStatusChange({ notes: value });
        setNotesModalOpen(false);
    };

    const handleOrderedNewBatchClick = () => {
        handleStockStatusChange({ orderedNewBatch: !stockStatus.orderedNewBatch });
    };

    const handleThresholdChange = (value: number) => {
        handleStockStatusChange({ alarmThreshold: value });
    };

    const handleNotesModalCancel = () => {
        setNotesModalOpen(false);
    };

    const handleElementClick = (e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation();

    return (
        <div className="w-full flex flex-col duration-500" onClick={handleElementClick}>
            <span className="flex space-x-2 items-center">
                { ss.availability < ss.alarmThreshold && <Dot /> }
                <span>{ss.name}</span>
                <Tooltip content={notes || ''}>
                    <span
                        onClick={() => setNotesModalOpen(true)}
                        className={`cursor-pointer ${notes ? 'text-yellow-500' : 'text-gray-500'}`}
                    >📝</span>
                </Tooltip>
                <span onClick={handleOrderedNewBatchClick} className={`cursor-pointer ${ss?.orderedNewBatch ? 'text-green-500' : 'text-gray-500'}`}>
                    { stockStatus?.orderedNewBatch ? 
                        <Tooltip content={t("stock.new_stock.confirm")}>
                            <Icon url={waitingIcon}/>
                        </Tooltip>
                         : 
                        <Tooltip content={t("stock.new_stock.request")}>
                            <Icon url={requestIcon}/> 
                        </Tooltip>
                        }
                </span>
            </span>
            <span className="flex space-x-2">
                <InputNumber
                    label={"stock.threshold"}
                    validationFn={(value) => !!value && value >= 0}
                    onChange={(value) => handleThresholdChange(Number(value.target.value))}
                    defaultValue={ss.alarmThreshold}
                    errorMessage={"errors.minimum_zero"}
                />
                <InputNumber
                    label={"stock.availability"}
                    validationFn={(value) => !!value && value >= 0}
                    onChange={(value) => handleAvailabilityChange(Number(value.target.value))}
                    defaultValue={ss.availability}
                    errorMessage={"errors.minimum_zero"}
                />
                <InputNumber
                    label={`${t("stock.consumed")} (${t(unitsToTranslationKey(ss.type))})`}
                    validationFn={(value) => !!value && value >= 0}
                    onChange={e => null}
                    errorMessage={"errors.minimum_zero"}
                    isDisabled
                    defaultValue={ss.total}
                />
            </span>
            {notesModalOpen && (
                <Modal
                    modalTitle={"stock.notes"}
                    canClose
                    onClose={handleNotesModalCancel}
                >
                    <>
                        <TextArea
                            label={"admin.form.notes"}
                            validationFn={(value) => value?.length >= 0 && value?.length < 50}
                            onChange={(e) => setNotes(e.target.value)}
                            errorMessage={"errors.too_short"}
                            defaultValue={notes || ''}
                        />
                        <div className="flex justify-center">
                            <PrimaryButton text={t("global.confirm")!} fn={() => handleNotesModalConfirm(notes || '')} />
                        </div>
                    </>
                </Modal>
            )}
        </div>
    )
}

export default ReferenceStockLevels;