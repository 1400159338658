import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  label?: string,
  validationFn?: (input: string) => boolean,
  onValidationChange?: (status: boolean) => void,
  onChange: (input: React.ChangeEvent<HTMLInputElement>) => void,
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void,
  errorMessage?: string,
  isDisabled?: boolean,
  placeholder?: string,
  defaultValue?: string,
  isLoading?: boolean
};

function InputShortText({label = '', validationFn, onKeyDown = () => null, onValidationChange = () => null, errorMessage = '', isDisabled = false, placeholder = '', onChange = () => null, defaultValue = '', isLoading = false}: Props) {
  
  const { t } = useTranslation();
  const [isInvalidInput, setIsInvalidInput] = useState<boolean>();
  const [localValue, setLocalValue] = useState<string>('');

  useEffect(() => {
    if (defaultValue && validationFn && !validationFn(defaultValue)) {
      setIsInvalidInput(true);
    }
  }, [defaultValue, validationFn]);

  useEffect(() => {
    setLocalValue(defaultValue);
  }, [defaultValue]);

  const displayError: string = useMemo(() => {
    return isInvalidInput ? t(errorMessage) : '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInvalidInput]);

  const onChangeWithValidation = (input: React.ChangeEvent<HTMLInputElement>) => {
    const value = input.currentTarget.value;

    if (validationFn) {
      if (validationFn(value)) {
        const isValid = validationFn(value);
        onValidationChange(isValid);
        setIsInvalidInput(false);
        setLocalValue(value);
        onChange(input);
      } else {
        setIsInvalidInput(true);
        setLocalValue(value);
      }
    } else {
      setLocalValue(value);
      onChange(input);
    }
  };

  return (
    <div className="flex-1 max-w-full">
      { label && <p className="text-sm font-extrabold">{ t(label) }:</p> }
      {isLoading ? (
        <div className="bg-gray-200 animate-pulse p-1 w-full border rounded"></div>
      ) : (
        <input 
          className={`p-3 w-full border-stone-200 border rounded focus:outline-none ${isInvalidInput ? 'border-amber-700' : null}`}
          type="text" 
          placeholder={t(placeholder) || ''} 
          onChange={(ev) => onChangeWithValidation(ev)}
          onKeyDown={onKeyDown}
          disabled={isDisabled}
          value={localValue}
        />
      )}
      <p className="text-xs text-red-600 my-0">{ displayError }</p>
    </div>
  );
}

export default InputShortText;
