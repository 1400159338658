import { MeetingType } from "./meetingTypeToTranslationKey";

export const getMeetingBorderColor = (type: MeetingType) => {
  switch (type) {
    case "VISIT":
      return "#f54758";
    case "TAKING_MEASUREMENTS":
      return "#ad7611"; // brown
    case "FIRST_MEASUREMENT":
      return "#12ad27"; // green
    case "SECOND_MEASUREMENT":
      return "#4899ad"; // blue
    case "OTHER":
      return "#bebebf"; // gray
    case "CONTRACT":
      return "#ffff5b"; // yellow
    case "RECEIVING":
      return "#ad579f"; // pink
    case "WAITING_LIST":
      return "#CFE2F3"; // blue
    case "NOTE":
      return "#bebebf"; // gray
    case "SECOND_MEASUREMENT_AND_RECEIVING":
      return "#fdeb92"; // yellow
    default:
      return "black";
  }
};

export const getMeetingColor = (type: MeetingType) => {
    switch (type) {
      case "VISIT":
        return "#FADADD"; // red
      case "TAKING_MEASUREMENTS":
        return "#ad7611"; // orange
      case "FIRST_MEASUREMENT":
        return "#b4fab5"; // green
      case "SECOND_MEASUREMENT":
        return "#b1c8fa"; // blue
      case "OTHER":
        return "#bebebf"; // gray
      case "CONTRACT":
        return "#ffff5b"; // yellow
      case "RECEIVING":
        return "#fabcf6"; // pink
      case "WAITING_LIST":
        return "#CFE2F3"; // blue
      case "NOTE":
        return "grey"; // gray
      case "SECOND_MEASUREMENT_AND_RECEIVING":
        return "#ffff5b"; // yellow
      default:
        return "black";
    }
};
