import { Collection } from '../../api/CollectionsAPI';
import IconButton from '../IconButton/IconButton';
import fullStarIcon from "../../assets/images/star-fill.svg";
import starIcon from "../../assets/images/star.svg";
import { Icon } from '../Icon/Icon';
import Tooltip from '../Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';

type Props = {
  collection: Collection,
  actions?: JSX.Element
}

function MarketingCollectionElement({ collection, actions }: Props) {

  const { t } = useTranslation();

  return (
    <div className="flex justify-between space-x-3">
      <div className="flex space-x-3 items-center">
        <Tooltip content={
            collection.featured ?
            t("admin.collections.items.featured") :
            t("admin.collections.items.not_featured")
        }>
          <IconButton>
            <Icon url={collection.featured ? fullStarIcon : starIcon } cssStyles="w-5" />
          </IconButton>
        </Tooltip>
        <p className="bolder">{collection.name}</p>
      </div>
      <span className="flex space-x-5">
        { actions }
      </span>
    </div>
  );
}

export default MarketingCollectionElement;
