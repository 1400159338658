import Button from '../Button/Button';
import * as amplitude from '@amplitude/analytics-browser';

type Props = {
  text: string | JSX.Element,
  isDisabled?: boolean,
  fn?: (a?: any) => void,
  eventName?: string,
  actionName?: string
};

function DangerPrimaryButton(
  { 
    text = '', 
    fn = () => null, 
    isDisabled = false, 
    eventName = '',
    actionName = 'Danger Button'
  }: Props) {

  const track = (e: any) => {
    if (!!eventName) amplitude.track(eventName);
    fn();
  }

  return (
    <Button 
      fn={track} 
      text={text} 
      isDisabled={isDisabled} 
      cssStyles="bg-[#e85252] hover:bg-[#b14a4a] hover:cursor-pointer text-white hover:text-black"
      actionName={actionName}
    />
  );
}

export default DangerPrimaryButton;
