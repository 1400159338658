import CONFIG from '../config/config';
import { instanceWithV2 as APIClient } from './SimpleAPIClient';

type BodyApiResponse = string[];

const ProductTypesAPI = {
  get: () => APIClient(CONFIG.API_ENDPOINT).get<BodyApiResponse>(`/v1/collections/types`)
};

export default ProductTypesAPI;
