import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  label?: string,
  validationFn?: (input: number) => boolean,
  onChange: (input: React.ChangeEvent<HTMLInputElement>) => void,
  errorMessage?: string,
  isDisabled?: boolean,
  placeholder?: string,
  defaultValue?: number
  symbol?: string
};

function InputNumber({label = '', validationFn = () => true, errorMessage = '', symbol = '', isDisabled = false, placeholder = '', onChange = () => null, defaultValue}: Props) {
  
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState(defaultValue ?? '');
  const [isInvalidInput, setIsInvalidInput] = useState<boolean>(false);

  const displayError: string = useMemo(() => {
    return isInvalidInput ? t(errorMessage) : '';
  }, [isInvalidInput, errorMessage, t]);

  const onChangeWithValidation = (input: React.ChangeEvent<HTMLInputElement>) => {
    const value = input.currentTarget.value;
    setInputValue(value);

    if (value === '' || validationFn(parseFloat(value))) {
      setIsInvalidInput(false);
      onChange(input);
    } else {
      setIsInvalidInput(true);
    }
  };

  const disableScrollChange = (event: React.WheelEvent<HTMLInputElement>) => {
    event.preventDefault();
  };

  useEffect(() => {
    setInputValue(defaultValue ?? '');
  }, [defaultValue]);

  return (
    <>
      <div className="flex-1 max-w-full">
        { label && <p className="text-sm font-extrabold">{ t(label) }:</p> }
        <div className="relative rounded-md shadow-sm">
          { symbol && 
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span className="text-gray-500 sm:text-sm">{symbol}</span>
            </div>
          }
          <input 
            className={`p-3 w-full border-stone-200 border rounded focus:outline-none ${isInvalidInput ? 'border-amber-700' : ''} ${symbol ? 'pl-7' : ''}`}
            type="text"
            placeholder={t(placeholder) || ''} 
            onChange={onChangeWithValidation}
            disabled={isDisabled}
            value={inputValue}
            onWheel={disableScrollChange}
          />
        </div>
        <p className="text-xs text-red-600 my-0">{ displayError }</p>
      </div>
    </>
  );
}

export default InputNumber;
