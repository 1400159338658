import React from 'react';

type Props = {
  text: string;
  clazz?: string;
};

const CatalogSubtitle: React.FC<Props> = ({ text, clazz = 'text-xl' }) => {
  return (
    <h2
      className={`duration-1000 ease-in-out ${clazz} md:text-2xl font-light text-center text-rose-600/[.75] tracking-normal leading-snug`}
    >
      {text}
    </h2>
  );
};

export default CatalogSubtitle;