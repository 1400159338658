import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ShopClientsAPI, { OrderComment } from "../../api/ShopClientsAPI";
import { toastService } from "../../services/ToastService";
import { PermissionFlags } from "../PermissionFlags/PermissionFlags";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import SectionTitle from "../SectionTitle/SectionTitle";
import TextArea from "../TextArea/TextArea";
import { isValidText } from "../../shared/validation";
import ListContainer from "../ListContainer/ListContainer";
import CommentCard from "../CommentCard/CommentCard";
import * as amplitude from '@amplitude/analytics-browser';

type Props = {
  orderId: number,
  isDisabled: boolean,
  onOrderChange?: (status: string) => void,
};

function OrderComments({ orderId = 0, isDisabled = false }: Props) {

  const { t } = useTranslation();

  const [comments, setComments] = useState<OrderComment[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [draftComment, setDraftComment] = useState<OrderComment | undefined>();

  const load = async () => {
    if (!!orderId) {
      setIsLoading(true);
      const storyResp = await ShopClientsAPI.getComments(orderId);
      setIsLoading(false);
      setComments(storyResp.data);
    }
  };

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const saveComment = async () => {
    if (!!orderId) {
      amplitude.track('Comment added to External Order'); 
      setIsLoading(true);
      try {
        await ShopClientsAPI.addComment(orderId, draftComment!);
        setDraftComment(undefined);
      } catch (error) {
        toastService.showToast(t("orders.story.add_step.error"), 'error');
      }
      load();
      setIsLoading(false);
    }
  }

  return ( 
    <div className="flex-1 h-full space-y-1">
      <SectionTitle text={`${t("orders.external.add_form.section.comments")!} ${comments.length > 0 ? `(${comments.length})` : ''}`} />
      { (comments && comments.length > 0) 
        && <PermissionFlags requiredFlags={['order-story-read'] || []}>
          <div className="max-h-80 py-5 px-1 overflow-y-auto space-y-3">
            { comments.map((c, index) => <CommentCard key={index} comment={c}/>)}
          </div>
        </PermissionFlags>
      }
      <PermissionFlags requiredFlags={['order-story-write'] || []}>
        { isLoading ? 
          <ListContainer isLoading={isLoading} toElementFn={() => <></>}/> 
          : 
          <>
            <TextArea
                validationFn={value => !!value && isValidText(value, 0, 1000)}
                label={t("orders.external.add_form.comment")!}
                errorMessage={"errors.too_long_short"}
                onChange={e => setDraftComment(prev => ({
                  ...prev,
                  comment: e.target.value,
                }))}
                isDisabled={isDisabled}
            />
            <PrimaryButton text={t("global.add")!} isDisabled={isDisabled || !draftComment} fn={saveComment} />
          </> 
      }
      </PermissionFlags>
    </div> 
  );
}

export default OrderComments;
