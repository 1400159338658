import React from 'react';
import { useTranslation } from 'react-i18next';
import { Reference } from '../../api/ProvidersAPI';
import CONFIG from '../../config/config';
import { useFormValidation } from '../../hooks/useFormValidation';
import { unitsToTranslationKey, UnitsType } from '../../shared/unitsToTranslationKey';
import { isValidText } from '../../shared/validation';
import FileUploadWrapper from '../FileUploadWrapper/FileUploadWrapper';
import InputShortText from '../InputShortText/InputShortText';
import PriceField from '../PriceField/PriceField';
import SelectInput from '../SelectInput/SelectInput';
import StyledImage from '../StyledImage/StyledImage';
import TagsInput from '../TagsInput/TagsInput';
import TextArea from '../TextArea/TextArea';

type Props = {
    reference: Reference,
    onReferenceFormSubmit: (value: any) => void,
    isDisabled?: boolean,
    actionButton?: JSX.Element
};

const getInitialState = (ref?: Reference) => {
    if (ref) {
      return {
        name: isValidText(ref.name, 4),
        referenceId: isValidText(ref.referenceId, 4),
      };
    }
  };

function ReferenceForm({ reference, onReferenceFormSubmit, isDisabled = false, actionButton }: Props) {
    const { t } = useTranslation();
    const requiredFields = ['name', 'referenceId'];
    const initialState = getInitialState(reference);
    const { updateFieldValidation, isFormValid } = useFormValidation(requiredFields, initialState);

    const disabledActionButton = actionButton
        ? React.cloneElement(
            actionButton,
            {},
            React.Children.map(actionButton.props.children, (child) => {
                return child ? React.cloneElement(child, { isDisabled: !isFormValid() }) : null;
            })
        )
        : null;

    return (
        <div className="flex flex-col flex-wrap">
            <div className="flex-1 space-y-5">
                <InputShortText
                    label={`${t("references.form.name")}${requiredFields.includes('name') ? '*' : ''}`}
                    validationFn={(value) => !!value && isValidText(value, 4)}
                    onChange={e => onReferenceFormSubmit({ ...reference, name: e.currentTarget.value })}
                    onValidationChange={(isValid) => updateFieldValidation('name', isValid)}
                    errorMessage={"errors.too_short"}
                    defaultValue={reference?.name || ''}
                    isDisabled={isDisabled}
                />
                <InputShortText
                    label={`${t("references.form.reference_id")}${requiredFields.includes('referenceId') ? '*' : ''}`}
                    validationFn={(value) => !!value && isValidText(value, 4)}
                    onChange={e => onReferenceFormSubmit({ ...reference, referenceId: e.currentTarget.value })}
                    onValidationChange={(isValid) => updateFieldValidation('referenceId', isValid)}
                    errorMessage={"errors.too_short"}
                    defaultValue={reference?.referenceId || ''}
                    isDisabled={isDisabled}
                />
                <div className="flex space-x-3">
                    <PriceField 
                        label={"admin.collections.items.form.cost"}
                        currency={reference?.currency || 'EUR'}
                        price={reference?.price || 0}
                        currencies={[''].concat(CONFIG.SUPPORTED_CURRENCIES)}
                        onCurrencyChange={e => onReferenceFormSubmit({ ...reference, currency: e })}
                        onPriceChange={value => onReferenceFormSubmit({ ...reference, price: value, currency: reference?.currency || 'EUR' })}
                        isDisabled={isDisabled}
                    />
                    <SelectInput 
                        values={CONFIG.PRODUCTION_PLAN.MATERIALS.TYPES.map(e => { return { value: e, readableValue: t(unitsToTranslationKey(e as UnitsType)) } })}
                        onChange={e => onReferenceFormSubmit({ ...reference, type: e.currentTarget.value })}
                        label={"references.form.type"}
                        defaultValue={reference?.type || 'OTHER'}
                        isDisabled={isDisabled}
                    />
                </div>
                <TagsInput
                    label={"references.form.tags"}
                    onChange={tags => onReferenceFormSubmit({ ...reference, tags })}
                    tags={reference.tags || []}
                    isDisabled={isDisabled}
                />
                <TextArea
                    label={"references.form.notes"}
                    validationFn={(value) => !value || (!!value &&  isValidText(value, 0, 1024))}
                    onChange={e => onReferenceFormSubmit({ ...reference, notes: e.currentTarget.value })}
                    errorMessage={"errors.too_short"}
                    defaultValue={reference?.notes || ''}
                    isDisabled={isDisabled}
                />
                <FileUploadWrapper
                    label={`${t("references.form.image")}.`} 
                    onChange={file => onReferenceFormSubmit({ ...reference, file: file })}
                    allowedTypes={CONFIG.IMAGE_TYPES}
                    isDisabled={isDisabled}

                />
                { !!reference.pictureUrl && 
                    <div className="mt-5 mb-5">
                        <StyledImage alt="Reference" src={reference.pictureUrl} />
                    </div>
                }
            </div>
            {disabledActionButton}
        </div>
    );
}

export default ReferenceForm;
