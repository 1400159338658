import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InputNumber from "../../../../components/InputNumber/InputNumber";
import InputShortText from "../../../../components/InputShortText/InputShortText";
import Notice from "../../../../components/Notice/Notice";
import SelectInput from "../../../../components/SelectInput/SelectInput";
import SmallCheckBox from "../../../../components/SmallCheckBox/SmallCheckBox";
import Subtitle from "../../../../components/Subtitle/Subtitle";
import TextArea from "../../../../components/TextArea/TextArea";
import TinyText from "../../../../components/TinyText/TinyText";
import CONFIG from "../../../../config/config";
import { isValidText } from "../../../../shared/validation";
import { UpdateCallback } from "../../sections/NotificationsSegment";

interface VisitReminderProps {
  update: UpdateCallback;
  defaultConfig?: VisitReminderConfig;
}

interface ReminderDetails {
  phoneId?: string;
  emailTitle?: string;
  text?: string;
  daysInAdvance?: number;
}

export interface VisitReminderConfig {
    enabled: boolean;
    type: 'sms' | 'email';
    details?: ReminderDetails;
}

export function VisitReminder({ update, defaultConfig }: VisitReminderProps) {
  const [enabled, setEnabled] = useState(defaultConfig?.enabled ?? false);
  const [type, setType] = useState(defaultConfig?.type ?? 'sms');
  const [details, setDetails] = useState<ReminderDetails>(defaultConfig?.details || {});
  const { t } = useTranslation();

  useEffect(() => {
    if (defaultConfig) {
      setEnabled(defaultConfig.enabled || false);
      setType(defaultConfig.type || 'sms');
      setDetails(defaultConfig.details || {});
    }
  }, [defaultConfig]);

  useEffect(() => {
    const config: VisitReminderConfig = {
      enabled,
      type,
      details
    };
    update('smsReminder', config);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, type, details]);

  return (
    <>
      <Subtitle text={t("notifications.visit_reminder")} />
      <TinyText text={t("notifications.visit_reminder.description")} />
      <SmallCheckBox
        label={t("global.enabled")!}
        onChange={() => setEnabled(prev => !prev)}
        defaultValue={enabled}
      />
      <br/>
      <InputNumber
          label={t("notifications.visit_reminder.days_in_advance")!}
          validationFn={(value) => !!value && value >= 1}
          onChange={e => setDetails({ ...details, daysInAdvance: Number(e.currentTarget.value) })}
          defaultValue={details.daysInAdvance || 2}
          errorMessage={"errors.minimum_zero"}
      />
      <SelectInput
        values={CONFIG.NOTIFICATION_CHANNELS.map(e => ({ value: e, readableValue: e }))}
        onChange={(e: any) => setType(e.target.value)}
        label={t("notifications.visit_reminder.channel")!}
        defaultValue={type}
      />
      <InputShortText
        label={type === 'sms' ? t("notifications.visit_reminder.phone_id")! : t("notifications.visit_reminder.email_title")!}
        validationFn={(value) => isValidText(value, 1, type === 'sms' ? 7 : 50)}
        onChange={(e: any) => setDetails({ ...details, [type === 'sms' ? 'phoneId' : 'emailTitle']: e.target.value })}
        errorMessage={"errors.too_long"}
        defaultValue={type === 'sms' ? details.phoneId : details.emailTitle}
      />
      <Notice 
        pillText={t("global.notice")} 
        tinyText={t("notifications.visit_reminder.message.keywords")}
      />
      <TextArea
        validationFn={value => isValidText(value, 5, type === 'sms' ? 200 : 1000)}
        label={t("notifications.visit_reminder.message")!}
        onChange={e => setDetails({ ...details, text: e.currentTarget.value })}
        defaultValue={details.text}
      />
    </>
  );
}
