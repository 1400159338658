type Props = {
  text: string
};

function HeaderTenantTitle({text}: Props) {
  return <h1 className={
    `duration-1000 ease-in-out md:text-4xl font-extralight text-left text-slate-700/[.85] font-thin tracking-tighter`
  } >{text}</h1>;
};

export default HeaderTenantTitle;