import { Auth } from "aws-amplify";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoadingScreen } from "../components/LoadingScreen/LoadingScreen";
import { useConfiguration } from "../hooks/useConfiguration";
import { updateSettings } from "../store/reducers/AccountSlice";
import CONFIG from "./config";

type Props = {
    textOnExpanded?: string,
    textOnHiden?: string,
    children: JSX.Element
  };

export const ProtectedRoute = ({ children }: Props) => {
  const jwtToken = sessionStorage.getItem(CONFIG.LOCAL_SESSION.TOKEN);
  const isLoading = useConfiguration(jwtToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        sessionStorage.setItem(CONFIG.LOCAL_SESSION.TOKEN, user.signInUserSession.accessToken.jwtToken);
        dispatch(updateSettings({ isLoggedIn: true }));
      })
      .catch(() => {
        sessionStorage.removeItem(CONFIG.LOCAL_SESSION.TOKEN);
        sessionStorage.removeItem(CONFIG.LOCAL_SESSION.ROLE_FIELD);
        sessionStorage.removeItem(CONFIG.LOCAL_SESSION.FLAGS_FIELD);
        dispatch(updateSettings({ isLoggedIn: false }));
        navigate('/login');
      });
  }, [dispatch, navigate]);

  if (isLoading) {
    return <LoadingScreen />;
  }
  
  return children;
};