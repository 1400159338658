import PricingPlans from "../../components/PricingPlans/PricingPlans";
import { useTranslation } from "react-i18next";
import Banner from "../../components/Banner/Banner";

function PlanPage() {

  const { t } = useTranslation();

  return (
    <>
      <br/>
      <Banner
        title={t("plans.mission.title")}
        description={t("plans.mission.body")}
        primaryAction={{ label: t("plans.purchase"), onClick: () => null }}
        secondaryAction={{ label: t("plans.demo_cta"), onClick: () => window.open('https://calendly.com/eateliers', "_blank") }}
      />
      <br/>
      <PricingPlans />
    </>
  );
}

export default PlanPage;
