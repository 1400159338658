type Props = {
  text: string
  clazz?: string,
  cssStyles?: string,
};

function SectionTitle({text, clazz = 'md:text-2xl', cssStyles = ''}: Props) {
  return <h3 className={`duration-1000 ease-out font-extralight ${clazz} ${cssStyles}`}>{text}</h3>;
};

export default SectionTitle;