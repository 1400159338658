import axios from 'axios';
import CONFIG from '../config/config';
import { v4 as uuidv4 } from 'uuid';
import { Auth } from 'aws-amplify';
import { modalService } from '../services/ModalService';
import { refreshToken } from '../shared/authUtils';

const performLogout = async () => {
  try {
    await Auth.signOut();
    sessionStorage.removeItem(CONFIG.LOCAL_SESSION.TOKEN);
    sessionStorage.removeItem(CONFIG.LOCAL_SESSION.ROLE_FIELD);
    sessionStorage.removeItem(CONFIG.LOCAL_SESSION.FLAGS_FIELD);
    window.location.reload();
  } catch (error) {
    console.error('Error signing out:', error);
  }
};

const headers = {
  // "Authorization": "Bearer"
};

const successHandler = (response) => response;
const errorHandler = (error) => {
  console.log(error);
  return Promise.reject(error);
};

const CancelToken = axios.CancelToken;

const instance = axios.create({
  baseURL: CONFIG.API_ENDPOINT,
  headers: headers,
});

instance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error),
);

instance.interceptors.request.use((config) => {
  const storedSettings = sessionStorage.getItem(CONFIG.LOCAL_SESSION.ROLE_FIELD);
  const parsedSettings = storedSettings ? storedSettings : null;

  if (parsedSettings) {
    config.headers.authorization = `Bearer ${parsedSettings}-ok`;
  }

  return config;
});

instance.interceptors.request.use(request => {
  request.headers['X-Correlation-Id'] = uuidv4();
  return request;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { config, response } = error;
    const id = config.headers['X-Correlation-Id']; // is this one or from response?
    if (config && config.url && response) {
      modalService.showModal("global.error.instructions", id, response.data.error);
    }
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const refreshSuccessful = await refreshToken();
      if (refreshSuccessful) {
        const newToken = sessionStorage.getItem(CONFIG.LOCAL_SESSION.TOKEN);
        axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
        originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
        return axios(originalRequest);
      } else {
        await performLogout();
      }
    }

    return Promise.reject(error);
  }
);

const instanceWith = (url) => {
  const instance = axios.create({
      baseURL: url,
      headers: headers,
    });

    instance.interceptors.request.use(async (config) => {
      let token;
      try {
        const session = await Auth.currentSession();
        token = session.idToken.jwtToken;
      } catch (error) {
        console.log('error getting session', error);
      }
    
      if (token) {
        config.headers.authorization = `Bearer ${token}`;
      }

      return config;
    });
  instance.interceptors.request.use(request => {
    request.headers['X-Correlation-Id'] = uuidv4();
    return request;
  });
  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const { config, response } = error;
      const id = config.headers['X-Correlation-Id']; // is this one or from response?
      if (config && config.url && response) {
        modalService.showModal("global.error.instructions", id, response.data.error);
      }
      return Promise.reject(error);
    }
  );
  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
  
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
  
        const refreshSuccessful = await refreshToken();
        if (refreshSuccessful) {
          const newToken = sessionStorage.getItem(CONFIG.LOCAL_SESSION.TOKEN);
          axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
          originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
          return axios(originalRequest);
        } else {
          await performLogout();
        }
      }
  
      return Promise.reject(error);
    }
  );
  return instance;
};

const instanceWithV2 = (url) => {
  const instance = axios.create({
    baseURL: url,
    headers: headers,
  });

  instance.interceptors.request.use(async (config) => {
    let token;
    try {
      const session = await Auth.currentSession();
      token = session.idToken.jwtToken;
    } catch (error) {
      console.log('error getting session', error);
    }
  
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }

    return config;
  });
  instance.interceptors.request.use(request => {
    request.headers['X-Correlation-Id'] = uuidv4();
    return request;
  });
  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const { config, response } = error;
      const id = config.headers['X-Correlation-Id']; // is this one or from response?
      if (config && config.url && response) {
        modalService.showModal("global.error.instructions", id, response.data.error);
      }
      return Promise.reject(error);
    }
  );
  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
  
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
  
        const refreshSuccessful = await refreshToken();
        if (refreshSuccessful) {
          const newToken = sessionStorage.getItem(CONFIG.LOCAL_SESSION.TOKEN);
          axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
          originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
          return axios(originalRequest);
        } else {
          await performLogout();
        }
      }
  
      return Promise.reject(error);
    }
  );
  return instance;
};

export { instance, CancelToken, instanceWith, instanceWithV2 };
