import { useTranslation } from 'react-i18next';
import PageWrapper from '../PageWrapper/PageWrapper';
import Card from '../../components/Card/Card';
import Subtitle from '../../components/Subtitle/Subtitle';
import { AdminRestricted } from '../../components/AdminRestricted/AdminRestricted';
import ActiveOrdersGroupedByStatus from './graphs/ActiveOrdersGroupedByStatus';
import TotalOrdersPerYear from './graphs/TotalOrdersPerYear';
import OrdersMonthlyGroupedByYear from './graphs/OrdersMonthlyGroupedByYear';
import SalesByShopGroupedByYear from './graphs/SalesByShopGroupedByYear';
import OrdersMonthlyByShopGroupedByYear from './graphs/OrdersMonthlyByShopGroupedByYear';
import SalesByDressGroupedByYear from './graphs/SalesByDressGroupedByYear';

function OrdersStatisticsPage() {

  const { t } = useTranslation();

  return (
    <PageWrapper>
      <AdminRestricted>
      <>
        <Card>
          <ActiveOrdersGroupedByStatus />
        </Card>
        <div className="grid md:grid-cols-3 gap-4 mt-5 mb-5">
          <div className="col-span-1">
            <Card>
              <>
                <TotalOrdersPerYear />
                <br/>
                <OrdersMonthlyGroupedByYear />
              </>
            </Card>
          </div>
          <div className="col-span-2">
            <Card>
              <SalesByShopGroupedByYear />
            </Card>
          </div>
        </div>
        <Card>
          <OrdersMonthlyByShopGroupedByYear />
        </Card>
        <br/>

        <Subtitle text={t("stats.dresses")} />
        <Card>
          <SalesByDressGroupedByYear />
        </Card>
        <br/>
      </>
      </AdminRestricted>
    </PageWrapper>
  );
}

export default OrdersStatisticsPage;
