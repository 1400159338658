export type UnitsType = 'UNITS' | 'PAIRS' | 'METERS' | 'OTHER';

export const unitsArray: string[] = [
    'UNITS',
    'PAIRS',
    'METERS',
    'OTHER',
];
  

export function unitsToTranslationKey(type: UnitsType): string {
  switch (type) {
    case 'UNITS' as UnitsType:
      return "references.type.units";
    case 'PAIRS' as UnitsType:
        return "references.type.pairs";
    case 'METERS' as UnitsType:
        return "references.type.meters";
    default:
      return "references.type.other";
  }
}