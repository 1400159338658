import { t } from "i18next";
import { useEffect } from "react";
import { useState } from "react";
import NotificationsAPI, { Alerts } from "../../../api/AlertsAPI";
import AlertEmailField from "../../../components/AlertEmailField/AlertEmailField";
import Card from "../../../components/Card/Card";
import PrimaryButton from "../../../components/PrimaryButton/PrimaryButton";
import Subtitle from "../../../components/Subtitle/Subtitle";

function AlertsSegment() {

  const [isLoading, setIsLoading] = useState(false);
  const [alertsData, setAlertsData] = useState<Alerts>({
    orderAdded: { enabled: false, emails: [] },
    removalMeeting: { enabled: false, emails: [] },
    contractSigned: { enabled: false, emails: [] },
    backupOrders: { enabled: false, emails: [] },
    backupCalendar: { enabled: false, emails: [] },
    lowStock: { enabled: false, emails: [] },
    commentsOnOrder: { enabled: false, emails: [] },
    catalogImported: { enabled: false, emails: [] }
  });

  useEffect(() => {
    setIsLoading(true);
    const loadInitialData = async () => {
      try {
        const fetched = await NotificationsAPI.get();
        setAlertsData(fetched.data);
      } catch (error) {
        console.error("Failed to fetch initial reminders data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    loadInitialData();
  }, []); 

  const updateAlertData = (alertName: string, data: { enabled: boolean, emails: string[] }) => {
    setAlertsData(prev => ({ ...prev, [alertName]: data }));
  };

  const saveAllAlerts = async () => {
    setIsLoading(true);
    try {
      await NotificationsAPI.update(alertsData);
    } catch (error) {
      console.error("Failed to update reminders:", error);
    }
    setIsLoading(false);
  };

  return isLoading ? <p>...</p> : (
    <>
      <Card>
          <>
            <Subtitle text={t("settings.menu.alerts")} />
            <AlertEmailField
              isDisabled={isLoading}
              label={t("alerts.emails")!}
              title={t("alerts.option.removal_meeting")}
              initialEmails={alertsData.removalMeeting?.emails || []}
              initialIsEnabled={alertsData.removalMeeting?.enabled}
              onUpdate={data => updateAlertData('removalMeeting', data)}
            />
            <br/>
            <AlertEmailField
              isDisabled={isLoading}
              label={t("alerts.emails")!}
              title={t("alerts.option.order_added")}
              initialEmails={alertsData.orderAdded?.emails || []}
              initialIsEnabled={alertsData.orderAdded?.enabled}
              onUpdate={data => updateAlertData('orderAdded', data)}
            />
            <br/>
            <AlertEmailField
              isDisabled={isLoading}
              label={t("alerts.emails")!}
              title={t("alerts.option.contract_signed")}
              initialEmails={alertsData.contractSigned?.emails || []}
              initialIsEnabled={alertsData.contractSigned?.enabled}
              onUpdate={data => updateAlertData('contractSigned', data)}
            />
            <br/>
            <AlertEmailField
              isDisabled={isLoading}
              label={t("alerts.emails")!}
              title={t("alerts.option.low_stock")}
              initialEmails={alertsData.lowStock?.emails || []}
              initialIsEnabled={alertsData.lowStock?.enabled}
              onUpdate={data => updateAlertData('lowStock', data)}
            />
            <br/>
            <AlertEmailField
              isDisabled={isLoading}
              label={t("alerts.emails")!}
              title={t("alerts.option.comments_on_order")}
              initialEmails={alertsData.commentsOnOrder?.emails || []}
              initialIsEnabled={alertsData.commentsOnOrder?.enabled}
              onUpdate={data => updateAlertData('commentsOnOrder', data)}
            />
            <br/>
            <AlertEmailField
              isDisabled={isLoading}
              label={t("alerts.emails")!}
              title={t("alerts.option.catalog_imported")}
              initialEmails={alertsData.catalogImported?.emails || []}
              initialIsEnabled={alertsData.catalogImported?.enabled}
              onUpdate={data => updateAlertData('catalogImported', data)}
            />
          {/* <Divider />
          <Subtitle text={t("alerts.backups")} />
          <AlertEmailField
              isDisabled
              title={t("alerts.option.backup_orders")}
              label={t("alerts.emails")!}
              initialEmails={alertsData.backupOrders?.emails || []}
              initialIsEnabled={alertsData.backupOrders?.enabled}
              onUpdate={data => updateAlertData('backupOrders', data)}
            />
            <br/>
          <AlertEmailField
              isDisabled
              label={t("alerts.emails")!}
              title={t("alerts.option.backup_calendar")}
              initialEmails={alertsData.backupCalendar?.emails || []}
              initialIsEnabled={alertsData.backupCalendar?.enabled}
              onUpdate={data => updateAlertData('backupCalendar', data)}
            /> */}
          <br/>
          <div className="container flex flex-wrap space-x-5">
            <PrimaryButton text={t("global.save")!} fn={saveAllAlerts} isDisabled={isLoading} />
          </div>
        </>
      </Card>
    </>
  );
}

export default AlertsSegment;
