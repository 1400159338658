import { useMemo } from 'react';
import { Reference } from '../../api/ProvidersAPI';
import imageIcon from '../../assets/images/add-image.svg';
import { Icon } from '../Icon/Icon';
import Pill from '../Pill/Pill';

type Props = {
  reference: Reference,
  actions?: JSX.Element
}

function ReferenceElement({ reference, actions }: Props) {
  
  const tags = useMemo(() => reference.tags.length > 0 ?
  // eslint-disable-next-line react-hooks/exhaustive-deps
  `(${reference.tags.map(t => '#' + t).join(', ')})` : null, [reference.tags.length]);

  return (
    <>
      <div className="flex space-x-3">
        { !!reference.pictureUrl && <Icon url={imageIcon} /> }
        <p>[{ reference.referenceId}]</p>
        <p>{ reference.name}</p>
        { reference.price ? <Pill color='blue' text={`${reference.price} ${reference.currency}`}/> : null }
        <p>{ tags }</p>
      </div>
      <span className="flex">
        { actions }
      </span>
    </>
  );
}

export default ReferenceElement;
