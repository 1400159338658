import { useTranslation } from "react-i18next";
import { useState } from "react";
import ListContainer from "../../../../components/ListContainer/ListContainer";
import ListElement from "../../../../components/ListElement/ListElement";
import IconButton from "../../../../components/IconButton/IconButton";
import { Icon } from "../../../../components/Icon/Icon";
import deleteIcon from "../../../../assets/images/bin.svg";
import edit from "../../../../assets/images/pen.svg";
import { Modal } from "../../../../components/Modal/Modal";
import ElementActions from "../../../../components/ElementActions/ElementActions";
import { useGetEmployees } from "../../../../hooks/useGetEmployees";
import { Employee } from "../../../../api/EmployeesAPI";
import EmployeeElement from "../../../../components/EmployeeElement/EmployeeElement";
import EmployeeForm from "../../../../components/EmployeeForm/EmployeeForm";
import PrimaryButton from "../../../../components/PrimaryButton/PrimaryButton";
import { FormType } from "../../../../shared/form.type";
import ConfirmationPopup from "../../../../components/ConfirmationPopup/ConfirmationPopup";
import Card from "../../../../components/Card/Card";
import { PermissionFlags } from "../../../../components/PermissionFlags/PermissionFlags";
import Counter from "../../../../components/Counter/Counter";
import NoDataCTA from "../../../../components/NoDataCTA/NoDataCTA";
import PageWrapper from "../../../PageWrapper/PageWrapper";

const emptyEmployee: Employee = {
  name: '',
  active: true,
  flags: [],
  note: ''
}

function EmployeesSection() {

  const { t } = useTranslation();

  const [action, setAction] = useState<FormType>('READ');
  const [employee, setEmployee] = useState<Employee | null>();
  const { isLoadingEmployees, employees, addFn, removeFn, updateFn } = useGetEmployees();

  const actionButton = (action: FormType) => {
    switch (action) {
      case 'CREATE':
        return <span className="flex justify-center"><PrimaryButton text={t("global.create")!} fn={() => registerAndClose(employee!)} /></span>;
      case 'UPDATE':
        return <span className="flex justify-center"><PrimaryButton text={t("global.update")!} fn={() => registerAndClose(employee!)} /></span>;
      default:
        return;
    }
  };

  const registerAndClose = async (e: Employee) => {
    if (!!e) {
      if (action === 'CREATE') {
        addFn(e);
      } else {
        updateFn(e);
      }
      setEmployee(null);
    }
  }

  const viewEmployeeDetails = (e: Employee) => {
    setAction('READ');
    setEmployee(e);
  }

  const showNewEmployee = () => {
    setAction('CREATE');
    setEmployee(emptyEmployee);
  }

  const editEmployee = (e: Employee) => {
    setAction('UPDATE');
    setEmployee(e);
  }

  const remove = (e: Employee) => {
    if (!!e) {
      removeFn(e);
      setEmployee(null);
    }
  }

  return (
    <PageWrapper>
      <>
        <PermissionFlags requiredFlags={['see-employees'] || []}>
          <>
            <div className="container flex flex-col md:flex-row my-3 items-stretch space-x-0 md:space-x-5">
              <Card>
                <>
                  <div className="container flex flex-wrap space-x-5 my-3">
                    <PermissionFlags requiredFlags={['create-employees'] || []}>
                      <PrimaryButton text={t("admin.employee.form.new_employee.button")!} fn={showNewEmployee}/>
                    </PermissionFlags>
                  </div>
                </>
              </Card>
              <Counter data={employees.length} label={t("admin.employee.count")}  />
            </div>
            <Card>
              <>
                <ListContainer 
                    isLoading={isLoadingEmployees}
                    elements={employees}
                    emptyListComponent={
                      <NoDataCTA
                        headline={t("admin.employee.empty.cta.title")}
                        subtext={t("admin.employee.empty.cta.body")}
                        primaryActionText={t("admin.employee.form.new_employee.button")}
                        onPrimaryAction={showNewEmployee}
                      />
                    }
                    toElementFn={(em: Employee) => 
                      <ListElement onClick={() => viewEmployeeDetails(em)} key={em.id} css="bg-white text-black hover:bg-darker-gold-100">
                        <ElementActions actions={
                          <>
                            <PermissionFlags requiredFlags={['update-employees'] || []}>
                              <IconButton onClick={() => editEmployee(em)}>
                                <Icon url={edit} cssStyles="w-5"/>
                              </IconButton>
                            </PermissionFlags>
                            <PermissionFlags requiredFlags={['remove-employees'] || []}>
                              <IconButton>
                                <ConfirmationPopup onConfirm={() => remove(em)} confirmText={"global.confirm"} title={`${t("global.remove")}: ${em.name}`}>
                                  <Icon url={deleteIcon} cssStyles="w-5"/>
                                </ConfirmationPopup>
                              </IconButton>
                            </PermissionFlags>
                          </>
                        }>
                          <EmployeeElement employee={em} />
                        </ElementActions>
                      </ListElement>
                    }
                  />
              </>
            </Card>
            { employee && 
              <Modal modalTitle={employee.name || "admin.employee.form.new_employee"} canClose onClose={() => setEmployee(null)}>
                <EmployeeForm employee={employee} onEmployeeForm={setEmployee} actionButton={actionButton(action)} isDisabled={action === 'READ'}/>
              </Modal>
            }
          </>
        </PermissionFlags>
      </>
    </PageWrapper>
  );
}

export default EmployeesSection;
