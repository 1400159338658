import React from 'react';

interface PlainTextButtonProps {
  text: string;
  onClick: () => void;
  disabled?: boolean;
  actionName?: string
}

const PlainTextButton: React.FC<PlainTextButtonProps> = ({ text, onClick, disabled = false, actionName = 'Plain Text Button' }) => {
  return (
    <button
      onClick={(e) => { e.stopPropagation(); onClick(); }} 
      disabled={disabled}
      className={`font-light transition duration-300 underline
        ${disabled ? 'cursor-default' : 'hover:text-blue-700 focus:outline-none'}`}
      data-dd-action-name={actionName}
    >
      {text}
    </button>
  );
};

export default PlainTextButton;