import { useCallback, useEffect, useState } from "react";
import OrdersAPI, { ExternalOrder, isExternalOrder, isInternalOrder, Order } from "../api/OrdersAPI";
import { useTranslation } from "react-i18next";
import { CancelToken } from "../api/SimpleAPIClient";
import { toastService } from "../services/ToastService";
import { useImageUploader } from "./useImageUploader";

export enum SORT { DELIVERY_ASC };

export type Filters = {
  date?: string;
  shopId?: string;
  hidden?: boolean;
  external?: boolean;
}

type ReturnedProps = {
  isLoadingOrders: boolean,
  orders: Order[],
  addFn: (order: Order) => Promise<any>,
  updateFn: (order: Order | ExternalOrder) => Promise<any>,
  removeFn: (order: Order | ExternalOrder) => Promise<any>,
  refresh: () => Promise<void>,
  updateOrderStatus: (order: Order) => void,
  externalOrders: any[],
};

export function useGetOrders({ date, shopId, hidden, external }: Filters): ReturnedProps {
  
  const { t } = useTranslation();
  const [orders, setOrders] = useState<Order[]>([]);
  const [isLoadingOrders, setIsLoading] = useState(false);
  const [externalOrders, setExternalOrders] = useState<ExternalOrder[]>([]);
  const { uploadImage } = useImageUploader();

  const refresh = useCallback(async () => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, shopId, hidden, external]);

  const addFn = async (order: Order) => {
    try {
      const resp = await OrdersAPI.add(order.shopIdV2!, order);
      setOrders(prevOrders => [...prevOrders, resp.data]);
    } catch (err) {
      toastService.showToast(t("toast.errors.orders.add"), 'error');
    }
  };

  const uploadFileAndGetUrl = async (file: File) => {
    try {
      const response = await uploadImage(file, 'files/orders/uploadV2');
      return response;
    } catch (error) {
      return '';
    }
  };

  const uploadFiles = async (files: File[]): Promise<string[]> => {
    const attachmentUrls = await Promise.all(files.map(async file => {
      try {
        const fullUrl = await uploadFileAndGetUrl(file);
        if (fullUrl && fullUrl.length > 50) {
          // it's been uploaded as 'HeavyFile' and contains the entire S3 url
          const url = new URL(fullUrl);
          const filename = url.pathname.split('/').pop();
          return filename;
        } else {
          return fullUrl;
        }
      } catch (err) {
        toastService.showToast(`${t("toast.errors.add_file")}: ${file.name}`, 'error');
        return '';
      }
    }));
    return attachmentUrls.filter((url): url is string => !!url);
  };

  const updateOrder = async (order: Order | ExternalOrder, isInternal: boolean) => {
    if (isInternal) {
      let internal = order as Order;
      try {
        const resp = await OrdersAPI.update(internal.shopIdV2!, internal.id!, internal);
        updateOrderStatus(resp.data);
      } catch (err) {
        toastService.showToast(t("toast.errors.orders.update"), 'error');
      }
    } else {
      let external = order as ExternalOrder;
      try {
        const resp = await OrdersAPI.updateExternal(external.id!, external);
        updateOrderStatus(resp.data);
      } catch (err) {
        toastService.showToast(t("toast.errors.orders.update"), 'error');
      }
    }
  };

  const updateFn = async (order: Order | ExternalOrder) => {
    setIsLoading(true);
    try {
      if (order.files) {
        const filteredUrls = await uploadFiles(order.files);
        if (order.attachmentUrls) {
          order.attachmentUrls = [...order.attachmentUrls, ...filteredUrls];
        } else {
          order.attachmentUrls = filteredUrls;
        }
      }
      if (isInternalOrder(order)) {
        await updateOrder(order, true);
      } else if (isExternalOrder(order)) {
        await updateOrder(order, false);
      }
    } catch (err) {
      toastService.showToast(t("toast.errors.orders.update"), 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const removeFn = async (order: Order | ExternalOrder) => {
    try {
      if (isInternalOrder(order)) {
        await OrdersAPI.delete(order.shopIdV2!, order.id!);
        setOrders(prevOrders => prevOrders.filter(or => or.id !== order.id));
      } else if (isExternalOrder(order)) {
        await OrdersAPI.deleteExternal(order.id!);
        load();
      }
    } catch (err) {
      toastService.showToast(t("toast.errors.orders.remove"), 'error');
    }
  };

  const updateOrderStatus = (updatedOrder: Order | ExternalOrder) => {
    if (isInternalOrder(updatedOrder)) {
      setOrders(prevOrders => prevOrders.map(or => or.id === updatedOrder.id! ? updatedOrder : or));
    } else {
      setExternalOrders(prevOrders => prevOrders.map(or => or.id === updatedOrder.id! ? updatedOrder : or));
    }
  };

  const load = () => {
    const asyncLoad = async () => {
      setIsLoading(true);
      try {
        if (external) {
          const response = await OrdersAPI.getExternalOrders(date, shopId);
          const ordersRaw = response.data;
          const orderedByDate = ordersRaw.sort((a: ExternalOrder, b: ExternalOrder) => new Date(a.inStoreDate).getTime() - new Date(b.inStoreDate).getTime());
          let filteredOrders = orderedByDate;
          setExternalOrders(filteredOrders);
        } else {
          const ordersResp = await OrdersAPI.get(date, shopId, hidden);
          const ordersRaw = ordersResp.data;
          const orderedByDate = ordersRaw.sort((a: Order, b: Order) => new Date(a.deliveryDate).getTime() - new Date(b.deliveryDate).getTime());
          let filteredOrders = orderedByDate;
          setOrders(filteredOrders);
        }
      } catch (err) {
        toastService.showToast(t("toast.errors.orders.load"), 'error');
      } finally {
        setIsLoading(false);
      }
    };
    asyncLoad();
    return CancelToken.source().cancel("Cancelling...");
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(load, [date, shopId, hidden, external]);

  return { isLoadingOrders, orders, externalOrders, addFn, updateFn, removeFn, refresh, updateOrderStatus };
};