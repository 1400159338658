import CONFIG from '../config/config';
import { instanceWithV2 as APIClient } from './SimpleAPIClient';

export type TabletConfig = {
  pin: string;
  catalogues: string[];
};

export type Config = {
  tenantId?: string,
  brandName?: string,
  domain?: string,
  logoUrl?: string,
  websiteUrl?: string,
  timezone?: string,
  openingHours?: string,
  closingHours?: string,
  country?: string,
  shopId?: string,

  taxId?: string,
  companyName?: string,
  companyAddress?: string,
  companyPostalCode?: string,
  bankAccount?: string,

  deliveryAddress?: string,
  deliveryPostalCode?: string,

  currency?: string,

  primaryColor?: string,
  backgroundColor?: string,
}

const ConfigAPI = {
  get: () => APIClient(CONFIG.API_ENDPOINT).get<Config>(`v1/configuration`),
  getTablet: () => APIClient(CONFIG.API_ENDPOINT).get<TabletConfig>(`v1/configuration/tablet`),
  update: (value: Config) => APIClient(CONFIG.API_ENDPOINT).put<Config>(`v1/configuration`, value),
  updateTablet: (value: TabletConfig) => APIClient(CONFIG.API_ENDPOINT).put<Config>(`v1/configuration/tablet`, value),
};

export default ConfigAPI;
