import CONFIG from '../config/config';
import { instanceWithV2 as APIClient } from './SimpleAPIClient';

export type StatisticsApiResponse = {
  [collectionName: string]: { [modelName: string]: number };
};

export type CustomerVisit = {
  appVersion: string,
  collectionId: number,
  name: string,
  createdDate: number,
  dateOfWedding: string,
  dressesName: string,
  id: number,
  region: string,
  shopId: string,
  surname: string
}

type VisitorListApiResponse = CustomerVisit[]

const StatisticsAPI = {
  getMostPopularDresses: () =>
    APIClient(CONFIG.API_ENDPOINT).get<StatisticsApiResponse>(
      '/v1/statistics/customers/summary/mostPopularDresses'
    ),
  getWeddingMonthSummary: () =>
    APIClient(CONFIG.API_ENDPOINT).get<StatisticsApiResponse>(
      '/v1/statistics/customers/summary/weddingMonth'
  ),
  getByShops: () =>
    APIClient(CONFIG.API_ENDPOINT).get<StatisticsApiResponse>(
    '/v1/statistics/customers/summary/total'
  ),
  getVisitors: () =>
    APIClient(CONFIG.API_ENDPOINT).get<StatisticsApiResponse>(
    '/v1/statistics/customers/summary/noOfVisitors'
  ),
  getVisitorsList: (startTime: string, endTime: string) =>
    APIClient(CONFIG.API_ENDPOINT).get<VisitorListApiResponse>(
    `/v1/customers?startTime=${startTime}&endTime=${endTime}`
  ),
  deleteVisit: (visitId: number) =>
    APIClient(CONFIG.API_ENDPOINT).delete<VisitorListApiResponse>(
    `/v1/statistics?id=${visitId}`
  ),
  getTotalOrdersPerYear: () =>
    APIClient(CONFIG.API_ENDPOINT).get<StatisticsApiResponse>(
      '/v1/statistics/orders/summary/total-per-year'
    ),
  getMonthlyOrdersGroupedByYear: () =>
    APIClient(CONFIG.API_ENDPOINT).get<StatisticsApiResponse>(
      '/v1/statistics/orders/summary/monthly-grouped-by-year'
  ),
  getMonthlyOrdersByShopGroupedByYear: () =>
    APIClient(CONFIG.API_ENDPOINT).get<StatisticsApiResponse>(
      `/v1/statistics/orders/summary/monthly-by-shop-grouped-by-year`
  ),
  getTotalSalesByShopGroupedByYear: () =>
    APIClient(CONFIG.API_ENDPOINT).get<StatisticsApiResponse>(
      `/v1/statistics/orders/summary/total-sales-by-shop-grouped-by-year`
  ),
  getActiveOrdersGroupedByStatus: () =>
    APIClient(CONFIG.API_ENDPOINT).get<StatisticsApiResponse>(
      '/v1/statistics/orders/summary/active-orders-grouped-by-status'
    ),
    getTotalSalesByDressGroupedByYear: () =>
    APIClient(CONFIG.API_ENDPOINT).get<StatisticsApiResponse>(
      '/v1/statistics/orders/summary/sold-dresses-per-year'
    ),
};

export default StatisticsAPI;
