import { useState, useEffect } from 'react';

const usePermissionFlag = (requiredFlags?: string[]) => {
  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    if (!requiredFlags) {
      setHasAccess(true);
    } else {
      const storedFlags = sessionStorage.getItem('flags');
      const parsedFlags = storedFlags ? JSON.parse(storedFlags) : [];

      const access = requiredFlags.length === 0 || requiredFlags.some((flag) => parsedFlags.includes(flag))
      setHasAccess(access);
    }
  }, [requiredFlags]);

  return { hasAccess };
};

export default usePermissionFlag;
