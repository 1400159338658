export type INBOUND_CHANNEL = "GOOGLE" | "FACEBOOK" | "INSTAGRAM" | "TIKTOK" | "WORD_OF_MOUTH" | "SHOW" | "WALK_IN";

export function getInboundChannels(): INBOUND_CHANNEL[] {
  return ["GOOGLE", "FACEBOOK", "INSTAGRAM", "TIKTOK", "WORD_OF_MOUTH", "SHOW", "WALK_IN"];
}

export function inboundChannelToTranslationKeys(status: string | undefined): string {
  switch (status) {
    case 'GOOGLE':
      return "customers.inbound_channel.google";
    case 'FACEBOOK':
        return "customers.inbound_channel.facebook";
    case 'INSTAGRAM':
        return "customers.inbound_channel.instagram";
    case 'TIKTOK':
        return "customers.inbound_channel.tiktok";
    case 'WORD_OF_MOUTH':
        return "customers.inbound_channel.word_of_mouth";
    case 'SHOW':
        return "customers.inbound_channel.show";
    case 'WALK_IN':
        return "customers.inbound_channel.walk_in";
    default:
      return "global.unknown";
  }
}