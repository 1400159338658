type Props = {
    children: JSX.Element,
    onClick?: (a?: any) => void,
    actions?: JSX.Element
  };
  
// TODO: Is it needed, can't we just use ListElement or the specific element like OrderElement?
function SimpleListElement({children, onClick = () => null, actions}: Props) {
return (
    <div className="flex w-full justify-between space-x-5 items-center py-3" onClick={onClick}>
    { children }
    <span className="flex items-center">
        { actions }
    </span>
    </div>
);
}

export default SimpleListElement;
  