import InputShortText from "../InputShortText/InputShortText";
import TextArea from "../TextArea/TextArea";
import { useTranslation } from "react-i18next";
import SectionTitle from "../SectionTitle/SectionTitle";
import { Meeting } from "../../api/MeetingsAPI";
import SelectInput from "../SelectInput/SelectInput";
import CONFIG from "../../config/config";
import { MeetingType, meetingTypeToTranslationKey } from "../../shared/meetingTypeToTranslationKey";
import CustomDatePicker from "../CustomDatePicker/CustomDatePicker";
import { useFormValidation } from "../../hooks/useFormValidation";
import { isFutureDate, isValidEmail, isValidPhone, isValidText } from "../../shared/validation";
import React from "react";
import useSessionStorage from "../../hooks/useSessionStorage";
import IconButton from "../IconButton/IconButton";
import { Icon } from "../Icon/Icon";
import copy from "../../assets/images/copy.svg";
import cut from "../../assets/images/cut.svg";
import paste from "../../assets/images/paste.svg";
import Tooltip from "../Tooltip/Tooltip";
import { addMinutes } from "date-fns";
import { ResourceAction, ResourceActionType } from "../../shared/resourceAction.type";
import { toastService } from "../../services/ToastService";
import SmallCheckBox from "../SmallCheckBox/SmallCheckBox";
import { PermissionFlags } from "../PermissionFlags/PermissionFlags";
import PhoneNumberInput from "../PhoneNumberInput/PhoneNumberInput";
import CustomerInput from "../CustomerInput/CustomerInput";
import { Customer } from "../../api/CustomersAPI";

type Props = {
  meeting: Meeting | null,
  onMeetingChange?: (value: Meeting) => void,
  isDisabled?: boolean,
  actionButton?: JSX.Element,
  timeZone?: string;
};

const getInitialState = (meeting: Meeting | null) => {
  if (meeting) {
    return {
      customerName: isValidText(meeting.customerName, 2),
    };
  }
};

function MeetingForm({ meeting, onMeetingChange = () => null, isDisabled = false, actionButton, timeZone }: Props) {

  const { t } = useTranslation();
  const requiredFields = ['customerName'];
  const initialState = getInitialState(meeting);
  const { updateFieldValidation, isFormValid } = useFormValidation(requiredFields, initialState);
  const [copiedMeeting, setCopiedMeeting] = useSessionStorage<ResourceAction<Meeting> | null>('copiedResource', null);

  const registerAction = (action: ResourceActionType) => {
    displayMessageFeedback(action);
    setCopiedMeeting({ action: action, resource: meeting! });
  }

  const displayMessageFeedback = (action: ResourceActionType) => {
    if (action === "copy") {
      toastService.showToast(t("global.already_copy"));
    } else {
      toastService.showToast(t("global.already_cut"));
    }
  }

  const handleCut = () => {
    if (meeting) registerAction('cut');
  };

  const handleCopy = () => {
    if (meeting) registerAction('copy');
  };

  const handleMeetingChange = (updatedMeeting: Meeting) => {
    onMeetingChange(updatedMeeting);
    updateFieldValidation('customerName', isValidText(updatedMeeting.customerName, 2));
  };  

  const handlePaste = () => {
    if (copiedMeeting) {
      if (copiedMeeting.action === 'copy') {
        handleMeetingChange({
          ...copiedMeeting.resource,
          id: meeting?.id,
          startTime: meeting?.startTime,
          endTime: meeting?.endTime,
        } as Meeting);
      } else if (copiedMeeting.action === 'cut') {
        handleMeetingChange({
          ...copiedMeeting.resource,
          startTime: meeting?.startTime,
          endTime: meeting?.endTime,
        } as Meeting);
      } else {
        console.error("Could not execute action on resource!");
      }
    }
  };

  const disabledActionButton = actionButton
    ? React.cloneElement(
        actionButton,
        {},
        React.Children.map(actionButton.props.children, (child) => {
          return child ? React.cloneElement(child, { isDisabled: !isFormValid() }) : null;
        })
      )
    : null;

  const updateMeetingTimes = (start: Date) => {
    const end = addMinutes(start, 60);
    onMeetingChange({...meeting, startTime: start.toISOString(), endTime: end.toISOString() } as Meeting);
  };

  const fillCustomerData = (customer: Customer) => {
    onMeetingChange(
      {...meeting, 
        customerName: customer.name, 
        customerSurname: customer.surname,
        customer: customer,
        phone: customer?.phone,
        email: customer?.email
      } as Meeting);
  }

  return (
    <>
      <div>
        <PermissionFlags requiredFlags={['create-meetings'] || []}>
          <div className="flex justify-end">
            {(!isDisabled && copiedMeeting) && (
              <Tooltip content={t("global.paste")}>
                <IconButton onClick={handlePaste} disabled={isDisabled || !copiedMeeting}>
                  <Icon url={paste} cssStyles="w-5"/>
                </IconButton>
              </Tooltip>
            )} 
            <Tooltip content={t("global.cut")}>
              <IconButton onClick={handleCut}>
                <Icon url={cut} cssStyles="w-5"/>
              </IconButton>
            </Tooltip>
            <Tooltip content={t("global.copy")}>
              <IconButton onClick={handleCopy}>
                <Icon url={copy} cssStyles="w-5"/>
              </IconButton>
            </Tooltip>
          </div>
        </PermissionFlags>
        <div className="flex flex-wrap">
          <SelectInput values={CONFIG.MEETINGS.STATUS.map(e => { return { value: e, readableValue: t(meetingTypeToTranslationKey(e as MeetingType)) } })}
            onChange={e => onMeetingChange({ ...meeting, type: e.currentTarget.value } as Meeting)}
            label={"calendar.meeting.form.type"}
            defaultValue={meeting?.type || 'VISIT'}
            isDisabled={isDisabled}
          />
        </div>
        <div className="flex flex-wrap space-x-5">
          <CustomerInput
            onCustomerSelect={c => fillCustomerData(c)}
            onValidationChange={(isValid) => updateFieldValidation('customerName', isValid)}
            label={"calendar.meeting.form.name"}
            onChange={e => onMeetingChange({ ...meeting, customerName: e.currentTarget.value } as Meeting)}
            errorMessage={"errors.too_short"}
            defaultValue={meeting?.customer?.name || meeting?.customerName || ''}
            isDisabled={isDisabled}
            // validationFn={(value) => !!value && isValidText(value, 2)}
          />
          { !meeting?.customer && 
            <InputShortText
              label={"calendar.meeting.form.surname"}
              validationFn={(value) => !!value && isValidText(value, 2)}
              onChange={e => onMeetingChange({ ...meeting, customerSurname: e.currentTarget.value } as Meeting)}
              errorMessage={"errors.too_short"}
              defaultValue={meeting?.customer?.surname || meeting?.customerSurname || ''}
              isDisabled={isDisabled}
            />
          }
        </div>
      </div>
      <br/>
      <div className="flex flex-wrap space-x-5">
        <CustomDatePicker
          validationFn={(value) => !!value && isFutureDate(value)}
          errorMessage={"errors.past_date"}
          dateFormat="dd/MM/yyyy HH:mm"
          label="calendar.meeting.form.start"
          value={meeting?.startTime}
          onChange={c => updateMeetingTimes(c)}
          showTimeSelect
          isDisabled={isDisabled}
          timeZone={timeZone}
        />
        <CustomDatePicker
          dateFormat="dd/MM/yyyy HH:mm"
          label="calendar.meeting.form.end"
          validationFn={(value) => !!value && isFutureDate(value)}
          errorMessage={"errors.past_date"}
          value={meeting?.endTime}
          onChange={c => onMeetingChange({...meeting, endTime: c!.toISOString() } as Meeting)}
          showTimeSelect
          isDisabled={isDisabled}
          timeZone={timeZone}
        />
      </div>
      <br/>
      <SectionTitle text={t("admin.form.other_data")} />
      <div className="flex flex-wrap space-x-5">
        <CustomDatePicker
          value={meeting?.weddingDate}
          onChange={e => onMeetingChange({ ...meeting, weddingDate: e.toISOString() } as Meeting)}
          label={"calendar.meeting.form.wedding_date"}
          dateFormat="LL/yyyy"
          isDisabled={isDisabled}
          timeZone={timeZone}
        />
        <InputShortText
          label={"calendar.meeting.form.email"}
          validationFn={(value) => !!value && isValidEmail(value)}
          onChange={e => onMeetingChange({ ...meeting, email: e.currentTarget.value } as Meeting)}
          errorMessage={"errors.invalid_email"}
          defaultValue={meeting?.customer?.email || meeting?.email || ''}
          isDisabled={isDisabled}
        />
      </div>
      <div>
        <div className="flex flex-wrap space-x-5">
          <PhoneNumberInput 
            validationFn={(value) => !!value && isValidPhone(value)} 
            label={"calendar.meeting.form.phone"}
            onChange={e => onMeetingChange({ ...meeting, phone: e } as Meeting)}
            defaultValue={meeting?.customer?.phone || meeting?.phone || ''}
            isDisabled={isDisabled}
          />
        </div>
        { meeting?.type === 'VISIT' && 
        <div className="flex flex-wrap">
          <SmallCheckBox
            label={"calendar.meeting.form.phone.reminder"}
            isDisabled={isDisabled}
            defaultValue={meeting?.reminder}
            onChange={e => 
              onMeetingChange({ ...meeting, reminder: Boolean(e.currentTarget.checked) } as Meeting)}
          />
        </div>
        }
        <TextArea
          label={"calendar.meeting.form.notes"}
          validationFn={(value) => !value || (!!value &&  isValidText(value, 0, 1000))}
          onChange={e => onMeetingChange({ ...meeting, notes: e.currentTarget.value } as Meeting)}
          errorMessage={"errors.too_long"}
          defaultValue={meeting?.notes || ''}
          isDisabled={isDisabled}
        />
      </div>
      { disabledActionButton }
    </>
  );
}

export default MeetingForm;
