import React, { useEffect, useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { parseISO } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { useTranslation } from 'react-i18next';

function parseInput(input: string | Date | null | undefined, timeZone?: string): Date {
    let date = input instanceof Date ? input : typeof input === 'string' ? parseISO(input) : new Date();
    return timeZone ? utcToZonedTime(date, timeZone) : date;
}

interface Props {
    value: string | Date | null | undefined;
    onChange: (date: Date) => void;
    label?: string;
    showTimeSelect?: boolean;
    dateFormat?: string;
    isDisabled?: boolean;
    validationFn?: (date: Date) => boolean;
    errorMessage?: string;
    disablePast?: boolean;
    timeZone?: string;
}

const CustomDatePicker: React.FC<Props> = ({
  value,
  onChange,
  label,
  showTimeSelect,
  dateFormat = "dd/MM/yyyy",
  isDisabled = false,
  validationFn,
  errorMessage = '',
  disablePast = true,
  timeZone,
}) => {
  const { t, i18n } = useTranslation();
  const [isInvalidInput, setIsInvalidInput] = useState<boolean>(false);

  const dateValue = parseInput(value, timeZone);

  useEffect(() => {
    if (validationFn) {
      setIsInvalidInput(!validationFn(dateValue));
    }
  }, [dateValue, validationFn]);

  const displayError: string = useMemo(() => {
    return isInvalidInput ? t(errorMessage) : '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInvalidInput]);

  const onChangeWithValidation = (date: Date | null) => {
    if (date) {
      const utcDate = timeZone ? zonedTimeToUtc(date, timeZone) : date;
      if (validationFn && !validationFn(utcDate)) {
        setIsInvalidInput(true);
      } else {
        setIsInvalidInput(false);
        onChange(utcDate);
      }
    } else {
      onChange(new Date());
    }
  };

  return (
    <div className="flex-1 max-w-full">
      {label && <p className="text-sm font-extrabold">{t(label || '')}:</p>}
      <ReactDatePicker
        selected={dateValue}
        onChange={onChangeWithValidation}
        showTimeSelect={showTimeSelect}
        timeFormat="HH:mm"
        timeIntervals={30}
        calendarStartDay={1}
        disabled={isDisabled}
        locale={i18n.language}
        dateFormat={dateFormat || (showTimeSelect ? "Pp" : "P")}
        minDate={disablePast ? new Date() : null}
      />
      <p className="text-xs text-red-600 my-0">{displayError}</p>
    </div>
  );
};

export default CustomDatePicker;