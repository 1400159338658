import { useMemo } from "react";

export type PillColor = 'green' | 'red' | 'blue' | 'grey' | 'yellow' | 'orange';

type Props = {
  text: string,
  color?: PillColor
};

function Pill({ text, color = 'blue' }: Props ) {

  const colorCode = useMemo(() => {
    switch (color) {
      case 'green':
        return 'bg-lime-100 border-lime-200 border text-green-800';
      case 'blue':
          return 'bg-blue-100 border-blue-200 border text-blue-800';
      case 'red':
          return 'bg-red-100 border-red-200 border text-red-800';
      case 'yellow':
          return 'bg-yellow-100 border-yellow-200 border text-yellow-800';
      case 'orange':
          return 'bg-orange-100 border-orange-200 border text-orange-800';
      default:
        return 'bg-darker-gold-200';
    }
  }, [color])

  return (
    <span 
    className={
      `p-1 px-3 leading-5 font-semibold rounded-full max-h-7 text-xs lowercase whitespace-nowrap truncae ${colorCode}`
    } >
      {text}
    </span>
  );
}

export default Pill;
