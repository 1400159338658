import { Item } from "../../api/CollectionsAPI";
import DressCard from "../DressCard/DressCard";
import noImage from "../../assets/images/null.png";

type DressGridProps = {
    items: any[];
    onCardClick?: (item: Item) => void;
}

const DressGrid = ({ items, onCardClick = () => null }: DressGridProps) => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
      {items.map((item) => (
        <div onClick={() => onCardClick(item)} key={item.id}>
          <DressCard imageUrl={item.media[0] || noImage} name={item.name} />
        </div>
      ))}
    </div>
  );
};

export default DressGrid;
