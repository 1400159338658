import { useState, useEffect } from 'react';
import StatisticsAPI, { StatisticsApiResponse } from '../api/StatisticsAPI';

export type StatisticsType = 'total-year' | 'total-month' | 'total-shop' | 'total-visits' | 'orders-total-per-year' | 'orders-monthly-grouped-by-year' | 'orders-monthly-by-shop-grouped-by-year' | 'total-sales-by-shop-grouped-by-year' | 'active-orders-grouped-by-status' | 'sold-dresses-per-year';

const StatisticsTypeToApiMapping: Record<StatisticsType, () => Promise<{data: StatisticsApiResponse}>> = {
  'total-year': StatisticsAPI.getMostPopularDresses,
  'total-month': StatisticsAPI.getWeddingMonthSummary,
  'total-shop': StatisticsAPI.getByShops,
  'total-visits': StatisticsAPI.getVisitors,
  'orders-total-per-year': StatisticsAPI.getTotalOrdersPerYear,
  'orders-monthly-grouped-by-year': StatisticsAPI.getMonthlyOrdersGroupedByYear,
  'orders-monthly-by-shop-grouped-by-year': StatisticsAPI.getMonthlyOrdersByShopGroupedByYear,
  'total-sales-by-shop-grouped-by-year': StatisticsAPI.getTotalSalesByShopGroupedByYear,
  'active-orders-grouped-by-status': StatisticsAPI.getActiveOrdersGroupedByStatus,
  'sold-dresses-per-year': StatisticsAPI.getTotalSalesByDressGroupedByYear
};

export function useStatistics(statisticsType: StatisticsType) {
  const [statistics, setStatistics] = useState<StatisticsApiResponse>({});

  useEffect(() => {
    const fetchData = async () => {
      const apiMethod = StatisticsTypeToApiMapping[statisticsType];

      if (!apiMethod) {
        throw new Error('Invalid statistics type');
      }

      try {
        const response = await apiMethod();
        setStatistics(response.data);
      } catch (error) {
        console.error('Error fetching statistics data:', error);
      }
    };

    fetchData();
  }, [statisticsType]);

  return statistics;
}
