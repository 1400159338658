import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InputShortText from "../../../../components/InputShortText/InputShortText";
import Notice from "../../../../components/Notice/Notice";
import SmallCheckBox from "../../../../components/SmallCheckBox/SmallCheckBox";
import Subtitle from "../../../../components/Subtitle/Subtitle";
import TextArea from "../../../../components/TextArea/TextArea";
import TinyText from "../../../../components/TinyText/TinyText";
import { isValidText } from "../../../../shared/validation";
import { UpdateCallback } from "../../sections/NotificationsSegment";

interface DefaultConfig {
    enabled?: boolean;
    emailTitle?: string;
    text?: string;
}

interface WeddingRateReminderProps {
    update: UpdateCallback;
    defaultConfig?: DefaultConfig;
}

export function WeddingRateReminder({ update, defaultConfig }: WeddingRateReminderProps) {
    const [enabled, setEnabled] = useState(defaultConfig?.enabled || false);
    const [emailTitle, setEmailTitle] = useState(defaultConfig?.emailTitle || '');
    const [text, setText] = useState(defaultConfig?.text || '');
    const { t } = useTranslation();
  
    useEffect(() => {
      if (defaultConfig) {
        setEnabled(defaultConfig.enabled || false);
        setEmailTitle(defaultConfig.emailTitle || '');
        setText(defaultConfig.text || '');
      }
    }, [defaultConfig]);    

    useEffect(() => {
      const config = {
        enabled,
        emailTitle,
        text
      };
      update('weddingRateReminder', config);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enabled, emailTitle, text]);
  
    return (
      <div>
        <Subtitle text={t("notifications.wedding_rate_reminder")} />
        <TinyText text={t("notifications.wedding_rate_reminder.description1")} />
        <br/>
        <TinyText text={t("notifications.wedding_rate_reminder.description2")} />
        <SmallCheckBox
            label={t("global.enabled")!}
            onChange={() => setEnabled(prev => !prev)}
            defaultValue={enabled}
        />
        <br/>
        <InputShortText
            label={t("notifications.wedding_rate_reminder.email_title")!}
            validationFn={(value) => !!value && isValidText(value, 1, 50)}
            onChange={(e: any) => setEmailTitle(e.target.value)}
            errorMessage={"errors.too_long_short"}
            defaultValue={emailTitle}
        />
        <Notice 
          pillText={t("global.notice")} 
          tinyText={t("notifications.wedding_rate_reminder.email_message.keywords")}
        />
        <TextArea
            validationFn={value => !!value && isValidText(value, 5, 1000)}
            label={t("notifications.wedding_rate_reminder.email_message")!}
            onChange={e => setText(e.currentTarget.value)}
            defaultValue={text}
        />
      </div>
    );
}