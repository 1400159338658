import InputShortText from "../InputShortText/InputShortText";
import { useTranslation } from "react-i18next";
import { isValidText, isValidEmail, isValidPassword } from "../../shared/validation";
import { useFormValidation } from "../../hooks/useFormValidation";
import React from "react";
import SelectInput from "../SelectInput/SelectInput";
import { roleDescriptionToTranslationKey, roleToTranslationKey } from "../../shared/roleToTranslationKey";
import { Account } from "../../api/AccountAPI";
import TinyText from "../TinyText/TinyText";
import Pill from "../Pill/Pill";

type Props = {
  account: Account | null,
  onAccountForm?: (value: Account) => void,
  isDisabled?: boolean,
  actionButton?: JSX.Element
};

const getInitialState = (account: Account | null) => {
  if (account) {
    return {
      email: isValidEmail(account.email),
      password: isValidText(account.password, 8),
      role: isValidText(account.role, 3),
    };
  }
};

function AccountForm({ account, onAccountForm = () => null, isDisabled = false, actionButton }: Props) {
  const ROLES = ['manager', 'office', 'shop', 'worker'];

  const { t } = useTranslation();
  const requiredFields = ['email', 'role', 'password'];
  const initialState = getInitialState(account);
  const { updateFieldValidation, isFormValid } = useFormValidation(requiredFields, initialState);

  const disabledActionButton = actionButton
  ? React.cloneElement(
      actionButton,
      {},
      React.Children.map(actionButton.props.children, (child) => {
        return child ? React.cloneElement(child, { isDisabled: !isFormValid() }) : null;
      })
    )
  : null;

  return ( 
      <div className="flex flex-col flex-wrap">
        <div className="flex-1">
          <InputShortText 
            label={"settings.accounts.form.email"} 
            validationFn={(value) => !!value && isValidEmail(value)} 
            onChange={e => onAccountForm({ ...account, email: e.currentTarget.value } as Account)}
            onValidationChange={(isValid) => updateFieldValidation('email', isValid)}
            errorMessage={"errors.invalid_email"}
            defaultValue={account?.email || ''}
            isDisabled={isDisabled}
          />
          <br/>
          <SelectInput 
            label={"settings.accounts.form.role"} 
            onChange={e => onAccountForm({ ...account, role: e.currentTarget.value } as Account)}
            defaultValue={account?.role || ''}
            isDisabled={isDisabled}
            values={ROLES.map(role => { return { value: role, readableValue: t(roleToTranslationKey(role)) }})}
          />
          <TinyText text={t(roleDescriptionToTranslationKey(account?.role))} />
          <br/>
          <br/>
          <InputShortText 
            label={"settings.accounts.form.password"} 
            validationFn={(value) => !!value && isValidPassword(value)} 
            onChange={e => onAccountForm({ ...account, password: e.currentTarget.value } as Account)}
            onValidationChange={(isValid) => updateFieldValidation('password', isValid)}
            errorMessage={"errors.password_format"}
            defaultValue={account?.password || ''}
            isDisabled={isDisabled}
          />
        </div>
        <div>
          <Pill text={t("global.warning")} color='red'/>
          &nbsp;
          <TinyText text={t("settings.accounts.form.warning")} />
        </div>
        <br/>
        { disabledActionButton }
      </div>
  );
}

export default AccountForm;
