import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import BarChart from '../../../components/BarChart/BarChart';
import NoData from '../../../components/NoData/NoData';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
import SelectInput, { ValueOption } from '../../../components/SelectInput/SelectInput';
import { useStatistics } from '../../../hooks/useStatistics';

interface MonthlyData {
  [shopName: string]: number;
}

interface YearlyData {
  [month: string]: MonthlyData;
}

interface StatisticsApiResponse {
  [year: string]: YearlyData; // Use string keys for years
}

interface ChartData {
  label: string;
  data: number[];
  hidden: boolean;
  backgroundColor: string[];
  borderColor: string[];
  borderWidth: number;
}

function isStatisticsApiResponse(data: any): data is StatisticsApiResponse {
  if (typeof data !== 'object' || data === null) return false;
  return Object.keys(data).every(year => {
    if (typeof data[year] !== 'object' || data[year] === null) return false;
    return Object.keys(data[year]).every(month => {
      if (typeof data[year][month] !== 'object' || data[year][month] === null) return false;
      return Object.values(data[year][month]).every(count => typeof count === 'number');
    });
  });
}

function OrdersMonthlyByShopGroupedByYear() {
  const { t } = useTranslation();

  const [selectedYear, setSelectedYear] = useState<string>('');
  const [dataSets, setDataSets] = useState<ChartData[]>([]);
  const statisticsDataRaw = useStatistics('orders-monthly-by-shop-grouped-by-year');

  useEffect(() => {
    if (!isStatisticsApiResponse(statisticsDataRaw)) {
      console.error('Invalid data format', statisticsDataRaw);
      return;
    }

    const sortedYears = Object.keys(statisticsDataRaw).sort();
    if (sortedYears.length > 0 && !selectedYear) {
      setSelectedYear(sortedYears[sortedYears.length - 1]);
    }
  }, [statisticsDataRaw, selectedYear]);

  useEffect(() => {
    if (!isStatisticsApiResponse(statisticsDataRaw)) {
      return;
    }

    const statisticsData = statisticsDataRaw as StatisticsApiResponse;

    if (selectedYear && statisticsData[selectedYear]) {
      const dataRaw = statisticsData[selectedYear];

      const xLabels = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
      const colors = [
        'rgba(255, 99, 132, 0.5)', 
        'rgba(54, 162, 235, 0.5)', 
        'rgba(255, 206, 86, 0.5)', 
        'rgba(75, 192, 192, 0.5)', 
        'rgba(153, 102, 255, 0.5)', 
        'rgba(255, 159, 64, 0.5)'
      ];
      const borderColors = colors.map(color => color.replace('0.5', '1'));

      const shopNames = new Set<string>();
      xLabels.forEach(month => {
        const monthKey = month.replace(/^0/, '');
        if (dataRaw[monthKey]) {
          Object.keys(dataRaw[monthKey]).forEach(shopName => shopNames.add(shopName));
        }
      });

      const newDataSets: ChartData[] = Array.from(shopNames).sort().map((shopName, index) => {
        const dataValues = xLabels.map(month => {
          const monthKey = month.replace(/^0/, '');
          return dataRaw[monthKey]?.[shopName] ?? 0;
        });
        return {
          label: shopName,
          data: dataValues,
          hidden: false,
          backgroundColor: Array(dataValues.length).fill(colors[index % colors.length]),
          borderColor: Array(dataValues.length).fill(borderColors[index % borderColors.length]),
          borderWidth: 1,
        };
      });

      setDataSets(newDataSets);
    }
  }, [selectedYear, statisticsDataRaw]);

  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedYear(event.target.value);
  };

  const yearOptions: ValueOption[] = Object.keys(statisticsDataRaw).sort().map(year => ({
    value: year,
    readableValue: year,
  }));

  const xLabels = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

  return (
    <>
      <SectionTitle text={t("stats.orders_x_month_by_shop_x_year")} />
      {yearOptions.length > 0 ? (
        <>
          <SelectInput
            values={yearOptions}
            label="Select a year"
            onChange={handleYearChange}
            defaultValue={selectedYear}
          />
          <BarChart title={selectedYear} dataSets={dataSets} xLabels={xLabels} />
        </>
      ) : (
        <NoData message={t("global.empty_results")} />
      )}
    </>
  );
}

export default OrdersMonthlyByShopGroupedByYear;