import { useState } from 'react';
import { Icon } from '../Icon/Icon';
import rightArrowIcon from '../../assets/images/arrow-right.svg';
import leftArrowIcon from '../../assets/images/arrow-left.svg';
import IconButton from '../IconButton/IconButton';
import { CloseButton } from '../CloseButton/CloseButton';
import StyledImage from '../StyledImage/StyledImage';

type Props = {
    images: string[],
    canRemoveImages?: boolean,
    canChangeImageOrder?: boolean,
    onImageRemove?: (removedImageUrl: string) => void,
    onImageOrderChange?: (newOrder: string[]) => void
};

const ImageGallery = ({ 
  images: initialImages, 
  canRemoveImages, 
  canChangeImageOrder, 
  onImageRemove, 
  onImageOrderChange 
}: Props) => {
  const [images, setImages] = useState(initialImages);
  const [visibleIndex, setVisibleIndex] = useState(0);
  const imagesToShow = 3;
  const [selectedImage, setSelectedImage] = useState(initialImages[0] || '');

  const nextImages = () => {
    if (visibleIndex < images.length - imagesToShow) {
      setVisibleIndex(visibleIndex + 1);
    }
  };

  const prevImages = () => {
    if (visibleIndex > 0) {
      setVisibleIndex(visibleIndex - 1);
    }
  };

  const removeImage = (indexToRemove: number) => {
    const removedImage = images[indexToRemove];
    const newImages = images.filter((_, index) => index !== indexToRemove);
    setImages(newImages);
    if (removedImage === selectedImage && newImages.length > 0) {
      setSelectedImage(newImages[0]);
    }
    if (onImageRemove) {
      onImageRemove(removedImage);
    }
    if (visibleIndex > 0 && visibleIndex === images.length - imagesToShow) {
      setVisibleIndex(visibleIndex - 1);
    }
  };

  const moveImageLeft = (index: number) => {
    if (index > 0) {
      const newImages = [...images];
      [newImages[index], newImages[index - 1]] = [newImages[index - 1], newImages[index]];
      setImages(newImages);
      if (onImageOrderChange) {
        onImageOrderChange(newImages);
      }
    }
  };

  const moveImageRight = (index: number) => {
    if (index < images.length - 1) {
      const newImages = [...images];
      [newImages[index], newImages[index + 1]] = [newImages[index + 1], newImages[index]];
      setImages(newImages);
      if (onImageOrderChange) {
        onImageOrderChange(newImages);
      }
    }
  };

  const displayedImages = images.slice(visibleIndex, visibleIndex + imagesToShow);

  return (
    <div className="flex flex-col items-center mb-5">
      <div className="flex justify-between w-full items-center space-x-2">
        <IconButton onClick={prevImages}>
          <Icon url={leftArrowIcon} cssStyles="w-5" />
        </IconButton>
        <div className="flex space-x-1">
            {displayedImages.map((image, index) => (
                <div key={index} className="relative">
                <img
                    src={image}
                    className={`w-24 h-24 object-cover cursor-pointer ${
                    image === selectedImage ? 'border-2 border-blue-500' : ''
                    }`}
                    alt=""
                    onClick={() => setSelectedImage(image)}
                />
                { canChangeImageOrder && (
                  <div className="absolute mt-1.5 items-center top-0 left-0 flex space-x-2">
                    <IconButton onClick={() => moveImageLeft(visibleIndex + index)}>
                      <Icon url={leftArrowIcon} cssStyles="w-4 m-0" />
                    </IconButton>
                    <span className="text-xs backdrop-brightness-95 bg-slate-50 px-1 rounded-lg">{images.indexOf(image) + 1}</span>
                    <IconButton onClick={() => moveImageRight(visibleIndex + index)}>
                      <Icon url={rightArrowIcon} cssStyles="w-4" />
                    </IconButton>
                  </div>
                )}
                {canRemoveImages && (
                    <span className="absolute top-0 right-0">
                        <CloseButton fn={() => removeImage(visibleIndex + index)}/>
                    </span>
                )}
                </div>
            ))}
        </div>
        <IconButton onClick={nextImages}>
          <Icon url={rightArrowIcon} cssStyles="w-5" />
        </IconButton>
      </div>
      <div className="mt-4">
        <StyledImage src={selectedImage} alt={selectedImage}/>
      </div>
    </div>
  );
};

export default ImageGallery;
