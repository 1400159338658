
import CONFIG from '../config/config';
import { instanceWithV2 as APIClient } from './SimpleAPIClient';

export type AlertType = {
  enabled: boolean;
  emails: string[];
};

export type Alerts = {
  orderAdded: AlertType;
  removalMeeting: AlertType;
  contractSigned: AlertType;
  backupOrders: AlertType;
  backupCalendar: AlertType;
  lowStock: AlertType;
  catalogImported: AlertType;
  commentsOnOrder: AlertType;
};

const AlertsAPI = {
  get: () => APIClient(CONFIG.API_ENDPOINT).get<Alerts>(`v1/configuration/alerts`),
  update: (alerts: Alerts) => APIClient(CONFIG.API_ENDPOINT).put<Alerts>(`v1/configuration/alerts`, alerts),
};

export default AlertsAPI;
