import * as amplitude from '@amplitude/analytics-browser';

type Props = {
  fn: () => void,
  eventName?: string,
};

export function CloseButton({ fn = () => null, eventName = ''}: Props) {

  const track = (e: any) => {
    e.stopPropagation();
    if (!!eventName) amplitude.track(eventName);
    fn();
  }

  return (
    <span
      className="text-right hover:cursor-pointer bg-black rounded px-1.5 py-0.5 text-xs text-white font-bold"
      onClick={(e) => track(e)}
    >
      &#x2715;
    </span>
  );
}
