import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Collection } from '../../api/CollectionsAPI';
import { useFormValidation } from '../../hooks/useFormValidation';
import { isValidText } from '../../shared/validation';
import Divider from '../Divider/Divider';
import InputShortText from "../InputShortText/InputShortText";
import Pill from '../Pill/Pill';
import SelectInput from '../SelectInput/SelectInput';
import SmallCheckBox from '../SmallCheckBox/SmallCheckBox';
import TextArea from '../TextArea/TextArea';
import TinyText from '../TinyText/TinyText';

type Props = {
  collection: Collection | null,
  onCollectionForm: (value: Collection) => void,
  isDisabled?: boolean,
  actionButton?: JSX.Element
};

const getInitialState = (partner?: Collection | null) => {
  if (partner) {
    return {
      name: isValidText(partner.name, 2),
      shortName: isValidText(partner.name, 2),
    };
  }
};

function CollectionForm({ collection, onCollectionForm, isDisabled = false, actionButton }: Props) {

  const { t } = useTranslation();
  const [productType] = useState("dresses");
  const initialState = getInitialState(collection);
  const requiredFields = ['name', 'shortName'];
  const { updateFieldValidation, isFormValid } = useFormValidation(requiredFields, initialState);

  const disabledActionButton = actionButton
    ? React.cloneElement(
        actionButton,
        {},
        React.Children.map(actionButton.props.children, (child) => {
          return child ? React.cloneElement(child, { isDisabled: !isFormValid() }) : null;
        })
      )
    : null;

  return (
    <div className="flex flex-col flex-wrap">
      <div className="flex-1">
        <InputShortText
          label={"admin.collections.form.name"}
          validationFn={(value) => !!value && value.length > 2}
          onValidationChange={(isValid) => updateFieldValidation('name', isValid)}
          onChange={e => onCollectionForm({ ...collection, name: e.currentTarget.value } as Collection)}
          errorMessage={"errors.too_short"}
          defaultValue={collection?.name || ''}
          isDisabled={isDisabled}
        />
        <InputShortText
          label={"admin.collections.form.short_name"}
          validationFn={(value) => !!value && value.length > 2}
          onChange={e => onCollectionForm({ ...collection, shortName: e.currentTarget.value } as Collection)}
          onValidationChange={(isValid) => updateFieldValidation('shortName', isValid)}
          errorMessage={"errors.too_short"}
          defaultValue={collection?.shortName || ''}
          isDisabled={isDisabled}
        />
        <SelectInput
          label={"admin.collections.form.type"}
          defaultValue={productType}
          values={[{ readableValue: 'Dresses', value: 'dresses' }]}
          onChange={e => onCollectionForm({ ...collection, type: e.target.value } as Collection)}
          isDisabled={isDisabled}
        />
        <br/>
        <SmallCheckBox
          label={"admin.collections.form.featured"}
          isDisabled={isDisabled}
          defaultValue={collection?.featured}
          onChange={(e: any) => onCollectionForm({ ...collection, featured: e.target.checked } as Collection)}
        />
        <br/>
        <div className="flex-1">
          <span className="flex items-center">
              <Pill text={t("global.warning")} color="blue" />&nbsp;
              <TinyText text={t("admin.collection.form.public.note")} />
            </span>
          <SmallCheckBox
            label={"admin.collections.form.public"}
            defaultValue={collection?.public}
            onChange={(e: any) => onCollectionForm({ ...collection, public: e.target.checked } as Collection)}
            isDisabled={isDisabled}
          />
        </div>
        <SmallCheckBox
          label={"admin.collections.form.shareable"}
          defaultValue={collection?.shareableByLink}
          onChange={(e: any) => onCollectionForm({ ...collection, shareableByLink: e.target.checked } as Collection)}
          isDisabled={isDisabled}
        />
        <SmallCheckBox
          label={"admin.collections.form.public_prices"}
          defaultValue={collection?.pricesArePublic}
          onChange={(e: any) => onCollectionForm({ ...collection, pricesArePublic: e.target.checked } as Collection)}
          isDisabled={isDisabled}
        />
        <Divider />
        <TextArea
          label={"admin.collections.form.description"}
          validationFn={(value) => !!value && isValidText(value, 0, 10000)} 
          onChange={e => onCollectionForm({ ...collection, description: e.currentTarget.value } as Collection)}
          errorMessage={"errors.too_short"}
          defaultValue={collection?.description}
          isDisabled={isDisabled}
        />
      </div>
      { disabledActionButton }
    </div>
  );
}

export default CollectionForm;
