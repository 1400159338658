import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Lead } from "../../api/LeadsAPI";
import ConfirmationPopup from "../../components/ConfirmationPopup/ConfirmationPopup";
import IconButton from "../../components/IconButton/IconButton";
import ListContainer from "../../components/ListContainer/ListContainer";
import ListElement from "../../components/ListElement/ListElement";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import { useGetLeads } from "../../hooks/useGetLeads";
import PageWrapper from "../PageWrapper/PageWrapper";
import deleteIcon from "../../assets/images/bin.svg";
import editIcon from "../../assets/images/pen.svg";
import Card from "../../components/Card/Card";
import { Icon } from "../../components/Icon/Icon";
import LeadForm from "../../components/LeadForm/LeadForm";
import { Modal } from "../../components/Modal/Modal";
import { FormType } from "../../shared/form.type";
import Collapsable from "../../components/Collapsable/Collapsable";
import FilterPill from "../../components/FilterPill/FilterPill";
import { getLeadStatuses, leadStatusToTranslationKey } from "../../shared/leadStatusToTranslationKey";
import ElementActions from "../../components/ElementActions/ElementActions";
import NoDataCTA from "../../components/NoDataCTA/NoDataCTA";
import LeadElement from "../../components/LeadElement/LeadElement";

const EmptyLead: Lead = {
  name: '',
  status: 'TO_CONTACT',
  notes: '',
  countryCode: 'pl',
  email: ''
};

const LeadsPage = () => {
  const [action, setAction] = useState<FormType>('READ');
  const [selectedLead, setSelectedLead] = useState<Lead | null>(null);
  const [filters, setFilters] = useState({ country: "", status: "" });
  const { isLoading, leads, addFn, updateFn, removeFn } = useGetLeads(filters);

  const { t } = useTranslation();

  const handleFilterChange = (filterType: 'country' | 'status', value: string) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      if (updatedFilters[filterType] === value) {
        delete updatedFilters[filterType];
      } else {
        updatedFilters[filterType] = value;
      }
      return updatedFilters;
    });
  };
  


  const actionButton = (action: FormType) => {
    switch (action) {
      case 'CREATE':
        return <span className="flex justify-center"><PrimaryButton text={t("global.create")!} fn={() => registerAndClose(selectedLead!)} /></span>;
      case 'UPDATE':
        return <span className="flex justify-center"><PrimaryButton text={t("global.update")!} fn={() => registerAndClose(selectedLead!)} /></span>;
      default:
        return;
    }
  };

  const registerAndClose = async (e: Lead) => {
    if (!!e) {
      if (action === 'CREATE') {
        addFn(e);
      } else {
        updateFn(e);
      }
      setSelectedLead(null);
      setAction("READ");
    }
  }

  return (
    <PageWrapper>
        <div className="space-y-5">
          <Card>
            <>
              <Collapsable textOnExpanded={t("leads.filters.statuses.hide")!} textOnHiden={t("leads.filters.statuses.expand")!}>
                <div className="container flex flex-wrap max-h-48 overflow-y-scroll">
                  {
                    getLeadStatuses().map((s, i) => <FilterPill isActive={filters?.status === s} key={i} text={t(leadStatusToTranslationKey(s))} fn={() => handleFilterChange("status", s)} />)
                  }
                </div>
              </Collapsable>
              <div className="container flex flex-wrap space-x-5">
                <PrimaryButton text={t("global.create")!} fn={() => { setAction('CREATE');setSelectedLead(EmptyLead);}} />
              </div>
            </>
          </Card>
          <Card>
              <>
                <ListContainer
                  isLoading={isLoading}
                  elements={leads}
                  emptyListComponent={
                    <NoDataCTA
                      headline={t("leads.empty.cta.title")}
                      subtext={t("leads.empty.cta.body")}
                      primaryActionText={t("global.create")}
                      onPrimaryAction={() => { setAction('CREATE');setSelectedLead(EmptyLead);}}
                    />
                  }
                  toElementFn={(lead: Lead) => (
                    <ListElement
                      key={lead.id}
                      onClick={() => setSelectedLead(lead)}
                      css="bg-white text-black hover:bg-darker-gold-100">
                      <ElementActions actions={
                        <>
                          <IconButton onClick={() => { setAction('UPDATE'); setSelectedLead(lead);}} >
                            <Icon url={editIcon} cssStyles="w-5" />
                          </IconButton>
                          <IconButton>
                            <ConfirmationPopup onConfirm={() => removeFn(lead)} confirmText={"global.confirm"} title={`global.remove: ${lead.name}`}>
                              <Icon url={deleteIcon} cssStyles="w-5" />
                            </ConfirmationPopup>
                          </IconButton>
                        </>
                      }>
                        <LeadElement lead={lead} />
                      </ElementActions>
                    </ListElement>
                  )}
                />
              </>
          </Card>
          { selectedLead && 
            <Modal modalTitle={selectedLead.name || "leads.form.new_lead"} canClose onClose={() => setSelectedLead(null)}>
              <LeadForm lead={selectedLead} onLeadChange={setSelectedLead} actionButton={actionButton(action)} isDisabled={action === 'READ'}/>
            </Modal>
          }
      </div>
    </PageWrapper>
  );
}

export default LeadsPage;
