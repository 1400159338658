import FilesAPI from "../../api/FilesAPI";

type Props = {
    file: string,
    text?: string
    type?: string
}

function extractFileName(url: string) {
    if (!url) return '';
    const fileName = url.split('/').pop();

    return fileName ? decodeURI(fileName) : '';
}

export function PrivateResourceLink({ file, text = '', type = 'orders' }: Props) {

    const openFile = async (fileName: string) => {
        const url = await FilesAPI.getUrl(fileName, type);
        window.open(url.data.url, "_blank")?.focus();
      }

    return (
        <span onClick={() => openFile(extractFileName(file))} className="font-mono text-xs text-bolder hover:opacity-50">
            { text ? text : extractFileName(file) || '-'}
        </span>
    );
}