import CONFIG from '../config/config';
import { ConversionStatus } from '../shared/leadStatusToTranslationKey';
import { instanceWithV2 as APIClient } from './SimpleAPIClient';

export type Lead = {
  id?: number,
  name: string,
  email: string,
  countryCode: string,
  notes?: string,
  status: ConversionStatus,
}

export type LeadResponse = {
  lead: Lead,
}

type BodyApiResponse = Lead[];

const LeadsAPI = {
  get: ({ status }: any) => {
    const queryParams = new URLSearchParams();
    if (status) queryParams.append("status", status);
    return APIClient(CONFIG.API_ENDPOINT).get<BodyApiResponse>(`v1/leads${!!queryParams.toString() ? `?${queryParams.toString()}` : ''}`)
  },
  add: (lead: Lead) => APIClient(CONFIG.API_ENDPOINT).post<Lead>(`/v1/leads`, lead),
  update: (id: number, lead: Lead) => APIClient(CONFIG.API_ENDPOINT).put<Lead>(`/v1/leads/${id}`, lead),
  remove: (id: number) => APIClient(CONFIG.API_ENDPOINT).delete<BodyApiResponse>(`/v1/leads/${id}`),
};

export default LeadsAPI;
