import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Settings } from "../../store/reducers/AccountSlice";
import HeaderTenantTitle from "../HeaderTenantTitle/HeaderTenantTitle";
import { Icon } from "../Icon/Icon";

type Props = {
  children: JSX.Element
};

function MenuContainer({children}: Props) {
  
  const settings: Settings = useSelector((state: any) => state.settings.value);
  const navigate = useNavigate(); 

  const handleIconClick = () => {
    navigate('/calendar');
  };

  return (
    <div className="flex flex-row flex-wrap justify-center md:justify-between items-center p-3 px-1 md:px-7 md:top-0 md:sticky md:shadow-sm bg-white w-full m-0 z-20">
      <div className="flex items-center">
        { settings.logoUrl ? 
          <Icon url={settings.logoUrl} cssStyles="h-12 w-auto min-w-full max-w-full mr-3" onClick={handleIconClick}/> 
        : <HeaderTenantTitle text={settings.tenantName || 'eAteliers'}/>
        }
        &nbsp;
      </div>
      { children }
    </div>
  );
}

export default MenuContainer;
