import CONFIG from '../config/config';
import { instanceWithV2 as APIClient } from './SimpleAPIClient';
import { User } from './UsersAPI';

type NewUserResponse = User;

export type Account = {
    email: string;
    role: string;
    password: string;
};  

type BodyApiResponse = NewUserResponse;

const AccountsAPI = {
    add: (account: Account) => APIClient(CONFIG.API_ENDPOINT).post<BodyApiResponse>(`/v1/admin/users`, account),
    delete: (uuid: string) => APIClient(CONFIG.API_ENDPOINT).delete<User>(`/v1/admin/users`, { data: { uuid: uuid}}),
  };
  
export default AccountsAPI;
  