import { OrderComment } from '../../api/ShopClientsAPI';

type Props = {
    comment: OrderComment;
}

const CommentCard = ({ comment }: Props) => {
    const { comment: text, creationTime, shopName, self } = comment;

    const sideBarColor = self ? 'border-gold-500' : 'border-gold-600';
    const formattedDate = new Date(creationTime!).toLocaleString();

    return (
        <div className={`bg-white shadow rounded-lg p-4 animate-fade-in-up relative border-l-4 ${sideBarColor}`}>
            {/* <div className={`${sideBarColor} w-1 h-full absolute left-0 top-0 rounded-l-lg`}></div> */}
            <div className="flex justify-between items-center mb-2">
                <h4 className="text-lg font-semibold">{shopName}</h4>
                <span className="text-sm text-gray-600">{formattedDate}</span>
            </div>
            <p className="text-base text-gray-800">{text}</p>
        </div>
    );
};

export default CommentCard;
