import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CollectionsAPI, { Collection, Item } from '../api/CollectionsAPI';
import { toastService } from '../services/ToastService';
import { simplifyUrl } from '../shared/urlUtils';
import { useImageUploader } from './useImageUploader';

type ReturnedProps = {
  isLoadingCollections: boolean;
  collections: Collection[];
  addCollection: (c: Collection) => Promise<void>;
  duplicateCollection: (id: number) => Promise<void>;
  removeCollection: (c: Collection) => Promise<void>;
  removeItem: (c: Item) => Promise<void>;
  addItem: (c: Item) => Promise<void>;
  updateItem: (c: Item) => Promise<void>;
  updateCollection: (c: Collection) => Promise<void>;
  reorderCollectionItems: (c: Collection) => Promise<void>;
  expandedCollection: Collection | null;
  setExpandedCollection: any;
  refresh: () => Promise<void>;
};

export function useGetCollections(): ReturnedProps {

  const { t } = useTranslation();
  const [collections, setCollections] = useState<Collection[]>([]);
  const [expandedCollection, setExpandedCollection] = useState<Collection | null>(null);
  const [isLoadingCollections, setIsLoading] = useState(true);
  const { uploadImage } = useImageUploader('', (resp: any) => resp.data, "catalogues");

  const refresh = useCallback(async () => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addImageToItem = async (collectionId: number, item: Item, file: File): Promise<Item | string> => {
    const itemWithImage = await uploadImage(file, `collections/${collectionId}/${item.id}/files/images`, { media: item.media });
    return itemWithImage;
  };

  const addCollection = async (collection: Collection) => {
    setIsLoading(true);
    await CollectionsAPI.add(collection);
    load();
  };

  const duplicateCollection = async (id: number) => {
    setIsLoading(true);
    await CollectionsAPI.duplicate(id);
    load();
  };

  const addItem = async (item: Item) => {
    setIsLoading(true);
    try {
      let newItem = (await CollectionsAPI.addItem(expandedCollection?.id!, item)).data;
      if (!!item.files) {
        for (let tempFile of item.files) {
          try {
            const urlOrUpdatedItem = await addImageToItem(expandedCollection?.id!, newItem, tempFile);
            if (typeof urlOrUpdatedItem === 'string') {
              newItem.media = newItem.media || [];
              newItem.media.push(simplifyUrl(urlOrUpdatedItem));
            } else {
              newItem.media = urlOrUpdatedItem.media;
            }
          } catch (error) {
            toastService.showToast(`${t("toast.errors.add_file")}: + ${tempFile.name}`, 'error');
          }
        }
      }
      await CollectionsAPI.updateItem(expandedCollection!.id!, newItem.id!, newItem);
      load();
    } catch (error) {
      toastService.showToast(`${t("toast.errors.catalogues.item.add")}`, 'error');
    } finally {
      setIsLoading(false);
    }
  }  

  const updateCollection = async (collection: Collection) => {
    setIsLoading(true);
    await CollectionsAPI.update(collection.id!, collection);
    load();
  };

  const reorderCollectionItems = async (collection: Collection) => {
    await CollectionsAPI.update(collection.id!, collection);
    load();
  };

  const updateItem = async (item: Item) => {
    setIsLoading(true);
    let newItem = { ...item };
    newItem.media = newItem.media || [];
    try {
      if (!!item.files) {
        for (let tempFile of item.files) {
          try {
            // Pass frontend-updated media in body request as well?
            const urlOrUpdatedItem = await addImageToItem(item.collection!, newItem, tempFile);
            if (typeof urlOrUpdatedItem === 'string') {
              newItem.media.push(simplifyUrl(urlOrUpdatedItem));
            } else {
              const newMedia: Set<string> = new Set(newItem.media);
              urlOrUpdatedItem.media?.forEach(mediaUrl => newMedia.add(mediaUrl));
              newItem.media = Array.from(newMedia);
            }
          } catch (error) {
            toastService.showToast(`${t("toast.errors.add_file")}: + ${tempFile.name}`, 'error');
          }
        }
      }
      await CollectionsAPI.updateItem(item.collection!, item.id!, newItem);
      load();
    } catch (error) {
      toastService.showToast(`${t("toast.errors.catalogues.item.update")}`, 'error');
    } finally {
      setIsLoading(false);
    }
  };  

  const removeCollection = async (collection: Collection) => {
    setIsLoading(true);
    await CollectionsAPI.remove(collection.id!);
    load();
  };

  const removeItem = async (item: Item) => {
    try {
      setIsLoading(true);
      await CollectionsAPI.removeItem(item.collection!, item.id!);
      load();
    } catch (error) {
      toastService.showToast(`${t("toast.errors.catalogues.item.remove")}`, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const load = () => {
    const asyncLoad = async () => {
      try {
        const collectionsRaw = await CollectionsAPI.getCollections();
        const collectionsApi = collectionsRaw.data;

        setCollections(collectionsApi);
      } catch (err) {
        toastService.showToast(`${t("toast.errors.catalogues.load")}`, 'error');
      } finally {
        setIsLoading(false);
      }
    };
    asyncLoad();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(load, []);

  return { 
    isLoadingCollections, 
    collections, 
    addCollection, 
    duplicateCollection,
    removeCollection, 
    updateCollection, 
    expandedCollection, 
    setExpandedCollection, 
    removeItem,
    updateItem,
    addItem,
    reorderCollectionItems,
    refresh
  };
}
