import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { monthIndexToTranslationKey } from "../../shared/monthIndexToTranslationKey";

type DateOption = {
  value: string,
  readableValue: string
}

type Props = {
  values?: DateOption[],
  onSelect?: (e: any) => void,
  value?: string
};

// TODO: reuse SelectInput with custom CSS?
function DropSelect({ values = [], onSelect, value = '' }: Props) {

  const { t } = useTranslation();
  const [options, setOptions] = useState<DateOption[]>([]);

  useEffect(() => {
    const today = new Date();
    const monthYears: DateOption[] = [];
    for (let i = today.getFullYear(); i <= today.getFullYear() + 1; i++) {
      for (let j = 0; j < 12; j++) {
        const date = new Date(i, j, 3);
        monthYears.push({ value: date.toISOString().split('T')[0], readableValue: `${t(monthIndexToTranslationKey(date.getMonth()))} ${date.getFullYear()}`});
      }
    }
    setOptions(monthYears);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <select required className="border pr-3 pt-3 pb-3 bg-transparent text-[#c5b078ad] border-transparent outline-none focus:ring-0 focus:border-transparent"
      onChange={onSelect} value={value}
      data-dd-action-name={'Dropdown Month Select'}
      >
      <option hidden value="">{t("global.month_picker")}</option>
      <option value="">{ t("orders.filters.month.all") }</option>
      { options.map((m, i) => 
        <option value={m.value}  key={i} data-dd-action-name={'Dropdown Month Select Option'}>
          { m.readableValue }
        </option>
      )}
    </select>
  );
}

export default DropSelect;
