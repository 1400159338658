import React from 'react';

type Props = {
  text: string;
  clazz?: string;
};

const CatalogTitle: React.FC<Props> = ({ text, clazz = 'text-4xl' }) => {
  return (
    <h1
      className={`duration-1000 ease-in-out ${clazz} md:text-6xl font-semibold text-center text-rose-600 tracking-wide leading-tight`}
    >
      <span className="inline-block bg-gradient-to-r from-pink-400 via-rose-400 to-pink-400 bg-clip-text text-transparent">
        {text}
      </span>
    </h1>
  );
};

export default CatalogTitle;