import React, { Component } from "react";

import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import momentTimezonePlugin from '@fullcalendar/moment-timezone';

import { connect } from 'react-redux';
import PageWrapper from "../PageWrapper/PageWrapper";
import { WithTranslation, withTranslation } from "react-i18next";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import MeetingsAPI, { Meeting } from "../../api/MeetingsAPI";
import Legend from "../../components/Legend/Legend";
import { getMeetingBorderColor, getMeetingColor } from "../../shared/meetingTypeToColor";
import DropSelect from "../../components/DropSelect/DropSelect";
import { Modal } from "../../components/Modal/Modal";
import { Autocomplete } from "../../components/Autocomplete/Autocomplete";
import { CancelToken } from "../../api/SimpleAPIClient";
import CalendarFooter from "../../components/CalendarFooter/CalendarFooter";
import MeetingForm from "../../components/MeetingForm/MeetingForm";
import { FormType } from "../../shared/form.type";
import deleteIcon from "../../assets/images/bin.svg";
import IconButton from "../../components/IconButton/IconButton";
import ConfirmationPopup from "../../components/ConfirmationPopup/ConfirmationPopup";
import { Icon } from "../../components/Icon/Icon";
import BlockedDatesForm from "../../components/BlockedDatesForm/BlockedDatesForm";
import SelectInput from "../../components/SelectInput/SelectInput";
import { toastService } from "../../services/ToastService";
import EmployeesAPI, { Employee } from "../../api/EmployeesAPI";
import Card from "../../components/Card/Card";
import { PermissionFlags } from "../../components/PermissionFlags/PermissionFlags";
import DangerPrimaryButton from "../../components/DangerPrimaryButton/DangerPrimaryButton";
import { meetingTypeToTranslationKey } from "../../shared/meetingTypeToTranslationKey";
import { BolderText } from "../../components/BolderText/BolderText";
import Tooltip from "../../components/Tooltip/Tooltip";
import { parseDateTime } from "../../shared/timeUtils";
import ListElement from "../../components/ListElement/ListElement";
import usePermissionFlag from "../../hooks/usePermissionFlag";
import * as amplitude from '@amplitude/analytics-browser';
import CONFIG from "../../config/config";
import { Settings } from "../../store/reducers/AccountSlice";
import Subtitle from "../../components/Subtitle/Subtitle";
import { AdminRestricted } from "../../components/AdminRestricted/AdminRestricted";
import PlainTextButton from "../../components/PlainTextButton/PlainTextButton";

interface State {
  events: any[];
  view: string;
  selectedEvent: Meeting | null;
  calendarApi: any;
  currentViewName: string;
  searchIsLoading: boolean;
  action: FormType;
  showBlockedDates: boolean;
  signers: Employee[];
  showOverlapWarning: boolean;
}

const emptyEvent: Meeting = {
  customerName: '',
  customerSurname: '',
  startTime: new Date().toISOString(),
  endTime: new Date().toISOString(),
  type: 'VISIT',
  reminder: false,
  disable: false,
  weddingDate: null,
  phone: null,
  lastUpdateTime: null,
  notes: null,
  title: null
}

const isAllDay = (start: string, end: string) => {
  const startDate = new Date(start);
  const endDate = new Date(end);

  const startHour = startDate.getUTCHours();
  const endHour = endDate.getUTCHours();

  return startHour <= 7 && endHour >= 18;
};

const mapStateToProps = (state: any) => ({
  settings: state.settings.value,
});

interface CalendarPageOwnProps {
  hasAccess?: boolean;
}

type CalendarPageProps = CalendarPageOwnProps & StateProps & WithTranslation;

const CalendarPageWrapper: React.FC<CalendarPageProps> = (props) => {
  const { hasAccess } = usePermissionFlag(['create-meetings']);

  return <CalendarPage {...props} hasAccess={hasAccess} />;
};

interface StateProps {
  settings: Settings;
}

class CalendarPage extends Component<CalendarPageProps & StateProps, State> {
  calendarRef = React.createRef<any>();

  static defaultProps = {
    hasAccess: false
  };

  state: State = {
    events: [],
    view: "dayGridMonth",
    selectedEvent: null,
    calendarApi: null,
    currentViewName: "dayGridMonth",
    searchIsLoading: false,
    action: 'READ',
    showBlockedDates: false,
    signers: [],
    showOverlapWarning: false,
  };

  search = (input: string, resultsSize: number, setResultsFn: any) => {
    this.setState({ searchIsLoading: true });
    amplitude.track('Meeting search');
    MeetingsAPI.search(input).then((resp: any) => {
      const raw = resp.data;
      const respRaw = raw.slice(0, resultsSize);
      this.setState({ searchIsLoading: false });
      setResultsFn(respRaw);
    });
    return CancelToken.source().cancel("Cancelling...");
  };

  showMeeting = (meeting: Meeting, action?: FormType) => {
    this.goToDay(Date.parse(meeting.startTime));
    
    if (this.props.hasAccess) {
      this.setState({ action: "UPDATE" });
    } else {
      this.setState({ action: action ? action : "READ" });
    }

    this.setState({ selectedEvent: meeting });
  }

  handleNewButtonClick = () => {
    this.setState({ showBlockedDates: true });
  }

  eventContent = (info: any, view: any) => {
    const event = info.event;

    if (event.extendedProps.isBlockedDate) {
      return (
        <div
          className="rounded pl-1 text-black hover:bg-blue-400 cursor-pointer min-h-full w-100"
          style={{
            backgroundColor: event.backgroundColor,
          }}
        >
          <span className="truncate flex items-end">
            <PermissionFlags requiredFlags={['remove-meetings'] || []}>
              <IconButton>
                <ConfirmationPopup 
                  onConfirm={(employeeName) => this.handleEventDelete(info, employeeName.target.value)} 
                  confirmText={"global.confirm"} 
                  title={"global.confirmation_popup.title"}
                  extraContent={(setExtraState) => 
                    <SelectInput values={this.state.signers.map(e => { return { value: e.name, readableValue: e.name}})}
                      onChange={setExtraState}
                      label={"calendar.meetings.removal.signature"} 
                    />
                  }
                  >
                  <Icon url={deleteIcon} cssStyles="w-5" />
                </ConfirmationPopup>
              </IconButton>
            </PermissionFlags>
            <p><BolderText text={event.title} /></p>
          </span>
        </div>
      );
    } else {
      if (view === 'dayGridMonth') {
        return (
          <>
            <Tooltip content={event.title} >
              <div className="fc-daygrid-event-dot" style={{backgroundColor: event.borderColor}}></div>
            </Tooltip>
          </>
        );
      } else {
        return (
          <div
            className="rounded pl-1 text-black hover:bg-blue-400 cursor-pointer min-h-full w-100"
            style={{
              backgroundColor: event.backgroundColor,
            }}
            onClick={() => this.handleEventClick(info)}
          >
            <span className="truncate flex items-center">
              <PermissionFlags requiredFlags={['remove-meetings'] || []}>
                <IconButton>
                  <ConfirmationPopup 
                    onConfirm={(employeeName) => this.handleEventDelete(info, employeeName.target.value)} 
                    confirmText={"global.confirm"} 
                    title={"global.confirmation_popup.title"}
                    extraContent={(setExtraState) => 
                      <SelectInput values={this.state.signers.map(e => { return { value: e.name, readableValue: e.name}})}
                        onChange={setExtraState}
                        label={"calendar.meetings.removal.signature"} 
                      />
                    }
                    >
                    <Icon url={deleteIcon} cssStyles="w-5" />
                  </ConfirmationPopup>
                </IconButton>
              </PermissionFlags>
              <p><BolderText text={event.title} /></p>
            </span>
        </div>);
      }
    }
  }

  componentWillUnmount() {
    if (this.calendarRef.current) {
      const calendarApi = this.calendarRef.current.getApi();
      calendarApi.destroy();
    }
    window.removeEventListener('resize', this.handleResize);
  }

  async componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();

    const resp = await EmployeesAPI.getMeetingRemovalSigners();
    this.setState({ signers: resp.data });

    const now = new Date();
    const startOfMonth = new Date(
      now.getFullYear(),
      now.getMonth(),
      1
    ).toISOString();
    const endOfMonth = new Date(
      now.getFullYear(),
      now.getMonth() + 1,
      0
    ).toISOString();

    this.fetchEvents(startOfMonth, endOfMonth);
  }

  handleResize = () => {
    const calendarApi = this.calendarRef.current.getApi();
    if (window.innerWidth < CONFIG.MOBILE_BREAKPOINT) {
        calendarApi.changeView('timeGridDay');
    } else {
        calendarApi.changeView('dayGridMonth');
    }
  }

  fetchEvents = async (startDate: any, endDate: any) => {
    const { data: blockedDates } = await MeetingsAPI.getUnavailableDates(startDate, endDate);

    const { data: meetings } = await MeetingsAPI.get(startDate, endDate);

    const transformedEvents = [
      ...blockedDates.map((date: any) => ({
        id: date.id,
        start: date.start,
        title: this.props.t("calendar.blocked_dates"),
        end: date.end,
        rendering: "background",
        backgroundColor: "#ffe9e9",
        allDay: isAllDay(date.start, date.end),
        extendedProps: {
          isBlockedDate: true,
          id: date.id
        },
      })),
      ...meetings.map((meeting: any) => ({
        id: meeting.id,
        title: `${meeting.customerName} 
                ${meeting.customerSurname} 
                ${meeting.phone ? "- " + meeting.phone + " " : ''}
                ${meeting.notes ? "(" + meeting.notes + ")" : ''}
                ${"- " + this.props.t(meetingTypeToTranslationKey(meeting.type))}
                `,
        start: meeting.startTime,
        end: meeting.endTime,
        allDay: ['WAITING_LIST', 'NOTE'].includes(meeting.type),
        backgroundColor: getMeetingColor(meeting.type),
        borderColor: getMeetingBorderColor(meeting.type),
        extendedProps: {
          ...meeting,
        },
      })),
    ];

    this.setState({ events: transformedEvents });
  };

  handleMonthChange = async (e: any) => {
    const selectedDate = new Date(e.target.value);
    const calendarApi = this.calendarRef.current.getApi();

    // Change the view to the selected month and year
    if (window.innerWidth < CONFIG.MOBILE_BREAKPOINT) {
      calendarApi.changeView('timeGridDay', selectedDate);
    } else {
        calendarApi.changeView('dayGridMonth', selectedDate);
    }

    // Fetch events for the new month
    const startOfMonth = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      1
    ).toISOString();
    const endOfMonth = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth() + 1,
      0
    ).toISOString();
    await this.fetchEvents(startOfMonth, endOfMonth);
  };

  handleEventDelete = async (info: any, employeeName?: string) => {
    if (info.event.extendedProps) {
      try {
        const meetingId = info.event.extendedProps.id
        if (info.event.extendedProps.isBlockedDate) {
          await MeetingsAPI.unblock(meetingId);
        } else {
          await MeetingsAPI.delete(meetingId, employeeName);
        }
        this.setState({ selectedEvent: null });
        this.setState({ action: "READ" });

        // Refetch events to update the calendar
        const calendarApi = this.calendarRef.current.getApi();
        const startDate = calendarApi.view.activeStart.toISOString();
        const endDate = calendarApi.view.activeEnd.toISOString();
        await this.fetchEvents(startDate, endDate);
      } catch (error) {
        toastService.showToast(this.props.t("toast.errors.remove_meeeting"), 'error');
      }
    }
  };

  handleEventClick = (info: any) => {
    if (!info.event.extendedProps.isBlockedDate) {
      if (this.props.hasAccess) {
        this.setState({ action: "UPDATE" });
      }
      this.setState({ selectedEvent: info.event.extendedProps });
    }
  };

  closeModal = () => {
    this.setState({ selectedEvent: null });
    this.setState({ action: "READ" });
  };

  isWeekOrDayView = (view: string) => {
    return view && (view === 'timeGridDay' || view === 'timeGridWeek');
  }

  handleDateClick = (arg: any) => {
    const clickViewType = arg.view.type;
    if (this.isWeekOrDayView(clickViewType) && this.props.hasAccess) {
      const emptyEventWithDate = { ...emptyEvent, startTime: arg.start.toISOString(), endTime: arg.end.toISOString() };
      this.setState({ action: 'CREATE' });
      this.setState({ selectedEvent: emptyEventWithDate });
    } else {
      const calendarApi = this.calendarRef.current.getApi();
      calendarApi.changeView("timeGridDay", arg.start);
      this.setState({ currentViewName: "timeGridDay" });
    }
  };
  
  handleToday = async () => {
    this.goToDay();
  }

  goToDay = async (date: number = Date.now()) => {
    const calendarApi = this.calendarRef.current.getApi();
    calendarApi.changeView("timeGridDay", date);
  }

  handleRefresh = async () => {
    const today = Date.now();
    this.handleMonthChange({ target: { value: today }});
  };

  handleDatesSet = async (arg: any) => {
    const startDate = arg.start.toISOString();
    const endDate = arg.end.toISOString();

    await this.fetchEvents(startDate, endDate);
  };

  refresh = async () => {
    const calendarApi = this.calendarRef.current.getApi();
    const startDate = calendarApi.view.activeStart.toISOString();
    const endDate = calendarApi.view.activeEnd.toISOString();
    await this.fetchEvents(startDate, endDate);
  }

  refreshAfterBlockDates = async () => {
    this.setState({ showBlockedDates: false }); 
    this.refresh();
  }

  render() {
    const { events, view, selectedEvent, searchIsLoading, action, showBlockedDates, showOverlapWarning } = this.state;
    const { settings } = this.props;

    const checkForOverlap = async (meeting: Meeting) => {
      return MeetingsAPI.checkAvailability(meeting.startTime);
    }

    const finalizeMeetingRegistration = async (e: Meeting) => {
      if (this.state.action === 'CREATE') {
        await MeetingsAPI.add(e);
      } else if (this.state.action === 'UPDATE') {
        await MeetingsAPI.update(e.id!, e);
      }
      
      this.goToDay(Date.parse(e.startTime));
      this.setState({ selectedEvent: null, action: "READ", showOverlapWarning: false });
      this.refresh();
    }
    
    const registerAndClose = async (e: Meeting) => {
      if (!!e) {
        const overlapExists = await (await checkForOverlap(e)).data.matches;
    
        if (overlapExists) {
          this.setState({
            showOverlapWarning: true,
          });
        } else {
          await finalizeMeetingRegistration(e);
        }
      }
    }
    
    const handleOverlapConfirm = async (e: Meeting | null) => {
      if (e) {
        await finalizeMeetingRegistration(e);
      }
    }
    
    const handleOverlapCancel = () => {
      this.setState({ showOverlapWarning: false });
    }    

    const actionButton = (action: FormType) => {
      switch (action) {
        case 'CREATE':
          return <span className="flex justify-center"><PrimaryButton text={this.props.t("global.create")!} fn={() => registerAndClose(selectedEvent!)} /></span>;
        case 'UPDATE':
          return <span className="flex justify-center"><PrimaryButton text={this.props.t("global.update")!} fn={() => registerAndClose(selectedEvent!)} /></span>;
        default:
          return;
      }
    };

    const showNewMeeting = () => {
      amplitude.track('Create Meeting form opened');
      this.setState({ action: 'CREATE' });
      this.setState({ selectedEvent: emptyEvent });
    };

    const overlapModal = this.state.showOverlapWarning && (
      <Modal modalTitle={"calendar.meetings.overlap_popup.title"} canClose onClose={handleOverlapCancel}>
        <>
          <p className="py-5">{ this.props.t("calendar.meetings.overlap_popup.body") }</p>
          <div className="flex justify-center mt-5">
            <DangerPrimaryButton text={this.props.t('global.confirm')!} fn={() => handleOverlapConfirm(selectedEvent)} />
          </div>
        </>
      </Modal>
    );

    return (
      <PageWrapper>
        <>
          <div className="space-y-5">
            <Card>
              <>
                <div className="calendar-header flex-col md:flex md:flex-row justify-between items-center space-x-3">
                  <DropSelect onSelect={this.handleMonthChange} />
                  <Autocomplete
                    resultsSize={15}
                    searchFn={this.search}
                    onSelect={(m: Meeting) => this.showMeeting(m)}
                    placeholder={"calendar.meetings.search.placeholder"}
                    isLoading={searchIsLoading}
                    errorMessage={"global.empty_results"}
                    toElementFn={(m: Meeting) => (
                      <ListElement key={m.id}>
                        <>
                          <p>
                            <BolderText text={ `${m.customerName} ${m.customerSurname}` }/>&nbsp;
                            - <BolderText text={ `${this.props.t(meetingTypeToTranslationKey(m.type))}` }/>&nbsp;
                            - <BolderText text={parseDateTime(m.startTime).date} />,&nbsp;
                            <BolderText text={`${parseDateTime(m.startTime).time} - ${parseDateTime(m.endTime).time}`} />
                          </p>
                        </>
                      </ListElement>
                    )}
                  />
                </div>
                <CalendarFooter onNewFn={showNewMeeting} />
              </>
            </Card>
            <Card>
              <>
                <div id="timezone">
                  { this.props.t("global.timezone") }: 
                  <div className="flex items-end space-x-3">
                    <Subtitle text={settings.timezone || 'UTC'} />
                    <AdminRestricted>
                      <PlainTextButton text={this.props.t("calendar.timezone.change")} onClick={() => window.location.href = '/settings'}/>
                    </AdminRestricted>
                  </div>
                </div>
                <div className="calendar-body">
                  <FullCalendar
                    longPressDelay={0}
                    plugins={[interactionPlugin, dayGridPlugin, timeGridPlugin, momentTimezonePlugin]}
                    ref={this.calendarRef}
                    events={events}
                    firstDay={1}
                    eventContent={e => this.eventContent(e, this.calendarRef.current.getApi().view.type)}
                    headerToolbar={{
                      left: "prev,today,next refresh",
                      center: "title",
                      right: "dayGridMonth,timeGridWeek,timeGridDay block",
                    }}
                    customButtons={{
                      today: {
                        text: `${this.props.t("global.today")}`,
                        click: this.handleToday,
                      },
                      block: {
                        text: `${this.props.t("global.block")}`,
                        click: this.handleNewButtonClick,
                      },
                      refresh: {
                        text: `${this.props.t("global.refresh")}`,
                        click: this.handleRefresh,
                      },
                    }}
                    buttonText={
                      {
                        today:    this.props.t("global.today")!,
                        month:    this.props.t("global.month")!,
                        week:     this.props.t("global.week")!,
                        day:      this.props.t("global.day")!,
                      }
                    }
                    initialView={view}
                    nowIndicator
                    selectable={true}
                    select={this.handleDateClick}
                    eventClick={this.handleEventClick}
                    datesSet={this.handleDatesSet}
                    businessHours={{
                      daysOfWeek: [ 1, 2, 3, 4, 5, 6 ],
                      startTime: settings.openingHours,
                      endTime: settings.closingHours,
                    }}
                    slotMinTime="06:00:00"
                    dayMaxEvents={50}
                    timeZone={settings.timezone}
                    locale={this.props.i18n.language}
                    // TODO: leads to loop of API requests
                    // validRange={{
                    //     start: '2023-08-29'
                    //   }
                    // }
                  />
                </div>
                <br/>
                <Legend />
              </>
            </Card>
          </div>
          {overlapModal}
          {selectedEvent && !showOverlapWarning && (
            <Modal modalTitle={selectedEvent.customerName || this.props.t("calendar.new_meeting")} canClose onClose={() => this.closeModal()}>
              <MeetingForm 
                meeting={selectedEvent} 
                onMeetingChange={e => this.setState({ selectedEvent: e })} 
                actionButton={actionButton(this.state.action)} 
                isDisabled={action === 'READ'} 
                timeZone={settings.timezone}
              />
            </Modal>
          )}
          {showBlockedDates && (
            <Modal modalTitle={"calendar.blocked_dates.form.new_dates"} canClose onClose={() => this.setState({ showBlockedDates: false})}>
              <BlockedDatesForm fn={this.refreshAfterBlockDates} defaultDate={this.calendarRef.current.getApi().getDate()}/>
            </Modal>
          )}
        </>
      </PageWrapper>
    );
  }
}

const connectedCalendarPage = connect(mapStateToProps)(withTranslation()(CalendarPageWrapper));

export default connectedCalendarPage;
