import React, { useEffect, useState } from "react";
import { Order, OrderDress } from "../../api/OrdersAPI";
import CONFIG from "../../config/config";
import ProductTypesAPI from "../../api/ProductTypesAPI";
import ShopClientsAPI, { ShopClient } from "../../api/ShopClientsAPI";
import SelectInput from "../SelectInput/SelectInput";
import TextArea from "../TextArea/TextArea";
import { orderStatusToTranslationKeys } from "../../shared/orderStatusToTranslationKey";
import { useTranslation } from "react-i18next";
import { paymentStatusToTranslationKey, productTypeToTranslationKey } from "../../shared/paymentStatusToTranslationKey";
import 'react-datepicker/dist/react-datepicker.css';
import ListContainer from "../ListContainer/ListContainer";
import FileUploadWrapper from "../FileUploadWrapper/FileUploadWrapper";
import SectionTitle from "../SectionTitle/SectionTitle";
import OrderTrackingForm from "../OrderTrackingForm/OrderTrackingForm";
import CustomDatePicker from "../CustomDatePicker/CustomDatePicker";
import ElementActions from "../ElementActions/ElementActions";
import ListElement from "../ListElement/ListElement";
import IconButton from "../IconButton/IconButton";
import deleteIcon from "../../assets/images/bin.svg";
import fileIcon from "../../assets/images/file.svg";
import { Icon } from "../Icon/Icon";
import { toastService } from "../../services/ToastService";
import { format } from "date-fns";
import { PrivateResourceLink } from "../PrivateResourceLink/PrivateResourceLink";
import { Dot } from "../Dot/Dot";
import SmallCheckBox from "../SmallCheckBox/SmallCheckBox";
import { isValidText } from "../../shared/validation";
import useFeatureFlag from "../../hooks/usePermissionFlag";
import CustomerInput from "../CustomerInput/CustomerInput";
import { ItemAutocomplete } from "../ItemAutocomplete/ItemAutocomplete";
import { ItemVariant } from "../../api/CollectionItemVariantsAPI";

type Props = {
  order: Order | null,
  onOrderChange?: (value: Order) => void,
  isDisabled?: boolean,
  actionButton?: JSX.Element
};

function OrderForm({ order, onOrderChange = () => null, isDisabled = false, actionButton }: Props) {

  const { t } = useTranslation();

  const { hasAccess } = useFeatureFlag(['update-orders']);
  const [showTracking, setShowTracking] = useState(false);
  const [types, setTypes] = useState<string[]>([]);
  const [shops, setShops] = useState<ShopClient[]>([]);
  const [variants, setVariants] = useState<ItemVariant[]>([]);

  useEffect(() => {
    setShowTracking(!!order && !!order.id);
    const load = async () => {
      const shopsResp = await ShopClientsAPI.get();
      const typesResp = await ProductTypesAPI.get();
      setTypes(typesResp.data);
      setShops(shopsResp.data.map(s => s));
    };
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeLink = (url: string) => {
    const updatedAttachments = order?.attachmentUrls?.filter((attachmentUrl) => attachmentUrl !== url);
    onOrderChange({ ...order, attachmentUrls: updatedAttachments } as Order);
  }

  const removeFile = (fileName: string) => {
    const updatedFiles = order?.files?.filter((file) => file.name !== fileName);
    onOrderChange({ ...order, files: updatedFiles } as Order);
  }

  const processFile = async (files: File[]) => {
    try {
      const newFiles = [...order?.files || [], ...files];
      onOrderChange({ ...order, files: newFiles } as Order);
    } catch (error) {
      toastService.showToast(t("toast.errors.add_file"), 'error');
    }
  };

  const disabledActionButton = actionButton
    ? React.cloneElement(
      actionButton,
      {},
      React.Children.map(actionButton.props.children, (child) => {
        return child ? React.cloneElement(child, { isDisabled: isDisabled }) : null;
      })
    )
    : null;

  return (
    <>
      {/* <div className="flex justify-end">
        <Invoice isDisabled={!hasAccess} />
      </div> */}
      <div className="container flex space-x-5 flex-wrap mb-5">
        <div className="flex-1 space-y-1">
          <SectionTitle text={t("orders.orders_basket.add_form.general_data")} />
          <CustomerInput
            onCustomerSelect={c => 
              onOrderChange({ ...order, customer: c, clientName: `${c?.name}${c?.surname ? ` ${c?.surname}` : ''}`} as Order)
            }
            label={"orders.orders_basket.add_form.name"}
            onChange={(e: any) => onOrderChange({ ...order, clientName: e.currentTarget.value } as Order)}
            errorMessage={"errors.too_short"}
            defaultValue={order?.clientName || ''}
            isDisabled={isDisabled || !hasAccess}
          />
          {/* <DressAutocomplete
            onChange={e => {
              const { id, ...rest } = e;
              const newDress = { ...rest, dressPlanId: id };
              onOrderChange({ ...order, dress: newDress } as Order);
            }}
            label={"orders.orders_basket.add_form.dress"}
            defaultValue={(order?.dress as OrderDress)?.name}
            isDisabled={isDisabled || !hasAccess}
          /> */}
          <ItemAutocomplete
            onChange={e => {
              setVariants(e.variations || []);
              const { id, ...rest } = e;
              const newDress = { ...rest, dressPlanId: id, type: "CATALOG_ITEM" };
              onOrderChange({ ...order, dress: newDress } as Order);
            }}
            label={"orders.orders_basket.add_form.dress"}
            defaultValue={(order?.dress as OrderDress)?.name}
            isDisabled={isDisabled || !hasAccess}
          />
          { variants && variants.length > 0 
           && 
            <SelectInput values={variants.map(v => { return { value: v.name, readableValue: v.name};} )}
              onChange={e => {
                const variantId = variants.filter(v => v.name === e.target.value).map(v => v.id)[0];
                const itemVariant = { dressPlanId: String(variantId), type: "CATALOG_ITEM_VARIANT", name: e.target.value };
                onOrderChange({ ...order, dress: itemVariant } as Order);
              }}
              label={"orders.orders_basket.add_form.dress.variant"}
              isDisabled={isDisabled || !hasAccess}
            />
          }
          <CustomDatePicker
            isDisabled={isDisabled || !hasAccess}
            label="orders.orders_basket.add_form.delivery_time"
            value={order?.deliveryDate}
            onChange={c => onOrderChange({ ...order, deliveryDate: format(c, 'yyyy-MM-dd') } as Order)}
          />
          <div className="flex flex-wrap space-x-5">
            <SelectInput
              values={shops.map(e => { return { value: String(e.id!), readableValue: e.name } })}
              onChange={e => 
                onOrderChange(
                  { ...order, 
                    linkedShopId: Number(e.currentTarget.value), 
                    shopName: shops.filter(s => String(s.id) === e.currentTarget.value)[0].name 
                  } as Order)
              }
              label={"orders.orders_basket.add_form.salon"}
              defaultValue={String(order?.linkedShopId)}
              isDisabled={isDisabled || !hasAccess}
            />
            <SelectInput
              values={types.map(e => { return { value: e, readableValue: t(productTypeToTranslationKey(e)) } })}
              onChange={e => onOrderChange({ ...order, type: e.target.value } as Order)}
              label={"orders.orders_basket.add_form.type"}
              defaultValue={order?.type}
              isDisabled={isDisabled || !hasAccess}
            />
          </div>
          <div className="flex flex-wrap space-x-5">
            <SelectInput values={CONFIG.ORDERS.PAYMENT_STATUS.map(e => { return { value: e, readableValue: t(paymentStatusToTranslationKey(e)) } })}
              onChange={e => onOrderChange({ ...order, paymentStatus: e.target.value } as Order)}
              label={"orders.orders_basket.add_form.payment_status"}
              defaultValue={order?.paymentStatus}
              isDisabled={isDisabled || !hasAccess}
            />
            <SelectInput values={CONFIG.ORDERS.STATUS.map(e => { return { value: e, readableValue: t(orderStatusToTranslationKeys(e)) } })}
              onChange={e => onOrderChange({ ...order, status: e.target.value } as Order)}
              label={"orders.orders_basket.add_form.status"}
              defaultValue={order?.status}
              isDisabled={isDisabled || !hasAccess}
            />
          </div>
          <TextArea
            label={"orders.orders_basket.add_form.notes"}
            validationFn={(value) => !value || (!!value &&  isValidText(value, 0, 1000))}
            onChange={e => onOrderChange({ ...order, notes: e.currentTarget.value } as Order)}
            errorMessage={"errors.too_short"}
            defaultValue={order?.notes || ''}
            isDisabled={isDisabled || !hasAccess}
          />
          <div className="flex flex-wrap space-x-5">
              <SmallCheckBox
                defaultValue={order?.prioritized}
                isDisabled={isDisabled || !hasAccess}
                label={`orders.orders_basket.add_form.priority`}
                onChange={e => onOrderChange({ ...order, prioritized: e.target.checked } as Order)}
              />
              <SmallCheckBox
                isDisabled={isDisabled || !hasAccess}
                defaultValue={order?.pendingMeasures}
                label={`orders.orders_basket.add_form.missing_measures`}
                onChange={e => onOrderChange({ ...order, pendingMeasures: e.target.checked } as Order)}
              />
          </div>
          <div>
          <p>{`${t("orders.orders_basket.add_form.files")} [${order?.attachmentUrls?.length || 0}] (${CONFIG.MAX_FILE_UPLOAD}MB):`}</p>
            <ListContainer
              elements={order?.attachmentUrls || []}
              toElementFn={(url: string, index: number) =>
                <ListElement>
                  <ElementActions actions={
                    isDisabled || !hasAccess ? <></> :
                      <IconButton onClick={() => removeLink(url)}>
                        <Icon url={deleteIcon} cssStyles="w-5" />
                      </IconButton>
                  }>
                  <span><Icon url={fileIcon} cssStyles="w-5" /> <PrivateResourceLink file={url} /></span>
                  </ElementActions>
                </ListElement>
              }
            />
            <ListContainer
              elements={order?.files || []}
              toElementFn={(file: File, index: number) =>
                <ListElement>
                  <ElementActions actions={
                    isDisabled || !hasAccess ? <></> :
                      <IconButton onClick={() => removeFile(file.name)}>
                        <Icon url={deleteIcon} cssStyles="w-5" />
                      </IconButton>
                  }>
                  <span><Dot /> {file.name}</span>
                  </ElementActions>
                </ListElement>
              }
            />
            <FileUploadWrapper
              label={`${t("orders.orders_basket.add_form.files.button")}.`}
              onChange={processFile}
              isDisabled={isDisabled || !hasAccess}
              allowedMultipleFiles
              maxSize={50}
            />
          </div>
        </div>
        { showTracking && <OrderTrackingForm 
          onOrderChange={(e: string) => onOrderChange({ ...order, status: e } as Order)} 
          order={order} 
          orderId={order?.id!} 
          shopId={order?.shopIdV2!} 
          isDisabled={isDisabled} 
          />}
      </div>
      {disabledActionButton}
    </>
  );
}

export default OrderForm;
