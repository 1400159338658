import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomersAPI, { Customer } from "../api/CustomersAPI";
import { toastService } from "../services/ToastService";

export type Filters = {
    employee?: string;
};

type ReturnedProps = {
  isLoading: boolean,
  customers: Customer[],
  addFn: (customer: Customer) => Promise<any>,
  updateFn: (customer: Customer) => Promise<any>,
  removeFn: (customer: Customer) => Promise<any>,
};

export function useGetCustomers({ employee }: Filters): ReturnedProps {
  const { t } = useTranslation();
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const addFn = async (customer: Customer) => {
    try {
      const resp = await CustomersAPI.add(customer);
      setCustomers(prevCustomers => [...prevCustomers, resp.data]);
    } catch (err) {
      toastService.showToast(t("toast.errors.customers.add"), 'error');
    }
  };

  const updateFn = async (customer: Customer) => {
    try {
      const resp = await CustomersAPI.update(customer.id!, customer);
      setCustomers(prevCustomers => prevCustomers.map(c => c.id === resp.data.id ? resp.data : c));
    } catch (err) {
      toastService.showToast(t("toast.errors.customers.update"), 'error');
    }
  };

  const removeFn = async (customer: Customer) => {
    try {
      await CustomersAPI.remove(customer.id!);
      setCustomers(prevCustomers => prevCustomers.filter(c => c.id !== customer.id));
    } catch (err) {
      toastService.showToast(t("toast.errors.customers.remove"), 'error');
    }
  };

  const load = useCallback(() => {
    setIsLoading(true);
    CustomersAPI.get({ employee })
      .then(response => {
        setCustomers(response.data);
      })
      .catch(() => {
        toastService.showToast(t("toast.errors.customers.load"), 'error');
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee]);

  useEffect(() => {
    load();
  }, [load]);

  return { isLoading, customers, addFn, updateFn, removeFn };
};
