import React from 'react';
import useFeatureFlag from '../../hooks/usePermissionFlag';

type PermissionFlagsProps = {
  requiredFlags?: string[];
  children: JSX.Element;
};

export const PermissionFlags: React.FC<PermissionFlagsProps> = ({ requiredFlags, children }) => {
  const { hasAccess } = useFeatureFlag(requiredFlags);

  return hasAccess ? <>{children}</> : null;
};
