import React from 'react';
import { useTranslation } from 'react-i18next';
import { Provider } from '../../api/ProvidersAPI';
import { useFormValidation } from '../../hooks/useFormValidation';
import { isValidEmail, isValidPhone, isValidText } from '../../shared/validation';
import InputShortText from "../InputShortText/InputShortText";

type Props = {
  provider: Provider,
  onProviderFormSubmit: (value: any) => void,
  isDisabled?: boolean,
  actionButton?: JSX.Element
};

const getInitialState = (provider?: Provider) => {
  if (provider) {
    return {
      name: isValidText(provider.name, 2),
      email: isValidEmail(provider.email),
    };
  }
};

function ProviderForm({ provider, onProviderFormSubmit, isDisabled = false, actionButton }: Props) {

  const { t } = useTranslation();
  const requiredFields = ['name', 'email'];
  const initialState = getInitialState(provider);
  const { updateFieldValidation, isFormValid } = useFormValidation(requiredFields, initialState);

  const disabledActionButton = actionButton
  ? React.cloneElement(
      actionButton,
      {},
      React.Children.map(actionButton.props.children, (child) => {
        return child ? React.cloneElement(child, { isDisabled: !isFormValid() }) : null;
      })
    )
  : null;

  return (
    <div className="flex flex-col flex-wrap space-y-5">
      <div className="flex-1">
        <InputShortText
          label={`${t("providers.form.name")}${requiredFields.includes('name') ? '*' : ''}`}
          validationFn={(value) => !!value && isValidText(value, 2)}
          onChange={e => onProviderFormSubmit({ ...provider, name: e.currentTarget.value })}
          onValidationChange={(isValid) => updateFieldValidation('name', isValid)}
          errorMessage={"errors.too_short"}
          defaultValue={provider?.name || ''}
          isDisabled={isDisabled}
        />
        <InputShortText
          label={`${t("providers.form.email")}${requiredFields.includes('email') ? '*' : ''}`}
          validationFn={(value) => !!value && isValidEmail(value)}
          onChange={e => onProviderFormSubmit({ ...provider, email: e.currentTarget.value })}
          onValidationChange={(isValid) => updateFieldValidation('email', isValid)}
          errorMessage={"errors.invalid_email"}
          defaultValue={provider?.email || ''}
          isDisabled={isDisabled}
        />
        <InputShortText
          label={"providers.form.phone"}
          validationFn={(value) => !!value && isValidPhone(value)}
          onChange={e => onProviderFormSubmit({ ...provider, phone: e.currentTarget.value })}
          onValidationChange={(isValid) => updateFieldValidation('phone', isValid)}
          errorMessage={"errors.invalid_phone"}
          defaultValue={provider?.phone || ''}
          isDisabled={isDisabled}
        />
      </div>
      { disabledActionButton }
    </div>
  );
}

export default ProviderForm;
