import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContractTemplate, Instalment } from '../../hooks/useContractTemplate';
import { isValidText } from '../../shared/validation';
import Card from '../Card/Card';
import { DraggableList } from '../DraggableList/DraggableList';
import ElementActions from '../ElementActions/ElementActions';
import { Icon } from '../Icon/Icon';
import IconButton from '../IconButton/IconButton';
import InputNumber from '../InputNumber/InputNumber';
import InputShortText from '../InputShortText/InputShortText';
import ListContainer from '../ListContainer/ListContainer';
import ListElement from '../ListElement/ListElement';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import deleteIcon from '../../assets/images/bin.svg';
import SelectInput from '../SelectInput/SelectInput';
import SmallCheckBox from '../SmallCheckBox/SmallCheckBox';
import Subtitle from '../Subtitle/Subtitle';
import TextArea from '../TextArea/TextArea';
import TinyText from '../TinyText/TinyText';
import { PaymentMethodType, paymentMethodTypeToTranslationKey } from '../../shared/paymentMethodToTranslationKey';
import { Label } from '../Label/Label';
import NoData from '../NoData/NoData';

type TemplateProps = {
    contractTemplate: ContractTemplate;
    updateContractTemplate: (value: any) => void;
};

const ContractTemplateEditor: React.FC<TemplateProps> = ({ contractTemplate, updateContractTemplate }) => {

  const { t } = useTranslation();

  const [percentage, setPercentage] = useState<number>(100);
  const [method, setMethod] = useState<PaymentMethodType>("CARD");

  const handleRemoveInstalment = (indexToRemove: number) => {
    const updatedInstalments = contractTemplate.payment.instalments.filter((_, index) => index !== indexToRemove);
    updateContractTemplate({
        payment: {
            ...contractTemplate.payment,
            instalments: updatedInstalments
        }
    });
  };

  const handleAddInstalment = () => {
      if (percentage) {
          const newInstalment = {
              percentage: parseFloat(percentage.toString()),
              method
          };
          updateContractTemplate({
              payment: {
                  ...contractTemplate.payment,
                  instalments: [...contractTemplate?.payment?.instalments || [], newInstalment]
              }
          });
          setPercentage(0);
      }
  };

  return (
    <>
        <Card>
            <>
                <Subtitle text={t('contract_template.general.title')} />
                <TinyText text={t('contract_template.general.hint')} />
                <div className="flex space-x-3">
                    <InputShortText
                    defaultValue={contractTemplate?.general?.companyName}
                    label={t("contract_template.general.company_name")!}
                    onChange={(c) => 
                        updateContractTemplate({
                            general: { ...contractTemplate.general, companyName: c.target.value },
                        })
                    }
                    />
                    <InputShortText
                    defaultValue={contractTemplate?.general?.taxID}
                    label={t("contract_template.general.company_tax_id")!}
                    onChange={(c) =>
                        updateContractTemplate({
                        general: { ...contractTemplate.general, taxID: c.target.value },
                        })
                    }
                    />
                </div>

                <TextArea
                validationFn={value => !!value && isValidText(value, 5)}
                defaultValue={contractTemplate?.general?.companyAddress}
                label={t("contract_template.general.company_address")!}
                onChange={(c) =>
                    updateContractTemplate({
                    general: { ...contractTemplate.general, companyAddress: c.target.value },
                    })
                }
                />

                <Label text={t("contract_template.general.logo")!} />
                { !contractTemplate?.general?.logoUrl ? 
                    <Icon url={contractTemplate?.general?.logoUrl} cssStyles="w-48 min-w-full max-w-full mr-3"/> 
                    : <NoData message={t("contract_template.general.logo.empty")} />
                }
            </>
        </Card>

        <Card>
            <>
                <Subtitle text={t('contract_template.order.title')} />
                <TinyText text={t('contract_template.order.hint')} />
                <DraggableList 
                    placeholder={t("contract_template.order.form.new_condition.label")!}
                    buttonText={t("contract_template.order.form.button")}
                    items={contractTemplate?.conditions?.conditions || []} 
                    setItems={(items) => updateContractTemplate({ conditions: { conditions: items } })}
                />
            </>
        </Card>

        <Card>
            <>
                <Subtitle text={t('contract_template.payment_method.title')} />
                
                <TinyText text={t('contract_template.payment_method.other_specifications.hint')} />
                <ListContainer
                    elements={contractTemplate?.payment?.instalments || []}
                    toElementFn={(instalment: Instalment, index: number) =>
                        <ListElement key={index}>
                            <ElementActions actions={
                                <IconButton onClick={() => handleRemoveInstalment(index)}>
                                    <Icon url={deleteIcon} cssStyles="w-5" />
                                </IconButton>
                            }>
                                    <span>{index + 1}. {instalment.percentage}% - {t(paymentMethodTypeToTranslationKey(instalment.method))}</span>
                            </ElementActions>
                        </ListElement>
                    }
                />
                <div className="flex space-x-3">
                    <InputNumber
                        defaultValue={percentage}
                        validationFn={(value) => !!value && value >= 0} 
                        label={t("contract_template.payment_method.percentage_label")!}
                        onChange={(e) => setPercentage(Number(e.target.value))}
                    />
                    <SelectInput
                        values={[
                            { value: "CARD", readableValue: t(paymentMethodTypeToTranslationKey("CARD")) },
                            { value: "CASH", readableValue: t(paymentMethodTypeToTranslationKey("CASH")) },
                            { value: "TRANSFER", readableValue: t(paymentMethodTypeToTranslationKey("TRANSFER")) }
                        ]}
                        onChange={(e: any) => setMethod(e.currentTarget.value)}
                        label={t("contract_template.payment_method.method_label")!}
                    />
                </div>
                <PrimaryButton fn={handleAddInstalment} text={t("contract_template.payment_method.add_button")!}/>
                <br/>
                <TextArea
                    validationFn={value => !!value && isValidText(value, 5)}
                    defaultValue={contractTemplate?.payment?.paymentSpecifications}
                    label={t('contract_template.payment_method.other_specifications')!}
                    onChange={(c) =>
                        updateContractTemplate({
                        payment: { ...contractTemplate.payment, paymentSpecifications: c.target.value },
                        })
                    }
                />
            </>
        </Card>

        <Card>
            <>
                <Subtitle text={t('contract_template.remarks.title')} />
                <TinyText text={t('contract_template.remarks.hint')} />
                <DraggableList 
                    placeholder={t("contract_template.remarks.form.new_condition.label")!}
                    buttonText={t("contract_template.remarks.form.button")}
                    items={contractTemplate?.remarks?.conditions || []} 
                    setItems={(items) => updateContractTemplate({ remarks: { conditions: items } })}
                />
            </>
        </Card>

        <Card>
            <>
                <Subtitle text={t('contract_template.additional_requirements.title')} />
                <TinyText text={t('contract_template.additional_requirements.requires_follow_up.hint')} />
                <SmallCheckBox
                defaultValue={contractTemplate?.additionalRequirements?.requiresFollowUpMeeting}
                label={t('contract_template.additional_requirements.requires_follow_up')!}
                onChange={(c) =>
                    updateContractTemplate({
                    additionalRequirements: { ...contractTemplate.additionalRequirements, requiresFollowUpMeeting: c.target.checked },
                    })
                }
                />
                <TinyText text={t('contract_template.additional_requirements.requires_employee_signature.hint')} />
                <SmallCheckBox
                defaultValue={contractTemplate?.additionalRequirements?.requiresEmployeeSignature}
                label={t('contract_template.additional_requirements.requires_employee_signature')!}
                onChange={(c) =>
                    updateContractTemplate({
                    additionalRequirements: { ...contractTemplate.additionalRequirements, requiresEmployeeSignature: c.target.checked },
                    })
                }
                />
            </>
        </Card>
    </>
  );
}

export default ContractTemplateEditor;
