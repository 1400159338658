import { Item } from '../../api/CollectionsAPI';
import { BolderText } from '../BolderText/BolderText';

type Props = {
  item: Item,
  actions?: JSX.Element
}

function MarketingCollectionItemElement({ item, actions }: Props) {
  const imageUrl = item.media && item.media[0] ? item.media[0] : null;
  const thumbnailUrl = imageUrl ? imageUrl.replace(/(\.[\w\d]+)$/, '-thumbnail$1') : undefined;

  return (
    <div className="flex justify-between space-x-3 h-5">
      <div className="flex space-x-3 items-center">
        { imageUrl && 
          <img src={thumbnailUrl} onError={(e) => e.currentTarget.src = imageUrl} className="w-8 h-8 rounded" alt="model" />
        }
        <BolderText text={item.name} />
        { item.collectionName && <p>{ item.collectionName } </p>}
      </div>
      <span className="flex space-x-5">
        { actions }
      </span>
    </div>
  );
}

export default MarketingCollectionItemElement;
