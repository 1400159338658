import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from '../Card/Card';
import ListContainer from '../ListContainer/ListContainer';
import PrimaryButton from '../PrimaryButton/PrimaryButton';

type WorkAreaProps = {
  items: any[],
  renderItem: (item: any) => JSX.Element,
  actions: JSX.Element,
  callbackFn: (newItems: any[]) => void,
  onItemToggle: (item: any) => void
};


const WorkArea: React.FC<WorkAreaProps> = ({ items, callbackFn, onItemToggle, renderItem, actions }) => {
  const [showList, setShowList] = useState(false);
  const { t } = useTranslation();

  const close = () => {
    setShowList(prev => !prev);
    callbackFn && callbackFn([]);
  }

  return (
    <div style={{ position: 'fixed', bottom: '10px', right: '10px' }}>
      {items.length > 0 && (
        <>
          {showList && (
            <Card>
                <div className="overflow-y-auto max-h-[300px]">
                    <ListContainer
                        elements={items}
                        toElementFn={item => (
                        <div 
                          key={item.id} 
                          className="bg-as-pink-100 rounded-lg m-1 p-2 shadow-md hover:shadow-lg transition-shadow duration-300"
                          onClick={() => onItemToggle(item)}
                        >
                            {renderItem(item)}
                        </div>
                        )}
                    />
                    <br/>
                    <div className="mt-2">{actions}</div>
                </div>
            </Card>
          )}
          <PrimaryButton fn={() => close()} text={t("work_area.unselect_all")!} />&nbsp;
          <PrimaryButton fn={() => setShowList(!showList)} text={`${t("work_area.selected_items")!} (${items.length})`} />
        </>
      )}
    </div>
  );
}

export default WorkArea;
