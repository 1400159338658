import { useState } from "react";
import { useTranslation } from "react-i18next";
import OrdersAPI, { Order } from "../../api/OrdersAPI";
import { Icon } from "../Icon/Icon";
import IconButton from "../IconButton/IconButton";
import ListContainer from "../ListContainer/ListContainer";
import ListElement from "../ListElement/ListElement";
import { Modal } from "../Modal/Modal";
import OrderElement from "../OrderElement/OrderElement";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import deleteIcon from "../../assets/images/bin.svg";
import { toastService } from "../../services/ToastService";
import CONFIG from "../../config/config";
import { format, parseISO } from "date-fns";
import OrderForm from "../OrderForm/OrderForm";
import FilesAPI from "../../api/FilesAPI";
import { isLargeFile, urlAndS3Encode } from "../../shared/fileUtils";
import { simplifyUrl } from "../../shared/urlUtils";
import * as amplitude from '@amplitude/analytics-browser';

const EmptyOrder: Order = {
  clientName: '',
  deliveryDate: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
  disable: false,
  prioritized: false,
  pendingMeasures: false,
  visible: true,
  shop: 'V2',
  shopIdV2: 'ATELIER',
  status: CONFIG.ORDERS.DEFAULT_STATUS,
  type: CONFIG.DEFAULT_PRODUCT_TYPE,
  paymentStatus: CONFIG.ORDERS.DEFAULT_PAYMENT_STATUS
};

type Props = {
  fn?: () => void
}

function OrderBasket({ fn = () => null }: Props) {

  const { t } = useTranslation();

  const [showForm, setShowForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState<Order[]>([]);
  const [wipOrder, setWipOrder] = useState<Order | null>(EmptyOrder);
  
  const addOrder = () => {
    setShowForm(prev => !prev);
    setOrders(prev => [...prev, wipOrder!]);
    setWipOrder(EmptyOrder);
  };

  const remove = (index: number) => {
    setOrders(prevOrders => {
      const nextOrders = prevOrders.filter((_, idx) => idx !== index);
      return nextOrders;
    });
  };

  // TODO: refactor with useGetOrders#uploadFileAndGetUrl
  const uploadFileAndGetUrl = async (file: File) => {
    const fileWithS3Encoding = urlAndS3Encode(file.name);
    const displayFileName = decodeURI(fileWithS3Encoding);
    try {
      if (isLargeFile(file)) {
        const uploadUrl = await (await FilesAPI.getUploadUrl(displayFileName)).data.url;
        const response = await FilesAPI.uploadHeavyFile(uploadUrl, file, fileWithS3Encoding);
        if (response.status === 200) {   
          return simplifyUrl(uploadUrl);
        } else {
          throw new Error();
        }
      } else {
        const formData = new FormData();
        formData.append('file', file);
        const response = await OrdersAPI.uploadFile({
          filename: fileWithS3Encoding,
          file: formData,
        });
        toastService.showToast(`${t("toast.success.add_file")}: ${displayFileName}`);
        return response.data.url;
      }
    } catch (error) {
      toastService.showToast(`${t("toast.errors.add_file")}: ${fileWithS3Encoding}`, "error");
      return;
    }
  };
  
  const uploadBatch = async () => {
    amplitude.track('Create Order batch triggered');
    setIsLoading(true);
    let uploadedFiles = 0;
    let totalFiles = 0;
    for (const order of orders) {
      totalFiles += order?.files?.length || 0;
    }
    
    const newOrders = await Promise.all(orders.map(async order => {
      if (!!order.files) {
        const attachmentUrls = await Promise.all(order.files.map(async file => {
          try {
            const fullUrl = await uploadFileAndGetUrl(file);
            uploadedFiles++;
            toastService.showToast(`${t("toast.success.add_file")} ${uploadedFiles}/${totalFiles}`);
            if (fullUrl && fullUrl.length > 50) {
              // it's been uploaded as 'HeavyFile' and contains the entire S3 url
              const url = new URL(fullUrl!);
              const filename = url.pathname.split('/').pop();
              return filename;
            } else {
              return fullUrl;
            }
          } catch (err) {
            toastService.showToast(`${t("toast.errors.add_file")}: ${file.name}`, 'error');
            return;
          }
        }));
        const filteredUrls = attachmentUrls
          .filter((url): url is string => url !== null)
          .map((url) => url?.replace(/\s+/g, '_'));
        return {
          ...order,
          attachmentUrls: filteredUrls,
        };  
      } else {
        return order;
      }
    }));
    
    try {
      await OrdersAPI.addBatch(newOrders);
      toastService.showToast(t("toast.success.orders.batch.add"));
      setOrders([]);
      fn();
    } catch (error) {
      toastService.showToast(t("toast.errors.orders.batch.add"), "error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="border-stone-200 border rounded p-5">
      { showForm &&
        <Modal modalTitle={t("orders.orders_basket.new_order")} canClose onClose={() => {setWipOrder(EmptyOrder); setShowForm(false)}}>
          <>
            <OrderForm order={wipOrder} onOrderChange={setWipOrder}/>
            <div className="flex justify-center mt-5">
              <PrimaryButton text={t("orders.orders_basket.add_form.button")!} fn={addOrder}/>
            </div>
          </>
        </Modal>
      }
      <ListContainer 
          elements={orders}
          toElementFn={(or: Order, i: number) => 
            <ListElement key={or.clientName}>
                <OrderElement order={or} actions={
                  <>
                    {/* TODO */}
                    {/* <IconButton onClick={() => {setWipOrder(or); setShowForm(true)}}><Icon url={editIcon} cssStyles="w-5"/></IconButton> */}
                    <IconButton onClick={() => remove(i)}><Icon url={deleteIcon} cssStyles="w-5"/></IconButton>
                  </>
                }/>
            </ListElement>
          }
      />
      <div className="flex space-x-1 mt-1">
        { isLoading ?
          <div className="flex justify-center py-5"><PrimaryButton isDisabled text={"..."} isLoading/></div> :
          <>
            <PrimaryButton text={`${t("orders.orders_basket.new_order")!}`} fn={() => setShowForm(true)}/>
            <PrimaryButton text={`${t("orders.orders_basket.add_batch")!}`} isDisabled={!(orders.length > 0) || isLoading} fn={uploadBatch}/>
          </>
        }
      </div>
    </div>
  );
}

export default OrderBasket;
