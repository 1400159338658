import { useState } from 'react';
import CollectionItemVariantsAPI, { ItemVariant } from '../api/CollectionItemVariantsAPI';

type Props = {
    collectionId: number,
    itemId: number,
    initialVariants: ItemVariant[],
    onNewCollectionItemFn?: (e: ItemVariant[]) => any;
}

const useVariantActions = ({ collectionId, itemId, initialVariants, onNewCollectionItemFn }: Props) => {
  const [variants, setVariants] = useState(initialVariants);
  const [loading, setLoading] = useState(false);

  const addVariant = async (variant: ItemVariant) => {
    if (itemId) {
      setLoading(true);
      try {
        const response = await CollectionItemVariantsAPI.add(collectionId, itemId, variant);
        setVariants(prev => [...prev, response.data]);
      } catch (error) {
        console.error('Failed to add variant', error);
      } finally {
        setLoading(false);
      }
    } else {
      if (onNewCollectionItemFn) {
        const newVariants = [...variants, variant];
        onNewCollectionItemFn(newVariants);
        setVariants(() => newVariants);
      }
    }
  };

  const updateVariant = async (variantId: number, updatedVariant: ItemVariant) => {
    if (itemId) {
      try {
        setVariants(prev => prev.map(v => v.id === variantId ? { ...v, ...updatedVariant } : v));
        await CollectionItemVariantsAPI.update(collectionId, itemId, variantId, updatedVariant);
      } catch (error) {
        console.error('Failed to update variant', error);
      } finally {
        setLoading(false);
      }
    } else {
      // TODO: to update; for now hidden in UX
    }
  };

  const removeVariant = async (variantId: number) => {
    if (itemId) {
      try {
        setVariants(prev => prev.filter(v => v.id !== variantId));
        await CollectionItemVariantsAPI.remove(collectionId, itemId, variantId);
      } catch (error) {
        console.error('Failed to delete variant', error);
      } finally {
        setLoading(false);
      }
    } else {
      if (onNewCollectionItemFn) {
        const filtered = variants.filter((_, index) => index !== variantId);
        onNewCollectionItemFn(filtered);
        setVariants(() => filtered);
      }
    }
  };

  return { variants, setVariants, addVariant, updateVariant, removeVariant, loading };
};

export default useVariantActions;
