import React, { useState, useEffect, ReactNode } from 'react';
import { Transition } from 'react-transition-group';

type ExpandableSectionProps = {
    collapsedContent: ReactNode;
    expandedContent: ReactNode;
    duration?: number;
    onExpand?: () => void,
    defaultState?: boolean;
}

const ExpandableSection = ({
    collapsedContent,
    expandedContent,
    duration = 300,
    onExpand,
    defaultState = false
}: ExpandableSectionProps) => {
    const [isExpanded, setIsExpanded] = useState(defaultState);
    const [overflow, setOverflow] = useState<'hidden' | 'auto'>('hidden');

    const defaultStyle = {
        transition: `max-height ${duration}ms ease-in-out`,
        overflow: 'hidden',
    };

    const transitionStyles = {
        entering: { maxHeight: '50vh' },
        entered:  { maxHeight: '50vh' },
        exiting:  { maxHeight: 0 },
        exited:  { maxHeight: 0 },
        unmounted: { maxHeight: 0 },
    };

    useEffect(() => {
        if (isExpanded) {
            const timer = setTimeout(() => setOverflow('auto'), duration);
            return () => clearTimeout(timer);
        } else {
            setOverflow('hidden');
        }
    }, [isExpanded, duration]);

    const toggleExpansion = () => { setIsExpanded(!isExpanded); onExpand && onExpand(); }

    return (
        <div className="w-full cursor-pointer">
            <div onClick={toggleExpansion}>
                { collapsedContent }
            </div>
            <Transition in={isExpanded} timeout={duration}>
                {state => (
                    <div style={{
                        ...defaultStyle,
                        ...transitionStyles[state],
                        overflow,
                    }}>
                        {isExpanded ? expandedContent : null}
                    </div>
                )}
            </Transition>
        </div>
    );
};

export default ExpandableSection;
