import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DressesAPI, { DressItem } from "../api/DressesAPI";
import { CancelToken } from "../api/SimpleAPIClient";
import { toastService } from "../services/ToastService";
import { useImageUploader } from "./useImageUploader";

export enum SORT { DELIVERY_ASC };

export type YearProductionCollection = {
  id: number,
  dresses: DressItem[]
}

type ReturnedProps = {
  isLoading: boolean,
  yearCollections: YearProductionCollection[],
  searchDresses: any,
  addDress: (newDress: DressItem) => Promise<void>,
  removeDress: (dressId: number) => Promise<void>,
  updateDress: (updatedDress: DressItem) => Promise<void>,
  createCatalog: (selectedItems: number[]) => Promise<void>
};

export function useGetProductionDresses(): ReturnedProps {
  
  const { t } = useTranslation();
  const [yearCollections, setDresses] = useState<YearProductionCollection[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { uploadImage } = useImageUploader('', (resp: any) => resp.data, "dressPlan");

  const addImageToDress = async (dress: DressItem, file: File): Promise<DressItem> => {
    const dressWithImage = await uploadImage(file, `dresses/${dress.id}/files/images`);
    return dressWithImage;
  };

  const addDress = async (newDress: DressItem) => {
    setIsLoading(true);
    try {
      let theDress = (await DressesAPI.add(newDress)).data;
      if (!!newDress.files) {
        for (let tempFile of newDress.files || []) {
          const urlOrUpdatedDress = await addImageToDress(theDress, tempFile);
          if (typeof urlOrUpdatedDress === 'string') {
            theDress.media = theDress.media || [];
            theDress.media.push(urlOrUpdatedDress);
          } else {
            theDress.media = urlOrUpdatedDress.media;
          }
        }
        await DressesAPI.update(theDress.id!, theDress);
        setDresses(prevDresses => {
          const dressYear = theDress.year;
          let yearCollection = prevDresses.find(col => col.id === dressYear);
    
          if (!yearCollection) {
            yearCollection = { id: dressYear, dresses: [] };
            prevDresses.push(yearCollection);
          }
    
          yearCollection.dresses.push(theDress);
          return [...prevDresses];
        });
      }
    } catch (error) {
      toastService.showToast(t("toast.errors.product_plan.add"), 'error');
    } finally {
      setIsLoading(false);
    }
  };
  
  const removeDress = async (dressId: number) => {
    try {
      await DressesAPI.remove(dressId);
      setDresses(prevDresses => {
        for (let yearCollection of prevDresses) {
          const dressIndex = yearCollection.dresses.findIndex(dress => dress.id === dressId);
          if (dressIndex > -1) {
            yearCollection.dresses.splice(dressIndex, 1);
            break;
          }
        }
  
        return [...prevDresses];
      });
    } catch (error) {
      toastService.showToast(t("toast.errors.product_plan.remove"), 'error');
    }
  };
  
  const updateDress = async (updatedDress: DressItem) => {
    setIsLoading(true);
    let theDress = { ...updatedDress };
    try {
      theDress = (await DressesAPI.update(theDress.id!, theDress)).data;
      for (let tempFile of updatedDress.files || []) {
        const urlOrUpdatedItem = await addImageToDress(theDress, tempFile);
        if (typeof urlOrUpdatedItem === 'string') {
          theDress.media = theDress.media || [];
          theDress.media.push(urlOrUpdatedItem);
        } else {
          theDress.media = urlOrUpdatedItem.media;
        }
      }
      await DressesAPI.update(theDress.id!, theDress);
      setDresses(prevDresses => {
        for (let yearCollection of prevDresses) {
          const dressIndex = yearCollection.dresses.findIndex(dress => dress.id === theDress.id);
          if (dressIndex > -1) {
            yearCollection.dresses[dressIndex] = theDress;
            break;
          }
        }
  
        return [...prevDresses];
      });
    } catch (error) {
      toastService.showToast("toast.errors.product_plan.update", 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const load = () => {
    const asyncLoad = async () => {
      setIsLoading(true);
      try {
        const dressesApiResponse = await DressesAPI.getProduction();
        const dressesByYear = dressesApiResponse.data.dressesByYear;
        const dressesArray: YearProductionCollection[] = Object.keys(dressesByYear).map((year) => {
          return {
            id: parseInt(year),
            dresses: dressesByYear[year],
          };
        });
        setDresses(dressesArray);
      } catch (err) {
        console.error("Could not load dresses");
      }
      setIsLoading(false);
    };
    asyncLoad();
    return CancelToken.source().cancel("Cancelling...");
  }

  const createCatalog = async (selectedIds: number[]) => {
    setIsLoading(true);
    try {
      const response = await DressesAPI.createCatalog(selectedIds);
      if (response.data) {
        return response.data;
      }
    } catch (err) {
      toastService.showToast("toast.errors.product_plan.catalog", 'error');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(load, []);

  const searchDresses = async (searchText: string) => {
    const fetchedProviders = await DressesAPI.get(searchText);
    return fetchedProviders.data;
  };
  
  return { isLoading, yearCollections, searchDresses, addDress, updateDress, removeDress, createCatalog };
};