import { Auth } from 'aws-amplify';
import CONFIG from '../config/config';

export const refreshToken = async () => {
  try {
    const currentUser = await Auth.currentAuthenticatedUser();
    const currentSession = currentUser.getSignInUserSession();
    const refreshToken = currentSession.getRefreshToken();
    
    const newSession = await Auth.refreshSession(refreshToken);
    sessionStorage.setItem(CONFIG.LOCAL_SESSION.TOKEN, newSession.idToken.jwtToken);
  } catch (error) {
    console.error('Error refreshing token:', error);
  }
};
