type Props = {
  children: JSX.Element,
  actions: JSX.Element
};

function ElementActions({children, actions}: Props) {
  return (
    <>
        { children }
        <span className="flex">
          { actions }
        </span>
    </>
  );
}

export default ElementActions;
