import Card from '../Card/Card';

type CounterSize = 'medium' | 'large';
type CounterLayout = 'horizontal' | 'vertical';

type Props = {
    data: any[] | number;
    label: string;
    size?: CounterSize;
    layout?: CounterLayout;
}

const Counter = ({ data, label, size = 'medium', layout = 'horizontal' }: Props) => {
  const count = Array.isArray(data) ? data.length : data;

  return (
    <Card>
      <div className={`text-center flex justify-center items-center ${layout === 'horizontal' ? null : 'flex-col'}`}>
        <p className={`${size === 'medium' ? 'text-5xl' : 'text-9xl'}`}>{count}&nbsp;</p>
        <p>{label}</p>
      </div>
    </Card>
  );
};

export default Counter;
