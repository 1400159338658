import { useState } from 'react';
import DressesAPI, { DressItem } from "../../api/DressesAPI";
import { Autocomplete } from '../Autocomplete/Autocomplete';
import { BolderText } from '../BolderText/BolderText';
import ListElement from '../ListElement/ListElement';

type Props = {
  onChange: (input: any) => void,
  filterDress?: (dress: DressItem) => boolean,
  label?: string,
  placeholder?: string,
  defaultValue?: string,
  isDisabled?: boolean
};

export function DressAutocomplete({ onChange = () => null, filterDress = () => true, label = '', placeholder = '', defaultValue = '', isDisabled = false }: Props) {

  const [loadingSearch, setLoadingSearch] = useState(false);
  const search = (input: string, resultsSize: number, setResultsFn: any) => {
    setLoadingSearch(true);
    DressesAPI.get().then((resp) => {
      const raw = resp.data
        .filter(filterDress)
        .filter(d => d.name.toLowerCase().includes(input.toLowerCase()));

      const respRaw = raw.slice(0, resultsSize);
      setLoadingSearch(false);
      setResultsFn(respRaw);
    });
  };

  return (<Autocomplete
    resultsSize={15}
    searchFn={search}
    onSelect={onChange}
    label={label} 
    placeholder={placeholder} 
    isLoading={loadingSearch}
    errorMessage={"global.empty_results"}
    toElementFn={(dress: DressItem) =>
      <ListElement key={dress.id}>
        <>
          <p>{dress.name}</p>
          &nbsp;
          <BolderText text={`(${dress.year})`} />
        </>
      </ListElement>}
    defaultValue={defaultValue}
    isDisabled={isDisabled}
  />);
}
