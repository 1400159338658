import React from 'react';

interface DescriptionProps {
  description: string;
}

const CatalogDescription: React.FC<DescriptionProps> = ({ description }) => {
  return (
    <div className="p-5 bg-gray-50 shadow-sm w-full rounded">
      <p className="text-base leading-relaxed text-gray-700 whitespace-pre-wrap text-center">
        {description}
      </p>
    </div>
  );
};

export default CatalogDescription;