import CONFIG from '../config/config';
import { CustomerStatus } from '../shared/customerStatusToTranslationKey';
import { INBOUND_CHANNEL } from '../shared/inboundChannelToTranslationKey';
import { instanceWithV2 as APIClient } from './SimpleAPIClient';

export type CustomerResponse = {
  customer: Customer,
};

export type Customer = {
  id?: string;
  name: string;
  surname: string;
  countryCode?: string;
  phone?: string;
  email?: string;
  status: CustomerStatus;
  employeeId?: string;
  attachments: string[];
  notes?: string;
  weddingDate?: string;
  inboundChannel?: INBOUND_CHANNEL;
  orders?: any[];
  meetings?: any[];
}

type BodyApiResponse = Customer[];

const CustomersAPI = {
  get: ({ employee }: any) => {
    const queryParams = new URLSearchParams();
    if (employee) queryParams.append("employee", employee);
    return APIClient(CONFIG.API_ENDPOINT).get<BodyApiResponse>(`v1/customersCrm${!!queryParams.toString() ? `?${queryParams.toString()}` : ''}`)
  },
  search: (text: string) => APIClient(CONFIG.API_ENDPOINT).get<BodyApiResponse>(`v1/customersCrm?searchText=${text}`),
  add: (customer: Customer) => APIClient(CONFIG.API_ENDPOINT).post<Customer>(`/v1/customersCrm`, customer),
  update: (id: string, customer: Customer) => APIClient(CONFIG.API_ENDPOINT).put<Customer>(`/v1/customersCrm/${id}`, customer),
  remove: (id: string) => APIClient(CONFIG.API_ENDPOINT).delete<BodyApiResponse>(`/v1/customersCrm/${id}`),
};

export default CustomersAPI;
