import CONFIG from '../config/config';
import { Item } from './CollectionsAPI';
import { DressItem } from './DressesAPI';
import { instanceWithV2 as APIClient } from './SimpleAPIClient';

export type ItemOrVariant = ItemVariant | Item; 

export type ItemVariant = {
  id?: number;
  name: string;
  price?: number;
  currency?: string;
  cost?: number;
  costCurrency?: string;
  plan?: DressItem;
};

const CollectionItemVariantsAPI = {
  update: (id: number, itemId: number, variantId: number, variant: ItemVariant) => APIClient(CONFIG.API_ENDPOINT).patch<ItemVariant>(`/v1/collections/${id}/${itemId}/variations/${variantId}`, variant),
  add: (id: number, itemId: number, variant: ItemVariant) => APIClient(CONFIG.API_ENDPOINT).post<ItemVariant>(`/v1/collections/${id}/${itemId}/variations`, variant),
  remove: (id: number, itemId: number, variantId: number) => APIClient(CONFIG.API_ENDPOINT).delete<ItemVariant>(`/v1/collections/${id}/${itemId}/variations/${variantId}`),
};

export default CollectionItemVariantsAPI;
