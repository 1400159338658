import { useMemo } from "react";
import { Employee } from "../../api/EmployeesAPI";

type Props = {
  employee: Employee,
  actions?: JSX.Element
};

function EmployeeElement({employee, actions}: Props) {

  const notesIcon = useMemo(() => employee.note? '📝' : null, [employee.note]);
  const activeIcon = useMemo(() => employee.active? '✔️' : null, [employee.active]);
  
  return ( 
    <>
        <div className="flex space-x-3">
          <p>{ employee.name}</p>
          <p>{ notesIcon }</p>
          <p>{ activeIcon }</p>
        </div>
        <span className="flex">
          { actions }
        </span>
    </>
  );
}

export default EmployeeElement;
