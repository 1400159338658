import { DressItem } from '../../api/DressesAPI';
import { Icon } from '../Icon/Icon';
import imageIcon from '../../assets/images/add-image.svg';
// import Pill from '../Pill/Pill';

type Props = {
  item: DressItem,
  actions?: JSX.Element
}

function DressItemElement({ item, actions }: Props) {
  return (
    <div className="flex justify-between space-x-3">
      <div className="flex space-x-3 items-center">
        { !!item.pictureUrl && <Icon url={imageIcon} /> }
        <p className="bolder">{item.name}</p>
        {/* { item.defaultSellPrice ? <Pill color='blue' text={`${item.defaultSellPrice} ${item.defaultSellCurrency}`}/> : null } */}
        <p className="bolder">[Refs: {item.materials?.length || 0}]</p>
      </div>
      <span className="flex space-x-5">
        { actions }
      </span>
    </div>
  );
}

export default DressItemElement;
