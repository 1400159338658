import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { toastService } from '../../services/ToastService';
import Toast from '../Toast/Toast';

const ToastContainer: React.FC = () => {
  const [toasts, setToasts] = useState<Array<{ message: string; id: string; type: 'success' | 'error' }>>([]);

  const addToast = useCallback((message: string, id: string, type: 'success' | 'error') => {
    setToasts((toasts) => [...toasts, { message, id, type }]);
  }, []);

  const removeToast = useCallback((id: string) => {
    setToasts((toasts) => toasts.filter((toast) => toast.id !== id));
  }, []);

  useEffect(() => {
    toastService.addListener(addToast);
    return () => {
      toastService.removeListener(addToast);
    };
  }, [addToast]);

  return (
    <div 
      className="toast-container fixed top-0 flex flex-col justify-start space-y-4 mt-4 px-4"
      style={{ zIndex: 100, left: '50%', transform: 'translateX(-50%)' }}
    >
      {toasts.map((toast) => (
        <Toast key={toast.id} message={toast.message} id={toast.id} removeToast={removeToast} type={toast.type} />
      ))}
    </div>
  );   
};

export default ToastContainer;
