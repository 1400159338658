import { useEffect, useState } from 'react';
import OnboardingAPI, { OnboardingStatus } from '../api/OnboardingAPI';

export const useOnboarding = (isLoggedIn: boolean) => {
  const [onboardingStatus, setOnboardingStatus] = useState<OnboardingStatus | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!isLoggedIn) {
      setLoading(false);
      return;
    }

    const fetchOnboardingStatus = async () => {
      try {
        const response = await OnboardingAPI.get();
        setOnboardingStatus(response.data);
      } catch (error) {
        console.error('Failed to fetch onboarding status:', error);
        setOnboardingStatus(null);
      } finally {
        setLoading(false);
      }
    };

    fetchOnboardingStatus();
  }, [isLoggedIn]);

  const skipStep = async (step: string) => {
    try {
      await OnboardingAPI.updateStep(step);
      const response = await OnboardingAPI.get();
      setOnboardingStatus(response.data);
    } catch (error) {
      console.error(`Failed to skip step ${step}:`, error);
      setOnboardingStatus(null);
    }
  };

  return {
    onboardingStatus,
    skipStep,
    loading,
  };
};