import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isValidEmail, isValidPassword } from '../../shared/validation';
import InputPassword from '../InputPassword/InputPassword';
import InputShortText from '../InputShortText/InputShortText';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import TinyText from '../TinyText/TinyText';

type ForgotPasswordFormProps = {
  onBackToLogin: () => void;
}

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({ onBackToLogin }) => {
  const [username, setUsername] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [otp, setOtp] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [formState, setFormState] = useState('REQUEST_OTP');
  const { t } = useTranslation();

  const handleSubmit = async () => {
    setIsLoading(true);
    setError(null);
    try {
        await Auth.forgotPassword(username);
        setFormState('REQUEST_NEW_PASS');
    } catch (error: any) {
        if (error.code === 'UserNotFoundException') {
            setError(t("registration.forgot_passwrod.error")!);
        } else {
            console.log(error);
            setError(t("registration.forgot_passwrod.error")!);
        }
    } finally {
        setIsLoading(false);
    }
  };

  const confirmForgotPassword = async (username: string, otp: string, newpassword: string) => {
    setIsLoading(true);
    setError(null);
    try {
        await Auth.forgotPasswordSubmit(username, otp, newpassword);
        onBackToLogin();
    } catch (error: any) {
        if (error.code === 'UserNotFoundException') {
            setError(t("registration.forgot_passwrod.error")!);
        } else {
            console.log(error);
            setError(t("registration.forgot_passwrod.error")!);
        }
    } finally {
        setIsLoading(false);
    }
  };

  return (
    <form className="bg-white flex flex-col p-6 shadow-md rounded w-full md:w-96">
        { 'REQUEST_OTP' === formState ? 
         <>
            <div className="mb-4">
                <InputShortText 
                    validationFn={(value) => !!value && isValidEmail(value)} 
                    onChange={e => setUsername((prev) => e.target.value)}
                    errorMessage={"errors.too_short"}
                    label={t("global.email")!}
                    isLoading={isLoading}
                    defaultValue={username}
                />
            </div>
            {error && <p className="text-red-500 text-xs italic mb-4">{error}</p>}
            <br/>
            <PrimaryButton text={t("registration.request_code")!} fn={handleSubmit} isDisabled={isLoading} eventName="forgot_password_button_clicked"/>
         </>
         :
         <>
            <div className="mb-4">
                <InputShortText 
                    validationFn={(value) => !!value && value.length > 3} 
                    onChange={e => setOtp((prev) => e.target.value)}
                    errorMessage={"errors.too_short"}
                    label={t("global.otp")!}
                    isLoading={isLoading}
                    defaultValue={otp}
                />
                <TinyText text={`${t("registration.password.text")}.`} />
                <InputPassword 
                    validationFn={(value) => isValidPassword(value)}
                    onChange={e => setNewPassword(e.target.value)}
                    errorMessage={"errors.password_format"}
                    isLoading={isLoading}
                    label={t("registration.change_password.new")!}
                />
            </div>
            <br/>
            <PrimaryButton 
                text={t("registration.request_code")!} 
                fn={() => confirmForgotPassword(username, otp, newPassword)} 
                isDisabled={isLoading} 
                eventName="forgot_password_button_clicked"
                actionName="Forgot Password Button"
            />
        </>
        }
    </form>
  );
};

export default ForgotPasswordForm;
