import React, { useEffect, useState } from 'react';
import InputShortText from '../InputShortText/InputShortText';
import TextArea from '../TextArea/TextArea';
import { useTranslation } from 'react-i18next';
import { isValidEmail, isValidPhone, isValidText } from '../../shared/validation';
import { useFormValidation } from '../../hooks/useFormValidation';
import SelectInput from '../SelectInput/SelectInput';
import CONFIG from '../../config/config';
import { Customer } from '../../api/CustomersAPI';
import EmployeesAPI, { Employee } from '../../api/EmployeesAPI';
import { getCustomerStatuses } from '../../shared/customerStatusToTranslationKey';
import TinyText from '../TinyText/TinyText';
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker';
import SectionTitle from '../SectionTitle/SectionTitle';
import { getInboundChannels, inboundChannelToTranslationKeys } from '../../shared/inboundChannelToTranslationKey';
import PhoneNumberInput from '../PhoneNumberInput/PhoneNumberInput';
import { paymentStatusToAbbreviatedTranslationKey, paymentStatusToPillColor, paymentStatusToTranslationKey } from '../../shared/paymentStatusToTranslationKey';
import Tooltip from '../Tooltip/Tooltip';
import Pill from '../Pill/Pill';
import { BolderText } from '../BolderText/BolderText';
import { orderStatusToTranslationKeys } from '../../shared/orderStatusToTranslationKey';
import { Order, OrderDress } from '../../api/OrdersAPI';
import { meetingTypeToTranslationKey } from '../../shared/meetingTypeToTranslationKey';
import { parseDateTime } from '../../shared/timeUtils';
import Table, { ColumnDefinition } from '../Table/Table';
import { OrderColumn } from '../OrdersSegment/OrdersSegment';
import { Meeting } from '../../api/MeetingsAPI';

type Props = {
  customer: Customer,
  onCustomerChange?: (value: Customer) => void,
  isDisabled?: boolean,
  actionButton?: JSX.Element
};

export type MeetingColumn = ColumnDefinition<Meeting>;

const getInitialState = (customer?: Customer) => {
  if (customer) {
    return {
      name: isValidText(customer.name, 3),
      surname: isValidText(customer.surname, 3),
      email: isValidEmail(customer.email),
      phone: isValidPhone(customer.phone)
    };
  }
};

function CustomerForm({ customer, onCustomerChange = () => null, isDisabled = false, actionButton }: Props) {
  
  const { t } = useTranslation();
  const requiredFields = ['name'];
  const initialState = getInitialState(customer);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const { updateFieldValidation, isFormValid } = useFormValidation(requiredFields, initialState);

  const renderOrderDressName = (dress?: OrderDress, dressDescription?: string): React.ReactNode => {
    return (
      <Tooltip content={dress?.name || dressDescription || '-'}>
        <p className="w-32 truncate">{dress?.name || dressDescription}</p>
      </Tooltip>
    );
  };

  const orderColumns: OrderColumn[] = [
    {
      key: 'shopName',
      title: t("orders.details.salon"),
      render: (order) => 
      <Tooltip content={order.shopName!}>
        <p className="w-36 truncate">{order.shopName}</p>
      </Tooltip>,
      rowClass: (or) => 
      `
        ${or.visible && or.status !== 'BLOCKED' ? 'hover:bg-darker-gold-50' : ''} 
        ${or.status === 'BLOCKED' ? 'bg-custom-red' : ''}
        ${!or.visible ? 'bg-darker-gold-100 text-grey-50' : ''} 
        ${or.status === 'SENT' && or.visible ? 'bg-darker-gold-300' : ''}
      `
    },
    {
      key: 'paymentStatus',
      title: t("orders.details.payment_status"),
      render: (order) => (
        <Tooltip content={t(paymentStatusToTranslationKey(order.paymentStatus))}>
          <Pill text={t(paymentStatusToAbbreviatedTranslationKey(order.paymentStatus))} color={paymentStatusToPillColor(order.paymentStatus)}/>
        </Tooltip>
      ),
    },
    {
      key: 'dress',
      title: t("orders.details.description"),
      render: (order) => renderOrderDressName(order.dress, order.dressDescription),
    },
    {
      key: 'deliveryDate',
      title: t("orders.details.delivery_time"),
      render: (order) => <BolderText text={order.deliveryDate} /> ,
    },
    {
      key: 'status',
      title: t("orders.details.status"),
      render: (order) => <Tooltip content={t(orderStatusToTranslationKeys(order.status))}><p className="w-12 sm:w-20 truncate">{ t(orderStatusToTranslationKeys(order.status)) }</p></Tooltip>,
    },
  ];

  const meetingColumns: MeetingColumn[] = [
    {
      key: 'type',
      title: t("calendar.meeting.form.type"),
      render: (meeting) => (
          <Pill text={t(meetingTypeToTranslationKey(meeting.type))} color={'blue'}/>
      ),
    },
    {
      key: 'startTime',
      title: t("calendar.meeting.form.start"),
      render: (meeting) => <><BolderText text={ `${parseDateTime(meeting.startTime).date}` } />
      { `${parseDateTime(meeting.startTime).time}-${parseDateTime(meeting.endTime).time}` }</>,
    },
    {
      key: 'reminder',
      title: t("calendar.meeting.form.phone.reminder"),
      render: (meeting) => <span>{ meeting.reminder ? '🔔' : '🔕' }</span>,
    },
  ];

  const load = async () => {
    if (employees.length <= 0) {
      const employeesResp = await EmployeesAPI.getSalesRepresentatives();
      setEmployees(employeesResp.data);
    }
  };

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  const disabledActionButton = actionButton
    ? React.cloneElement(
        actionButton,
        {},
        React.Children.map(actionButton.props.children, (child) => {
          return child ? React.cloneElement(child, { isDisabled: !isFormValid() }) : null;
        })
      )
    : null;

  return (
    <>
      <div className="flex flex-wrap space-x-5">
        <div className="md:flex-1 w-full">
          <InputShortText 
            label={`${t("customers.form.name")}*`} 
            validationFn={(value) => !!value && isValidText(value, 3)}
            onValidationChange={(isValid) => updateFieldValidation('name', isValid)}
            onChange={e => onCustomerChange({ ...customer, name: e.currentTarget.value } as Customer)}
            errorMessage={t("errors.too_short")!}
            defaultValue={customer?.name || ''}
            isDisabled={isDisabled}
          />
          <InputShortText 
            label={`${t("customers.form.surname")}`} 
            validationFn={(value) => !!value && isValidText(value, 3)}
            onValidationChange={(isValid) => updateFieldValidation('surname', isValid)}
            onChange={e => onCustomerChange({ ...customer, surname: e.currentTarget.value } as Customer)}
            errorMessage={t("errors.too_short")!}
            defaultValue={customer?.surname || ''}
            isDisabled={isDisabled}
          />
          <div className="flex flex-wrap space-x-5">
            <SelectInput
                values={getCustomerStatuses().map(e => { return { value: e, readableValue: t(`customers.status.${e.toLocaleLowerCase()}`) } })}
                onChange={e => onCustomerChange({ ...customer, status: e.currentTarget.value } as Customer)}
                label={"customers.form.status"}
                defaultValue={customer?.status}
                isDisabled={isDisabled}
            />
            <SelectInput
              values={employees.map(e => { return { value: String(e.id!), readableValue: e.name }})}
              onChange={e => onCustomerChange({ ...customer, employeeId: e.target.value } as Customer)}
              label={"customers.form.employee"}
              defaultValue={customer?.employeeId}
              isDisabled={isDisabled}
            />
          </div>

          <br/>
          <SectionTitle text={t("customers.form.contact")} />
          <div className="flex flex-wrap space-x-5">
            <InputShortText 
              label={`${t("customers.form.email")}`} 
              validationFn={(value) => !!value && isValidEmail(value)}
              onValidationChange={(isValid) => updateFieldValidation('email', isValid)}
              onChange={e => onCustomerChange({ ...customer, email: e.currentTarget.value } as Customer)}
              errorMessage={t("errors.invalid_email")!}
              defaultValue={customer?.email || ''}
              isDisabled={isDisabled}
            />
          </div>
          <div className="flex flex-wrap">
            <PhoneNumberInput 
              label={"calendar.meeting.form.phone"}
              validationFn={(value) => !!value && isValidPhone(value)} 
              onChange={e => onCustomerChange({ ...customer, phone: e } as Customer)}
              defaultValue={customer?.phone || ''}
              isDisabled={isDisabled}
            />
          </div>

          <br/>
          <SectionTitle text={t("customers.form.demographics")} />
          <div className="flex flex-wrap space-x-5">
            <CustomDatePicker
              value={customer?.weddingDate}
              onChange={e => onCustomerChange({ ...customer, weddingDate: e.toISOString() } as Customer)}
              label={"customers.form.wedding_date"}
              dateFormat="LL/yyyy"
              isDisabled={isDisabled}
            />
            <SelectInput
              values={CONFIG.COUNTRY_CODES.map(e => { return { value: e.countryCode, readableValue: e.countryName } })}
              onChange={e => onCustomerChange({ ...customer, countryCode: e.currentTarget.value } as Customer)}
              label={`${t("customers.form.country")}`} 
              defaultValue={customer?.countryCode}
              isDisabled={isDisabled}
            />
            <SelectInput
              values={getInboundChannels().map(e => { return { value: e, readableValue: t(inboundChannelToTranslationKeys(e))} })}
              onChange={e => onCustomerChange({ ...customer, inboundChannel: e.currentTarget.value } as Customer)}
              label={"customers.form.inbound_channel"}
              defaultValue={customer?.inboundChannel}
              isDisabled={isDisabled}
            />
          </div>
          <SectionTitle text={t("customers.form.orders")} />
          <Table<Order>
              data={customer?.orders || []}
              columns={orderColumns}
              compareFunction={(item, selectedItem) => item.id === selectedItem.id}
              emptyListComponent={<TinyText text={`${t("customers.orders.empty")}.` }/>}
              selectedItems={[]}
            />
          <SectionTitle text={t("customers.form.meetings")} />
          <Table<Meeting>
              data={customer?.meetings || []}
              columns={meetingColumns}
              compareFunction={(item, selectedItem) => item.id === selectedItem.id}
              emptyListComponent={<TinyText text={`${t("customers.meetings.empty")}.` }/>}
              selectedItems={[]}
            />
          <br/>
          <SectionTitle text={t("customers.form.notes")}/>
          <TextArea
            label={t("customers.form.notes")!} 
            validationFn={(value) => !!value && isValidText(value, 0, 500)}
            onChange={e => onCustomerChange({ ...customer, notes: e.currentTarget.value } as Customer)}
            defaultValue={customer?.notes || ''}
            isDisabled={isDisabled}
          />
        </div>
      </div>
      { disabledActionButton }
    </>
  );
}

export default CustomerForm;
