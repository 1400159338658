
import CONFIG from '../config/config';
import { ReminderConfig } from '../pages/SettingsPage/sections/NotificationsSegment';
import { instanceWithV2 as APIClient } from './SimpleAPIClient';

export type Reminders = {
  smsReminder?: ReminderConfig;
  weddingRateReminder?: ReminderConfig;
};

const RemindersAPI = {
  get: () => APIClient(CONFIG.API_ENDPOINT).get<Reminders>(`v1/configuration/notifications`),
  update: (reminders: Reminders) => APIClient(CONFIG.API_ENDPOINT).put<Reminders>(`v1/configuration/notifications`, reminders),
};

export default RemindersAPI;
