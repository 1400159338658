import CONFIG from '../config/config';
import { UnitsType } from '../shared/unitsToTranslationKey';
import { instanceWithV2 as APIClient } from './SimpleAPIClient';

export type Material = {
  id?: number,
  quantity: number,
  referenceCode: string,
  referenceId: number,
  referenceName: string,
  type: UnitsType
}

export type Reference = {
  id?: number,
  name: string,
  referenceId: string,
  providerId: number,
  tags: string[],
  pictureUrl: string | null,
  type: UnitsType,
  notes: string | null,
  file?: File,
  price?: number,
  currency?: string,
}

export type Provider = {
  id?: number,
  name: string,
  email: string,
  phone: string,
  references: Reference[]
}

const ReferenceAPI = {
  getReferences: (search?: string) => APIClient(CONFIG.API_ENDPOINT).get<Reference[]>(`/v1/references${search ? `?search=${search}` : ''}`),
  add: (reference: Reference) => APIClient(CONFIG.API_ENDPOINT).post<Reference>(`/v1/references`, reference),
  update: (id: number, reference: Reference) => APIClient(CONFIG.API_ENDPOINT).put<Reference>(`/v1/references/${id}`, reference),
  remove: (id: number) => APIClient(CONFIG.API_ENDPOINT).delete<Reference>(`/v1/references/${id}`),
};

const ProviderAPI = {
  getProviders: (search?: string) => APIClient(CONFIG.API_ENDPOINT).get<Provider[]>(`/v1/providers${search ? `?search=${search}` : ''}`),
  add: (provider: Provider) => APIClient(CONFIG.API_ENDPOINT).post<Provider>(`/v1/providers`, provider),
  remove: (id: number) => APIClient(CONFIG.API_ENDPOINT).delete<Provider>(`/v1/providers/${id}`),
  update: (id: number, provider: Provider) => APIClient(CONFIG.API_ENDPOINT).put<Provider>(`/v1/providers/${id}`, provider),
};

export { ReferenceAPI, ProviderAPI };
