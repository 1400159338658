import { useTranslation } from "react-i18next";
import { Material } from "../../../api/ProvidersAPI";
import { unitsToTranslationKey } from "../../../shared/unitsToTranslationKey";
import { BolderText } from "../../BolderText/BolderText";

type Props = {
  material: Material,
  actions?: JSX.Element
};

function MaterialElement({material, actions}: Props) {

  const { t } = useTranslation();

  return ( 
    <div className="flex w-full justify-between items-center">
        <span><BolderText text={ material.referenceCode }/> [{ t(unitsToTranslationKey(material.type)) }] </span>
        <span className="flex items-center">
          { actions }
        </span>
    </div>
  );
}

export default MaterialElement;
