import { configureStore } from '@reduxjs/toolkit';
import { AccountSlice } from './reducers/AccountSlice';
import { MarketplaceBasketSlice } from './reducers/MarketplaceBasketSlice';

const store = configureStore({
  reducer: {
    settings: AccountSlice.reducer,
    basket: MarketplaceBasketSlice.reducer
  }
});

export default store;