import { useTranslation } from "react-i18next";
import { toastService } from "../../services/ToastService";

type Props = {
  text: string
};

function CopyText({text}: Props) {

  const { t } = useTranslation();

  const copyFn = () => {
    navigator.clipboard.writeText(text);
    toastService.showToast(t("global.already_copy"));
  }

  return (
    <button className="bg-slate-500 hover:bg-blue-300 text-white py-1 px-2 rounded text-xs" onClick={copyFn}>
          { t("global.copy") }
    </button>
  );
}

export default CopyText;
