import CONFIG from "../config/config";

const ADDITIONAL_SYMBOLS_AWS_REQUIRES = /[!'()*]/g;

export const isLargeFile = (file: File) => {
    const limit = CONFIG.HEAVY_FILE_THRESHOLD * 1024 * 1024;
    return file.size > limit;
}

export const shouldBePreCompressed = (file: File) => {
    const limit = CONFIG.SHOULD_BE_PRECOMPRESSED * 1024 * 1024;
    return file.size > limit;
}

export const urlEncode = (text: string) => {
    let normalizedString = text.normalize('NFD');
    normalizedString = normalizedString.replace(/\s+/g, '_');
    let encoded = encodeURIComponent(normalizedString);
    return encoded;
}

export const s3Encode = (text: string) => {
    let normalizedString = text.normalize('NFD');
    normalizedString = normalizedString.replace(/\s+/g, '_');
    return normalizedString.replace(ADDITIONAL_SYMBOLS_AWS_REQUIRES, function(c) {
        return '%' + c.charCodeAt(0).toString(16);
    });
}

export const urlAndS3Encode = (text: string) => {
    return s3Encode(urlEncode(text));
}