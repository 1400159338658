type Props = {
    text: string
    clazz?: string,
    cssStyles?: string,
  };

function Subtitle({text, clazz = 'md:text-3xl', cssStyles = ''}: Props) {
    return <h2 className={`duration-1000 ease-out font-extralight font-bold text-grey ${clazz} ${cssStyles}`}>{text}</h2>;
};

export default Subtitle;