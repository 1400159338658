import CONFIG from '../config/config';
import { instanceWithV2 as APIClient } from './SimpleAPIClient';

export type Catalog = {
  id?: number;
  name: string;
  shortName: string;
  description: string;
  items: CatalogItem[];
  uuid?: string;
  designer?: string;
  storeName?: string;
};

export type CatalogItem = {
  id?: number;
  uuid?: string;
  name: string;
  media?: string[];
  price?: number;
  currency?: string;
  description?: string;
  designer?: string,
  collectionName?: string,
};

const CataloguesAPI = {
  getCatalogues: () => APIClient(CONFIG.API_ENDPOINT).get<Catalog[]>(`/v1/public/catalogues`),
  getCatalog: (id?: string) => APIClient(CONFIG.API_ENDPOINT).get<Catalog>(`/v1/public/catalogues/${id}`),
  import: (id?: string) => APIClient(CONFIG.API_ENDPOINT).post<Catalog>(`/v1/public/catalogues/${id}/import`)
};

export default CataloguesAPI;
