import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CollectionsAPI, { Collection, Item } from '../../../api/CollectionsAPI';
import { Autocomplete } from '../../../components/Autocomplete/Autocomplete';
import ConfirmationPopup from '../../../components/ConfirmationPopup/ConfirmationPopup';
import { Icon } from '../../../components/Icon/Icon';
import IconButton from '../../../components/IconButton/IconButton';
import ListContainer from '../../../components/ListContainer/ListContainer';
import ListElement from '../../../components/ListElement/ListElement';
import MarketingCollectionElement from '../../../components/MarketingCollectionElement/MarketingCollectionElement';
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton';
import { useGetCollections } from '../../../hooks/useGetCollections';
import deleteIcon from "../../../assets/images/bin.svg";
import edit from "../../../assets/images/pen.svg";
import add from "../../../assets/images/plus.svg";
import CollectionForm from '../../../components/CollectionForm/CollectionForm';
import { Modal } from '../../../components/Modal/Modal';
import { FormType } from '../../../shared/form.type';
import CONFIG from '../../../config/config';
import CollectionItemForm from '../../../components/CollectionItemForm/CollectionItemForm';
import MarketingCollectionItemElement from '../../../components/MarketingCollectionItemElement/MarketingCollectionItemElement';
import { PermissionFlags } from '../../../components/PermissionFlags/PermissionFlags';
import Card from '../../../components/Card/Card';
import SimpleListElement from '../../../components/SimpleListElement/SimpleListElement';
import NoDataCTA from '../../../components/NoDataCTA/NoDataCTA';
import duplicate from '../../../assets/images/copy.svg';
import Tooltip from '../../../components/Tooltip/Tooltip';
import SecondaryButton from '../../../components/SecondaryButton/SecondaryButton';
import catalogImage from '../../../assets/images/catalog.webp';
import { useNavigate } from 'react-router-dom';
import tablet from '../../../assets/images/ipad.svg';
import ElementActions from '../../../components/ElementActions/ElementActions';
import ExpandableSection from '../../../components/ExpandableSection/ExpandableSection';
import shareIcon from '../../../assets/images/share.svg';
import { toastService } from '../../../services/ToastService';

const EmptyCollection = {
  name: '',
  shortName: '',
  type: CONFIG.DEFAULT_PRODUCT_TYPE,
  featured: false,
  lastUpdateTime: null,
  items: [],
  types: [CONFIG.DEFAULT_PRODUCT_TYPE],
  views: 0,
  description: '',
  public: false,
  shareableByLink: false,
  pricesArePublic: false
};

const EmptyItem = {
  name: '',
  featuredPictureUrl: '',
  type: CONFIG.DEFAULT_PRODUCT_TYPE,
  placeOrder: 0,
  images: [],
  price: 0,
  currency: ''
};

function MarketingCollectionsSegment() {
  const { t } = useTranslation();
  const { isLoadingCollections, collections, addCollection, removeCollection, updateCollection, setExpandedCollection, addItem, removeItem, updateItem, duplicateCollection, reorderCollectionItems } = useGetCollections();
  const [action, setAction] = useState<FormType>('READ');
  const [selectedCollection, setSelectedCollection] = useState<Collection | null>(null);
  const [selectedItem, setSelectedItem] = useState<Item | null>(null);
  const [showAppModal, setShowAppModal] = useState(false);
  let navigate = useNavigate();

  const redirectToTablet = () => {
    navigate('/tablet');
  };  

  const actionButton = (action: FormType) => {
    switch (action) {
      case 'CREATE':
        return <span className="flex justify-center pt-5"><PrimaryButton text={t("admin.collections.form.add")!} fn={() => handleNewCollection(selectedCollection!)} /></span>;
      case 'UPDATE':
        return <span className="flex justify-center pt-5"><PrimaryButton text={t("admin.collections.form.update")!} fn={() => handleEditCollection(selectedCollection!)} /></span>;
      default:
        return;
    }
  };

  const actionItemButton = (action: FormType) => {
    if (isLoadingCollections) {
      return <span className="flex justify-center pt-10"><PrimaryButton isDisabled text={"..."} isLoading/></span>
    }
    switch (action) {
      case 'CREATE':
        return <span className="flex justify-center pt-5"><PrimaryButton text={t("admin.collections.items.form.add")!} fn={() => handleNewCollectionItem(selectedItem!)} /></span>;
      case 'UPDATE':
        return <span className="flex justify-center pt-5"><PrimaryButton text={t("admin.collections.items.form.update")!} fn={() => handleEditCollectionItem(selectedItem!)} /></span>;
      default:
        return;
    }
  };

  const handleExpand = (collection: Collection) => {
    setExpandedCollection(collection);
  };

  const handleAddCollection = () => {
    setAction('CREATE');
    setSelectedCollection(EmptyCollection);
  };

  const editCollection = (c: Collection) => {
    setAction('UPDATE');
    setSelectedCollection(c);
  }

  const handleNewCollection = async (collection: Collection) => {
    await addCollection(collection);
    setSelectedCollection(null);
  };

  const handleEditCollectionItem = async (item: Item) => {
    await updateItem(item);
    setSelectedItem(null);
  };

  const editItem = (item: Item) => {
    setAction('UPDATE');
    setSelectedItem(item);
  }

  const handleRemoveCollectionItem = async (item: Item) => {
    await removeItem(item);
    setSelectedCollection(null);
  };

  const handleNewCollectionItem = async (item: Item) => {
    await addItem(item);
    setSelectedItem(null);
    setAction('READ');
  };

  const handleEditCollection = async (collection: Collection) => {
    await updateCollection(collection);
    setSelectedCollection(null);
    setAction('READ');
  };

  const handleRemoveCollection = async (collection: Collection) => {
    await removeCollection(collection);
    setSelectedCollection(null);
    setAction('READ');
  };

  const search = async (input: string, resultsSize: number, setResultsFn: any) => {
    try {
      const searchResults = await CollectionsAPI.searchItems(input);
      const items = searchResults.data;
      const filteredItems = items.slice(0, resultsSize);
      setResultsFn(filteredItems);
    } catch (err) {
      console.error('Could not search collections');
    }
  };

  const handleOpenItemForm = (c: Collection, item: Item) => {
    setExpandedCollection(c);
    setSelectedItem(item);
    setAction('CREATE');
  };

  const handleEditCollectionItems = async (col: Collection, items: Item[]) => {
    items.forEach((it, index) => it.placeOrder = index);
    col.items = items;
    await reorderCollectionItems(col);
  }

  const copyLink = async (catalogId: string, accessible: boolean) => {
    if (!accessible) {
      toastService.showToast(t("admin.collections.share.not_private"), 'error');
      return; 
    }
    try {
      const linkToCopy = `${window.location.protocol}//${window.location.host}/catalogues/${catalogId}`;
      await navigator.clipboard.writeText(linkToCopy);
      toastService.showToast(t("admin.collections.share.success"));
    } catch (error) {
      toastService.showToast(t("admin.collections.share.failure"), 'error');
    }
}

  return (
    <div className="space-y-5">
      <Card>
        <>
          <div className="container flex flex-wrap space-x-5">
            <Autocomplete
                  resultsSize={5}
                  searchFn={search}
                  onSelect={(item: Item) => null}
                  placeholder={t("admin.collections.search.placeholder")!}
                  isLoading={isLoadingCollections}
                  errorMessage={t('global.empty_results')!}
                  toElementFn={(item: Item) =>
                    <SimpleListElement onClick={(e) => {e.stopPropagation(); setSelectedItem(item); }} key={item.id} actions={
                      <>
                        <PermissionFlags requiredFlags={['update-marketing-collections'] || []}>
                          <>
                            <IconButton onClick={() => editItem(item)}>
                              <Icon url={edit} cssStyles="w-5" />
                            </IconButton>
                          </>
                        </PermissionFlags>
                      </>
                    }>
                      <MarketingCollectionItemElement item={item} />
                    </SimpleListElement>
                  }
                />
          </div>
          <div className="container flex flex-wrap space-x-5 my-3">
            <PermissionFlags requiredFlags={['create-marketing-collections'] || []}>
              <PrimaryButton
                text={t("admin.collections.form.new_collection.button")!}
                fn={handleAddCollection}
              />
            </PermissionFlags>
            <PermissionFlags requiredFlags={['create-marketing-collections'] || []}>
              <PrimaryButton
                text={t("admin.collections.form.import_collection.button")!}
                fn={handleAddCollection}
                isDisabled
              />
            </PermissionFlags>
            {/* <PrimaryButton 
              fn={() => navigate(`/catalogues/showroom`)} 
              text={t("marketplace.catalogues.see_listing")!}
            /> */}
          </div>
        </>
      </Card>
      <Card>
        <>
          <div className="text-right">
            <Icon url={tablet} />
            <SecondaryButton text={t("admin.collections.tablet.cta")!} fn={() => setShowAppModal(v => !v)}/>
          </div>
          <ListContainer
            isLoading={isLoadingCollections}
            elements={collections}
            emptyListComponent={
              <NoDataCTA
                headline={t("admin.collections.empty.cta.title")}
                subtext={t("admin.collections.empty.cta.body")}
                primaryActionText={t("admin.collections.form.new_collection.button")}
                onPrimaryAction={handleAddCollection}
              />
            }
            toElementFn={(collection: Collection) => (
              <ExpandableSection
                onExpand={() => handleExpand(collection)}
                expandedContent={
                  <>
                      <ListContainer
                        isLoading={isLoadingCollections}
                        elements={collection.items}
                        enableDragAndDrop
                        onMovedItem={(items) => handleEditCollectionItems(collection, items)}
                        emptyListComponent={
                          <NoDataCTA
                            headline={t("admin.collections.items.empty.cta.title")}
                            subtext={t("admin.collections.items.empty.cta.body")}
                            primaryActionText={t("admin.collections.items.empty.cta.button")}
                            onPrimaryAction={() => handleOpenItemForm(collection, EmptyItem)}
                          />
                        }
                        toElementFn={(item: Item, index: number) => (
                          <ListElement
                            key={item.id}
                            css="w-full flex duration-500 hover:bg-darker-gold-100"
                            onClick={(event) => {
                              event.stopPropagation();
                              setAction('READ');
                              setSelectedItem(item);
                            }}
                          >
                            <ElementActions actions={
                            <PermissionFlags requiredFlags={['update-marketing-collections'] || []}>
                                <>
                                  <IconButton onClick={() => editItem(item)}>
                                    <Icon url={edit} cssStyles="w-5" />
                                  </IconButton>
                                  <IconButton>
                                    <ConfirmationPopup onConfirm={() => handleRemoveCollectionItem(item)} confirmText={"global.confirm"} title={`${t("global.remove")}: ${collection.name}`}>
                                      <Icon url={deleteIcon} cssStyles="w-5" />
                                    </ConfirmationPopup>
                                  </IconButton>
                                </>
                              </PermissionFlags>}>
                              <div className="flex flex-col w-full duration-500 hover:bg-darker-gold-100">
                                <MarketingCollectionItemElement item={item} />
                              </div>
                            </ElementActions>
                          </ListElement>
                        )}
                      />
                  </>
                }
                collapsedContent={
                <div className="flex w-full justify-between duration-500 hover:bg-darker-gold-100 px-5 py-3">
                  <ElementActions actions={
                      <>
                      <PermissionFlags requiredFlags={['update-marketing-collections'] || []}>
                        <IconButton onClick={() => duplicateCollection(collection.id!)}>
                          <Tooltip position="bottom" content={t("global.duplicate")}>
                            <Icon url={duplicate} cssStyles="w-5" />
                          </Tooltip>
                        </IconButton>
                      </PermissionFlags>
                      <PermissionFlags requiredFlags={['update-marketing-collections'] || []}>
                        <IconButton onClick={() => copyLink(collection.uuid!, collection.public || collection.shareableByLink)}>
                          <Tooltip content={t("global.share")}>
                            <Icon url={shareIcon} cssStyles="w-5" />
                          </Tooltip>
                        </IconButton>
                      </PermissionFlags>
                      <PermissionFlags requiredFlags={['update-marketing-collections'] || []}>
                        <IconButton onClick={() => handleOpenItemForm(collection, EmptyItem)}>
                          <Tooltip content={t("global.add")}>
                            <Icon url={add} cssStyles="w-5" />
                          </Tooltip>
                        </IconButton>
                      </PermissionFlags>
                      <PermissionFlags requiredFlags={['update-marketing-collections'] || []}>
                        <IconButton onClick={() => editCollection(collection)}>
                          <Icon url={edit} cssStyles="w-5" />
                        </IconButton>
                      </PermissionFlags>
                      <PermissionFlags requiredFlags={['remove-marketing-collections'] || []}>
                        <IconButton>
                          <ConfirmationPopup onConfirm={() => handleRemoveCollection(collection)} confirmText={"global.confirm"} title={`${t("global.remove")}: ${collection.name}`}>
                            <Icon url={deleteIcon} cssStyles="w-5" />
                          </ConfirmationPopup>
                        </IconButton>
                      </PermissionFlags>
                    </>
                    }>
                      <MarketingCollectionElement collection={collection} />
                    </ElementActions>
                  </div>}
              />
            )}
          />
        </>
      </Card>
      {showAppModal &&
        <Modal modalTitle={t("admin.collections.tablet.form.title")} canClose onClose={() => setShowAppModal(false)}>
          <div className="clearfix">
            <img className="rounded-lg float-right ml-4 mb-4 w-full md:w-1/2 lg:w-1/3" src={catalogImage} alt="Tablet"/>
            <p>
              { t("admin.collections.tablet.form.paragraph1") }
            </p>
            <p className="mt-4">
            { t("admin.collections.tablet.form.paragraph2") }
            </p>
            <p className="mt-4">
            { t("admin.collections.tablet.form.paragraph3") }
            </p>
            <br/>
            <br/>
            <PrimaryButton isDisabled text={t("admin.collections.tablet.form.button")!} fn={redirectToTablet}/>
          </div>
        </Modal>
      }
      {selectedCollection &&
        <Modal modalTitle={selectedCollection?.name || "admin.collections.form.new_collection"} canClose onClose={() => setSelectedCollection(null)}>
          <CollectionForm
            collection={selectedCollection}
            onCollectionForm={setSelectedCollection}
            actionButton={actionButton(action)}
          />
        </Modal>
      }
      {selectedItem && (
        <Modal modalTitle={selectedItem?.name || "admin.collections.items.form.new_item"} canClose onClose={() => {setSelectedItem(null); setAction('READ');}}>
          <CollectionItemForm
            item={selectedItem}
            onCollectionItemForm={setSelectedItem}
            actionButton={actionItemButton(action)}
            isDisabled={action === 'READ'}
          />
        </Modal>
      )}
    </div>
  );
}

export default MarketingCollectionsSegment;
