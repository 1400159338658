import MarketingCollectionsSection from "../AdminPage/sections/MarketingCollectionsSection";
import PageWrapper from "../PageWrapper/PageWrapper";


function MarketingPage() {
  
  return (
    <PageWrapper>
        <>
          <MarketingCollectionsSection />
        </>
    </PageWrapper>
  );
}

export default MarketingPage;
