import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CataloguesAPI, { Catalog, CatalogItem } from "../../api/CataloguesAPI";
import { BolderText } from "../../components/BolderText/BolderText";
import DressCardDetails from "../../components/DressCardDetails/DressCardDetails";
import DressGrid from "../../components/DressGrid/DressGrid";
import { Icon } from "../../components/Icon/Icon";
import LoginFlow from "../../components/LoginFlow/LoginFlow";
import { Modal } from "../../components/Modal/Modal";
import NoData from "../../components/NoData/NoData";
import SlideOver from "../../components/SlideOver/SlideOver";
import PageWrapper from "../PageWrapper/PageWrapper";
import check from '../../assets/images/check.svg';
import FloatingCTAButton from "../../components/FloatingCTAButton/FloatingCTAButton";
// import { BasketItem } from "../../components/BasketItem/BasketItem";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import Confetti from 'react-dom-confetti';
import * as amplitude from '@amplitude/analytics-browser';
import Banner from "../../components/Banner/Banner";
import CatalogDescription from "../../components/CatalogDescription/CatalogDescription";
import CatalogTitle from "../../components/CatalogTitle/CatalogTitle";
import CatalogSubtitle from "../../components/CatalogSubtitle/CatalogSubtitle";
import { datadogRum } from '@datadog/browser-rum';

function CatalogSingleShowRoomPage() {
  let { someCatalogueId, itemId } = useParams();
  const { t } = useTranslation();
  const [showSelection, setShowSelection] = useState(false);
  const [catalog, setCatalog] = useState<Catalog | null>();
  const [isLoading, setIsLoading] = useState(false);
  const [isUserActionLoading, setIsUserActionLoading] = useState(false);
  const [activeItem, setActiveItem] = useState<CatalogItem | null>();
  const isLoggedIn = useSelector((state: any) => state.settings.value.isLoggedIn);
  // const basketItems = useSelector((state: any) => state.basket.items);
  const [displayLoginSignUpFlow, setDisplayLoginSignUpFlow] = useState(false);
  const [successfullyImported, setSuccessfullyImported] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const navigate = useNavigate();
  const config = { angle: 90, spread: 360, startVelocity: 40, elementCount: 170, dragFriction: 0.12, duration: 10000, stagger: 3, width: "10px", height: "10px", perspective: "500px", colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"] };

  useEffect(() => {
    if (!itemId) {
      setActiveItem(null);
    }
  }, [itemId]);

  useEffect(() => {
    const fetchItems = async () => {
      if (!someCatalogueId) return;
      setIsLoading(true);
      try {
        const catalogResponse = await CataloguesAPI.getCatalog(someCatalogueId);
        setCatalog(catalogResponse.data);
        if (itemId) {
          const item = catalogResponse.data.items.find((item: CatalogItem) => item.uuid === itemId);
          setActiveItem(item);
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    };
  
    fetchItems();
  }, [someCatalogueId, itemId]);

  const handleCardClick = (item: CatalogItem) => {
    setActiveItem(item);
    navigate(`/catalogues/${someCatalogueId}/${item.uuid}`);
  };

  const showSuccessPopup = () => {
    datadogRum.addAction('Catalog Successfully imported');
    amplitude.track('Catalog Successfully imported');
    setSuccessfullyImported(true);
    setTimeout(() => {
      setShowConfetti(true);
    }, 250);
  }

  const closeSuccessPopup = () => {
    setSuccessfullyImported(false);
    setShowConfetti(false);
  }

  const importCatalog = async () => {
    setIsUserActionLoading(true);
    await CataloguesAPI.import(someCatalogueId);
    setIsUserActionLoading(false);
    setDisplayLoginSignUpFlow(false);
    showSuccessPopup();
  }

  const handleImportCatalog = async () => {
    datadogRum.addAction('Import Catalog button clicked');  
    amplitude.track('Import Catalog button clicked');
    if (!isUserActionLoading) {
      if (!isLoggedIn) {
        setDisplayLoginSignUpFlow(true);
      } else {
        importCatalog();
      }
    }
  };

  if (isLoading) return <div>{t("global.loading")}...</div>;
  
  return (
    <PageWrapper>
        <>
          <Banner
            title={t("plans.mission.title")}
            description={t("plans.mission.body")}
            primaryAction={{ label: t("marketplace.catalogues.selection.cta"), onClick: handleImportCatalog }}
            secondaryAction={{ label: t("plans.demo_cta"), onClick: () => window.open('https://calendly.com/eateliers', "_blank") }}
            secondaryText={t("plans.mission.body_secondary")!}
          />
          <br/>
          <CatalogTitle text={catalog?.name || ''} clazz={'text-center'}/>
          <CatalogSubtitle text={catalog?.storeName || ''} clazz={'text-center'}/>
          <br/>
          { activeItem ? <DressCardDetails item={activeItem} backButton={() => navigate(`/catalogues/${someCatalogueId}`)}/> : 
            !someCatalogueId ? (
              <NoData message={t("marketplace.catalogues.not_found")!} />
            ) : (
              <div className="space-y-5">
                {/* <BackButton fn={() => navigate(`/catalogues/showroom`)} text="See More Catalogues" /> */}
                { catalog?.description && <CatalogDescription description={catalog.description} /> }
                <DressGrid items={catalog?.items || []} onCardClick={handleCardClick}/>
              </div>
            )
          }
          {/* { (basketItems.length > 0) && <FloatingCTAButton text={`${t("marketplace.basket.cta")!}`} fn={() => setShowSelection(true)} alignment="right"/>} */}
          { <FloatingCTAButton 
            text={`${t("marketplace.catalogues.selection.cta")!}`} 
            fn={() => setShowSelection(true)} 
            alignment="right"/>
          }
          { displayLoginSignUpFlow && 
            <Modal canClose modalTitle={t("guest.login.form.title")} onClose={() => setDisplayLoginSignUpFlow(false)}>
              <>
                <p>
                  { t("guest.login.form.description") }.
                </p>
                <p><Icon url={check}></Icon>&nbsp; <BolderText text={t("guest.login.form.reason_1")!}/></p>
                <p><Icon url={check}></Icon>&nbsp; <BolderText text={t("guest.login.form.reason_2")!}/></p>
                <p><Icon url={check}></Icon>&nbsp; <BolderText text={t("guest.login.form.reason_3")!}/></p>
                <p><Icon url={check}></Icon>&nbsp; ... { t("guest.login.form.reason_others") } </p>
                <br/>
                <LoginFlow callbackFn={importCatalog}/>
              </>
            </Modal> 
          }
          { successfullyImported && 
            <Modal canClose modalTitle={t("marketplace.import.title")} onClose={closeSuccessPopup}>
                <div className="flex flex-col space-y-5">
                  <p>{t("marketplace.import.body")}</p>
                  <div className="flex justify-center">
                    <Confetti active={showConfetti} config={config} />
                    <p className="text-9xl">🎉</p>
                  </div>
                  <div className="flex justify-center space-x-3">
                    <PrimaryButton 
                      text={t("marketplace.import.cta.order")!} fn={() => navigate('/orders')} 
                      actionName="Go to Orders"
                    />
                    <PrimaryButton 
                      text={t("marketplace.import.cta.catalogues")!} fn={() => navigate('/catalogues')} 
                      actionName="Go to Catalogues"
                    />
                  </div>
                </div>
            </Modal>
          }
          <br/>
          <SlideOver 
            isOpen={showSelection}
            setIsOpen={setShowSelection}
            title={t("marketplace.catalogues.selection.title")!}
            primaryButton={{ label: t("marketplace.catalogues.selection.cta"), onClick: handleImportCatalog}}
            isLoading={isUserActionLoading}
          >
            <>
              {/* { basketItems.map((i: CatalogItem, index: number) => <BasketItem key={i.id} item={i} index={index} isLoading={isUserActionLoading}/>)} */}
              <p>{ t("marketplace.catalogues.selection.notice") }</p>
            </>
          </SlideOver>
        </>
    </PageWrapper>
  );
}

export default CatalogSingleShowRoomPage;
