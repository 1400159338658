import { useTranslation } from "react-i18next";
import Title from "../Title/Title";
import Card from "../Card/Card";
import PageWrapper from "../../pages/PageWrapper/PageWrapper";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import SelectInput from "../SelectInput/SelectInput";
import Tooltip from "../Tooltip/Tooltip";
import Pill from "../Pill/Pill";
import SectionTitle from "../SectionTitle/SectionTitle";
import { useGetCollections } from "../../hooks/useGetCollections";
import { BolderText } from "../BolderText/BolderText";
import { isValidText } from "../../shared/validation";
import { useEffect, useState } from "react";
import { User } from "../../api/UsersAPI";
import useTabletConfig from "../../hooks/useTabletConfig";
import InputShortText from "../InputShortText/InputShortText";

const TabletPage = () => {
  
  const { t } = useTranslation();
  const { config, isLoading, error, updateConfig } = useTabletConfig(); // eslint-disable-line @typescript-eslint/no-unused-vars
  const { isLoadingCollections, collections } = useGetCollections();
  // const { isLoading, } = useGetUsers();
  const [tablet] = useState<User>();
  const [localPin, setLocalPin] = useState('');
  const [localCatalogue, setLocalCatalogue] = useState('');

  useEffect(() => {
    if (config) {
      setLocalPin(config.pin);
      setLocalCatalogue(config?.catalogues && config?.catalogues[0] || ''); // eslint-disable-line no-mixed-operators
    }
  }, [config]);


  const handleSaveConfig = () => {
    const newConfig = { pin: localPin, catalogues: [localCatalogue] };
    updateConfig(newConfig);
  };
  
  return (
    <PageWrapper>
        <div className="space-y-5">
          <span className="flex items-center">
            <Title text={t("menu.tablet")} />&nbsp;
            <Tooltip content={t("global.experimental.explanation")} position="bottom">
              <Pill text={t("global.experimental")} color="red" />
            </Tooltip>
          </span>
          <Card>
            <>
              <SectionTitle text={`1. ${t("tablet.create")}`} />
              <p className="mb-5">{ t("tablet.create.text") }</p>
              { tablet ? <PrimaryButton text={t("tablet.create.remove.button")!} isDisabled={isLoading}/> : <PrimaryButton text={t("tablet.create.add.button")!} isDisabled={isLoading}/> }
            </>
          </Card>
          <Card>
            <>
              <SectionTitle text={`2. ${t("tablet.kiosk_mode")}`} />
              <p>{ t("tablet.kiosk_mode.text") }</p>
              <div className="flex mb-5"><BolderText text={t("tablet.kiosk_mode.selected_catalog")!}/>:&nbsp;<p> { collections[0]?.shortName || 'N/A' }</p></div>
              <div className="flex flex-wrap space-x-5 mb-3">
                <InputShortText 
                  validationFn={(value) => !!value && isValidText(value, 3, 10)} 
                  onChange={e => setLocalPin(e.target.value)}
                  errorMessage={"errors.too_long_short"}
                  label={t("tablet.kiosk_mode.form.pin")!}
                  isLoading={isLoadingCollections}
                  isDisabled={isLoadingCollections || !tablet}
                />
                <SelectInput
                  values={collections.map(c => {return { readableValue: c.shortName || '', value: String(c.id) };})}
                  label={t("tablet.kiosk_mode.form.catalog")!}
                  onChange={e => null}
                  isDisabled={isLoadingCollections || !tablet}
                />
              </div>
              <PrimaryButton text={t("global.save")!} isDisabled={isLoadingCollections || !tablet} fn={() => handleSaveConfig()}/>
            </>
          </Card>
      </div>
    </PageWrapper>
  );
}

export default TabletPage;
