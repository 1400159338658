import axios from 'axios';
import CONFIG from '../config/config';
import { instanceWithV2 as instance } from './SimpleAPIClient';

export type FileUrl = {
  url: string;
};

const FilesAPI = {
  getUrl: (fileName: string, type?: string) => {
    const fName = encodeURIComponent(fileName);
    const queryParams = new URLSearchParams();
    if (type) queryParams.append("type", type);
    return instance(CONFIG.API_ENDPOINT).get<FileUrl>(`/v1/files/orders/signUrl/read/${fName}${!!queryParams.toString() ? `?${queryParams.toString()}` : ''}`)
  },
  getUrlForSharedResource: (xOrderId: number, fileName: string) => {
    const fName = encodeURIComponent(fileName);
    return instance(CONFIG.API_ENDPOINT).get<FileUrl>(`/v1/files/shared/orders/${xOrderId}/signUrl/read/${fName}`)
  },
  getUploadUrl: (fileName: string, type: string = "orders") => {
    return instance(CONFIG.API_ENDPOINT).get<FileUrl>(`/v1/files/${type}/signUrl/upload`, {
      headers: {
        'Filename': fileName,
      },
    })
  },
  upload: (resourceApi: string, filename: string, file: FormData) => {
    return instance(CONFIG.API_ENDPOINT).post<FileUrl>(resourceApi, file, {
      headers: {
        'Filename': filename
      },
    })
  },
  uploadHeavyFile: (uploadUrl: string, file: File, fileName: string) => {
    return axios.put(uploadUrl, file
      , {
      headers: {
        'Filename': fileName,
        'Content-Type': file.type,
      },
      }
    )
  },
};

export default FilesAPI;
