import React, { useState, useRef, useEffect, ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PermissionFlags } from '../PermissionFlags/PermissionFlags';

interface SubmenuItem {
  text: string;
  href?: string;
  onClick?: () => void;
  isDisabled?: boolean;
  flags?: string[];
  icon?: ReactNode;
  name?: string;
}

interface MenuItem {
  text: string;
  href: string;
  name?: string;
  isDisabled?: boolean;
  flags?: string[];
  submenuItems?: SubmenuItem[];
  icon?: ReactNode;
}

interface Props {
  menuItems: MenuItem[];
}

export const ResponsiveMenu: React.FC<Props> = ({ menuItems }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [submenuIndex, setSubmenuIndex] = useState<number | null>(null);
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
  const location = useLocation();
  
  const isActive = (href: string) => {
    return location.pathname === href;
  }
  
  const submenuRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  const handleMenuButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmenuClick = (index: number) => {
    if (!menuItems[index].isDisabled) {
      setSubmenuIndex(submenuIndex === index ? null : index);
    }
  };

  const handleExpandClick = (index: number) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (submenuRef.current && !submenuRef.current.contains(event.target as Node)) {
      setSubmenuIndex(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="md:hidden flex items-center relative" ref={submenuRef}>
      <nav className="hidden md:flex">
        {menuItems.map((item, index) => (
          <PermissionFlags requiredFlags={item.flags} key={index}>
            <div className="relative">
            { item.submenuItems ? (
              <button
                onClick={() => handleSubmenuClick(index)}
                className={`px-4 py-1 border-b-2 border-transparent transition-colors duration-300 ${
                  item.isDisabled
                    ? 'cursor-not-allowed pointer-events-none text-gray-400'
                    : `hover:text-darker-gold cursor-pointer text-gray-700 hover-underline-animation ${
                      isActive(item.href) ? 'text-custom-orange font-bold' : ''
                    }`
                }`}
              >
                {item.icon }
                {item.text}
              </button>)
              : (<button
                    onClick={() => handleNavigation(item.href!)}
                    className={`w-full px-4 border-b-2 border-transparent py-1 transition-colors duration-300 ${
                      item.isDisabled
                        ? 'cursor-not-allowed pointer-events-none text-gray-400'
                        : `hover:text-darker-gold cursor-pointer text-gray-700 hover-underline-animation ${
                          isActive(item.href) ? 'text-custom-orange font-bold' : ''
                        }`
                    }`}
                  >
                    { item.icon }
                    {item.text}
                  </button> )}
              {submenuIndex === index && item.submenuItems && (
                <div className="absolute left-0 mt-1 bg-white border border-gray-200 shadow-md">
                  {item.submenuItems.map((subItem, subIndex) => (
                    <PermissionFlags requiredFlags={subItem.flags} key={subIndex}>
                      {subItem.href ? (
                        <button
                            onClick={() => handleNavigation(subItem.href!)}
                            className={`w-full px-4 py-1 border-b-2 border-transparent transition-colors duration-300 ${
                              subItem.isDisabled
                                ? 'cursor-not-allowed pointer-events-none text-gray-400'
                                : `hover:text-darker-gold cursor-pointer text-gray-700 hover-underline-animation ${
                                  isActive(subItem.href) ? 'text-custom-orange font-bold' : ''
                                }`
                            }`}
                          >
                            {subItem.icon}
                            {subItem.text}
                          </button>
                      ) : (
                        <button
                          onClick={subItem.onClick}
                          disabled={subItem.isDisabled}
                          className={`block px-4 py-1 border-b-2 border-transparent transition-colors duration-300 ${
                            subItem.isDisabled
                              ? 'text-gray-400 cursor-not-allowed pointer-events-none'
                              : `text-gray-700 hover:text-darker-gold cursor-pointer hover-underline-animation ${
                                isActive(item.href) ? 'text-custom-orange font-bold' : ''
                              }`
                          }`}
                        >
                          <div className="flex items-center">
                            {subItem.icon} &nbsp;
                            <span>{subItem.text}</span>
                          </div>
                        </button>
                      )}
                    </PermissionFlags>
                  ))}
                </div>
              )}
            </div>
          </PermissionFlags>
        ))}
      </nav>
      <button
        onClick={handleMenuButtonClick}
        className="md:hidden focus:outline-none text-gray-700 px-4 py-2"
      >
        {isOpen ? 'Close' : 'Menu'}
      </button>
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 md:hidden" onClick={handleMenuButtonClick} />
      )}
      {isOpen && (
        <nav className="fixed inset-0 flex flex-col items-center justify-center bg-white z-10 md:hidden">
          <button
            onClick={handleMenuButtonClick}
            className="absolute top-4 right-4 focus:outline-none text-gray-700">
            X
          </button>
          {menuItems.map((item, index) => (
            <PermissionFlags requiredFlags={item.flags} key={index}>
              <div className="w-full text-center">
                {item.submenuItems ? (
                  <button
                    onClick={() => handleExpandClick(index)}
                    className={`w-full text-center text-gray-700 px-4 py-2 transition-colors duration-300 ${
                      item.isDisabled
                        ? 'cursor-not-allowed pointer-events-none text-gray-400'
                        : `hover:text-darker-gold cursor-pointer ${
                          isActive(item.href) ? 'text-custom-orange font-bold' : ''
                        }`
                    }`}
                  >
                    {item.text}
                  </button>
                ) : (
                  <button
                    onClick={() => handleNavigation(item.href)}
                    className={`w-full px-4 py-1 transition-colors duration-300 ${
                      item.isDisabled
                        ? 'cursor-not-allowed pointer-events-none text-gray-400'
                        : `hover:text-darker-gold cursor-pointer text-gray-700 ${
                          isActive(item.href) ? 'text-custom-orange font-bold' : ''
                        }`
                    }`}
                  >
                    {item.text}
                  </button>
                )}
                {expandedIndex === index &&
                  item.submenuItems &&
                  item.submenuItems.map((subItem, subIndex) => (
                    <PermissionFlags key={subIndex} requiredFlags={subItem.flags}>
                      <button
                        onClick={() => handleNavigation(subItem.href!)}
                        className={`w-full px-4 py-1 transition-colors duration-300 ${
                          subItem.isDisabled
                            ? 'cursor-not-allowed pointer-events-none text-gray-400'
                            : `hover:text-darker-gold cursor-pointer text-gray-700 ${
                              isActive(subItem.href!) ? 'text-custom-orange font-bold' : ''
                            }`
                        }`}
                      >
                        {subItem.text}
                      </button>
                    </PermissionFlags>
                  ))}
              </div>
            </PermissionFlags>
          ))}
        </nav>
      )}
    </div>
  );
};

