import FilesAPI from "../../api/FilesAPI";

type Props = {
    file: string,
    orderId: number,
    text?: string
}

function extractFileName(url: string) {
    if (!url) return '';
    const fileName = url.split('/').pop();

    return fileName ? decodeURI(fileName) : '';
}

export function PrivateSharedResourceLink({ file, orderId, text = '' }: Props) {

    const openFile = async (fileName: string) => {
        const url = await FilesAPI.getUrlForSharedResource(orderId, fileName);
        window.open(url.data.url, "_blank")?.focus();
      }

    return (
        <span onClick={() => openFile(extractFileName(file))} className="font-mono text-xs text-bolder hover:opacity-50">
            { text ? text : extractFileName(file) || '-'}
        </span>
    );
}