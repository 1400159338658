import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useGetProviders from '../../hooks/useGetProviders';
import useGetReferences from '../../hooks/useGetReferences';
import { Autocomplete } from '../Autocomplete/Autocomplete';
import ConfirmationPopup from '../ConfirmationPopup/ConfirmationPopup';
import ElementActions from '../ElementActions/ElementActions';
import { Icon } from '../Icon/Icon';
import IconButton from '../IconButton/IconButton';
import ListContainer from '../ListContainer/ListContainer';
import ListElement from '../ListElement/ListElement';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import ProviderElement from '../ProviderElement/ProviderElement';
import ReferenceElement from '../ReferenceElement/ReferenceElement';
import deleteIcon from "../../assets/images/bin.svg";
import edit from "../../assets/images/pen.svg";
import add from "../../assets/images/plus.svg";
import { Provider, Reference } from '../../api/ProvidersAPI';
import ProviderForm from '../ProviderForm/ProviderForm';
import { FormType } from '../../shared/form.type';
import { Modal } from '../Modal/Modal';
import ReferenceForm from '../ReferenceForm/ReferenceForm';
import Card from '../Card/Card';
import { PermissionFlags } from '../PermissionFlags/PermissionFlags';
import SimpleListElement from '../SimpleListElement/SimpleListElement';
import NoDataCTA from '../NoDataCTA/NoDataCTA';
import PageWrapper from '../../pages/PageWrapper/PageWrapper';


const EmptyProvider: Provider = {
  name: '',
  email: '',
  phone: '',
  references: []
};

const EmptyReference: Reference = {
  name: '',
  referenceId: '',
  providerId: -1,
  tags: [],
  pictureUrl: null,
  type: 'OTHER',
  notes: ''
};

type SearchMode = 'provider' | 'references';

function ProvidersSegment() {
  const { t } = useTranslation();
  const { isLoadingProviders, providers, searchProviders, addFn, removeFn, updateFn, refresh} = useGetProviders();
  const { isLoadingReferences, searchReferences, addReference, updateReference, removeReference } = useGetReferences();
  const [expandedProviderId, setExpandedProviderId] = useState<number | null>(null);
  const [provider, setProvider] = useState<Provider>(EmptyProvider);
  const [reference, setReference] = useState<Reference>(EmptyReference);
  const [showProviderForm, setShowProviderForm] = useState(false);
  const [showReferenceForm, setShowReferenceForm] = useState(false);
  const [searchMode, ] = useState<SearchMode>('references');
  const [action, setAction] = useState<FormType>('READ');

  const actionItemButton = (action: FormType) => {
    switch (action) {
      case 'CREATE':
        return <span className="flex justify-center"><PrimaryButton text={t("admin.collections.items.form.add")!} fn={processNewProvider} /></span>;
      case 'UPDATE':
        return <span className="flex justify-center"><PrimaryButton text={t("admin.collections.items.form.update")!} fn={processUpdateProvider} /></span>;
      default:
        return;
    }
  };

  const actionReferenceButton = (action: FormType) => {
    if (isLoadingReferences) {
      return <span className="flex justify-center"><PrimaryButton text="..." isLoading/></span>
    }
    switch (action) {
      case 'CREATE':
        return <span className="flex justify-center"><PrimaryButton text={t("admin.collections.items.form.add")!} fn={processNewReference} /></span>;
      case 'UPDATE':
        return <span className="flex justify-center"><PrimaryButton text={t("admin.collections.items.form.update")!} fn={processUpdateReference} /></span>;
      default:
        return;
    }
  };

  const toggleExpansion = (providerId: number) => {
    if (expandedProviderId === providerId) {
      setExpandedProviderId(null);
    } else {
      setExpandedProviderId(providerId);
    }
  };  

  const search = async (input: string, resultsSize: number, setResultsFn: any) => {
    let items = []
    try {
      if (searchMode === 'provider') {
        items = await searchProviders(input);
      } else {
        items = await searchReferences(input);
      }
      const filteredItems = items.slice(0, resultsSize);
      setResultsFn(filteredItems);
    } catch (err) {
      console.error('Could not search collections');
    }
  };

  async function processItem<T>(
    actionFn: (item: T) => Promise<void>,
    item: T,
    setShowForm: React.Dispatch<React.SetStateAction<boolean>>
  ) {
    await actionFn(item);
    setShowForm(false);
    setAction('READ');
    refresh();
  }
  
  const processNewProvider = () => processItem(addFn, provider, setShowProviderForm);
  const processNewReference = () => processItem(addReference, reference, setShowReferenceForm);
  const processUpdateProvider = () => processItem(updateFn, provider, setShowProviderForm);
  const processUpdateReference = () => processItem(updateReference, reference, setShowReferenceForm);
  

  const processRemoveProvider = async (provider: Provider) => {
    await removeFn(provider);
  }

  const processRemoveReference = async (reference: Reference) => {
    await removeReference(reference);
    refresh();
  }

  function handleEdit<T>(
    action: FormType,
    setShowForm: React.Dispatch<React.SetStateAction<boolean>>,
    setItem: React.Dispatch<React.SetStateAction<T>>,
    item: T
  ) {
    setAction(action);
    setShowForm(true);
    setItem(item);
  }

  const handleAddProvider = (provider: Provider) => handleEdit('CREATE', setShowProviderForm, setProvider, provider);
  const handleAddReference = (provider: Provider) => handleEdit('CREATE', setShowReferenceForm, setReference, { ...EmptyReference, providerId: provider.id! });
  const handleEditProvider = (provider: Provider) => handleEdit('UPDATE', setShowProviderForm, setProvider, provider);
  const handleEditReference = (reference: Reference) => handleEdit('UPDATE', setShowReferenceForm, setReference, reference);  
  const handleReadReference = (reference: Reference) => handleEdit('READ', setShowReferenceForm, setReference, reference);  

  const onSelect = (item: Provider | Reference) => {
    if (searchMode === 'provider') {
      handleEditProvider(item as Provider);
    } else {
      handleEditReference(item as Reference);
    }
  };  

  return (
    <PageWrapper>
      <div className="space-y-5">
        <Card>
          <>
            <div className="container flex flex-wrap space-x-5">
              {/* <Tooltip content={t("providers.search.mode")}>
                <PrimaryButton 
                  text={searchMode === 'provider' ? t('providers.search.mode.references')! : t('providers.search.mode.providers')!} 
                  fn={() => setSearchMode(sm => sm === 'provider' ? 'references' : 'provider')} 
                  />
              </Tooltip> */}
              <Autocomplete
                resultsSize={5}
                searchFn={search}
                onSelect={onSelect}
                placeholder={searchMode === 'provider' ? t('providers.search.placeholder.providers')! : t('providers.search.placeholder.references')!}
                isLoading={searchMode === 'provider' ? isLoadingProviders : isLoadingReferences}
                errorMessage={t('global.empty_results')!}
                toElementFn={(item: Reference | Provider) => 
                      <SimpleListElement onClick={() => onSelect(item)} key={item.id} actions={
                          <>
                            { 
                              searchMode === 'provider' ?
                                <PermissionFlags requiredFlags={['update-providers'] || []}>
                                  <>
                                    <IconButton onClick={() => handleAddReference(item as Provider)}>
                                      <Icon url={add} cssStyles="w-5" />
                                    </IconButton>
                                    <IconButton onClick={() => handleEditProvider(item as Provider)}>
                                      <Icon url={edit} cssStyles="w-5"/>
                                    </IconButton>
                                  </>
                                </PermissionFlags> : 
                              <PermissionFlags requiredFlags={['update-providers'] || []}>
                                  <IconButton onClick={() => handleEditReference(item as Reference)}>
                                    <Icon url={edit} cssStyles="w-5"/>
                                  </IconButton>
                              </PermissionFlags>
                            }
                          </>
                        }>
                          {/* TODO: it's a hack for now */}
                          { 'referenceId' in item ? 
                            <ReferenceElement reference={item}/> : 
                            <div className="flex space-x-5 justify-between">
                              <p>{ item.name }</p>
                            </div>
                          }
                      </SimpleListElement>
                }
              />
            </div>
            <div className="container flex flex-wrap space-x-5 my-3">
              <PermissionFlags requiredFlags={['create-providers'] || []}>
                <PrimaryButton text={t("providers.new_provider.button")!} fn={() => handleAddProvider(EmptyProvider)} />
              </PermissionFlags>
            </div>
          </>
        </Card>
        <Card>
          <>
            <ListContainer
              isLoading={isLoadingProviders}
              elements={providers}
              emptyListComponent={
                <NoDataCTA
                  headline={t("providers.empty.cta.title")}
                  subtext={t("providers.empty.cta.body")}
                  primaryActionText={t("providers.new_provider.button")}
                  onPrimaryAction={() => handleAddProvider(EmptyProvider)}
                />
              }
              toElementFn={(provider) => (
                <ListElement key={provider.id} css="bg-white text-black" onClick={() => toggleExpansion(provider.id)}>
                  <div className="flex flex-col w-full">
                      <ProviderElement provider={provider} actions={
                        <>
                          <PermissionFlags requiredFlags={['update-providers'] || []}>
                            <>
                              <IconButton onClick={() => handleAddReference(provider)}>
                                <Icon url={add} cssStyles="w-5" />
                              </IconButton>
                              <IconButton onClick={() => handleEditProvider(provider)}>
                                <Icon url={edit} cssStyles="w-5"/>
                              </IconButton>
                            </>
                          </PermissionFlags>
                          <PermissionFlags requiredFlags={['remove-providers'] || []}>
                            <IconButton>
                              <ConfirmationPopup onConfirm={() => processRemoveProvider(provider)} confirmText={"global.confirm"} title={`${t("global.remove")}: ${provider.name}`}>
                                <Icon url={deleteIcon} cssStyles="w-5"/>
                              </ConfirmationPopup>
                            </IconButton>
                          </PermissionFlags>
                        </>
                      }
                      />
                    { !!expandedProviderId && expandedProviderId === provider.id ?
                      <ListContainer
                      isLoading={isLoadingReferences}
                      elements={provider.references}
                      toElementFn={(reference: Reference) => (
                          <ListElement 
                            key={reference.id} 
                            css="w-full flex duration-500 hover:bg-darker-gold-100"
                            onClick={(e) => { e.stopPropagation(); handleReadReference(reference); }}
                            >
                            <>
                              <ElementActions actions={
                                <>
                                  <PermissionFlags requiredFlags={['update-providers'] || []}>
                                      <>
                                        <IconButton onClick={() => handleEditReference(reference)}>
                                          <Icon url={edit} cssStyles="w-5"/>
                                        </IconButton>
                                        <IconButton>
                                          <ConfirmationPopup onConfirm={() => processRemoveReference(reference)} confirmText={"global.confirm"} title={`${t("global.remove")}: ${reference.name}`}>
                                            <Icon url={deleteIcon} cssStyles="w-5"/>
                                          </ConfirmationPopup>
                                        </IconButton>
                                      </>
                                  </PermissionFlags>
                                </>
                              }>
                                <ReferenceElement reference={reference} />
                              </ElementActions>
                            </>
                          </ListElement>
                      )}
                      /> 
                      : null }
                  </div>
                </ListElement>
              )}
            />
          </>
        </Card>
        { showProviderForm && (
          <Modal modalTitle={provider?.name || "providers.form.new_provider"} canClose onClose={() => setShowProviderForm(false)}>
            <ProviderForm
              provider={provider}
              onProviderFormSubmit={setProvider}
              actionButton={actionItemButton(action)}
              isDisabled={action === 'READ' || isLoadingProviders}
            />
          </Modal>
        )}
        { showReferenceForm && (
          <Modal
            modalTitle={
              reference?.name || "references.form.new_reference"
            }
            canClose
            onClose={() => setShowReferenceForm(false)}
          >
            <ReferenceForm
              reference={reference}
              onReferenceFormSubmit={setReference}
              actionButton={actionReferenceButton(action)}
              isDisabled={action === 'READ' || isLoadingReferences}
            />
          </Modal>
        )}
      </div>
    </PageWrapper>
  );
}

export default ProvidersSegment;
