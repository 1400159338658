export type ORDER_STATUS = 'WAITING' | 'CHECKED' | 'TRANSLATION' | 'CUT' | 'DECORATION' | 'CONNECTED' | 'READY_TO_SEND' | 'SENT' | 'FIRST_MEASUREMENT' | 'PICKED_UP' | 'BLOCKED';

export function getStatusesRaw(): ORDER_STATUS[] {
  return ['WAITING', 'CHECKED', 'TRANSLATION', 'CUT', 'DECORATION', 'CONNECTED', 'READY_TO_SEND', 'SENT', 'FIRST_MEASUREMENT', 'PICKED_UP', 'BLOCKED'];
}

export function orderStatusToTranslationKeys(status: string | undefined): string {
  switch (status) {
    case 'WAITING':
      return "orders.status.waiting";
    case 'CHECKED':
        return "orders.status.checked";
    case 'TRANSLATION':
        return "orders.status.translation";
    case 'CUT':
        return "orders.status.cut";
    case 'DECORATION':
        return "orders.status.decoration";
    case 'CONNECTED':
        return "orders.status.connected";
    case 'READY_TO_SEND':
        return "orders.status.ready_to_send";
    case 'SENT':
        return "orders.status.sent";
    case 'FIRST_MEASUREMENT':
      return "orders.status.first_measurement";
    case 'PICKED_UP':
      return "orders.status.picked_up";
    case 'BLOCKED':
      return "orders.status.blocked";
    default:
      return "global.unknown";
  }
}