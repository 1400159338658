export function itemTypeToIcon(type: string | undefined): string {
  if (type === 'dresses') {
    return '👗';
  }
  if (type === 'shoes') {
    return '👠';
  }
  if (type === 'veils') {
    return '👰';
  }
  return '❓';
}

export function itemTypeToTranslationKey(type: string | undefined): string {
  
  if (type === 'dresses') {
    return "orders.type.dress";
  }
  if (type === 'shoes') {
    return "orders.type.shoes";
  }
  if (type === "veils") {
    return "orders.type.veils";
  }
  return "global.unknown";
}