import React from 'react';
import { OrderStep } from '../../api/ShopClientsAPI';
import OrderWorkStep from '../OrderWorkStep/OrderWorkStep';

type ActivityFeedProps = {
  steps: OrderStep[];
  loading: boolean;
};

const OrderActivityFeed: React.FC<ActivityFeedProps> = ({ steps, loading }) => {
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="relative border-l border-gray-200">
    {steps.map((step, index) => (
      <div key={index} className="mb-5 ml-4">
        <span className="flex absolute -left-1.5 justify-center items-center w-3 h-3 bg-darker-gold-300 rounded-full ring-3 ring-white">
        </span>
        <div className="flex items-start space-x-4">
            <OrderWorkStep step={step} />
        </div>
      </div>
    ))}
  </div>
  );
};

export default OrderActivityFeed;
