import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export type ValueOption = {
  value: string,
  readableValue: string
}

type Props = {
  values?: ValueOption[],
  label?: string,
  onChange: (input: React.ChangeEvent<HTMLSelectElement>) => void,
  isDisabled?: boolean,
  defaultValue?: string
};

function SelectInput({ values = [], label = '', isDisabled = false, onChange = () => null, defaultValue = '' }: Props) {

  const { t } = useTranslation();
  const defaultOption = useMemo(() => {
    if (!!defaultValue) {
      return values.filter(v => v.value?.toLocaleLowerCase() === defaultValue.toLocaleLowerCase())[0];
    }
  }, [values, defaultValue]);

  return (
    <div className="flex-1 max-w-full">
      { !!label && <p className="text-sm font-extrabold">{ t(label) }:</p> }
      <select 
        required 
        data-dd-action-name={'Dropdown Select'}
        disabled={isDisabled} 
        onChange={(ev) => onChange(ev)} 
        className={`px-1 pt-3 pb-3 w-full border-stone-200 border rounded focus:outline-none focus:ring-0 ${
            isDisabled ? 'bg-stone-100' : ''
        }`}
         value={defaultOption?.value}>
        <option hidden value="">{t("global.null")}</option>
        { values.map((m, i) => 
          <option value={m.value.toString()}  key={i} data-dd-action-name={'Dropdown Select Option'}>
            { t(m.readableValue) }
          </option>
        )}
      </select>
    </div>
  );
}

export default SelectInput;
