import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import EmployeesAPI, { Employee } from "../../api/EmployeesAPI";
import { Order } from "../../api/OrdersAPI";
import ShopClientsAPI, { NewOrderStep, OrderStep } from "../../api/ShopClientsAPI";
import CONFIG from "../../config/config";
import { useGetOrders } from "../../hooks/useGetOrders";
import { toastService } from "../../services/ToastService";
import { orderStatusToTranslationKeys, ORDER_STATUS } from "../../shared/orderStatusToTranslationKey";
import { PermissionFlags } from "../PermissionFlags/PermissionFlags";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import SectionTitle from "../SectionTitle/SectionTitle";
import SelectInput from "../SelectInput/SelectInput";
import NoDataCTA from "../NoDataCTA/NoDataCTA";
import TextArea from "../TextArea/TextArea";
import { isValidText } from "../../shared/validation";
import { AdminRestricted } from "../AdminRestricted/AdminRestricted";
import { useNavigate } from "react-router-dom";
import OrderActivityFeed from "../OrderActivityFeed/OrderActivityFeed";
import ListContainer from "../ListContainer/ListContainer";

type Props = {
  shopId: string,
  orderId: number,
  isDisabled: boolean,
  order: Order | null,
  onOrderChange: (status: string) => void,
};

function OrderTrackingForm({ shopId = '', orderId = 0, isDisabled = false, order, onOrderChange }: Props) {

  const { t } = useTranslation();

  const navigate = useNavigate();
  const { updateOrderStatus } = useGetOrders({});
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [steps, setSteps] = useState<OrderStep[]>([]);
  const [isLoadingStory, setLoadingStory] = useState(true);
  const [draftStep, setDraftStep] = useState<NewOrderStep>({ status: 'CHECKED', workerName: ''})

  const load = useCallback(async () => {
    const employeesResp = await EmployeesAPI.get(true);
    if (!!orderId) {
      setLoadingStory(true);
      const storyResp = await ShopClientsAPI.getStory(shopId, orderId);
      setLoadingStory(false);
      setSteps(storyResp.data);
    }
    setEmployees(employeesResp.data);
  }, [orderId, shopId]);

  const redirectToEmployees = () => {
    navigate('/employees');
  };  

  useEffect(() => {
    load();
  }, [load]);

  //
  const saveStep = async () => {
    if (!!orderId && !!order) {
      setLoadingStory(true);
      try {
        await ShopClientsAPI.addStoryStep(shopId, orderId, draftStep!);
        order.status = draftStep!.status;
        onOrderChange(draftStep!.status);
        updateOrderStatus(order);
        setDraftStep({ status: 'CHECKED', workerName: ''});
      } catch (error) {
        toastService.showToast(t("orders.story.add_step.error"), 'error');
      }
      load();
      setLoadingStory(false);
    }
  }

  return ( 
    <div className="flex-1 h-full space-y-1">
      <SectionTitle text={t("orders.orders_basket.add_form.order_story")} />
      <PermissionFlags requiredFlags={['order-story-write'] || []}>
        { isLoadingStory ? 
          <ListContainer isLoading={isLoadingStory} toElementFn={() => <></>}/> 
          : 
          employees && employees.length > 0 ? 
          <>
            <SelectInput values={CONFIG.ORDERS.STATUS.map(e => { return { value: e, readableValue: t(orderStatusToTranslationKeys(e))}})}
              label={"orders.story.status"}
              onChange={e => setDraftStep(prev => ({
                ...prev,
                status: e.target.value as ORDER_STATUS || CONFIG.ORDERS.DEFAULT_STATUS,
              }))} 
              isDisabled={isDisabled}
            />
            <SelectInput values={employees.map(e => e.name).map(e => { return { value: e, readableValue: e}})}
              onChange={e => {
                const employee = employees.find(emp => emp.name === e.target.value);
                setDraftStep(prev => ({
                ...prev,
                workerName: employee?.name || "",
                workerId: String(employee?.id!)
              }))}}
              label={"orders.story.name"}
              isDisabled={isDisabled}
            />
            <TextArea
                validationFn={value => !!value && isValidText(value, 2, 200)}
                label={"orders.story.comment"}
                errorMessage={"errors.too_long_short"}
                onChange={e => setDraftStep(prev => ({
                  ...prev,
                  comment: e.target.value,
                }))}
                isDisabled={isDisabled || (!draftStep.status && !draftStep.workerName)}
            />
            <PrimaryButton text={t("orders.story.add_step")!} isDisabled={isDisabled || !draftStep || !draftStep.status || !draftStep.workerName} fn={saveStep} />
          </> 
        :
        <AdminRestricted>
          <NoDataCTA
            headline={t("orders.story.empty.cta.title")}
            subtext={t("orders.story.empty.cta.body")}
            primaryActionText={t("orders.story.empty.cta.link")}
            onPrimaryAction={redirectToEmployees}
          /> 
        </AdminRestricted>
      }
      </PermissionFlags>
      <PermissionFlags requiredFlags={['order-story-read'] || []}>
        <div className="max-h-80 px-3 pt-5 overflow-y-auto space-y-1">
          <OrderActivityFeed steps={steps} loading={false}/>
        </div>
      </PermissionFlags>
    </div> 
  );
}

export default OrderTrackingForm;
