type Props = {
  text: string,
  fn?: (a?: any) => void,
  isActive?: boolean
};

function FilterPill({ text, fn, isActive = false }: Props ) {
  return (
    <span 
    className={`py-3 px-5 rounded uppercase font-light text-xs whitespace-nowrap hover:cursor-pointer duration-500 hover:bg-darker-gold-100 ${isActive ? `bg-darker-gold-200` : `bg-transparent`}`} 
    onClick={fn}>
      {text}
    </span>
  );
}

export default FilterPill;
